import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import DataGrid from "../../components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import {
  BLOCK_EMPLOYEE,
  EMPLOYEES,
  EMPLOYEES_PERMISSIONS,
  JOBS,
  JOB_PERMISSIONS,
  USER_PERMISSIONS,
} from "../../data/APIs";
import { useState } from "react";
import format from "../../utils/ISOToReadable";
import useDataGrid from "../../hooks/useDataGrid";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogHeading,
  DialogForm,
  DialogInputField,
  DialogContent,
  DialogPhoneField,
} from "../../features/dialog";
import usePropState from "../../hooks/usePropState";
import useControls from "../../hooks/useControls";
import DialogSelectField from "../../features/dialog/components/DialogSelectField";
import { Avatar, InputAdornment, MenuItem, TextField } from "@mui/material";
import useAfterEffect from "../../hooks/useAfterEffect";
import { InputField, SelectField } from "../../features/form";
import compare from "../../utils/Compare";
import PermissionToggles from "../../components/PermissionToggles";
import filter from "../../utils/ClearNull";
import _ from "lodash";
import { useRef } from "react";
import { Stack } from "@mui/system";
import Breadcrumbs from "../../components/Breadcrumbs";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

const ViewEmployees = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewEmployeesLang = useSelector(
    (state) => state.lang.value.viewEmployees
  );
  const globalLang = useSelector((state) => state.lang.value.global);
const confirmMessage = useSelector(
  (state) => state.lang.value.DeleteMessages.EmployeeMessage
);

  ///////////////////////////////
  const employeesStore = useSelector((state) => state.employees.value);
 
   
  const dispatch = useDispatch();

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const handleGetEmployees = (urlParams) => {
    employeesGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "employees/set", payload: res.data });
      },
    });
  };

  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: handleGetEmployees,
  });

  const [employeeDeteleRequest, employeeDeleteResponse] = useRequest({
    path: EMPLOYEES,
    method: "delete",
    successMessage: "تم حذف الموظف بنجاح",
  });

  // const [handlePreventDeleteEmployee, deleteEmployeeAlertDialog] =
  //   useConfirmMessage({
  //     title: "تحذير",
  //     text: "لا يمكن حذف هذا الموظف بسبب متابعته لبعض العملاء ، الرجاء نقل هؤلاء العملاء اولاً",
  //     variant: "alert",
  //   });

  const deleteEmployee = (e, row) => {
    // if (Boolean(row.client_count)) {
    //   handlePreventDeleteEmployee();
    //   return;
    // }
    employeeDeteleRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "employees/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteEmployee, deleteEmployeeConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteEmployee,
      text: confirmMessage[lang],
    }
  );

  const [openEditInfo, setOpenEditInfo] = useState(null);

  const handleOpenEdit = (e, row) => {
    setOpenEditInfo(row);
  };

  const [blockEmployeePostRequest] = useRequest({
    path: BLOCK_EMPLOYEE,
    method: "post",
  });

  const handleBlockEmployee = (e, row) => {
    blockEmployeePostRequest({
      body: {
        agent: row.id,
      },
      onSuccess: (res) => {
        dispatch({ type: "employees/blockItem", payload: { id: row.id } });
      },
    });
  };

  const [openEditPassword, setOpenEditPassword] = useState(null);

  const userInfo = useSelector((state) => state.userInfo.value);

  const isPermitted = useIsPermitted();

  // Filters

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.job[lang],
      component: <JobFilter />,
    },
    {
      name: globalLang.phone[lang],
      component: <PhoneFilter />,
    },
    {
      name: globalLang.DateAdded[lang],
      component: <DateFilter />,
    },
  ];

  /////////////////

  // Columns name
  let columns = [
    {
      field: "avatar",
      headerName: viewEmployeesLang.avatar[lang],
      customContent: (params) => <Avatar src={params.user.image} />,
    },
    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: (params) =>
        params.user.first_name
          ? `${params.user.first_name} ${params.user.last_name}`
          : "-",
    },

    {
      field: "username",
      headerName: globalLang.username[lang],
      customContent: (params) =>
        params.user.username ? params.user.username : "-",
    },
    {
      field: "phone",
      headerName: globalLang.phone[lang],
      customContent: (params) =>
        `${params.user.country_code}${params.user.phone}`,
    },

    // {
    //   field: "clients",
    //   headerName: viewEmployeesLang.employeesNumbers[lang],
    //   customContent: (params) => params.client_count,
    //   customEmpty: "0",
    // },
    // {
    //   field: "newClients",
    //   headerName: viewEmployeesLang.newEmployeesNumbers[lang],
    //   customContent: (params) => params.new_client_count,
    //   customEmpty: "0",
    // },
    {
      field: "job",
      headerName: globalLang.job[lang],
      customContent: (params) => (params.job?.title ? params.job?.title : "-"),
    },
    {
      field: "salary",
      headerName: viewEmployeesLang.salary[lang],
      customContent: (params) => params.salary,
      customEmpty: "0",
    },
    {
      field: "incentives",
      headerName: viewEmployeesLang.incentives[lang],
      customContent: (params) => params.hwafez,
      customEmpty: "0",
    },

    // {
    //   field: "date",
    //   headerName: viewEmployeesLang.createdAt[lang],
    //   customContent: (params) =>
    //     params.created_at ? format(params.created_at) : "-",
    // },
  ];
//   useEffect(()=>{
// console.log(hiddencolumns)
// console.log(columns)
// hiddencolumns.map((hidden)=>{
//   const indexOfcolumn=columns.findIndex(obj=>obj.field===hidden)
//   columns.splice(indexOfcolumn,1)

// })
//   },[hiddencolumns])
  //////////////////////

  return (
    <Wrapper>
      <Breadcrumbs
        path={[
          globalLang.Employees[lang],
          viewEmployeesLang.viewEmployees[lang],
        ]}
      />
      <DataGrid

        columns={columns}
        rows={employeesStore.results.filter(
          (employee) => employee.user.id !== userInfo.id
        )}
        total={employeesStore.count}
        isPending={employeesGetResponse.isPending}
        onDelete={isPermitted(handleDeleteEmployee, ["delete_factoryemployee"])}
        onBlock={handleBlockEmployee}
        onChangePassword={(e, row) => setOpenEditPassword(row.id)}
        onEdit={isPermitted(handleOpenEdit, ["change_factoryemployee"])}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
      />
      <EditInfoDialog
        open={Boolean(openEditInfo)}
        onClose={() => setOpenEditInfo(null)}
        data={openEditInfo}
      />
      <EditPasswordDialog
        open={Boolean(openEditPassword)}
        onClose={() => setOpenEditPassword(null)}
        id={openEditPassword}
      />
      {deleteEmployeeConfirmDialog}

      {employeeDeleteResponse.successAlert}
      {employeeDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewEmployees;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const JobFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [jobs, setJobs] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["job", e.target.value],
      renderedValue: jobs.find((job) => job.id === e.target.value).title,
      value: e.target.value,
    });
  };

  const [jobGetRequest, jobGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });

  const getJobs = () => {
    jobGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setJobs(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.job[lang]}
      renderValue={(selected) => jobs.find((job) => job.id === selected).title}
      onOpen={getJobs}
      isPending={jobGetResponse.isPending}
      onChange={handleChange}
    >
      {jobs.map((job, index) => (
        <MenuItem value={job.id} key={`employeeFilterJob ${index}`}>
          {job.title}
        </MenuItem>
      ))}
    </SelectField>
  );
};
const PhoneFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

 

  const handleChange = (e) => {
    onChange({
      query: ["phone", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };




  return (
    <InputField
      placeholder={globalLang.phone[lang]}
      value={value}
      onChange={handleChange}
  />

  );
};

const EditInfoDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
const viewEmployeesLang = useSelector(
  (state) => state.lang.value.viewEmployees
);
  ///////////////////////////////
  const userInfo = useSelector((state) => state.userInfo.value);

  const dispatch = useDispatch();

  const [{ controls, invalid }, { setControl, validate }] = useControls(
    [
      {
        control: "name",
        value: `${data?.user?.first_name} ${data?.user?.last_name}`,
      },
      {
        control: "email",
        value: data?.user?.email,
        validations: [
          {
            test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "البريد غير صالح",
          },
          ``,
        ],
      },
      {
        control: "countryCode",
        value: data?.user?.country_code,
      },
      {
        control: "phone",
        value: data?.user?.phone,
        validations: [
          {
            customValidation: ({ countryCode }) => countryCode !== "",
            message: "انت تحاول إضافة كود دولة بدون رقم",
          },
        ],
      },
      {
        control: "username",
        value: data?.user?.username.split("@")[0],
      },
      {
        control: "job",
        value: data?.job?.id,
      },
      {
        control: "salary",
        value: data?.salary,
      },
      {
        control: "hwafez",
        value: data?.hwafez,
      },
    ],
    [data]
  );

  const [jobs, setJobs] = useState([]);

  const [jobsGetRequest, jobsGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });

  const getJobs = () => {
    jobsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setJobs(res.data.results);
      },
    });
  };

  const [employees, setEmployees] = useState([]);

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const getEmployees = () => {
    employeesGetRequest({
      params: {
        top: 1,
        job: controls.job,
        size: 1000,
      },
      onSuccess: (res) => {
        setEmployees(res.data);
      },
    });
  };

  const originalEmployeePermissions = useRef();

  const [UserPermissions, setUserPermissions] = useState([]);

  const [UserPermissionsGetRequest, UserPermissionsGetResponse] =
    useRequest({
      path: USER_PERMISSIONS,
      method: "get",
    });

  const getUserPermissions = () => {
    console.log(data)
    UserPermissionsGetRequest({
      params: {
        id: data.user.id,
      },
      onSuccess: (res) => {
        originalEmployeePermissions.current = res.data.map(
          (perm) => perm.codename
        );
        setUserPermissions(res.data.map((perm) => perm.codename));
      },
    });
  };

  const [permissionsState, setPermissionsState] = useState([]);

  const [jobPermissionsGetRequest, jobPermissionsGetResponse] = useRequest({
    path: JOB_PERMISSIONS,
    method: "get",
  });

  const getJobPermissions = () => {
    jobPermissionsGetRequest({
      params: {
        id: controls.job,
      },
      onSuccess: (res) => {
        setPermissionsState(res.data);
        getUserPermissions();
      },
    });
  };

  useAfterEffect(() => {
    if (!Boolean(controls.job)) return;
    getJobPermissions();
  }, [controls.job]);

  const [employeePatchRequest, employeePatchResponse] = useRequest({
    path: EMPLOYEES,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.name.split(" ").slice(0, -1).join(" "), data.user.first_name],
        [controls.name.split(" ").slice(-1).join(" "), data.user.last_name],
        [controls.email, data.user.email],
        [controls.job, data.job.id],

        [originalEmployeePermissions.current, UserPermissions],
        [controls.phone, data?.user?.phone],
        [controls.countryCode, data?.user?.country_code],
        [controls.username, data?.user?.username],
        [controls.salary, data?.salary],
        [controls.hwafez, data?.hwafez],
      ],
      true
    );
  if (!isThereChange) return;
 

      validate().then((output) => {
        const { isOk } = output;
        if (!isOk) return;
        console.log(output);
        const requestBody = filter({
          obj: {
            user: {
              first_name: controls.name.split(" ").slice(0, -1).join(" "),
              last_name: controls.name.split(" ").slice(-1).join(" "),
              email: controls.email,
              username:
                !compare([[controls.username, data?.user?.username]]) &&
                controls.username,

              phone: controls.countryCode + controls.phone,
              user_permissions: UserPermissions?.map((perm) => ({
                codename: perm,
              })),
            },

            job: controls.job,
            salary: controls.salary,
            hwafez: controls.hwafez,
          },
        });
        console.log(requestBody)

        employeePatchRequest({
          body: requestBody,
          id: data.id,
          onSuccess: (res) => {
            dispatch({
              type: "employees/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        });
      });
    
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      paperProps={{ height: "100vmax" }}
      isPending={
        jobPermissionsGetResponse.isPending ||
        UserPermissionsGetResponse.isPending
      }
    >
      <DialogHeading>{globalLang.editEmployeeData[lang]}</DialogHeading>
      <DialogForm>
        <DialogInputField
          label={globalLang.name[lang]}
          placeholder={globalLang.name[lang]}
          value={controls.name}
          onChange={(e) => setControl("name", e.target.value)}
        />
        <DialogInputField
          label={globalLang.email[lang]}
          placeholder={globalLang.email[lang]}
          value={controls.email}
          onChange={(e) => setControl("email", e.target.value)}
          error={Boolean(invalid.email)}
          helperText={invalid.email}
        />
        <DialogInputField
          label={globalLang.username[lang]}
          placeholder={globalLang.name[lang]}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment
          //       position="start"
          //       sx={{ direction: "rtl", paddingLeft: "10px" }}
          //     >
          //       @{userInfo?.organization?.name?.replace(/\s/gi, "")}.com
          //     </InputAdornment>
          //   ),
          // }}
          value={controls.username}
          onChange={(e) => setControl("username", e.target.value)}
          error={Boolean(invalid.username)}
          helperText={invalid.username}
        />
        <DialogPhoneField
          label={globalLang.phone[lang]}
          placeholder={globalLang.phone[lang]}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => {
              setControl("countryCode", e.target.value);
            },
          }}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
          error={Boolean(invalid.phone)}
          helperText={invalid.phone}
        />
        <DialogInputField
          label={viewEmployeesLang.salary[lang]}
          placeholder={viewEmployeesLang.salary[lang]}
          value={controls.salary}
          onChange={(e) => setControl("salary", e.target.value)}
          error={Boolean(invalid.salary)}
          helperText={invalid.salary}
        />
        <DialogInputField
          label={viewEmployeesLang.incentives[lang]}
          placeholder={viewEmployeesLang.incentives[lang]}
          value={controls.hwafez}
          onChange={(e) => setControl("hwafez", e.target.value)}
          error={Boolean(invalid.hwafez)}
          helperText={invalid.hwafez}
        />

        <DialogSelectField
          label={globalLang.job[lang]}
          placeholder={globalLang.job[lang]}
          onOpen={getJobs}
          isPending={jobsGetResponse.isPending}
          value={controls.job}
          onChange={(e) => {
            setControl("job", e.target.value);
       
          }}
          renderValue={(selected) => {
            return Boolean(jobs.find((job) => job.id === controls.job))
              ? jobs.find((job) => job.id === selected).title
              : data?.job?.title;
          }}
        >
          {jobs.map((job, index) => (
            <MenuItem value={job.id} key={`employeeJobEdit ${index}`}>
              {job.title}
            </MenuItem>
          ))}
        </DialogSelectField>
        {/* <DialogSelectField
          label={globalLang.followed[lang]}
          placeholder={globalLang.followed[lang]}
          onOpen={getEmployees}
          isPending={employeesGetResponse.isPending}
          disabled={!Boolean(controls.job)}
          value={controls.to}
          onChange={(e) => setControl("to", e.target.value)}
          renderValue={(selected) => {
            return Boolean(employees.find((job) => job.id === controls.to))
              ? `${
                  employees.find((job) => job.id === selected).user.first_name
                } ${
                  employees.find((job) => job.id === selected).user.last_name
                }`
              : data?.parent?.name;
          }}
        >
          {employees
            .filter((employee) => employee.id !== data?.id)
            .map((employee, index) => (
              <MenuItem value={employee.id} key={`employeeSuperEdit ${index}`}>
                {employee.user.first_name} {employee.user.last_name}
              </MenuItem>
            ))}
        </DialogSelectField> */}
        <PermissionToggles
          permissions={permissionsState}
          initialToggles={UserPermissions}
          onToggle={({ toggles }) => setUserPermissions(toggles)}
        />
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={employeePatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {employeePatchResponse.failAlert}
    </Dialog>
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `من ${formatDate(String(startPoint))} - إلى ${formatDate(
        String(endPoint)
      )}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};

const EditPasswordDialog = ({ open, onClose, id }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [password, setPassword] = useState("");

  const [changePasswordPatchReqeust, changePasswordPatchResponse] = useRequest({
    path: EMPLOYEES,
    method: "patch",
    successMessage: "تم تغير الرقم السري بنجاح",
  });

  const handleSubmit = () => {
    if (!password) return;
    changePasswordPatchReqeust({
      body: {
        user: {
          password: password,
        },
      },
      id: id,
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ maxWidth: 600 }}>
      <DialogContent
        sx={{
          height: "100% !important",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <DialogButtonsGroup sx={{ width: "100%" }}>
            <TextField
              variant="standard"
              label={globalLang.password[lang]}
              placeholder={globalLang.password[lang]}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                width: "100%",
                "& .MuiInputLabel-formControl": {
                  fontSize: 20,
                  fontWeight: "normal",
                  transform: "translate(10px, -10.5px) scale(0.75)",
                  color: "white",
                },
                "& .MuiInput-input": {
                  paddingBlock: 1.2,
                  fontSize: 15,
                },
                "& .MuiInputBase-formControl": {
                  borderColor: "white",
                  bgcolor: "white",
                },

                "& .MuiInputLabel-formControl.Mui-focused": {
                  color: "unset",
                },
              }}
            />
          </DialogButtonsGroup>
          <DialogButtonsGroup>
            <DialogButton
              onClick={handleSubmit}
              sx={{ width: "100%" }}
              disabled={changePasswordPatchResponse.isPending}
            >
              {globalLang.save[lang]}
            </DialogButton>
            <DialogButton
              variant="close"
              onClick={onClose}
              sx={{ width: "100%" }}
            >
              {globalLang.cancel[lang]}
            </DialogButton>
          </DialogButtonsGroup>
        </Stack>
      </DialogContent>
      {changePasswordPatchResponse.failAlert}
    </Dialog>
  );
};

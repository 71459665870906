import { Box } from "@mui/system";
import React from "react";

const EmptyBox = (props) => (
  <Box
    component="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    {...props}
  >
    <path d="m860.3 374.7 124.4-124.4c4-3.9 5.8-9.4 5.2-14.8-.8-5.6-4-10.4-8.7-13.2L645.7 28.5c-6.9-4-15.7-2.8-21.3 2.9L500 155.8 375.6 31.5c-5.7-5.7-14.4-6.9-21.4-2.9L18.8 222.4c-4.9 2.8-8 7.6-8.7 13.2-.7 5.3 1 10.9 4.9 14.8l124.4 124.4L15.1 499.3c-3.9 3.9-5.7 9.4-4.9 14.8.6 5.4 3.8 10.3 8.7 13l128 74v161.2c0 6.4 3.4 12.2 8.8 15.4l335.5 193.9c2.7 1.7 5.8 2.3 8.8 2.3 3 0 6.1-.7 8.8-2.3l335.4-193.9c5.4-3.2 8.8-9 8.8-15.4V601.1l128.1-74c4.7-2.8 7.9-7.6 8.7-13 .7-5.5-1.2-10.9-5.2-14.8L860.3 374.7zm-60.4-2.2L500 545.8 200 372.5l224.9-130 75.1-43.3 271.3 156.6 28.6 16.7zM639.8 63.8l303.6 175.6-111 111-18.2-10.5-285.5-165L639.8 63.8zm-279.6 0 111.1 111.1-303.8 175.4-111.1-111L360.2 63.8zM167.5 394.6l303.6 175.6-111 111-186.8-108h-.1L56.4 505.7l111.1-111.1zm14.7 224.7 172 99.3c2.8 1.7 5.8 2.4 8.8 2.4.4 0 .6-.4 1-.4 4.1-.1 8.3-1.5 11.5-4.7l106.8-106.8v314.2L182.2 749.8V619.3zm635.4 130.5-300 173.4V609.1l106.6 106.8c3.1 3.1 7.2 4.4 11.4 4.7.4 0 .7.4 1 .4 3.1 0 6.1-.7 8.8-2.4l172-99.3v130.5h.2zm-177.9-68.7-111-111 303.7-175.6 111 111.1-303.7 175.5z" />
  </Box>
);

export default EmptyBox;

import React, { useRef } from "react";
import {
  Avatar,
  Badge,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Skeleton,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useLocation, useNavigate } from "react-router-dom";
import pages from "../data/pages";
import useControls from "../hooks/useControls";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ConnectedTvOutlined } from "@mui/icons-material";
import Logo from './../assets/logoCrm.png'
import { Logout } from "../data/APIs";
import useRequest from "../hooks/useRequest";
import Image from "mui-image";
const Sidebar = ({
  width,
  name,
  // role,
  // organization,
  avatar,
  onLogout = () => {},
  onChangeAvatar,
  onClose,
  onOpen,
  permanent,
  open,
  permissions,
  isNamePending = false,
  // isRolePending = false,
  // isOrganizationPending = false,
  isTabsPending = false,
  isAvatarPending = false,
}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const sm = useMediaQuery("(max-width:712px)");
  ////////////////////

  //----states----
  const [{ controls }, { setControl }] = useControls(
    pages
      .filter((page) => page && Boolean(page?.hasOwnProperty("subtabs") ) )
      .map((page) => ({
        control: page.name[lang],
        value: false,
        isRequired: false,
      })),
    
  );

  
  //----hooks----
  const pictureFileInput = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [LogoutRequest,LogoutResponse] = useRequest({
    path: Logout,
    method: "post",
  });
  const dispatch=useDispatch()
  const LogOut = () => {
    
    LogoutRequest({
      onSuccess: (res) => {
        console.log('d')
        dispatch({ type: "userInfo/logout" })
      },
    });
  };
  //----functions----
  const handleClose = () => {
    onClose();
  };

  return (
    <SwipeableDrawer
      variant={permanent ? "permanent" : "temporary"}
      open={open}
      onOpen={onOpen}
      anchor="right"
      sx={{
        width: width,
        "& .MuiDrawer-paper": {
          width: width?"241px":width,
          bgcolor: "#FFFFFF",
          
          border: "none",
          borderRadius: "20px 0px 0px 20px;",
          // boxShadow: "2px -1px 10px -1px rgb(35 57 117)",
          right: "none",
          left: 0,
        },
        "& .MuiBackdrop-root": {
          // bgcolor: "rgb(255 255 255 / 50%)",
          overflowY: "scroll",
        },
      }}
      onClose={handleClose}
    >
      <IconButton>
          <Image 
        alt="Factory CRM" 
        src={Logo}
        style={{ width: 68, height:68 }}
        />
          </IconButton>
          <Divider
                    sx={{ borderColor: "rgb(255 255 255 / 8%)",borderRadius:"71.5px",backgroundColor:"#000000", width:"80%",opacity:"0.2" }}
                    
                  />
      <List>
        {isTabsPending ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              WebkitMaskImage:
                "linearGradient(0deg, rgba(9,9,121,0) 0%, rgba(255,255,255,1) 100%)",
              maxHeight: 600,
            }}
          >
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
            <Skeleton variant="rounded" width="100%" height={40} />
          </Box>
        ) : (
          <>
            {pages.map((page, pageIndex) => {
              if (!Boolean(page))
                return (
                  <Divider
                    sx={{ borderColor: "rgb(255 255 255 / 8%)" }}
                    key={`divider ${pageIndex}`}
                  />
                );

              switch (page.hasOwnProperty("subtabs")) {
                
                case true:
                  
                  if (!page.permitted.length) {
                    if (
                      page.subtabs.every((subtab) => {
                        !subtab.permitted.some((perm) =>
                          permissions?.includes(perm)
                        );
                      })
                    )
                      return;
               
                    return (
                      <React.Fragment key={`page ${pageIndex}`}>
                        <ListItemButton
                          onClick={() =>
                            setControl(`${page.name[lang]}`, (old) => !old)
                          }
                        >
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            {React.cloneElement(page.icon, {
                              sx:{color:"rgb(2,0,36)"},
                            })}
                          </ListItemIcon>
                          <ListItemText  sx={{color:"rgb(2,0,36)"}} primary={page.name[lang]} />
                          {controls[page.name[lang]] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>

                        <Collapse
                          in={Boolean(controls[page.name[lang]])}
                          timeout="auto"
                          unmountOnExit
                        >
                          {page.subtabs.map((subtab, subTabIndex) => {
                            if (
                              subtab.permitted.length
                                ? !Boolean(
                                    subtab.permitted.some((perm) =>
                                      permissions?.includes(perm)
                                    )
                                  )
                                : ""
                            )
                              return;

                            return (
                              <ListItemButton
                                key={`subtab ${subTabIndex}`}
                                sx={{
                                  pl: 7,
                                  bgcolor:
                                    location.pathname ===
                                    page.path + subtab.path
                                      ? "#ffffff"
                                      : "initial",
                                  // "& *": {
                                    color:
                                      location.pathname ===
                                      page.path + subtab.path
                                        ? "#5A5A5A"
                                        : "white",
                                  // },
                                  // "&:hover": {
                                  //   bgcolor:
                                  //     location.pathname ===
                                  //     page.path + subtab.path
                                  //       ? "#ffffff"
                                  //       : "initial",
                                  // },
                                  // "&:hover *": {
                                  //   color:
                                  //     location.pathname ===
                                  //     page.path + subtab.path
                                  //       ? "#8e8e93"
                                  //       : "white",
                                  // },
                                }}
                                onClick={() => {
                                  navigate(page.path + subtab.path);
                                  sm ? handleClose() : "";
                                }}
                              >
                                <ListItemText
                                  primary={
                                    lang === "en" ? subtab.name_en : subtab.name
                                  }
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: ".80rem",
                                    },
                                  }}
                                />
                              </ListItemButton>
                            );
                          })}
                        </Collapse>
                      </React.Fragment>
                    );
                  } else {
                    if (
                      !page.permitted.some((perm) =>
                        permissions?.includes(perm)
                      )
                    ) {
                      return;
                    }
                  
                    return (
                 
                      <React.Fragment key={`page ${pageIndex}`}>
                        <ListItemButton
                          onClick={() =>
                            setControl(`${page.name[lang]}`, (old) => !old)
                          }
                        >
                          <ListItemIcon sx={{ minWidth: "30px" }}>
                            {React.cloneElement(page.icon, {
                              sx: { color:"rgb(2,0,36)" },
                            })}
                          </ListItemIcon>
                          <ListItemText  primary={page.name[lang]} />
                          {controls[page.name[lang]] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )}
                        </ListItemButton>
                            
                        <Collapse
                          in={Boolean(controls[page.name[lang]])}
                          timeout="auto"
                          unmountOnExit
                        >
                          {page.subtabs.map((subtab, subTabIndex) => {
                            if (
                              subtab.permitted.length
                                ? !Boolean(
                                    subtab.permitted.some((perm) =>
                                      permissions?.includes(perm)
                                    )
                                  )
                                : ""
                            )
                              return;

                            return (
                              <ListItemButton
                                key={`subtab ${subTabIndex}`}
                                sx={{
                                  pl: 7,
                                  bgcolor:
                                    location.pathname ===
                                    page.path + subtab.path
                                      ? "#fff"
                                      : "initial",
                                  // "& *": {
                                    color:
                                      location.pathname ===
                                      page.path + subtab.path
                                        ? "#5A5A5A"
                                        : "#5A5A5A",
                                  // },
                                  // "&:hover": {
                                  //   bgcolor:
                                  //     location.pathname ===
                                  //     page.path + subtab.path
                                  //       ? "#f4f4f4"
                                  //       : "initial",
                                  // },
                                  // "&:hover *": {
                                  //   color:
                                  //     location.pathname ===
                                  //     page.path + subtab.path
                                  //       ? "primary.main"
                                  //       : "white",
                                  // },
                                }}
                                onClick={() => {
                                  navigate(page.path + subtab.path);
                                  sm ? handleClose() : "";
                                }}
                              >
                                <ListItemText
                                  primary={
                                    lang === "en" ? subtab.name_en : subtab.name
                                  }
                                  sx={{
                                    "& .MuiListItemText-primary": {
                                      fontSize: ".80rem",
                                    },
                                  }}
                                />
                              </ListItemButton>
                            );
                          })}
                        </Collapse>
                      </React.Fragment>
                    );
                  }

                case false:
                  if (
                    Boolean(page.permitted.length) &&
                    !Boolean(
                      page.permitted.some((perm) => permissions?.includes(perm))
                    )
                  )
                    return;

                  return (
                    <ListItemButton
                      key={pageIndex}
                      sx={{
                        bgcolor:
                          location.pathname === page.path
                            ? "#fffff"
                            : "initial",
                        "& *": {
                          color:
                            location.pathname === page.path
                              ?" rgb(2,0,36);"
                              : "#5A5A5A",
                        },
                        // "&:hover": {
                        //   bgcolor:
                        //     location.pathname === page.path
                        //       ? "#fffff"
                        //       : "initial",
                        // },
                        // "&:hover *": {
                        //   color:
                        //     location.pathname === page.path
                        //       ? "#231F20"
                        //       : "white",
                        // },
                      }}
                      onClick={() => {
                        navigate(page.path);
                        sm ? handleClose() : "";
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {React.cloneElement(page.icon, {
                          sx: { color: "#231F20" },
                        })}
                      </ListItemIcon>
                      <ListItemText primary={page.name[lang]} />
                    </ListItemButton>
                  );
                default:
                  return;
              }
            })}
            <ListItemButton
              sx={{
                bgcolor: "#ffffff",
                "& *": {
                  color: "#5A5A5A",
                },
                "&:hover": {
                 
                },
                "&:hover *": {
                  color: "#5A5A5A",
                },
              }}
              onClick={LogOut}
            >
              <ListItemIcon sx={{ minWidth: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={globalLang.logout[lang]} />
            </ListItemButton>
          </>
        )}
      </List>
    </SwipeableDrawer>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  // role: PropTypes.string.isRequired,

  permissions: PropTypes.arrayOf(PropTypes.string),
  onLogout: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onChangeAvatar: PropTypes.func,
  permanent: PropTypes.bool,
  open: PropTypes.bool,
  isNamePending: PropTypes.bool,
  // isRolePending: PropTypes.bool,
  // isOrganizationPending: PropTypes.bool,
  isTabsPending: PropTypes.bool,
  isAvatarPending: PropTypes.bool,
};

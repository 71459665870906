import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import HomeDistribution from "./HomeDistribution";
import ViewHomeDistribution from "./ViewHomeDistribution";
const HomeDistributionView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factoryhomeawaypermit"]}>
        <HomeDistribution />
      </PermissionsGate>

      <PermissionsGate permissions={["view_factoryhomeawaypermit"]}>
        <ViewHomeDistribution />
      </PermissionsGate>
    </Wrapper>
  );
};
export default HomeDistributionView;

import {
 
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {

  STATIONS,
  TRADEREXITBILL,
  TRDAERBILL,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,

} from "../../../features/form";

import Wrapper from "../../../components/Wrapper";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const FillingBill = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const values = ["enter_empty",'enter_filled','exit_empty','exit_filled',"product", "volume",'fill_type','returned','fill','wallet','price','paid','tips'];


  const TraderExitBillStore = useSelector(
    (state) => state.trader_exit_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [TraderBillPostRequest, TraderBillPostResponse] = useRequest({
    path: TRDAERBILL,
    method: "post",
    successMessage: "تم التعديل بنجاح",
  });

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    trader_exit_bill: "",
  });

  const [TraderExitBillData, setTraderExitBillData] = useState({});
 

  const [gasBill, setGasBill] = useState([
    {

      price: "",
      paid: "",
      dokhan: "",
  
    },
  ]);


  ///////////////////////////////
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });
  const getStations = () => {
    stationsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "bill_number",
      value: "",
      isRequired: true,
    },
    {
      control: "bills",
      value: [],
      isRequired: false,
    },
    {
      control: "bill_date",
      value: new Date(),
      isRequired: true,
    },
 
  ]);


  const [TraderExitBillGetRequest, TraderExitBillGetResponse] = useRequest({
    path: TRADEREXITBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTraderExitBill = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed", false);
    TraderExitBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "trader_exit_bill/set", payload: res.data });
      },
    });
  };
  console.log(gasBill);

  const handleChange = (event, index, id) => {
    let data = [...gasBill];

    data[index] = {
      ...data[index],
      [event.target.name]: parseInt(event.target.value),
      
    'bill_id':id
    };
    setGasBill(data);

    console.log(gasBill);

  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      console.log(output);

      const requestBody = filter({
        obj: {
          exit_permit_uuid: filterdata.trader_exit_bill,
          bill_date:controls.bill_date.toISOString(),
          bill_uuid: parseInt(controls.bill_number),
          bills: [...gasBill],
        },
        output: "object",
      });

      TraderBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          handleresetFilters();
          resetControls();
          dispatch({
            type: "trader_bill/putItem",
            payload: { id: res.data.id, item: res.data },
          });
        },
      }).then((res) => {
        if(!res.id){
     setInvalid(res?.message);
        }

   
      });
    });
  };

  const handleFilterSubmit = () => {


    if (!filterdata.trader_exit_bill) {
      return;
    }

    TraderExitBillGetRequest({
      id: TraderExitBillStore.results?.find(
        (bf) => bf?.exit_permit_uuid === filterdata?.trader_exit_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setTraderExitBillData(res?.data);
      },
    });
  };
  const handleresetFilters = () => {
    setfilterdata({ trader_exit_bill: "" });
    setGasBill([]);
    setfiltered(true);

    setIsCleared(uuid());
  };

  return (
    <>
    
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.trader_exit_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <AutocompleteField
            label={globalLang.exit_enter_number[lang]}
            sx={{ width: "30vw" }}
            placeholder={globalLang.exit_enter_number[lang]}
            isPending={TraderExitBillGetResponse.isPending}
            onOpen={getTraderExitBill}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({
                    trader_exit_bill: options.value,
                  });
                  break;
                case "clear":
                  setfilterdata({ trader_exit_bill: "" });
              }
            }}
            data={[
              ...TraderExitBillStore.results.map((bf, index) => ({
                label: String(bf.exit_permit_uuid),
                value: bf.exit_permit_uuid,
              })),
            ]}
            value={filterdata.trader_exit_bill}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: TraderBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: TraderBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ padding: "20px", gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <InputField
                disabled
                label={globalLang.Exit_number[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                defaultValue={filterdata.trader_exit_bill}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                label={globalLang.exit_date[lang]}
                value={format(TraderExitBillData?.exit_date)}
              />
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                label={globalLang.enter_date[lang]}
                value={format(TraderExitBillData?.enter_date)}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.driver[lang]}
                placeholder={globalLang.driver[lang]}
                value={TraderExitBillData?.driver}
              ></InputField>
              <InputField
                disabled
                label={globalLang.transportation[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.transportation[lang]}
                value={TraderExitBillData?.transport}
              ></InputField>
              <NumberField
                label={globalLang.bill_number[lang]}
                placeholder={globalLang.bill_number[lang]}
                value={controls.bill_number}
                onChange={(e) => setControl("bill_number", e.target.value)}
                required={required.includes("bill_number")}
                error={Boolean(invalid.bill_number)}
                helperText={invalid.bill_number}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={globalLang.billDate[lang]}
                  value={controls.bill_date}
                  onChange={(newValue) => {
                    setControl("bill_date", newValue);
                  }}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Box>
              <Stack sx={{ gap: 2 }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    padding: "10px",
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "100%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography  sx={{ flexGrow: "1",fontSize:'1.3rem' }}>
                      الغازات
                    </Typography>
                    {/* <Tooltip title="أضف منتج ">
                      <AddIcon
                        htmlColor="green"
                        sx={{ cursor: "pointer" }}
                        onClick={handleAddGasRow}
                      />
                    </Tooltip> */}
                  </Stack>

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {values.map((value) => (
                          <TableCell>{globalLang[value][lang]}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TraderExitBillData?.bills?.map(
                        (bill, Gasindex) =>
                          bill.product_type === "cylinder" && (
                            <TableRow
                              key={`${bill.id}${Gasindex}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                          
                                  sx={{
                                    fontWeight: "bold",
                                    
                                  }}
                                >
                                  {bill.enter_empty === 0
                                    ? "0"
                                    : bill.enter_empty}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                              
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.enter_filled === 0
                                    ? "0"
                                    : bill.enter_filled}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                         
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.exit_empty ? bill.exit_empty : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.exit_filled ? bill.exit_filled : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.product_name}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.volume}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.fill_type ? bill.fill_type &&bill.fill_type==="factory"&&"مصنع"||bill.fill_type==="tank"&&"تانك": "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityreturned
                                    ? bill.quantityreturned
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.fill
                                    ? bill.fill
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.store
                                    ? bill.store
                                    : "-"}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <InputField
                                  placeholder={globalLang.price[lang]}
                                  name="price"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.paid[lang]}
                                  name="paid"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.tips[lang]}
                                  name="dokhan"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  component={Paper}
                  sx={{
                    padding: "10px",
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "100%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography  sx={{ flexGrow: "1" ,fontSize:'1.3rem'}}>
                      السوائل
                    </Typography>
                  </Stack>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {values.map((value) => (
                          <TableCell>{globalLang[value][lang]}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TraderExitBillData?.bills?.map(
                        (bill, Liquidindex) =>
                          bill.product_type === "dewar" && (
                            <TableRow
                              key={`${bill.id}${Liquidindex}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.enter_empty === 0
                                    ? "0"
                                    : bill.enter_empty}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.enter_filled === 0
                                    ? "0"
                                    : bill.enter_filled}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.exit_empty === 0
                                    ? "0"
                                    : bill.exit_empty
                                    ? bill.exit_empty
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.exit_filled === 0
                                    ? "0"
                                    : bill.exit_filled
                                    ? bill.exit_filled
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.product_name}
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.volume}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.fill_type ? bill.fill_type : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityreturned
                                    ? bill.quantityreturned
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.price[lang]}
                                  name="price"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.paid[lang]}
                                  name="paid"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.tips[lang]}
                                  name="dokhan"
                                  type="number"
                                  onChange={(e) => {
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                ></InputField>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Box>
          </Stack>
        </Form>
      )}

      {TraderBillPostResponse.successAlert}
      {TraderBillPostResponse.failAlert}
    </>
  );
};
export default FillingBill;

import React, { useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddStock from "./AddStock";
import OutsStock from "./outsStock";
import CounterReading from "./CounterReading";
import AddTank from "./AddTank";
import ViewCounterReadingsHistory from "./ViewCounterReadinsHistory";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

const PermissionPanelٌReadings = ({ index,boxes}) => {
  const [value, setValue] = React.useState(index);
  const [subValue, setSub] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSub = (event, newValue) => {
    setSub(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
           {boxes.map((box, index) => {
              return <Tab label={box.title} {...a11yProps(index)} />
            })}
          </Tabs>
        </Box>
        {boxes.map((box,index)=>(
          <TabPanel value={value} index={index} key={index}>
            {box.title=="الرصيد"?(
        <PermissionsGate
          permissions={["add_practicalstock", "add_factoryloans"]}
        >
          <TabPanel value={value} index={0}>
            <Tabs
              value={subValue}
              onChange={handleSub}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "black",
                  background: "rgba(35,57,117,0.2)",
                },
              }}
            >
              <Tab label="الرصيف" {...a11yProps(0)} />
              <Tab label="الخوارج" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={subValue} index={0}>
              <AddStock period="d" />
            </TabPanel>
            <TabPanel value={subValue} index={1}>
              <OutsStock period="m" />
            </TabPanel>
          </TabPanel>
        </PermissionsGate>
        ):(
        <PermissionsGate
          permissions={["add_factorytankreadings", "view_factorytankreadings"]}
        >
          <TabPanel value={value} index={1}>
            <ViewCounterReadingsHistory />
          </TabPanel>
        </PermissionsGate>
        )}
        {/* <TabPanel value={value} index={2}>
            <AddTank/>
        </TabPanel> */}
        </TabPanel>
        ))}
      </Box>
      
    </>
  );
};
export default PermissionPanelٌReadings;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

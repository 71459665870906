import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TANK } from "../data/APIs";

import { InputField, SelectField } from "../features/form";
import useRequest from "../hooks/useRequest";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CustomTable = ({ data, type, columns, getFormData, info, filling }) => {
  // change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  // slice
  const tanksStore = useSelector((state) => state.tank.value);
  //------states
  const [GassRows, setGassRows] = useState([{}]);
  const [LiquidRows, setLiquidRows] = useState([{}]);
  const [TableColumns, setTableColumns] = useState([{}]);
  // dispatch
  const dispatch = useDispatch();
  //----- when change
  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);

  useEffect(() => {
    type === "cylinder" ? getFormData(GassRows) : getFormData(LiquidRows);
  }, [GassRows, LiquidRows]);

  //-- request
  const [tanksgetRequest, tanksgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const getTanks = () => {
    tanksgetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };

  //------
  const fill_types = [
    {
      name: "تانك",
      value: "tank",
    },
    {
      name: "مصنع",
      value: "factory",
    },
  ];

  //------ onChange
  const handleChange = (event, index, id) => {
    let data = [...GassRows];
    // data.push({ [event.target.name]: parseInt(event.target.value), bill_id: id });
    data[index] = {
      ...data[index],
      [event.target.name]:
        event.target.name === "fill_type"
          ? event.target.value
          : parseInt(event.target.value),
      product_id: id,
    };
    setGassRows(data);
  };

  const handleLiquidChange = (event, index, id) => {
    let data = [...LiquidRows];
    // data.push({ [event.target.name]: parseInt(event.target.value), bill_id: id });
    data[index] = {
      ...data[index],
      [event.target.name]:
        event.target.name === "fill_type"
          ? event.target.value
          : parseInt(event.target.value),
      product_id: id,
    };
    setLiquidRows(data);
  };

  {
    console.log(tanksStore);
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          padding: "10px",
          height: "fit-content",
          margin: "10px",
        }}
      >
        <Typography sx={{ flexGrow: "1", fontSize: "1.3rem" }}>
          {type === "cylinder" ? "الغازات" : "السوائل"}
        </Typography>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((ele, index) => (
                <TableCell key={index}>{ele.value}</TableCell>
              ))}
              {GassRows.filter((item) => item.fill_type === "tank").length >
                0 && filling ? (
                <TableCell>{globalLang.tank[lang]}</TableCell>
              ) : undefined}
              {LiquidRows.filter((item) => item?.fill_type === "tank").length >
                0 && filling ? (
                <TableCell>{globalLang.tank[lang]}</TableCell>
              ) : undefined}
            </TableRow>
          </TableHead>
          <TableBody>
            {type === "cylinder"
              ? data?.map(
                  (bill, Gasindex) =>
                    bill.product_type === "cylinder" && (
                      <TableRow
                        key={`${bill.id}${Gasindex}`}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        {columns.map((column, index) =>
                          info.includes(column.name) ? (
                            typeof bill[column.name] === "object" ? (
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill[column.name].name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill[`${column.name}`]}
                                </Typography>
                              </TableCell>
                            )
                          ) : column.name === "fill_type" ? (
                            <TableCell>
                              <SelectField
                                placeholder={globalLang.fill_type[lang]}
                                name="fill_type"
                                renderValue={(selected) => {
                                  return fill_types.find(
                                    (type) => type.value === selected
                                  )?.name;
                                }}
                                onChange={(e) => {
                                  handleChange(e, Gasindex, bill.id);
                                }}
                              >
                                {fill_types?.map((type, index) => (
                                  <MenuItem
                                    value={type.value}
                                    key={`${type.name} ${index}`}
                                  >
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </SelectField>
                            </TableCell>
                          ) : (
                            <TableCell>
                              <InputField
                                placeholder={column.value}
                                name={column.name}
                                type="number"
                                onChange={(e) => {
                                  handleChange(e, Gasindex, bill.id);
                                }}
                              ></InputField>
                            </TableCell>
                          )
                        )}
                        {GassRows[Gasindex]?.fill_type === "tank" && filling ? (
                          <TableCell>
                            <SelectField
                              name="tank"
                              placeholder={globalLang.tank[lang]}
                              renderValue={(selected) => {
                                return tanksStore.results.find(
                                  (tank) => tank.id === selected
                                )?.tank_uuid;
                              }}
                              isPending={tanksgetResponse.isPending}
                              onOpen={getTanks}
                              onChange={(e) => {
                                handleChange(e, Gasindex, bill.id);
                              }}
                              value={GassRows[Gasindex]?.tank || ""}
                            >
                              {tanksStore?.results?.map((tank, index) => (
                                <MenuItem
                                  value={tank.id}
                                  key={`${tank?.product?.name} ${index}`}
                                >
                                  {tank?.tank_uuid}
                                </MenuItem>
                              ))}
                            </SelectField>
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    )
                )
              : data?.map(
                  (bill, index) =>
                    bill.product_type === "dewar" && (
                      <TableRow
                        key={`${bill.id}${index}`}
                      >
                        {TableColumns.map((col, colIndex) =>
                          info.includes(col.name) ? (
                            typeof bill[col.name] === "object" ? (
                              <TableCell  key={colIndex}>
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  component="span"
                                  variant="p"
                                >
                                  {bill[col.name].name}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell key={colIndex}>
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  component="span"
                                  variant="p"
                                >
                                  {bill[col.name]}
                                </Typography>
                              </TableCell>
                            )
                          ) : col.name === "fill_type" ? (
                            <TableCell
                            >
                              <SelectField
                                placeholder={globalLang.fill_type[lang]}
                                name="fill_type"
                                renderValue={(selected) => {
                                  return fill_types.find(
                                    (type) => type.value === selected
                                  )?.name;
                                }}
                                onChange={(e) => {
                                  handleLiquidChange(e, index, bill.id);
                                }}
                              >
                                {fill_types?.map((type, index) => (
                                  <MenuItem
                                    value={type.value}
                                    key={`${type.name} ${index}`}
                                  >
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </SelectField>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={colIndex}
                            >
                              <InputField
                                placeholder={col.value}
                                name={col.name}
                                type="number"
                                onChange={(e) => {
                                  handleLiquidChange(e, index, bill.id);
                                }}
                              ></InputField>
                            </TableCell>
                          )
                        )}
                        {LiquidRows[index]?.fill_type === "tank" && filling ? (
                          <TableCell
                            key={index}
                          >
                            <SelectField
                              name="tank"
                              placeholder={globalLang.tank[lang]}
                              renderValue={(selected) => {
                                return tanksStore.results.find(
                                  (tank) => tank.id === selected
                                )?.tank_uuid;
                              }}
                              isPending={tanksgetResponse.isPending}
                              onOpen={getTanks}
                              onChange={(e) => {
                                handleLiquidChange(e, index, bill.id);
                              }}
                              value={LiquidRows[index]?.tank || ""}
                            >
                              {tanksStore?.results?.map((tank, index) => (
                                <MenuItem
                                  value={tank.id}
                                  key={`${tank?.product?.name} ${index}`}
                                >
                                  {tank?.tank_uuid}
                                </MenuItem>
                              ))}
                            </SelectField>
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    )
                )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomTable;

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import Wrapper from "../../../components/Wrapper";
import Print from "../../../components/PrintComponent";
import {

  PRODUCTS,
 
  TRDAERBILL,
} from "../../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,

  DialogTable,
} from "../../../features/dialog";

import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

import EditReceiptDialog from "../../../components/EditReceiptDialog";
import { UUIDFilter } from "../Distribution/ViewDistibrutionBill";

const ViewFillingBill = () => {
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  const [editData, setEditData] = useState(null);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [TraderBillDetails, setTraderBillDetails] = useState({
    details: null,
    tab: "details",
  });
  ///////////////////////////////

  const TraderBillStore = useSelector((state) => state.trader_bill.value);

  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
      customContent: ({ bill_uuid }) => (bill_uuid === null ? "-" : bill_uuid),
    },
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => (bill_date ? format(bill_date) : "-"),
    },
    {
      field: "enter_permit_uuid",
      headerName: globalLang.Enter_number[lang],
    },
    {
      field: "exit_permit_uuid",
      headerName: globalLang.Exit_number[lang],
    },

    {
      field: "driver",
      headerName: globalLang.driver[lang],

      customEmpty: "-",
    },
    {
      field: "transport",
      headerName: globalLang.transportation[lang],

      customEmpty: "-",
    },

    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ trader }) => (trader ? trader.name : "-"),
      customEmpty: "-",
    },
    {
      field: "enter_date",
      headerName: globalLang.enter_date[lang],
      customContent: ({ enter_date }) => format(enter_date),

      customEmpty: "-",
    },

    {
      field: "exit_date",
      headerName: globalLang.exit_date[lang],
      customContent: ({ exit_date }) =>
        exit_date === null ? "-" : format(exit_date),

      customEmpty: "-",
    },
  ];

  const dispatch = useDispatch();
  const handleCloseDetailsDialog = () => {
    setTraderBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };
  const [TraderBillGetRequest, TraderBillGetResponse] = useRequest({
    path: TRDAERBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTraderBills = () => {
    urlParams.append('completed', true)
    TraderBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "trader_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object?.values(requestParams)?.map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );

    getTraderBills();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: TRDAERBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({

      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "trader_bill/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });



  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const filters = [
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
  ];
  
  useAfterEffect(() => {
    if (!TraderBillDetails.details) return;
    setTraderBillDetails((old) => ({
      ...old,
      details: TraderBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [TraderBillStore]);

  const isPermitted = useIsPermitted();

  
  return (
    <>
      <Breadcrumbs
        path={[globalLang.fillreceipt[lang], globalLang.view[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={TraderBillStore.results}
        total={TraderBillStore.count}
        isPending={TraderBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factorytraderbill']) }
        onEdit={isPermitted((e, row) => setEditData(row),['change_factorytraderbill','change_factorytraderproduct']) }
        onView={isPermitted((e, row) => {
          setTraderBillDetails((old) => ({
            ...old,
            details: row,
          }));
        },['view_factorytraderproduct'])}
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(
          TraderBillDetails.details && TraderBillDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={Boolean(TraderBillDetails.details) && TraderBillDetails.details}
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewFillingBill;

const EditDialog = ({ open = false, onClose = () => { }, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [FormData, setFormData] = useState([]);
  const getFormData = (data) => {
    setFormData([...data]);
  };
  ///////////////////////////////
  const columns = [

    {
      field: "enter_empty",
      headerName: globalLang.enter_empty[lang],
    },
    {
      field: "enter_filled",
      headerName: globalLang.enter_filled[lang],
    },
    {
      field: "exit_empty",
      headerName: globalLang.exit_empty[lang],
    },
    {
      field: "exit_filled",
      headerName: globalLang.exit_filled[lang],
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
    },

    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
    },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "bill_id",
        value: "",
        isRequired: true,
      },
      {
        control: "bills",
        value: [],
        isRequired: true,
      },
    ],
    [data]
  );
  const EditableColumns = ['price', 'paid', 'dokhan']
  const [trader_data, setData] = useState(data);
  useEffect(() => {
    data?.bills.map((bill, billindex) => {
      setControl("bills", (old) => [
        ...old,
        {
          price: bill["price"],
          paid: bill["paid"],
          dokhan: bill["dokhan"],
        },
      ]);
    });
  }, [data?.bills]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);


  const handleEditBill = (id, index) => {
    console.log(id)
    const requestBody = filter({
      obj: {
        bill_id: id,
        bills: [FormData[index]],
      },
      output: "object",
    });

    TraderBillPatchRequest({
      id: trader_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "trader_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };
  console.log(controls.bills);

  const [inform, setinform] = useState(false);
  const [TraderBillPatchRequest, TraderBillPatchResponse] = useRequest({
    path: TRDAERBILL,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const isThereChange = !compare(
        [[controls.bills, data.station_bills]],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
            home_date: controls.home_date,
            home_quantity: controls.home_quantity,
            total_price: controls.total_price,
          },
          output: "object",
        });

        TraderBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => { },
        });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
          <EditReceiptDialog
            data={data?.bills}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />

        </DialogForm>

        <DialogButtonsGroup>
          {/* <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton> */}
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {TraderBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};
export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  //   {
  //     name: globalLang.product[lang],
  //     // value: `${data?.station_bills.product}`,
  //   },
  //   {
  //     name: globalLang.driver[lang],
  //     value: `${data?.driver ? data?.driver.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.AwayDate[lang],
  //     value: data?.away_date ? format(data?.away_date) : "-",
  //   },
  //   {
  //     name: globalLang.transportation[lang],
  //     value: data?.transport ? data?.transport?.name : "-",
  //   },
  //   {
  //     name: globalLang.product[lang],
  //     value: `${data?.product ? data?.product?.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.product_type[lang],
  //     value: `${data?.product_type ? data?.product_type : "-"}`,
  //   },
  //   {
  //     name: globalLang.away_quantity[lang],
  //     value: `${data?.away_quantity ? data?.away_quantity : "-"}`,
  //   },
  //   {
  //     name: globalLang.BackDate[lang],
  //     value: `${data?.home_date ? format(data?.home_date) : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_empty[lang],
  //     value: `${data?.home_quantity_empty ? data?.home_quantity_empty : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_filled[lang],
  //     value: `${data?.home_quantity_filled ? data?.home_quantity_filled : "-"}`,
  //   },
  //   {
  //     name: globalLang.total_price[lang],
  //     value: `${data?.total_price ? data?.total_price : "-"}`,
  //   },

  // ];

  const columns = [
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ rowData }) =>
        rowData.product ? `${rowData.product?.name} ` : "-",
    },


    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
      customContent: ({ rowData }) =>
        rowData?.fill_type === "factory" ? "مصنع" : "تانك",
    },
    {
      field: "tank",
      headerName: globalLang.tank[lang],
      customContent: ({ rowData }) => (!rowData?.tank ? "-" : rowData?.tank),
    },


    {
      field: "enter_empty",
      headerName: globalLang.enter_empty[lang],
      customContent: ({ rowData }) =>
        rowData?.enter_empty === 0 ? "0" : rowData?.enter_empty,
    },

    {
      field: "enter_filled",
      headerName: globalLang.enter_filled[lang],
      customContent: ({ rowData }) =>
        rowData?.enter_filled === 0 ? "0" : rowData?.enter_filled,
    },
    {
      field: "exit_empty",
      headerName: globalLang.exit_empty[lang],
      customContent: ({ rowData }) =>
        rowData?.exit_empty === 0 ? "0" : rowData?.exit_empty,
    },

    {
      field: "exit_filled",
      headerName: globalLang.exit_filled[lang],
      customContent: ({ rowData }) =>
        rowData?.exit_filled === 0 ? "0" : rowData?.exit_filled,
    },

    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
      customContent: ({ rowData }) =>
        rowData?.quantityreturned === 0 ? "0" : rowData?.quantityreturned,
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ rowData }) =>
        rowData.fill === 0 ? "0" : rowData.fill ? rowData.fill : "-"
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ rowData }) =>
        rowData.price === 0 ? "0" : rowData.price ? rowData.price : "-"
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ rowData }) =>
        rowData.fillprice === 0 ? "0" : rowData.fillprice ? rowData.fillprice : "-"
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ rowData }) =>
        rowData.paid === 0 ? "0" : rowData.paid ? rowData.paid : "-",
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ rowData }) =>
        rowData.dokhan === 0 ? "0" : rowData.dokhan ? rowData.dokhan : "-",
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ rowData }) =>
        rowData.remain_price === 0 ? "0" : rowData.remain_price ? rowData.remain_price : "-",
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData.store === 0 ? "0" : rowData.store ? rowData.store : "-",
    },
    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ rowData }) =>
        rowData.safi === 0 ? "0" : rowData.safi ? rowData.safi : "-",
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },


  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading> */}
      {/* <DialogInfoWindow information={info} /> */}

      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "cylinder"
            )}
            columns={columns}
          />
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

// const HomeDateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
//   // Change lang
//   const lang = useSelector((state) => state.lang.value.lang);
//   const globalLang = useSelector((state) => state.lang.value.global);

//   ///////////////////////////////
//   const [startPoint, setStartPoint] = useState("");
//   const [endPoint, setEndPoint] = useState("");

//   const formatDate = (date) => {
//     return date?.split("-").reverse().join("/");
//   };

//   useEffect(() => {
//     if (!startPoint && !endPoint) return;
//     onChange({
//       query: [
//         "home_date",
//         `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
//       ],
//       renderedValue: `${globalLang.from[lang]} ${formatDate(
//         String(startPoint)
//       )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
//       value: {
//         start: startPoint,
//         end: endPoint,
//       },
//     });
//   }, [startPoint, endPoint]);

//   const handleChangeStartPoint = (e) => {
//     setStartPoint(e.target.value);
//   };

//   const handleChangeEndPoint = (e) => {
//     setEndPoint(e.target.value);
//   };

//   return (
//     <Stack spacing={2}>
//       <TextField
//         variant="standard"
//         type="date"
//         label={globalLang.from[lang]}
//         value={value.start}
//         onChange={handleChangeStartPoint}
//       />
//       <TextField
//         variant="standard"
//         type="date"
//         label={globalLang.to[lang]}
//         value={value.end}
//         onChange={handleChangeEndPoint}
//       />
//     </Stack>
//   );
// };

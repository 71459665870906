import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { CLIENTS, PRODUCTS, SUMMARYCLIENT } from "../../data/APIs";

import { InputField, SelectField } from "../../features/form";
import { Box, MenuItem } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";


const ViewClientBillOverview = ({ period, isHistoryPending,
  open,
  onOpen,
  onClose,}) => {
  //states
  const lang = useSelector((state) => state.lang.value.lang); //lang
  const globalLang = useSelector((state) => state.lang.value.global); //lang
  const SummaryclientReceiptStore = useSelector(
    (state) => state.summaryclient.value
  );

  //format Date
  const getDate = () => {
    var today = new Date();
    let date = "";
    if (period === "d") {
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    } else if (period === "m") {
      date = today.getFullYear() + "-" + (today.getMonth() + 1);
    } else if (period === "a") {
      date = today.getFullYear();
    }
    console.log(date);
    return date;
  };

  const [date, setdate] = useState(getDate());
  const [params, setparams] = useState({
    date: date,
    page: 1,
    size: (period === "m" && 30) || (period === "a" && 12),
  });
  
  ///////////////////////////////

  const dispatch = useDispatch();

  //requests
  const [summaryclientreceiptGetRequest, summaryclientreceiptGetResponse] =
    useRequest({
      path: SUMMARYCLIENT,
      method: "get",
    });

  useEffect(() => {
    const urlParams = new URLSearchParams();
    Object.keys(params).map((key) => urlParams.append(key, params[key]));
    summaryclientreceiptGetRequest({
      id: period,
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "summary-client/set", payload: res.data });
      },
    });
  }, [params]);

  // Filters
  const filters = [
    {
      name: globalLang.client[lang],
      component: <ClientFilter />,
    },
    // {
    //   name: globalLang.receiptnumber[lang],
    //   component: <UUIDFilter />,
    // },
    {
      name: globalLang.product[lang],
      component: <ProductFilter />,
    },
  ];

  const handleFilter = (filters) => {
    const filter = filters.map(({ query }) => query);
    let values = Object.values(filter).map(([key, value]) => {
      return { [key]: value };
    });
    const object = Object.assign({}, ...values);

    setparams({
      date: params.date,
      page: params.page,
      size: params.size,
      ...object,
    });
  };
  const handleChangeAmount = ({ value }) => {
    setparams((old) => ({
      ...old,
      page: 1,
      size: value,
    }));
  };
  const handlePaginate = (params) => {
    console.log(params);
    setparams((old) => ({
      ...old,
      page: params.current,
    }));
  };

  //filter using date
  const handleChangeFilter = (date) => {
    let newDate = "";

    if (period === "d") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}-${date["$D"]}`;
    } else if (period === "m") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}`;
    } else if (period === "a") {
      newDate = `${date["$y"]}`;
    } else {
      newDate = date["$y"];
    }
    setparams((old) => ({ ...old, date: newDate }));
  };

  /////////////////
  const isPermitted = useIsPermitted();
  // Columns name

  let columns = [
    {
      field: "bill_date",
      headerName:
        period === "m" ? globalLang.date[lang] : globalLang.month[lang],
      customContent: ({ bill_date }) =>
        bill_date ? bill_date.split("T")[0] : "-",
    },
    {
      field: "bill_uuid",
      headerName: globalLang.bill_uuid[lang],
      customContent: ({ bill_uuid }) => (bill_uuid ? bill_uuid : "-"),
    },
    {
      field: "client_name",
      headerName: globalLang.client[lang],
      customContent: ({ client_name }) => (client_name ? client_name : "-"),
    },
    {
      field: "product_name",
      headerName: globalLang.product[lang],
      customContent: ({ product_name }) => (product_name ? product_name : "-"),
    },

    {
      field: "driven",
      headerName: globalLang.driven[lang],
      customContent: ({ driven }) =>
        driven === 0 ? "0" : driven ? driven : "-",
    },
    {
      field: "taken",
      headerName: globalLang.taken[lang],
      customContent: ({ taken }) => (taken === 0 ? "0" : taken ? taken : "-"),
    },
    {
      field: "returned",
      headerName: globalLang.returned[lang],
      customContent: ({ returned }) =>
        returned === 0 ? "0" : returned ? returned : "-",
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ fill }) => (fill === 0 ? "0" : fill ? fill : "-"),
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) => (price === 0 ? "0" : price ? price : "-"),
    },

    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ fillprice }) => (fillprice === 0 ? "0" : fillprice),
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid === 0 ? "0" : paid),
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ remain_price }) =>
        remain_price === 0 ? "0" : remain_price,
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ dokhan }) =>
        dokhan === 0 ? "0" : dokhan ? dokhan : "-",
    },

    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ safi }) => (safi === 0 ? "0" : safi),
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ store }) => (store === 0 ? "0" : store),
    },
  ];
  if (period === "a") {
    columns = columns.filter(
      (obj) =>
        obj.field !== "bill_uuid" &&
        obj.field !== "product_name" &&
        obj.field !== "client_name"
    );
  } else if (period === "m") {
    columns = columns.filter(
      (obj) =>
        obj.field !== "bill_uuid" &&
        obj.field !== "product_name" &&
        obj.field !== "client_name" &&
        obj.field !== "price"
    );
  } else if (period === "d") {
    columns = columns.filter((obj) => obj.field !== "bill_date");
  }

  
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          openTo={period === "d" ? "day" : "year"}
          views={
            period === "d"
              ? ["year", "month", "day"]
              : period === "m"
              ? ["year", "month"]
              : ["year"]
          }
          // period==="a"? new Date().getFullYear().toString(): params.date
          value={params.date.toString()}
          onChange={(newValue) => {
            handleChangeFilter(newValue);
          }}
          renderInput={(params) => (
            <InputField
              sx={{
                width: "20%",
                margin: "15px",
                width: {
                  lg: "auto",
                  xl: "auto",
                  md: "auto",
                  sm: "90%",
                  xs: "90%",
                },
                "& .MuiInputBase-root": {
                  borderColor: "#F0F0F8",
                  paddingRight: "5px",
                },
                "& .MuiFormLabel-root": {
                  color: "#666666",
                },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      <DataGrid
        getDate={params.date}
        columns={columns}
        sum={SummaryclientReceiptStore.total}
        rows={SummaryclientReceiptStore.results}
        total={SummaryclientReceiptStore.count}
        isPending={summaryclientreceiptGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
        remainData={SummaryclientReceiptStore?.remain_price_details}
        period={period}
      />
    </Box>
  );
};

export default ViewClientBillOverview;

export const ProductFilter = ({ value, onChange, func }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [products, setProducts] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["product", e.target.value],
      renderedValue: products.find((p) => p.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.product[lang]}
      renderValue={(selected) => products.find((p) => p.id === selected).name}
      onOpen={getProducts}
      isPending={productsGetResponse.isPending}
      onChange={handleChange}
    >
      {products.map((product, index) => (
        <MenuItem value={product.id} key={`${index}`}>
          {product.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
const ClientFilter = ({ value, onChange, func }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [clients, setClients] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["client", e.target.value],
      renderedValue:
        clients.find((c) => c.id === e.target.value)?.user.first_name +
        " " +
        clients.find((c) => c.id === e.target.value)?.user.last_name,
      value: e.target.value,
    });
  };

  const [clientsGetRequest, clientsGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    clientsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setClients(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.client[lang]}
      renderValue={(selected) => {
        let client = clients.find((c) => c.id === selected)?.user;
        return client.first_name + " " + client.last_name;
      }}
      onOpen={getClients}
      isPending={clientsGetResponse.isPending}
      onChange={handleChange}
    >
      {clients.map((client, index) => (
        <MenuItem value={client.id} key={`${index}`}>
          {client?.user?.first_name + " " + client?.user.last_name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  BACKANDFORTHRECEIPT,
  STATIONENTERBILL,
  STATIONEXITBILL,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import CustomTable from "../../../components/CustomTable";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterForm from "../../../components/FilterationForm";

const EnterPermissionStation = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const StationExitBillStore = useSelector(
    (state) => state.station_exit_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [StationEnterBillPostRequest, StationEnterBillPostResponse] =
    useRequest({
      path: STATIONENTERBILL,
      method: "post",
      successMessage: "تم التعديل بنجاح",
    });

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    Exit_Permission_Station_bill: "",
  });
 const[success,setSuccess]=useState(false)
  const [ExitPermissiondata, setExitPermissiondata] = useState({});
  const [gasBill, setGasBill] = useState([]);
  const getBill = (bill) => {
    setfilterdata({ Exit_Permission_Station_bill: bill });
  };
  
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (data) => {
    setFormData([...data]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };

  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "enter_date",
      value: new Date(),
      isRequired: true,
    },

  ]);

  const test = [
    { name: "quantityout", header: globalLang.exit_quantity[lang] },
    { name: "product", header: globalLang.product[lang] },
    { name: "volume", header: globalLang.volume[lang] },
    {
      name: "quantityin",
      header: globalLang.enter_quantity[lang],
    },
    { name: "fill_type", header: globalLang.fill_type[lang] },
    { name: "quantityreturned", header: globalLang.returned[lang] },
  ];
  const info = ["quantityout", "product_name", "volume"];
  
//  const text = [
//   {text: globalLang.driven[lang]},
//   {text: globalLang.product[lang]},
//   {text: globalLang.volume[lang]},
//   {text: globalLang.taken_empty[lang]},
//   {text: globalLang.taken_filled[lang]},
// ]
const columns = [
  {
    name: "quantityout",
    value: globalLang.exit_quantity[lang],
  },
  {
    name: "product_name",
    value: globalLang.product[lang],
  },
  {
    name: "volume",
    value: globalLang.volume[lang],
  },
  {
    name: "quantityin",
    value: globalLang.enter_quantity[lang],
  },
  {
    name: "fill_type",
    value: globalLang.fill_type[lang],
  },
  {
    name: "quantityreturned",
    value: globalLang.returned[lang],
  },
];
  const [StationExitBillGetRequest, StationExitBillGetResponse] = useRequest({
    path: STATIONEXITBILL,
    method: "get",
  });

  const urlParams = new URLSearchParams();
  const getStationExitBill = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed", false);
    StationExitBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "station_exit_bill/set", payload: res.data });
      },
    });
  };
  const fill_types = [
    {
      name: "تانك",
      value: "tank",
    },
    {
      name: "مصنع",
      value: "factory",
    },
  ];
  const handleChange = (event, index, id) => {
    let data = [...gasBill];

    data[index] = {
      ...data[index],
      [event.target.name]:
        event.target.name === "fill_type"
          ? event.target.value
          : parseInt(event.target.value),
      bill_id: id,
    };
    console.log(data);
    setGasBill(data);
  };

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          permit_uuid: filterdata.Exit_Permission_Station_bill,
          enter_date: controls.enter_date.toISOString(),
          bills: [
            ...FormData.filter((object) =>
            typeof object !== "undefined" && Object.keys(object).length !== 0
              ? Object.keys(object)
              : ""
          ),
          ...LiquidFormData.filter((object) =>
            typeof object !== "undefined" && Object.keys(object).length !== 0
              ? Object.keys(object)
              : ""
          ),
          ]
        },
        output: "object",
      });

      StationEnterBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          handleresetFilters();
          resetControls();
          setSuccess(true)
          // setGasBill([{}])

          dispatch({
            type: "station_enter_bill/putItem",
            payload: { id: res.data.id, item: res.data },
          });
        },
      }).then((res) => {
        let response = res?.response?.data;
        const responseBody = filter({
          obj: {
            home_date: response?.home_date,
            home_quantity_empty: response?.home_quantity_empty,
            home_quantity_filled: response?.home_quantity_filled,
            total_price: response?.total_price,
          },
          output: "object",
        });

        setInvalid(responseBody);
      });
    });
  };

  const handleFilterSubmit = () => {
 
    if (!filterdata.Exit_Permission_Station_bill) {
      return;
    }

    StationExitBillGetRequest({
      id: StationExitBillStore.results?.find(
        (bf) => bf?.permit_uuid === filterdata?.Exit_Permission_Station_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setExitPermissiondata(res?.data);
      },
    });
  };
  const handleresetFilters = () => {
    setfilterdata({ Exit_Permission_Station_bill: "" });
 
    setfiltered(true);

    setIsCleared(uuid());
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.shipreceipt[lang],
          globalLang.Enter_Permission[lang],
          globalLang.add[lang],
        ]}
      /> */}
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.Exit_Permission_Station_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <FilterForm
            data={StationExitBillStore}
            getData={getStationExitBill}
            isPending={StationExitBillGetResponse.isPending}
            label={globalLang.Exit_number[lang]}
            getFormData={getBill}
          />
        </Form>
      ) : (
      
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: StationEnterBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: StationEnterBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ gap: 2 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <InputField
                disabled
                label={globalLang.Exit_number[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                defaultValue={filterdata.Exit_Permission_Station_bill}
              />

              <InputField
                disabled
                label={globalLang.Date[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                value={format(ExitPermissiondata.exit_date)}
              />

              <InputField
                disabled
                label={globalLang.driver[lang]}
                placeholder={globalLang.driver[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                value={ExitPermissiondata.driver.name}
              ></InputField>
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",

                    color: "white !important",
                  },
                }}
                label={globalLang.transportation[lang]}
                placeholder={globalLang.transportation[lang]}
                value={ExitPermissiondata.transport.name}
              ></InputField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={globalLang.enter_date[lang]}
                  value={controls.enter_date}
                  onChange={(newValue) => {
                    setControl("enter_date", newValue);
                  }}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Box>
              <Stack
                sx={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomTable 
                      data={ExitPermissiondata?.bills} 
                      type="cylinder"
                
                      columns={columns}
                      getFormData={getFormData}
                      info={info}
                      filling={false}
                  /> 
                  <CustomTable 
                      data={ExitPermissiondata?.bills} 
                      type="dewar"
                  
                      columns={columns}
                      getFormData={getLiquidFormData}
                      info={info}
                      filling={false}
                  /> 
    
              </Stack>
            </Box>
          </Stack>
        </Form>
       
      )}

      {StationEnterBillPostResponse.successAlert}
      {StationEnterBillPostResponse.failAlert}
    </>
  );
};
export default EnterPermissionStation;

import { Box } from "@mui/material";

import React from "react";
import Charging from "../../assets/Charging.png";
import Distribution from "../../assets/Distribution.png"
import Filling from "../../assets/Filling.png"
import ReceiptBox from "../../components/ReceiptBox";
import { useSelector } from "react-redux";
const MainPermissions = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const allPermissions =  useSelector((state) => state.allPermissions.value);
  const boxes = [
    {
      image: Filling,
      title: "تعبئة",
      index: 0,
      perm: ["add_factorytraderpermit","view_factorytraderpermit"],
      type: "perms",
    },
    {
      image: Charging,
      title: "شحن",
      index: 1,
      perm: ["add_factorystationpermit","view_factorystationpermit"],
      type: "perms",
    },
  
    {
      image: Distribution,
      title: "توزيع",
      index: 2,
      perm: ["add_factoryhomeawaypermit","view_factoryhomeawaypermit"],
      type: "perms",
    },
    {
      image: Distribution,
      title: "وساطة",
      index: 3,
      perm: ["add_factoryhomeawaypermit","view_factoryhomeawaypermit"],
      type: "perms",
    },

  ];


  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default MainPermissions;

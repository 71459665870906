import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";;
import Print from "../../../components/PrintComponent";
import {
  CLIENTS,
  DISTRIBUTIONBILL,
  PRODUCTS,
} from "../../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,

  DialogSelectField,
  DialogTable,
} from "../../../features/dialog";
import { InputField, NumberField } from "../../../features/form";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import { ProductFilter } from "../../BillsOverView/ClientBillOverview";
import {
  MenuItem,
  Typography,
 
} from "@mui/material";

import EditReceiptDialog from "../../../components/EditReceiptDialog";
const ViewDistributionBill = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  const [editData, setEditData] = useState(null);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [DistributionBillDetails, setDistributionBillDetails] = useState({
    details: null,
    tab: "details",
  });
  ///////////////////////////////

  const DistributionBillStore = useSelector((state) => state.client_bill.value);

  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => (bill_date ? format(bill_date) : "-"),
    },
    {
      field: "home_away_permit",
      headerName: globalLang.BackAndForthreceipt[lang],
      customContent: ({ home_away_permit }) => home_away_permit?.permit_uuid,
    },

    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ client }) => (client ? client.name : "-"),
      customEmpty: "-",
    },

    {
      field: "total_paid",
      headerName: globalLang.paid[lang],
      customContent: ({ total_paid }) =>
        total_paid === 0 ? "0" : total_paid ? total_paid : "-",
    },
    {
      field: "total_remain",
      headerName: globalLang.remainprice[lang],
      customContent: ({ total_remain }) =>
        total_remain === 0 ? "0" : total_remain ? total_remain : "-",
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ dokhan }) =>
        dokhan === 0 ? "0" : dokhan ? dokhan : "-",
    },
    {
      field: "maintenance",
      headerName: globalLang.maintenance[lang],
      customContent: ({ maintenance }) =>
      maintenance === 0 ? "0" : maintenance ? maintenance : "-",
    },
    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ safi }) => (safi === 0 ? "0" : safi ? safi : "-"),
    },
  ];

  const dispatch = useDispatch();
  const handleCloseDetailsDialog = () => {
    setDistributionBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };
  const [ClientBillGetRequest, ClientBillGetResponse] = useRequest({
    path: DISTRIBUTIONBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getClientBills = () => {
    urlParams.append("completed", true);
    ClientBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "client_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object?.values(requestParams)?.map((item) =>
      item?.map(([key, value]) => urlParams.append(key, value))
    );

    getClientBills();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: DISTRIBUTIONBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({
      params: {
        completed: true,
      },
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "client_bill/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "factory",
      value: "",
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const filters = [
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
    {
      name: globalLang.product[lang],
      component: <ProductFilter />,
    },
  ];
  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };
  useAfterEffect(() => {
    if (!DistributionBillDetails.details) return;
    setDistributionBillDetails((old) => ({
      ...old,
      details: DistributionBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [DistributionBillStore]);

  const isPermitted = useIsPermitted();

  return (
    <>
      <Breadcrumbs
        path={[globalLang.distributionreceipt[lang], globalLang.view[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={DistributionBillStore.results}
        total={DistributionBillStore.count}
        isPending={ClientBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factoryclientdistribution']) }
        onEdit={isPermitted((e, row) => setEditData(row),['change_factoryclientdistribution','change_factoryhomeawayproduct'])}
        onView={isPermitted((e, row) => {
          setDistributionBillDetails((old) => ({
            ...old,
            details: row,
          }));
        },['view_factoryhomeawayproduct'])}
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(
          DistributionBillDetails.details &&
            DistributionBillDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={
          Boolean(DistributionBillDetails.details) &&
          DistributionBillDetails.details
        }
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewDistributionBill;

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  
  const columns = [
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ rowData }) =>
        rowData.product ? `${rowData.product?.name} ` : "-",
    },
    {
      field: "driven",
      headerName: globalLang.driven[lang],
      customContent: ({ rowData }) =>
        rowData?.driven === 0 ? "0" : rowData?.driven,
    },

    {
      field: "taken",
      headerName: globalLang.taken[lang],
      customContent: ({ rowData }) =>
        rowData?.taken === 0 ? "0" : rowData?.taken,
    },
    {
      field: "returned",
      headerName: globalLang.returned[lang],
      customContent: ({ rowData }) =>
        rowData?.returned === 0 ? "0" : rowData?.returned,
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ rowData }) =>
        rowData?.fill === 0 ? "0" : rowData?.fill,
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ rowData }) =>
        rowData?.price === 0 ? "0" : rowData?.price,
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ rowData }) =>
        rowData?.fillprice === 0 ? "0" : rowData?.fillprice,
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ rowData }) =>
        rowData?.paid === 0 ? "0" : rowData?.paid,
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ rowData }) =>
        rowData?.dokhan === 0 ? "0" : rowData?.dokhan,
    },

    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ rowData }) =>
        rowData?.remain_price === 0 ? "0" : rowData?.remain_price,
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData?.store === 0 ? "0" : rowData?.store,
    },

    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>


      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <Typography component="span" variant="p" sx={{ marginLeft: "20px" }}>
            {data?.client ? data?.client?.name : ""}
          </Typography>
          <Typography component="span" variant="p" sx={{ marginLeft: "20px" }}>
            {data?.created_at ? format(data.created_at) : ""}
          </Typography>
          <DialogTable
            rows={data?.client_bills?.filter(
              (bill) => bill?.product_type === "cylinder"
            )}
            columns={columns}
          />
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.client_bills?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};
export const UUIDFilter = ({ value, onChange }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["bill_uuid", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <NumberField
      placeholder={globalLang.receiptnumber[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const [Clients, setClients] = useState([]);
  const dispatch = useDispatch();
  const [FormData, setFormData] = useState([]);
  const getFormData = (data) => {
    setFormData([...data]);
  };
  ///////////////////////////////
  const columns = [
    // {
    //   field: "bill_uuid",
    //   headerName: globalLang.receiptnumber[lang],
    // },
    {
      field: "product",
      headerName: globalLang.product[lang],
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
    {
      field: "driven",
      headerName: globalLang.driven[lang],
    },
    {
      field: "taken",
      headerName: globalLang.taken[lang],
    },

    {
      field: "returned",
      headerName: globalLang.returned[lang],
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
    },
    // {
    //   field: "dokhan",
    //   headerName: globalLang.tips[lang],
    // },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "client",
        value: data?.client.id,
      },

      {
        control: "bill_date",
        value: data?.bill_date,
        isRequired: false,
      },

      {
        control: "bill_id",
        value: "",
        isRequired: false,
      },
      {
        control: "bills",
        value: [],
        isRequired: false,
      },
    ],
    [data]
  );
  const EditableColumns = ["driven", "taken", "returned", "price", "paid"];
  const [station_data, setData] = useState(data);
  useEffect(() => {
    data?.client_bills.map((bill, billindex) => {
      setControl("bills", (old) => [
        ...old,
        {
          driven: bill["driven"],
          taken: bill["taken"],
          returned: bill["returned"],
          price: bill["price"],
          paid: bill["paid"],
          // dokhan: bill["dokhan"],
        },
      ]);
    });
  }, [data?.client_bills]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  const [clientsGetRequest, clientsGetReponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    clientsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setClients(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);

  const handleEditBill = (id, index) => {
    const requestBody = filter({
      obj: {
        bill_id: id,
        products: [FormData[index]],
      },
      output: "object",
    });

    ClientBillPatchRequest({
      id: station_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "client_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };
  console.log(controls.bills);

  const [inform, setinform] = useState(false);
  const [ClientBillPatchRequest, ClientBillPatchResponse] = useRequest({
    path: DISTRIBUTIONBILL,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      const isThereChange = !compare(
        [
          [controls.bill_date, data.bill_date],
          [controls.client, data.client],
        ],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
            client: controls.client,
          },
          output: "object",
        });
        controls.bill_date !== data.bill_date
          ? (requestBody["bill_date"] = controls.bill_date.toISOString())
          : "";
        ClientBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "client_bill/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={() => {
          getClients(), getProducts();
        }}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
          <DialogSelectField
            placeholder={globalLang.client[lang]}
            label={globalLang.client[lang]}
      
            value={controls.client}
            onChange={(e) => setControl("client", e.target.value)}
            isPending={clientsGetReponse.isPending && productsGetResponse.isPending}
            renderValue={(selected) => {
              const client = Clients?.find(
                (client) => client?.id === selected
              )?.user;
              return client?.first_name + " " + client?.last_name;
            }}
          >
            {Clients?.map((client, index) => (
              <MenuItem value={client.id} key={`${client.id} ${index}`}>
                {client?.user?.first_name + " " + client?.user?.last_name}
              </MenuItem>
            ))}
          </DialogSelectField>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={globalLang.billDate[lang]}
              value={controls.bill_date}
              onChange={(newValue) => {
                setControl("bill_date", newValue);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      // borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      fontWeight: "400",
                      background: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                  required={required.includes("exit_date")}
                  error={Boolean(invalid.exit_date)}
                  helperText={invalid.exit_date}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <EditReceiptDialog
            data={data?.client_bills}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />
         
        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {ClientBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};

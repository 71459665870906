import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import AwayDistribution from "./AwayDistribution";
import ViewAwayDistribution from "./ViewAwayDistribution";
const AwayDistributionView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factoryhomeawaypermit']}>
        <AwayDistribution />
      </PermissionsGate>

      <PermissionsGate permissions={['view_factoryhomeawaypermit']}>
        <ViewAwayDistribution />
      </PermissionsGate>
    </Wrapper>
  );
};
export default AwayDistributionView;

import React from "react";
import PurchasesBill from "./PurchasesBill";
import PurchasesBillView from "./purchasesBillView";
import purchasesBillView from "./purchasesBillView";
function PurchaseBillOverView (){
    return(
        <>
            <PurchasesBill/>
            <PurchasesBillView/>
        </>
    )
}
export default PurchaseBillOverView
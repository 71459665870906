import { Box } from "@mui/material";

import React from "react";
import ReceiptBox from "../../components/ReceiptBox";
import AddClient from "../../assets/AddClient.png"
import AddTrader from "../../assets/AddTrader.png"
import AddStation from "../../assets/AddStation.png"
import { useSelector } from "react-redux";
const MainClients = () => {
  const userInfo = useSelector ((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const boxes = [
    {
      image: AddClient,
      title: "إضافة عميل",
      index: 0,
      perm: ["add_factoryclient", "view_factoryclient"],
      type: "clients",
    },
    {
      image: AddStation,
      title: "إضافة محطة",
      index: 1,
      perm: ["add_factorystation", "view_factorystation"],
      type: "clients",
    },
    {
      image: AddTrader,
      title: "إضافة تاجر",
      index: 2,
      perm: ["add_factorytrader", "view_factorytrader"],
      type: "clients",
    },
  ];

  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default MainClients;

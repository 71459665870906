import React from "react";
import AddTank from "./AddTank";
import ViewTankControl from "./ViewTankControl";
import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
function ViewTankPanel(){
    return(
    <Wrapper>
        <PermissionsGate permissions={['add_factorytank']}>
        <AddTank/>
        </PermissionsGate>
        <PermissionsGate permissions={['view_factorytank']}>
        <ViewTankControl/>
        </PermissionsGate>
       
        
    
    </Wrapper>
    )
}
export default ViewTankPanel
import React, { useEffect, useState } from "react";

import { InputField, SelectField } from "../features/form";

import useRequest from "../hooks/useRequest";
import useControls from "../hooks/useControls";
import {
  EMPLOYEES,
  PRODUCTS,
  STATIONEXITBILL,
  STATIONS,
  STOCK,
  TraderReceiptShipGas,
  TRANSPORT,
} from "../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Stack, Box, Typography, Tooltip } from "@mui/material";
import filter from "../utils/ClearNull";

import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveIcon from "@mui/icons-material/Remove";


const AddProduct = ({
 
  type,
  columns = [],
  rows = [],
  getformData,
  success,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----

  const productsStore = useSelector((state) => state.stocks.value);

  const dispatch = useDispatch();

  const [bills, setbills] = useState([]);
  const [liquidbills, setliquidbills] = useState([]);
  const [GasRows, setGasRows] = useState([]);
  const [LiquidRows, setLiquidRows] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const gasProducts = productsStore?.results?.filter(
    (product) => product.product_type === "cylinder"
  );
  const LiquidProducts = productsStore?.results?.filter(
    (product) => product.product_type === "dewar"
  );

  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);

  const handleAddGasRow = () => {
    setGasRows([...GasRows, {}]);
  };
  const handleAddLiquidRow = () => {
    setLiquidRows([...LiquidRows, {}]);
  };
  useEffect(() => {
    type === "cylinder" ? getformData(bills) : getformData(liquidbills);
  }, [bills, liquidbills]);
  useEffect(() => {
    if (success) {
      setGasRows([]);
      setLiquidRows([]);
    }
  }, [success]);
  const handleRemoveGasRow = (index) => {
    let gas_data = [...GasRows];
    gas_data.splice(index, 1);
    setGasRows(gas_data);
  };
  const handleRemoveLiquidRow = (index) => {
    let liquid_data = [...LiquidRows];
    liquid_data.splice(index, 1);
    setLiquidRows(liquid_data);
  };
console.log(bills,liquidbills)
  //----states----
  const handleChange = (event, index, type) => {
    let data = [...GasRows];

    data[index][event.target.name] =
      event.target.name === "product_type"
        ? event.target.value
        : parseFloat(event.target.value);
    data[index]["product_type"] = type;

    setbills(data);
  };
  const handleLiquidChange = (event, index, type) => {
    let liquid_data = [...LiquidRows];

    liquid_data[index][event.target.name] =
      event.target.name === "product_type"
        ? event.target.value
        : parseFloat(event.target.value);
    liquid_data[index]["product_type"] = type;

    setliquidbills(liquid_data);
  };

  //----request hooks----
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });

  //----functions----
  const getProducts = () => {
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          margin: "10px",
          padding: "10px",
          height: "fit-content",
          width: "auto",

          background: "#233975",
          "& .MuiTableCell-root": {
            borderBottom: "none !important",
          },
        }}
      >
        <Typography component="span" variant="p"  sx={{ flexGrow: "1", color: "white" }}>
          {type === "cylinder" ? "الغازات" : "السوائل"}
        </Typography>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "white",
                  }}
                >
                  {globalLang[column.value][lang]}
                </TableCell>
              ))}
              <TableCell>
                <Tooltip title="أضف منتج ">
                  <AddIcon
                    htmlColor="lightgreen"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      type === "cylinder"
                        ? handleAddGasRow()
                        : handleAddLiquidRow()
                    }
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {type === "cylinder"
              ? GasRows.map((row, index) => (
                  <TableRow
                    key={`${row.name}${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {columns.map((column, columnIndex) =>
                      column.name === "product" ? (
                        <TableCell
                          key={columnIndex}
                          sx={{
                            "& .MuiSelect-select": {
                              background: "white",
                              borderRadius: "5px",
                            },
                          }}
                        >
                          <SelectField
                            placeholder={globalLang.product[lang]}
                            renderValue={(selected) => {
                              return productsStore?.results?.find(
                                (s) => s?.product?.id === selected
                              )?.product?.name;
                            }}
                            name={column.name}
                            isPending={productsGetResponse.isPending}
                            onOpen={getProducts}
                            onChange={(e) => {
                              handleChange(e, index, type);
                            }}
                          >
                            {gasProducts?.map((product, Gasindex) => (
                              <MenuItem
                                value={product?.product?.id}
                                key={`${product?.product?.name} ${Gasindex}`}
                              >
                                {product?.product?.name}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={columnIndex}
                          sx={{
                            "& .MuiInputBase-input": {
                              background: "white",
                              borderRadius: "5px",
                            },
                          }}
                        >
                          <InputField
                            name={column.name}
                            type="number"
                            onChange={(e) => {
                              handleChange(e, index, type);
                            }}
                          ></InputField>
                        </TableCell>
                      )
                    )}
                    <TableCell>
                      <Tooltip title="حذف">
                        <RemoveIcon
                          htmlColor="red"
                          onClick={() => handleRemoveGasRow(index)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              : LiquidRows.map((liquidrow, index) => (
                  <TableRow
                    key={`${liquidrow.name}${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {TableColumns.map((column, columnIndex) =>
                      column.name === "product" ? (
                        <TableCell
                          key={columnIndex}
                          sx={{
                            "& .MuiSelect-select": {
                              background: "white",
                              borderRadius: "5px",
                            },
                          }}
                        >
                          <SelectField
                            placeholder={globalLang.product[lang]}
                            renderValue={(selected) => {
                              return productsStore?.results?.find(
                                (s) => s?.product?.id === selected
                              )?.product?.name;
                            }}
                            name={column.name}
                            isPending={productsGetResponse.isPending}
                            onOpen={getProducts}
                            onChange={(e) => {
                              handleLiquidChange(e, index, type);
                            }}
                          >
                            {LiquidProducts?.map((product, Gasindex) => (
                              <MenuItem
                                value={product?.product?.id}
                                key={`${product?.product?.name} ${Gasindex}`}
                              >
                                {product?.product?.name}
                              </MenuItem>
                            ))}
                          </SelectField>
                        </TableCell>
                      ) : (
                        <TableCell
                          key={columnIndex}
                          sx={{
                            "& .MuiInputBase-input": {
                              background: "white",
                              borderRadius: "5px",
                            },
                          }}
                        >
                          <InputField
                            name={column.name}
                            type="number"
                            onChange={(e) => {
                              handleLiquidChange(e, index, type);
                            }}
                          ></InputField>
                        </TableCell>
                      )
                    )}
                    <TableCell>
                      <Tooltip title="حذف">
                        <RemoveIcon
                          htmlColor="red"
                          onClick={() => handleRemoveLiquidRow(index)}
                          sx={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AddProduct;

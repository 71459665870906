import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";

import { COUNTRY_FILTER, STOCK, TANKSHIPRECETPI } from "../../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogNumberField,
} from "../../../features/dialog";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import compare from "../../../utils/Compare";
import format from "../../../utils/ISOToReadable";

const ViewTankReceipts = () => {
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);
 
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.StockMessage
  );

  ///////////////////////////////
  const tanksReceiptsStore = useSelector((state) => state.tank_ship_receipt.value);

  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => (bill_date ? format(bill_date) : "-"),
    },
    {
      field: "station",
      headerName: globalLang.station[lang],
      customContent: ({ station }) => (station ? station?.name : "-"),
    },
    {
      field: "tank",
      headerName: globalLang.tank[lang],
      customContent: ({ tank }) => (tank ? tank?.product : "-"),
    },
  
    {
      field: "empty",
      headerName: globalLang.empty[lang],
      customContent: ({ empty }) => (empty === 0 ? "0" : empty),
    },
    {
      field: "filled",
      headerName: globalLang.filled[lang],
      customContent: ({ filled }) => (filled === 0 ? "0" : filled),
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) => (price ? price : "0"),
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid ? paid : "0"),
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: (params) => {
        return `${params?.dokhan ? params?.dokhan : "-"}`;
      },
    },
  ];

  const dispatch = useDispatch();

  const [tankReceiptGetRequest, tankReceiptGetResponse] = useRequest({
    path: TANKSHIPRECETPI,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getReceipts = () => {
    tankReceiptGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "tank_ship_receipt/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getReceipts();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [TankShipreceiptDeleteRequest, TankShipReceiptDeleteResponse] = useRequest({
    path: TANKSHIPRECETPI,
    method: "delete",
    successMessage: "تم حذف الفاتورة",
  });

  const deleteReceipt = (e, row) => {
    TankShipreceiptDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "tank_ship_receipt/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeletereceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "factory",
      value: "",
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = () => {
    const requestBody = filter({
      obj: {
        name: controls.name,
        logo: controls.logo,
        url: controls.website,
        // state: controls.governorate,
        // city: controls.city,
        // area: controls.district,
        // part: controls.near,
        // flat_number: controls.number,
        // unit_type: controls.type,
        // sales_type: controls.sale,
        // floor_number: controls.floor,
        // area_size: controls.area,
        // room_number: controls.rooms,
        // bath_count: controls.bathrooms,
        // complete_type: controls.genre,
        // price: controls.price,
        // client: controls.client,
      },
    });

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody),
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  //   const getCountries = () => {
  //     countriesGetRequest({
  //       onSuccess: (res) => {
  //         setCountriesData(res.data.data);
  //       },
  //     });
  //   };

  //   const [governoratesData, setGovernoratesData] = useState([]);

  //   const [statesGetRequest, statesGetResponse] = useRequest({
  //     path: STATES,
  //     method: "post",
  //   });

  //   const getGovernorates = () => {
  //     statesGetRequest({
  //       body: {
  //         country: controls.country,
  //       },
  //       onSuccess: (res) => {
  //         setGovernoratesData(res.data.data);
  //       },
  //     });
  //   };

  //===End===== Get Countries logic =========

  return (
    <>
      <Breadcrumbs
        path={[globalLang.TankShipReceipt[lang], globalLang.view[lang]]}
      />

      <DataGrid
        columns={columns}
        rows={tanksReceiptsStore.results}
        total={tanksReceiptsStore.count}
        isPending={tankReceiptGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        // onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        // onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onDelete={handleDeletereceipt}
        // onEdit={(e, row) => setEditData(row)}
        // onView={(e, row) => setInfoData(row)}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      {/* <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      /> */}
      {deleteReceiptConfirmDialog}
      {TankShipReceiptDeleteResponse.successAlert}
      {TankShipReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewTankReceipts;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "station",
        value: data?.satation.id,
        isRequired: false,
      },

      {
        control: "tank",
        value: data?.tank.id,
        isRequired: false,
      },
      {
        control: "empty",
        value: data?.empty,
        isRequired: false,
      },
      {
        control: "filled",
        value: data?.filled,
        isRequired: false,
      },
      {
        control: "price",
        value: data?.price,
        isRequired: false,
      },
      {
        control: "paid",
        value: data?.paid,
        isRequired: false,
      },
      {
        control: "dokhan",
        value: data?.dokhan,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [stockPatchRequest, stockPatchResponse] = useRequest({
    path: STOCK,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.factorypower, data.factorypower],

        // [controls.district, data.area],
        // [controls.near, data.part],
        // [controls.address, data.address],
        // [controls.number, data.flat_number],
        // [controls.type, data.unit_type],
        // [controls.floor, data.floor_number],
        // [controls.area, data.area_size],
        // [controls.rooms, data.room_number],
        // [controls.bathrooms, data.bath_count],
        // [controls.genre, data.complete_type],
        // [controls.price, data.price],
        // [controls.client, data.client],
        // [controls.phone, data.phone_client],
        // [controls.countryCode, data.country_code_phone_client],
        // [controls.notes, data.comment],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          factorypower: controls.factorypower,

          // area: controls.district,
          // part: controls.near,
          // flat_number: controls.number,
          // unit_type: controls.type,
          // sales_type: controls.sale,
          // floor_number: controls.floor,
          // area_size: controls.area,
          // room_number: controls.rooms,
          // bath_count: controls.bathrooms,
          // complete_type: controls.genre,
          // price: controls.price,
          // client: controls.client,
          // phone_client: controls.countryCode + controls.phone,
          // comment: controls.notes,
        },
        output: "formData",
      });

      stockPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "stocks/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };
  //===Start===== Get Countries logic =========
  // const [countriesData, setCountriesData] = useState([]);

  // const [countriesGetRequest, countriesGetResponse] = useRequest({
  //   path: COUNTRY_FILTER,
  //   method: "get",
  // });

  // const getCountries = () => {
  //   if (countriesData.length) return;
  //   countriesGetRequest({
  //     onSuccess: (res) => {
  //       setCountriesData(res.data.data);
  //     },
  //   });
  // };

  // const [governoratesData, setGovernoratesData] = useState([]);

  // const [statesGetRequest, statesGetResponse] = useRequest({
  //   path: STATES,
  //   method: "post",
  // });

  // const getGovernorates = () => {
  //   if (governoratesData.length) return;
  //   statesGetRequest({
  //     body: {
  //       country: controls.country,
  //     },
  //     onSuccess: (res) => {
  //       setGovernoratesData(res.data.data);
  //     },
  //   });
  // };

  //===End===== Get Countries logic =========

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.editstockData[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.factorypower[lang]}
          placeholder={globalLang.factorypower[lang]}
          value={controls.factorypower}
          onChange={(e) => setControl("factorypower", e.target.value)}
        />
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={stockPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {stockPatchResponse.failAlert}
    </Dialog>
  );
};

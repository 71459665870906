import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import FillingBill from "./FillingBill";
import ViewFillingBill from "./ViewFillingBill";
const FillingBillView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factorytraderbill']}>
        <FillingBill />
      </PermissionsGate>

      <PermissionsGate permissions={['view_factorytraderbill']}>
        <ViewFillingBill />
      </PermissionsGate>
    </Wrapper>
  );
};
export default FillingBillView;

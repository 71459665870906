import React, { useEffect, useState } from "react";

import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { PRODUCTS, SUMMARYEXPENSES } from "../../data/APIs";
import { InputField, SelectField } from "../../features/form";
import { Box, MenuItem } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { STATIONS, SUMMARYSTATION } from "../../data/APIs";
import { ExpensesFilter } from "../Receipts/Expenses/ViewExpensesReceipts";

const ExpensesBillOverview = ({ period }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);


  //format Date
  const getDate = () => {
    var today = new Date();
    let date = "";
    if (period === "d") {
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    } else if (period === "m") {
      date = today.getFullYear() + "-" + (today.getMonth() + 1);
    } else if (period === "a") {
      date = today.getFullYear() ;
    } 
    else {
      date = today.getFullYear();
    }

    return date
  };
  const [date, setdate] = useState(getDate());
  const [params, setparams] = useState({ date: date, page: 1, size: period==='m'&&30 ||period==='a'&&12});
  ///////////////////////////////

  const dispatch = useDispatch();
  const SummaryExpensesBillStore = useSelector(
    (state) => state.summary_expenses.value
  );

  const [summaryExpensesBillGetRequest, summaryExpensesBillGetResponse] =
    useRequest({
      path: SUMMARYEXPENSES,
      method: "get",
    });
  // Filters
  let filters = [
    {
      name: globalLang.expenses[lang],
      component: <ExpensesFilter />,
    },

  ];
  useEffect(() => {
    const urlParams = new URLSearchParams();
    Object.keys(params).map((key) => urlParams.append(key, params[key]));
    summaryExpensesBillGetRequest({
      id: period,
      params: urlParams,

      onSuccess: (res) => {
        dispatch({ type: "summary-expenses/set", payload: res.data });
      },
    });
  }, [params]);

  
  /////////////////
  const handleChangeAmount = ({ value }) => {
    setparams((old) => ({
      ...old,
      page: 1,
      size: value,
    }));
  };
  const handlePaginate = (params) => {
    console.log(params);
    setparams((old) => ({
      ...old,
      page: params.current,
    }));
  };
  const handleFilter = (filters) => {
    const filter = filters.map(({ query }) => query);
    let values = Object.values(filter).map(([key, value]) => {
      return { [key]: value };
    });
    const object = Object.assign({}, ...values);

    setparams({
      date: params.date,
      page: params.page,
      size: params.size,
      ...object,
    });
  };


  //filter using date
  const handleChangeFilter = (date) => {
    let newDate = "";

    if (period === "d") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}-${date["$D"]}`;
    } else if (period === "m") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}`;
    }else if (period === "m") {
      newDate = `${date["$y"]}`;
    } 
    else {
      newDate = date["$y"];
    }

    setparams((old) => ({
      ...old,
      date: newDate,
    }));
  };

  // Columns name
  let columns = [
    {
      field: "created_date",
      headerName: period ==="a" ?globalLang.month[lang]:globalLang.date[lang],
      customContent: ({ created_date }) => (
         created_date ? created_date.split("T")[0] : "-"),
    },
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
      customContent: ({ bill_uuid }) => (bill_uuid ? bill_uuid : "-"),
    },
    {
      field: "category",
      headerName: globalLang.deparment[lang],
      customContent: ({ category }) => (
        category ?
         category==="distribution"&&"توزيع"||category==="filling"&&"تعبئة"||category==="shipping"&&"شحن" : "-"),
    },
    {
      field: "expense_type",
      headerName: globalLang.expenses[lang],
      customContent: ({ expense_type }) => (expense_type ? expense_type : "-"),
    },
    {
      field: "price",
      headerName: globalLang.expensesPrice[lang],
      customContent: ({ price }) => (price ? price : "-"),
    },
    {
      field: "comment",
      headerName: globalLang.comment[lang],
      customContent: ({ comment }) => (comment ? comment : "-"),
    },
    
  ];
  
  if (period === "m") {
    columns = columns.filter(
      (obj) =>
      obj.field !== "expense_type" &&
      obj.field !== "comment" &&
      obj.field!=="bill_uuid"
    );
  }
  else if (period === "a") {
    columns = columns.filter((obj) =>
    obj.field !== "expense_type" &&
    obj.field !== "comment"&&
    obj.field!=="bill_uuid"
    )
  }else if (period === "d") {
    columns = columns.filter(
      (obj) =>
      obj.field!=="created_date" 
    )
  }
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          openTo={period === "d" ? "day" : "year"}
          views={
            period === "d"
              ? ["year", "month", "day"]
              : period === "m"
              ? ["year", "month"]
              : ["year"]
              
          }
          
          value={params.date.toString()}
          onChange={(date) => {
            handleChangeFilter(date);
          }}
          renderInput={(params) => (
            <InputField
              sx={{
                width: "20%",
                margin: "15px",
                width: {
                  lg: "auto",
                  xl: "auto",
                  md: "auto",
                  sm: "90%",
                  xs: "90%",
                },
                "& .MuiInputBase-root": {
                  borderColor: "#F0F0F8",
                  paddingRight: "5px",
                },
                "& .MuiFormLabel-root": {
                  color: "#666666",
                },
                
              }}
              
              {...params}
              
            />
            
          )}
        />
        
      </LocalizationProvider>

      <DataGrid
        columns={columns}
        sum={SummaryExpensesBillStore.total}
        rows={SummaryExpensesBillStore.results}
        total={SummaryExpensesBillStore.count}
        isPending={summaryExpensesBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
      />
    </Box>
  );
};

export default ExpensesBillOverview;

const ProductFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [products, setProducts] = useState([]);

  const handleChange = (e) => {
    // func({ product: e.target.value });
    onChange({
      query: ["product", e.target.value],
      renderedValue: products.find((p) => p.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.product[lang]}
      renderValue={(selected) => products.find((p) => p.id === selected).name}
      onOpen={getProducts}
      isPending={productsGetResponse.isPending}
      onChange={handleChange}
    >
      {products.map((product, index) => (
        <MenuItem value={product.id} key={`${index}`}>
          {product.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cASTOM_FACTORY_Safe } from "../../data/APIs";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { InputField } from "../../features/form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Print from "../../components/PrintComponent";

function CustomSafeFactory({ backdropColor = null, indicatorColor = null }) {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const getEndDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const factorySafeStore = useSelector((state) => state.factorySafe.value);
  const [balanceData, setBalanceData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [salesBill, setSalesBill] = useState([]);
  const [purchaseBill, setPurchaseBill] = useState([]);

  const [balance, setBalance] = useState({
    total: null,
    total_before: null,
    day_total: null,
  });
  const [loading, setLouding] = useState(true);
  const [filterdata, setfilterdata] = useState({
    Date: getDate(),
  });
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "date",
      value: getDate(),
      isRequired: false,
    },
    {
      control: "end_date",
      value: getEndDate(),
      isRequired: false,
    },
    {
      control: "day_total",
      value: "",
      isRequired: true,
    },
    {
      control: "clients",
      value: "",
      isRequired: true,
    },
    {
      control: "traders",
      value: "",
      isRequired: true,
    },
    {
      control: "total",
      value: "",
      isRequired: true,
    },
    {
      control: "safe_before",
      value: "",
      isRequired: false,
    },
    {
      control: "expenses",
      value: "",
      isRequired: true,
    },
    {
      control: "stations",
      value: "",
      isRequired: true,
    },
  ]);
  const urlParams = new URLSearchParams();
  const [safeFactoryGetRequest, safeFactoryGetResponse] = useRequest({
    path: cASTOM_FACTORY_Safe,
    method: "get",
  });
  const getFactorySafe = () => {
    // if (Boolean(factorySafeStore.results.length)) return;
    // if (controls.date) {
    urlParams.append("start", controls.date);
    urlParams.append("end", controls.end_date);
    // }
    safeFactoryGetRequest({
      params: urlParams,

      onSuccess: (res) => {
        setLouding(false);
        dispatch({ type: "factorySafe/set", payload: res.data });
        setBalanceData(res.data.balance);
        setPaymentData(res.data.payment);
        setBalance({
          total: res.data.total,
        });
        console.log("sale_bills" in res.data.balance);
        "sale_bills" in res.data.balance &&
          setSalesBill(res.data.balance.sale_bills);

        "purchase_bills" in res.data.payment &&
          setPurchaseBill(res.data.payment.purchase_bills);
      },
    });
  };
  const [requestParams, setRequestParams] = useState({});

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getFactorySafe();
  }, [controls.date, controls.end_date, loading]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 3,
          flexDirection: {
            xl: "row",
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            },
            width: { xl: "48%", lg: "48%", md: "48%", sm: "100%", xs: "100%" },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.periodFrom[lang]}
              value={controls.date}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("date", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "45%",
                    width: {
                      xl: "45%",
                      lg: "45%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    ml: {
                      xl: 1,
                      lg: 1,
                      md: "auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      mb: 2,
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.to[lang]}
              value={controls.end_date}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("end_date", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "45%",
                    width: {
                      xl: "45%",
                      lg: "45%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    ml: {
                      xl: 1,
                      lg: 1,
                      md: "auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      mb: 2,
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
       
          <Stack
            gap={2}
            sx={{
              width: {
                xl: "50%",
                lg: "50%",
                md: "50%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography>
                <Typography sx={{ textAlign: "start" }}>
                  {globalLang.payment[lang]}
                </Typography>
                <Typography sx={{ textAlign: "end" }}>
                  {paymentData?.range_total}
                </Typography>
              </Typography>
              <LinearProgress
                variant="determinate"
                value={50}
                color="secondary"
                sx={{
                  height: "8px",
                  width: "100%",

                  p: 0,
                  borderRadius: "71.5px",
                }}
              />
            </Box>
            <Box width={"100%"}>
              <Typography>
                <Typography sx={{ textAlign: "start" }}>
                  {globalLang.balance[lang]}
                </Typography>
                <Typography sx={{ textAlign: "end" }}>
                  {balanceData?.range_total}
                </Typography>
              </Typography>
              <LinearProgress
                variant="determinate"
                value={50}
                sx={{
                  height: "8px",
                  width: "100%",
                  p: 0,
                  borderRadius: "71.5px",
                }}
              />
            </Box>
          </Stack>
        
      </Box>
      {loading ? (
        <Box
          sx={{
            bgcolor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress
            sx={{ color: indicatorColor ? indicatorColor : "black" }}
          />
        </Box>
      ) : (
        <Card
          sx={{
            width: "100%",
            // flexWrap: "wrap",
            border: "none",
            borderRadius: 4,
            boxShadow: "10px 10px 10px 10px rgba(0,0,0,0.1)",
            boxSizing: "border-box",
            position: "relative",
            backgroundColor: "#fff",
            overflowX: {
              xl: "hidden",
              lg: "hidden",
              md: "hidden",
              ms: "scroll",
              xs: "scroll",
            },
          }}
        >
          <Print
            sx={{
              bgcolor: "#090979",
              borderRadius: "8px",
              width: "20%",

              ":hover": { bgcolor: "#090979" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: {
                  xl: "flex",
                  lg: "flex",
                  md: "block",
                  sm: "block",
                  sx: "block",
                },
                // flexWrap: "wrap",
                border: "none",
                borderRadius: 4,
                boxSizing: "border-box",
                position: "relative",
                backgroundColor: "#fff",
              }}
            >
              <Table
                sx={{
                  width: {
                    xl: "50%",
                    lg: "50%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  borderLeftColor: {
                    xl: "#30475E",
                    lg: "#30475E",
                    md: "none",
                    sm: "none",
                    sx: "none",
                  },
                  ".MuiTableRow-head": {
                    padding: 2,
                    textAlign: "center",
                    bgcolor: "#090979",
                  },
                  ".MuiTableRow-root": {
                    width: "100%",
                  },
                  ".MuiTableCell-head": {
                    color: "#FFFFFF",
                    fontFamily: "cairo , sans-serif",
                    fontWeight: "700",
                    textAlign: "center",
                    Width: "100%",
                  },
                  ".MuiTableCell-body": {
                    textAlign: "center",
                    width: "50%",
                    cursor: "pointer",
                    borderBottom: "none",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" colSpan={2}>
                      {globalLang.balance[lang]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{globalLang.thevalue[lang]}</TableCell>
                    <TableCell>{globalLang.statement_bill[lang]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {balanceData?.clients}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.Destribution[lang]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {balanceData?.traders}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.Filling[lang]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {balanceData?.finance}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.financebill[lang]}
                    </TableCell>
                  </TableRow>
                  {salesBill.length > 0 &&
                    salesBill.map((bill) => {
                      return (
                        <>
                          <TableRow key={bill.index}>
                            <TableCell
                              sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                            >
                              {bill?.total_paid}
                            </TableCell>
                            <TableCell
                              sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                            >
                              {bill?.statement}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  <TableRow sx={{ height: "100%" }}>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      0
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.others[lang]}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Table
                sx={{
                  width: {
                    xl: "50%",
                    lg: "50%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  ".MuiTableRow-head": {
                    padding: 2,
                    textAlign: "center",
                    bgcolor: "#090979",
                  },
                  ".MuiTableRow-root": {
                    width: "100%",
                  },
                  ".MuiTableCell-head": {
                    color: "#FFF",
                    fontFamily: "cairo , sans-serif",
                    fontWeight: "700",
                    textAlign: "center",
                    Width: "100%",
                  },
                  ".MuiTableCell-body": {
                    textAlign: "center",
                    width: "50%",
                    cursor: "pointer",
                    borderBottom: "none",
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" colSpan={2}>
                      {globalLang.payment[lang]}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{globalLang.thevalue[lang]}</TableCell>
                    <TableCell>{globalLang.statement_bill[lang]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {paymentData?.expenses}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.expenses_safe[lang]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {paymentData?.stations}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.stations[lang]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {paymentData?.station_tank_bills}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.tankShiping[lang]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {paymentData?.deposit}
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.depositebill[lang]}
                    </TableCell>
                  </TableRow>

                  {purchaseBill.length > 0 &&
                    purchaseBill.map((bill) => {
                      return (
                        <>
                          <TableRow key={bill.index}>
                            <TableCell
                              sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                            >
                              {bill?.total_paid}
                            </TableCell>
                            <TableCell
                              sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                            >
                              {bill?.statement}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  <TableRow sx={{ height: "100%" }}>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      0
                    </TableCell>
                    <TableCell
                      sx={{ ":hover": { backgroundColor: "#f8f9fa" } }}
                    >
                      {globalLang.others[lang]}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>

            <Divider sx={{ mx: "5%" }} />

            <Table
              sx={{
                width: {
                  xl: "100%",
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  sx: "100%",
                },
                ".MuiTableCell-body": {
                  borderBottom: "none",
                },
                ".MuiTableRow-root": {
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100",
                    sm: "100%",
                    sx: "100%",
                  },
                },
                ".MuiTableCell-root": {
                  width: {
                    xl: "25%",
                    lg: "25%",
                    md: "50%",
                    sm: "50%",
                    sx: "50%",
                  },
                  flexWrap: "wrap",
                  height: "max-content",
                  textAlign: "center",
                  ":hover": {
                    backgroundColor: "#f8f9fa",
                    cursor: "pointer",
                  },
                },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell sx={{ color: "#4339F2", borderBottom: "1px" }}>
                    {globalLang.day_total_balance[lang]}
                  </TableCell>
                  <TableCell>{balanceData?.range_total}</TableCell>
                  <TableCell sx={{ color: "secondery" }}>
                    {globalLang.day_total_payment[lang]}
                  </TableCell>
                  <TableCell>{paymentData?.range_total}</TableCell>
                </TableRow>
                <TableRow sx={{ borderBottomColor: "#000" }}>
                  <TableCell></TableCell>
                  <TableCell
                    sx={{ fontSize: "16px", fontWeight: 700, color: "#181823" }}
                  >
                    {globalLang.safe_balance[lang]}
                  </TableCell>
                  <TableCell>
                    <Button
                      sx={{ backgroundColor: "#CCCCCC", maxWidth: "content" }}
                    >
                      {balance?.total}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Print>
        </Card>
      )}
    </>
  );
}
export default CustomSafeFactory;

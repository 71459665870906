import { createSlice } from "@reduxjs/toolkit";

export const NotificationSLice = createSlice({
  name: "notifications",
  initialState: {
    value: {
      results:[]
    
    },
  },
  reducers: {
    set: (state, action) => {
      state.value=action.payload
    },
    reset: (state) => {
      state.value =[]
    },
    deleteItem: (state, action) => {
      state.value.results = state.value.results.filter(
        (item) => item.id !== action.payload.id
      );
      state.value.count = state.value.count - 1;
    },
    // addItem: (state, action) => {
    //   state.value.results = [action.payload, ...state.value.results];
    // },
    // putItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results.splice(index, 1, action.payload.item);
    // },
    // patchItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results.splice(index, 1, {
    //     ...state.value.results[index],
    //     ...action.payload.item,
    //   });
    // },
    // blockItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results[index] = {
    //     ...state.value.results[index],
    //     isblock: !state.value.results[index].isblock,
    //   };
    // },
  },
});

export default NotificationSLice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const LoansStockSlice = createSlice({
  name: "loans-stock",
  initialState: {
    value: {
      // results: [],
      clients: [],
      stations: [],
      traders: [],
    },
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
    reset: (state) => {
      state.value = {
        count: 0,
        next: null,
        previous: null,
        // results: [],
        clients: [],
        stations: [],
        traders: [],
      };
    },
    // putItem: (state, action) => {
    //   const index = state.value.results.findIndex(
    //     (item) => item.id === action.payload.id
    //   );
    //   state.value.results.splice(index, 1, action.payload.item);
    // },
    deleteItem: (state, action) => {
      if (action.payload.type==='client'){
       
      state.value.clients = state.value.clients.filter(
        (item) => item.id !== action.payload.id
      );
      }else if(action.payload.type==='station'){
      state.value.stations = state.value.stations.filter(
        (item) => item.id !== action.payload.id
      );
    }else if(action.payload.type==='trader'){
      state.value.traders = state.value.traders.filter(
        (item) => item.id !== action.payload.id
      );
      state.value.count = state.value.count - 1;
    }
    },
    addItem: (state, action) => {
      state.value.clients = [action.payload, ...state.value.clients];
      state.value.stations = [action.payload, ...state.value.stations];
      state.value.traders = [action.payload, ...state.value.traders];
    },
    patchItem: (state, action) => {
    
      if (action.payload.type==='client'){
       
        const indexc = state.value.clients.findIndex(
          (item) => item.id === action.payload.id
        );
       state.value.clients[indexc]['owned_number']=action.payload.item
      }
   
      else if(action.payload.type==='station'){
      const indexs = state.value.stations.findIndex(
        (item) => item.id === action.payload.id
      )
      state.value.stations[indexs]['owned_number']=action.payload.item
      }
      
    else if(action.payload.type==='trader'){
      const indext = state.value.traders.findIndex(
        (item) => item.id === action.payload.id
      );
      state.value.traders[indext]["owned_number"]=action.payload.item
    }
    },
  
  },
});

export default LoansStockSlice.reducer;


import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import { Purchases_BILL } from "../../../data/APIs";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";

function PurchasesBillView() {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const purchaseBillStore = useSelector((state) => state.purchasebill.value);
//   const [filterdata, setfilterdata] = useState({
//     Date: new Date(),
//   });
  const [purchaseBillDetails, setPurchaseBillDetails] = useState({
    details: null,
    tab: "details",
  });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.FactoryPowerMessage
  );
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();

  const columns = [
    {
          field: "bill_date",
          headerName: globalLang.Date[lang],
          customContent: ({ bill_date }) =>
          bill_date? format(bill_date) : "-",
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid ? paid : "-"),
    },
    {
      field: "bill_uuid",
      headerName: globalLang.bill_uuid[lang],
      customContent: ({ bill_uuid }) => (bill_uuid ? bill_uuid : "-"),
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) =>
      statement? statement : "-",
    },
    {
      field: "type",
      headerName: globalLang.thetype[lang],
      customContent: ({ type }) =>
      type? type ==="C"?"وساطة":"غير وساطة" : "-",
    },
    {
      field: "station",
      headerName: globalLang.station[lang],
      customContent: ({ station }) =>
      station ? station : "-",
    },
    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ trader }) =>
      trader ? trader : "-",
    },
    {
      field: "tank",
      headerName: globalLang.tank[lang],
      customContent: ({ tank }) =>
      tank? tank : "-",
    },
  ];
  const [PurchaseBillGetRequest, PurchaseBillGetResponse] = useRequest({
    path: Purchases_BILL,
    method: "get",
  });

  // const [stockDetailsGetRequest, stockDetailsGetResponse] = useRequest({
  //   path: STOCK_OUTS,
  //   method: "get",
  // });

  const [{ controls }, { setControl }] = useControls([
    {
      control: "date",
      value: getDate(),
      isRequired: false,
    },
  ]);

  const getPurchaseBill = () => {
    // filterdata.stock && urlParams.append("id", filterdata.stock);
    // if (controls.date) {
    //   urlParams.append("date", controls.date);
    // }
    PurchaseBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "purchasebill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getPurchaseBill();
  }, [requestParams, controls.date]);

  useAfterEffect(() => {
    if (!purchaseBillDetails.details) return;
    setPurchaseBillDetails((old) => ({
      ...old,
      details: purchaseBillStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [purchaseBillStore]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
//   const [FACTORYpOWERDeleteRequest, FACTORYPOWERDeleteResponse] = useRequest({
//     path: DELETE_FACTORY_POWER,
//     method: "delete",
//     successMessage: "تم حذف الرصيد بنجاح",
//   });

//   const deleteTank = (e, row) => {
//     FACTORYpOWERDeleteRequest({
//       id: row.id,
//       onSuccess: (res) => {
//         dispatch({ type: "stock-balance/deleteItem", payload: { id: row.id } });
//       },
//     });
//   };

//   const [handleDeleteFactoryPower, deleteTankConfirmDialog] = useConfirmMessage({
//     onConfirm: deleteTank,
//     text: confirmMessage[lang],
//   });
//   const getStockDetails = () => {
//     PowerFactoryGetRequest({
//       id: powerDetails.details.id,

//       onSuccess: (res) => {
//         dispatch({ type: "purchasebill/set", payload: res.data });
//         console.log(res.data);
//       },
//     });
//   };
//   const handleCloseDetailsDialog = () => {
//     setPurchaseBillDetails((old) => ({ ...old, details: null, tab: "details" }));
//   };

  return (
    <>
      <Box
      sx={{my:3}}>
      <Breadcrumbs
        path={[globalLang.purchases_bill[lang], globalLang.view[lang]]}
       />
     
      <DataGrid
        columns={columns}
        rows={purchaseBillStore.results}
        total={purchaseBillStore.count}
        isPending={PurchaseBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        sum={purchaseBillStore.total}
        // onDelete={handleDeleteFactoryPower}
      />
      {/* {deleteTankConfirmDialog}
      {FACTORYPOWERDeleteResponse.successAlert}
      {FACTORYPOWERDeleteResponse.failAlert} */}
      </Box>
      
    </>
  );
}
export default PurchasesBillView;


import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import useControls from "../../hooks/useControls";
import Form, {
  InputField,
  PasswordField,
  PhoneField,
  SelectField,
} from "../../features/form";
import useRequest from "../../hooks/useRequest";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, MenuItem, Stack } from "@mui/material";
import PendingBackdrop from "../../components/PendingBackdrop";
import PermissionToggles from "../../components/PermissionToggles";
import { EMPLOYEES, JOBS, JOB_PERMISSIONS } from "../../data/APIs";
import useAfterEffect from "../../hooks/useAfterEffect";
import filter from "../../utils/ClearNull";

import AddIcon from "@mui/icons-material/Add";
const AddEmployees = () => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addEmployeesLang = useSelector(
    (state) => state.lang.value.addEmployees
  );
  const global = useSelector((state) => state.lang.value.global);
  const [phones, setPhones] = useState([{ phone: "" }]);
  const [codes, setCodes] = useState([{ code: "" }]);
  ///////////////////////////////
  const userInfo = useSelector((state) => state.userInfo.value);

  const jobsStore = useSelector((state) => state.jobs.value);

  const dispatch = useDispatch();

  //////////

  const [
    { controls, required, invalid },
    { setControl, resetControls, validate, setInvalid },
  ] = useControls([
    {
      control: "first_name",
      value: "",
      isRequired: true,
    },
    {
      control: "last_name",
      value: "",
      isRequired: true,
    },
    {
      control: "code",
      value: "",
      isRequired: true,
    },
    {
      control: "phone",
      value: "",
      isRequired: true,
    },
    {
      control: "salary",
      value: "",
      isRequired: true,
    },
    {
      control: "job",
      value: "",
      isRequired: true,
    },
    {
      control: "incentives",
      value: "",
      isRequired: false,
    },

    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
    // {
    //   control: "confirm",
    //   value: "",
    //   isRequired: true,
    //   validations: [
    //     {
    //       test: (controls) => new RegExp(`^${controls.password}$`),
    //       message: "الرقم السري لا يطابق",
    //     },
    //   ],
    // },
    {
      control: "email",
      value: "",
      isRequired: false,
      validations: [
        {
          test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "البريد غير صالح",
        },
      ],
    },
  ]);

  const [jobsGetRequest, jobsGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });

  const getJobs = () => {
    if (Boolean(jobsStore.results.length)) return;
    jobsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "jobs/set", payload: res.data });
      },
    });
  };

  const [employeesState, setEmployeesState] = useState([]);

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: "aqar/api/router/Employee/",
    method: "get",
  });

  const [jobPermissionsGetRequest, jobPermissionsGetResponse] = useRequest({
    path: JOB_PERMISSIONS,
    method: "get",
  });

  const [selectedPerms, setSelectedPerms] = useState([]);

  const [permissionsState, setPermissionsState] = useState([]);

  const getJobPermissions = () => {
    if (!controls.job) return;
    jobPermissionsGetRequest({
      params: {
        id: controls.job,
      },
      onSuccess: (res) => {
        setPermissionsState(res.data);
      },
    });
  };

  useAfterEffect(() => {
    getJobPermissions();
  }, [controls.job]);

  const [employeePostRequest, employeePostResponse] = useRequest({
    path: EMPLOYEES,
    method: "post",
    successMessage: "تم إضافة موظف جديد بنجاح",
  });

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          user: {
            first_name: controls.first_name,
            last_name: controls.last_name,
            // username: `${
            //   controls.username
            // }@${userInfo?.organization?.name?.replace(/\s/gi, "")}.com`,
            username: controls.username,
            email: controls.email,
            phone: controls.code + controls.phone,
            password: controls.password,
            user_permissions: selectedPerms?.map((perm) => ({
              codename: perm,
            })),
          },
          job: controls.job,
          salary: controls.salary,
          hwafez: controls.incentives,
        },
      });
      console.log(requestBody);
      employeePostRequest({
        onSuccess: () => {
          setPermissionsState([]);
          resetControls();
        },
        body: requestBody,
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            first_name: res?.user?.last_name,
            last_name: res?.user?.last_name,
            email: res?.user?.email?.join("-"),
            phone:
              res?.user?.phone?.join("-") || res?.user?.country_code?.join("-"),
            username: res?.user.username,
            password: res?.user?.password?.join("-"),
            salary: res?.salary,
            job: res?.job?.join("-"),
          },
        });
        setInvalid(responseBody);
      });
    });
  };

  return (
    <Wrapper sx={{ position: "relative" }}>
      {jobPermissionsGetResponse.isPending && (
        <PendingBackdrop
          backdropColor="white"
          indicatorColor={(theme) => theme.palette.primary.main}
        />
      )}
      {/* <Breadcrumbs
        path={[global.Employees[lang], addEmployeesLang.addEmployee[lang]]}
      /> */}
      <Form
        component="form"
        onSubmit={() => handleSubmit()}
        childrenProps={{
          saveBtn: {
            disabled: employeePostResponse.isPending,
          },
          closeBtn: {
            onClick: () => {
              setSelectedPerms([]);
              setPermissionsState([]);
              resetControls();
            },
            disabled: employeePostResponse.isPending,
          },
        }}
      >
        <InputField
          label={global.first_name[lang]}
          placeholder={global.first_name[lang]}
          required={required.includes("first_name")}
          value={controls.first_name}
          onChange={(e) => setControl("first_name", e.target.value)}
          error={Boolean(invalid.first_name)}
          helperText={invalid.first_name}
        />
        <InputField
          label={global.last_name[lang]}
          placeholder={global.last_name[lang]}
          required={required.includes("last_name")}
          value={controls.last_name}
          onChange={(e) => setControl("last_name", e.target.value)}
          error={Boolean(invalid?.last_name)}
          helperText={invalid?.last_name}
        />
        <InputField
          label={global.username[lang]}
          placeholder={global.username[lang]}
          required={required.includes("username")}
          value={controls.username}
          onChange={(e) => setControl("username", e.target.value)}
          error={Boolean(invalid.username)}
          helperText={invalid.username}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment
          //       position="start"
          //       sx={{ direction: "rtl", paddingLeft: "10px" }}
          //     >
          //       @{userInfo?.organization?.name?.replace(/\s/gi, "")}.com
          //     </InputAdornment>
          //   ),
          // }}
        />
        {/* <Stack>
          <AddIcon
            htmlColor="green"
            onClick={() => {
              setPhones((old) => [...old, { phone: "" }]);
              setCodes((old) => [...old, { code: "" }]);
            }}
          />
          {phones.map((phone, index) => (
            <PhoneField
              label={global.phone[lang]}
              placeholder={global.phone[lang]}
              required={required.includes("phones")}
              requiredCode
              selectProps={{
                // value: codes[index]?.code || "",
                onChange: (e) => {
                  console.log(codes);
                  codes[index]["code"] = e.target.value;
                },
              }}
              value={phone.phone}
              onChange={(e) => {
                phones[index][
                  "phone"
                ] = `${codes[index].code}${e.target.value}`;
                console.log(phones);
              }}
              error={Boolean(invalid.phones)}
              helperText={invalid.phones}
            />
          ))}
        </Stack> */}
        <PasswordField
          label={global.password[lang]}
          placeholder={global.password[lang]}
          required={required.includes("password")}
          value={controls.password}
          onChange={(e) => setControl("password", e.target.value)}
          error={Boolean(invalid.password)}
          helperText={invalid.password}
        />
        <PhoneField
          label={global.phone[lang]}
          placeholder={global.phone[lang]}
          required={required.includes("phone")}
          requiredCode
          selectProps={{
            value: controls.code,
            onChange: (e) => setControl("code", e.target.value),
          }}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
          error={Boolean(invalid.phone)}
          helperText={invalid.phone}
        />
        
        <InputField
          label={global.email[lang]}
          placeholder={global.email[lang]}
          required={required.includes("email")}
          value={controls.email}
          onChange={(e) => setControl("email", e.target.value)}
          error={Boolean(invalid.email)}
          helperText={invalid.email}
        />

        {/* <PasswordField
          label={global.confirmPassword[lang]}
          placeholder={global.confirmPassword[lang]}
          required={required.includes("confirm")}
          value={controls.confirm}
          onChange={(e) => setControl("confirm", e.target.value)}
          error={Boolean(invalid.confirm)}
          helperText={invalid.confirm}
        /> */}

        
        <InputField
          label="الراتب"
          placeholder="الراتب"
          required={required.includes("salary")}
          value={controls.salary}
          onChange={(e) => setControl("salary", e.target.value)}
          error={Boolean(invalid.salary)}
          helperText={invalid.salary}
        />
        <InputField
          label="الحوافز"
          placeholder="الحوافز"
          required={required.includes("incentives")}
          value={controls.incentives}
          onChange={(e) => setControl("incentives", e.target.value)}
          error={Boolean(invalid.incentives)}
          helperText={invalid.incentives}
        />
        <SelectField
          label={addEmployeesLang.job[lang]}
          placeholder={addEmployeesLang.job[lang]}
          onOpen={() => getJobs()}
          isPending={jobsGetResponse.isPending}
          required={required.includes("job")}
          value={controls.job}
          renderValue={(selected) => {
            return jobsStore.results.find((job) => job.id === selected).title;
          }}
          onChange={(e) => {
            setControl("job", e.target.value);
          }}
          error={Boolean(invalid.job)}
          helperText={invalid.job}
        >
          {jobsStore.results.map((job, index) => (
            <MenuItem key={`${job.title} ${index}`} value={job.id}>
              {job.title}
            </MenuItem>
          ))}
        </SelectField>
        {/* <SelectField
          label={addEmployeesLang.followed[lang]}
          placeholder={addEmployeesLang.followed[lang]}
          disabled={!controls.job}
          isPending={employeesGetResponse.isPending}
          onOpen={getEmployees}
          required={required.includes("to")}
          value={controls.to}
          renderValue={(selected) => {
            return `${
              employeesState.find((employee) => employee.id === selected).user
                .first_name
            } ${
              employeesState.find((employee) => employee.id === selected).user
                .last_name
            }`;
          }}
          onChange={(e) => setControl("to", e.target.value)}
          error={Boolean(invalid.to)}
          helperText={invalid.to}
        >
          {employeesState.map((employee) => (
            <MenuItem value={employee.id} key={employee.id}>
              {employee.user.first_name} {employee.user.last_name}
            </MenuItem>
          ))}
        </SelectField> */}

        <PermissionToggles
          permissions={permissionsState}
          sx={{ gridColumn: "1 / -1" }}
          onToggle={({ toggles }) => setSelectedPerms(toggles)}
          // initialToggles={[]}
        />
      </Form>
      {employeePostResponse.successAlert}
      {employeePostResponse.failAlert}
    </Wrapper>
  );
};

export default AddEmployees;

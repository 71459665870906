import {
  MenuItem,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  STATIONS,
  CLIENTS,
  PRODUCTS,
  STOCK,
  STOCK_PRACTICAL,
  TRADERS,
  STOCK_OUTS 
} from "../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
const AddStock = () => {

  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
    
  };

  const lang = useSelector((state) => state.lang.value.lang);
  const volumes = [{ name: "40", value: 40 }, { name: "50", value: 50 }, { name: "20", value: 20 }, { name: "15", value: 15 }, { name: "10", value: 10 }]
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [responsebody, setResponse] = useState([]);
  const productsStore = useSelector((state) => state.products.value);
  const [preview, setpreview] = useState("");
  // get data for client with dispatch
 


  ///////////////////////////////
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "product",
      value: "",
      isRequired: true,
    },
    {
      control: "product_type",
      value: "",
      isRequired: true,
    },
    {
      control: "volume",
      value: "",
      isRequired: true,
    },
    {
      control: "empty_store",
      value: "",
      isRequired: true,
    },
    {
      control: "filled_store",
      value: "",
      isRequired: true,
    },
    {
      control: "outputs",
      value: "",
      isRequired: false,
    },
    {
      control: "clients",
      value: "",
      isRequired: false,
    },
    {
      control: "stations",
      value: "",
      isRequired: false,
    },
    {
      control: "traders",
      value: "",
      isRequired: false,
    },
    {
      control: "select_client",
      value: "",
      isRequired: false,
    },
    {
      control: "select_station",
      value: "",
      isRequired: false,
    },
    {
      control: "select_trader",
      value: "",
      isRequired: false,
    },
    {
      control:"owned_number",
      value:"",
      isRequired:false,
    },
    {
      control: "date",
      value: getDate(),
      isRequired: true,
    },
  ]);

  const [stockPostRequest, stockPostResponse] = useRequest({
    path: STOCK_PRACTICAL,
    method: "post",
    successMessage: "تم إضافة المنتج بنجاح",
  });
  const [stockOutsPostRequest, stockOutsPostResponse] = useRequest({
    path: STOCK_OUTS,
    method: "post",
  });
  // client data
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    if (Boolean(clientsStore.results.length)) return;
    clientGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  // station data connection to api
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });

  const getStations = () => {
    if (Boolean(stationsStore.results.length)) return;
    stationsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  // trader connection to api
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const getTraders = () => {
    if (Boolean(tradersStore.results.length)) return;
    tradersGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  const [stocksGetRequest, stocksGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getStocks = () => {
    stocksGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };
  // const location = useLocation();
  const product_type = [
    { name: "أسطوانة", value: "cylinder" },
    { name: "دوار", value: "dewar" },
  ];

 
  const handleSubmit = (e) => {
    e.preventDefault();
    
    validate().then((data) => {
      console.log(data);
      if (!data.isOk) return;

     
      
      //requst body of stock
      const requestBody = filter({
        obj: {
          product: controls.product,
          product_type: controls.product_type,
          volume: controls.volume,
          // connection request Api for empty and full field
          empty_store: controls.empty_store,
          filled_store: controls.filled_store,
          date: controls.date,
          
        },

        output: "object",
      });
      if (
        Object.keys(
          filter({
            obj: {
              product: controls.product,
              product_type: controls.product_type,
              //check for data or empty
              empty_store: controls.empty_store,
              filled_store: controls.filled_store,
              date: controls.date,
              
            },
          })
        ).length <= 1
      )
        return;

      stockPostRequest({
        body: requestBody,
        onSuccess: () => {
          // getStocks();
          resetControls();
        },
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            id: res?.id,
            product: res?.product,
            product_type: res?.product_type,
            // response body for data from Api
            empty_store: res?.empty_store,
            filled_store: res?.filled_store,
            volume: res?.volume,
            // outputs: res?.outputs,
            date: res?.date,
            // clients: res?.clients,
            // stations: res?.stations,
            // traders: res?.traders,

            // total_store: res?.total_store
            //   ? res?.total_store === 0
            //     ? "0"
            //     : res?.total_store
            //   : "0",

            empty_store: res?.empty_store
              ? res?.empty_store === 0
                ? "0"
                : res?.empty_store
              : "0",

            filled_store: res?.filled_store
              ? res?.filled_store === 0
                ? "0"
                : res?.filled_store
              : "0",
            factorypower: res?.factorypower
              ? res?.factorypower === 0
                ? "0"
                : res?.factorypower
              : "0",
            // created_at: res?.created_at,
          },
        });
        
        if (res?.id) {
        } else {
          setInvalid(res?.message);
        }
      });
     
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[globalLang.wallet[lang], globalLang.add[lang]]}
      /> */}
      <Form
         component="form"
         onSubmit={handleSubmit}
         childrenProps={{
           saveBtn: {
             disabled: stockPostResponse.isPending,
           },
           closeBtn: {
             disabled: stockPostResponse.isPending,
           },
         }}
      >
      
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={globalLang.DateAdded[lang]}
                value={controls.date}
                format="YYYY/MM/DD"
                onChange={(newValue) => {
                  const new_date = `${newValue["$y"]}-${
                    parseInt(newValue["$M"]) + 1
                  }-${newValue["$D"]}`;
      
                  setControl("date", new_date);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <SelectField
              label={globalLang.Gas[lang]}
              placeholder={globalLang.Gas[lang]}
              renderValue={(selected) => {
                return productsStore.results.find(
                  (product) => product.id === selected
                ).name;
              }}
              isPending={productsGetResponse.isPending}
              onOpen={getProducts}
              onChange={(e) => {
                setControl("product", e.target.value);
              }}
              value={controls.product}
              required={required.includes("product")}
              error={Boolean(invalid.product)}
              helperText={invalid.product}
            >
              {productsStore?.results?.map((product, index) => (
                <MenuItem value={product.id} key={`${product.name} ${index}`}>
                  {product.name}
                </MenuItem>
              ))}
            </SelectField>
            <SelectField
              label={globalLang.package[lang]}
              placeholder={globalLang.package[lang]}
              renderValue={(selected) => {
                return product_type.find((type) => type.value === selected).name;
              }}
              onChange={(e) => {
                setControl("product_type", e.target.value);
              }}
              value={controls.product_type}
              required={required.includes("product_type")}
              error={Boolean(invalid.product_type)}
              helperText={invalid.product_type}
            >
              {product_type.map((product, index) => (
                <MenuItem value={product.value} key={`${product.name} ${index}`}>
                  {product.name}
                </MenuItem>
              ))}
            </SelectField>
            {/* <NumberField
              label={globalLang.volume[lang]}
              placeholder={globalLang.volume[lang]}
              onChange={(e) => setControl("volume", e.target.value)}
              value={controls.volume}
              required={required.includes("volume")}
              error={Boolean(invalid.volume)}
              helperText={invalid.volume}
            ></NumberField> */}
            <SelectField
              label={globalLang.volume[lang]}
              placeholder={globalLang.volume[lang]}
              renderValue={(selected) => {
                return volumes?.find(
                  (v) => v.value === selected
                ).name;
              }}


              onChange={(e) => {
                setControl('volume', e.target.value)
              }}
              value={controls.volume}
              required={required.includes("volume")}
              error={Boolean(invalid.volume)}
              helperText={invalid.volume}
            >
              {volumes?.map((volume, index) => (
                <MenuItem
                  value={volume.value}
                  key={`${volume.name} ${index}`}
                >
                  {volume.name}
                </MenuItem>
              ))}
            </SelectField>
            {/* <NumberField
              label={globalLang.factorypower[lang]}
              placeholder={globalLang.factorypower[lang]}
              onChange={(e) => setControl("factorypower", e.target.value)}
              value={controls.factorypower}
              required={required.includes("factorypower")}
              error={Boolean(invalid.factorypower)}
              helperText={invalid.factorypower}
            ></NumberField> */}
            {/* add empty and full input field ,popper for outputs */}
            <NumberField
              label={globalLang.empty_store[lang]}
              placeholder={globalLang.empty_store[lang]}
              onChange={(e) => setControl("empty_store", e.target.value)}
              value={controls.empty_store}
              required={required.includes("empty_store")}
              error={Boolean(invalid.empty_store)}
              helperText={invalid.empty_store}
            ></NumberField>
            <NumberField
              label={globalLang.filled_store[lang]}
              placeholder={globalLang.filled_store[lang]}
              onChange={(e) => setControl("filled_store", e.target.value)}
              value={controls.filled_store}
              required={required.includes("filled_store")}
              error={Boolean(invalid.factorypower)}
              helperText={invalid.filled_store}
            ></NumberField>
      </Form>
     
      {stockPostResponse.successAlert}
      {stockPostResponse.failAlert}
    </>
  );
};

export default AddStock;

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DoneIcon from "@mui/icons-material/Done";
import useControls from "../hooks/useControls";
import { Paper, Typography, Tooltip } from "@mui/material";
import { InputField } from "../features/form";

import { useDispatch, useSelector } from "react-redux";
const EditReceiptDialog = ({
  columns,
  EditbleColumns,
  data,
  handleEditBill,
  getData,
}) => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "bill_id",
        value: "",
        isRequired: true,
      },
      {
        control: "bills",
        value: [],
        isRequired: true,
      },
    ],
    [data]
  );
  
  useEffect(() => {
    let object = [];
    data?.map((bill, billindex) => {
      EditbleColumns.map((column) => {
        object[billindex] = { ...object[billindex], [column]: bill[column] };
      });
    });
    setControl("bills", (old) => [...old, ...object]);
  }, [data]);


  const handleChange = (e, index, id) => {
 
    let data = [...controls.bills];
    data[index] = { ...data[index], [e.target.name]: parseInt(e.target.value) };

    setControl("bills", data);
 
  };

  useEffect(() => {
   getData(controls.bills);
  }, [controls.bills]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        margin: "10px",
        padding: "10px",
        height: "fit-content",
        background: "#233975",

        "& .MuiTableCell-root": {
          borderBottom: "none !important",
        },
      }}
    >
      <Table sx={{ padding: "10px" }}>
        <TableHead>
          <TableRow>
            {columns.map((column,index) => (
              <TableCell
               key={index}
                sx={{
                  color: "white",
                
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((bill, billindex) => (
            <>
              <TableRow key={billindex}>
                {columns.map((c, index) =>
                  EditbleColumns.includes(c.field) ? (
                    <>
                      <TableCell 
                      component={'td'}
                      key={index}
                 
                        width="20%"
                      >
                        <InputField
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "black",

                              background: "white",
                              borderRadius: "5px",
                            },
                          }}
                          type="number"
                          onChange={(e) => handleChange(e, billindex, bill.id)}
                          name={c.field}
                          defaultValue={bill[c.field]}
                        ></InputField>
                      </TableCell>
                    </>
                  ) : typeof bill[c.field] === "object" ? (
                    <TableCell component={'td'} >
                      <Typography sx={{ color: "white" }} component={'span'} variant={'p'}>
                        {bill[c.field]?.name}
                      </Typography>
                    </TableCell>
                  ) : bill[c.field] ? (
                    <TableCell component={'td'}>
                      <Typography sx={{ color: "white" }} component={'span'} variant={'p'}>
                        {bill[c.field]}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell component={'td'}>
                      <Typography sx={{ color: "white" }} component={'span'} variant={'p'}>-</Typography>
                    </TableCell>
                  )
                )}
                <TableCell>
                  <Tooltip title="حفظ">
                    <DoneIcon
                      htmlColor="lightgreen"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleEditBill(bill.id, billindex)}
                      // disabled={stationExitBillPatchResponse.isPending}
                    >
                      {globalLang.save[lang]}
                    </DoneIcon>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default EditReceiptDialog;

import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, {
  InputField,
  MultiSelectField,
  MultiSelectItem,
  NumberField,
  PasswordField,
  PhoneField,
  SelectField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import {
  CHANNELS,
  CLIENTS,
  EMPLOYEES,
  PRODUCTS,
  TRADERS,
} from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import {
  MenuItem,
  Stack,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  Paper,
  TableRow,
  Typography,
  Fade,
  Snackbar,
  Alert,
  useMediaQuery,
} from "@mui/material";
import filter from "../../utils/ClearNull";
import RemoveIcon from "@mui/icons-material/Remove";
function GrowTransition(props) {
  return <Grow {...props} />;
}
const AddMerchant = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const AddTraderLang = useSelector((state) => state.lang.value.addTrader);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const sm = useMediaQuery("(max-width:1100px)");
  const channelsStore = useSelector((state) => state.channels.value);
  const [errors, setErrors] = useState([]);
  const [phones, setPhones] = useState([]);
  const [state, setState] = useState({
    open: false,
    Transition: Fade,
  });
  const column_phones = [
    { name: "name", header: globalLang.name[lang] },
    { name: "phone", header: globalLang.phone[lang] },
    { name: "position", header: globalLang.job[lang] },

    { name: "note", header: globalLang.notes[lang] },
  ];
  const handleRemovePhone = (index) => {
    let phones_data = [...phones];
    phones_data.splice(index, 1);
    setPhones(phones_data);
  };
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const [phonesError, setPhonesError] = useState("");
  const dispatch = useDispatch();

  //----states----
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "first_name",
      value: "",
      isRequired: true,
    },
    {
      control: "last_name",
      value: "",
      isRequired: true,
    },
    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "driver_code",
      value: "",
      isRequired: false,
    },

    {
      control: "driver",
      value: "",
      isRequired: false,
    },
    {
      control: "phone",
      value: "",
      isRequired: false,
    },
    {
      control: "driver_phone",
      value: "",
      isRequired: false,
    },
    // {
    //   control: "employee",
    //   value: "",
    //   isRequired: true,
    // },
    {
      control: "channel",
      value: "",
      isRequired: false,
    },
    {
      control: "email",
      value: "",
      isRequired: false,
      validations: [
        {
          test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "البريد غير صالح",
        },
      ],
    },

    {
      control: "contact",
      value: "",
      isRequired: false,
    },
    {
      control: "tax_number",
      value: "",
      isRequired: false,
    },
    {
      control: "reg_number",
      value: "",
      isRequired: false,
    },
    // {
    //   control: "products",
    //   value: [],
    //   convert: (old) => old?.join("-"),
    //   isRequired: true,
    // },
  ]);

  //----request hooks----
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const [channelsGetRequest, channelsGetResponse] = useRequest({
    path: CHANNELS,
    method: "get",
  });

  const [traderPostRequest, traderPostResponse] = useRequest({
    path: TRADERS,
    method: "post",
    successMessage: "تم إضافة تاجر بنجاح",
  });

  //----functions----

  const getChannels = () => {
    if (Boolean(channelsStore.results.length)) return;
    channelsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "channels/set", payload: res.data });
      },
    });
  };

  const handleSubmit = () => {
    if (phones.length) {
      if (
        phones.filter(
          (phone) => !phone.phone || !phone.position || !phone.name
        ).length !== 0
      ) {
        setPhonesError(" برجاء إدخال بيانات هاتف صحيحة");
        setState({
          open: true,
          GrowTransition,
        });
        return;
      }



    } validate().then((output) => {
      if (!output.isOk) return;

      let requestBody = filter({
        obj: {
          user: {
            first_name: controls.first_name,
            last_name: controls.last_name,
            username: controls.username,
            email: controls.email,

            password: controls.password,
            user_permissions: [],
          },

          tax_number: controls.tax_number,

          // agent: controls.employee,
          //  driver_name: controls.driver,

          reg_number: controls.reg_number,
        },
        output: "object",
      });
      if (phones.length) {
        requestBody["phones"] = phones.map((phone) => {
          return {
            phone: phone.code + phone.phone,
            name: phone.name,
            note: phone.note,
            position: phone.position,
          };
        });
      }
      traderPostRequest({
        body: requestBody,
        onSuccess: () => {
          resetControls();
          setPhones([]);
          setPhonesError("")
        },
      }).then((res) => {
        let response = res?.response?.data;
        if (res?.id) {
          dispatch({ type: "traders/addItem", payload: res });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[globalLang.traders[lang], AddTraderLang.addTrader[lang]]}
      /> */}
      <Form
        subtitle="الرجاء ملئ المعلومات الآتية لاضافة تاجر جديد"
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: traderPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: traderPostResponse.isPending,
          },
        }}
      >
        <Stack sx={{ gap: 3 }}>
          <Stack
            sx={{
              gap: 2,
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
              flexWrap:"wrap",
              padding: "10px",
              "& .MuiTextField-root": {
                minWidth: "16px",
                width: {xl:"40%",lg:"40%",md:"100%",sm:"100%",xs:"100%",}
              },
            }}
          >
            {/* <Stack sx={{ gap: 3 }}> */}
              <InputField
                label={globalLang.first_name[lang]}
                placeholder={globalLang.first_name[lang]}
                onChange={(e) => setControl("first_name", e.target.value)}
                value={controls.first_name}
                required={required.includes("first_name")}
                error={Boolean(invalid.first_name)}
                helperText={invalid.first_name}
              />
              <InputField
                label={globalLang.last_name[lang]}
                placeholder={globalLang.last_name[lang]}
                onChange={(e) => setControl("last_name", e.target.value)}
                value={controls.last_name}
                required={required.includes("last_name")}
                error={Boolean(invalid.last_name)}
                helperText={invalid.last_name}
              />

              <InputField
                label={globalLang.email[lang]}
                placeholder={globalLang.email[lang]}
                onChange={(e) => {
                  setControl("email", e.target.value);
                }}
                value={controls.email}
                required={required.includes("email")}
                error={Boolean(invalid.email)}
                helperText={invalid.email}
              />
            {/* </Stack> */}
            {/* <Stack sx={{ gap: 3 }}> */}
              <InputField
                label={globalLang.username[lang]}
                placeholder={globalLang.username[lang]}
                required={required.includes("username")}
                value={controls.username}
                onChange={(e) => setControl("username", e.target.value)}
                error={Boolean(invalid.username)}
                helperText={invalid.username}
              />
              <NumberField
                label={globalLang.tax_number[lang]}
                placeholder={globalLang.tax_number[lang]}
                onChange={(e) => setControl("tax_number", e.target.value)}
                value={controls.tax_number}
                required={required.includes("tax_number")}
                error={Boolean(invalid.tax_number)}
                helperText={invalid.tax_number}
              />


            {/* </Stack> */}

            {/* <Stack sx={{ gap: 3 }}> */}
              <PasswordField
                label={globalLang.password[lang]}
                placeholder={globalLang.password[lang]}
                required={required.includes("password")}
                value={controls.password}
                onChange={(e) => setControl("password", e.target.value)}
                error={Boolean(invalid.password)}
                helperText={invalid.password}
              />
              <NumberField
                label={globalLang.reg_number[lang]}
                placeholder={globalLang.reg_number[lang]}
                onChange={(e) => setControl("reg_number", e.target.value)}
                value={controls.reg_number}
                required={required.includes("reg_number")}
                error={Boolean(invalid.reg_number)}
                helperText={invalid.reg_number}
              />

            {/* </Stack> */}
          </Stack>

          <Stack
            sx={{
              width: "90%",
              "& .MuiTextField-root": {
                minWidth: "16px",
                width: "100%",
              },
            }}
          >
            <Typography sx={{ color: "#666874" }}>الهواتف</Typography>
            <hr
              style={{
                width: "100%",
                color: "gray",
                marginBottom: "2px",
              }}
            />
            {sm ? (
              <TableContainer
                component={Paper}
                sx={{
                  "& .MuiTableCell-root": {
                    border: "0",
                    padding: "5px",
                    borderColor: phonesError ? "red" : "",
                  },
                  padding: "20px",

                  marginTop: "10px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ flexGrow: "1" }}>
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "green",
                            width: "100%",
                          }}
                          onClick={() => {
                            setPhones((old) => [...old, {}]);
                          }}
                        >
                          أضف هاتف
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {phones.map((phoneObj, index) => (
                      <>
                        <TableRow>
                          <TableCell>{globalLang.name[lang]}</TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.name[lang]}
                              onChange={(e) => {
                                let phones_data = [...phones];
                                phones_data[index]["name"] = e.target.value;

                                setPhones(phones_data);
                                if (!phoneObj.name) {
                                  let Errors = [...errors];
                                  Errors[index] = {
                                    ...Errors[index],
                                    name: "هذا الحقل إلزامي",
                                  };
                                  console.log(Errors);
                                  setErrors(Errors);
                                } else if (
                                  phoneObj?.name &&
                                  errors[index]?.name
                                ) {
                                  let Errors = [...errors];
                                  delete Errors[index]["name"];
                                  setErrors(Errors);
                                }
                              }}
                              value={phoneObj?.name || ""}
                              required={Boolean(phoneObj?.name)}
                              error={Boolean(errors[index]?.name)}
                              helperText={
                                <Typography sx={{ fontSize: "12px" }}>
                                  {errors[index]?.name}
                                </Typography>
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {" "}
                          <TableCell>{globalLang.phone[lang]}</TableCell>
                          <TableCell>
                            <PhoneField
                              placeholder={globalLang.phone[lang]}
                              requiredCode
                              selectProps={{
                                value: phoneObj.code,
                                onChange: (e) => {
                                  let phones_data = [...phones];
                                  phones_data[index]["code"] = e.target.value;
                                  setPhones(phones_data);
                                },
                              }}
                              onChange={(e) => {
                                let phones_data = [...phones];
                                phones_data[index]["phone"] = e.target.value;
                                setPhones(phones_data);
                                if (!phoneObj.phone) {
                                  let Errors = [...errors];
                                  Errors[index] = {
                                    ...Errors[index],
                                    phone: "هذا الحقل إلزامي",
                                  };
                                  console.log(Errors);
                                  setErrors(Errors);
                                } else if (
                                  phoneObj?.phone &&
                                  errors[index]?.phone
                                ) {
                                  let Errors = [...errors];
                                  delete Errors[index]["phone"];
                                  setErrors(Errors);
                                }
                              }}
                              value={phoneObj?.phone}
                              required={Boolean(phoneObj?.phone)}
                              error={Boolean(errors[index]?.phone)}
                              helperText={
                                <Typography sx={{ fontSize: "12px" }}>
                                  {errors[index]?.phone}
                                </Typography>
                              }
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{globalLang.job[lang]}</TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.job[lang]}
                              onChange={(e) => {
                                let phones_data = [...phones];
                                phones_data[index]["position"] = e.target.value;
                                setPhones(phones_data);
                                if (!phoneObj.position) {
                                  let Errors = [...errors];
                                  Errors[index] = {
                                    ...Errors[index],
                                    position: "هذا الحقل إلزامي",
                                  };
                                  console.log(Errors);
                                  setErrors(Errors);
                                } else if (
                                  phoneObj?.position &&
                                  errors[index]?.position
                                ) {
                                  let Errors = [...errors];
                                  delete Errors[index]["position"];
                                  setErrors(Errors);
                                }
                              }}
                              value={phoneObj?.position || ""}
                              required={Boolean(phoneObj?.position)}
                              error={Boolean(errors[index]?.position)}
                              helperText={
                                <Typography sx={{ fontSize: "12px" }}>
                                  {errors[index]?.position}
                                </Typography>
                              }
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>{globalLang.notes[lang]}</TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.notes[lang]}
                              onChange={(e) => {
                                let phones_data = [...phones];
                                phones_data[index]["note"] = e.target.value;
                                setPhones(phones_data);
                              }}
                              value={phoneObj.note || ""}
                              required={false}
                              helperText={
                                <Typography sx={{ fontSize: "12px" }}>
                                  {errors[index]?.note || ""}
                                </Typography>
                              }
                            />
                          </TableCell>
                        </TableRow>

                        <RemoveIcon
                          htmlColor="red"
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleRemovePhone(index)}
                        />
                        <hr />
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer
                component={Paper}
                sx={{
                  "& .MuiTableCell-root": {
                    border: "0",
                    padding: "5px",
                    borderColor: phonesError ? "red" : "",
                  },
                  padding: "20px",

                  marginTop: "10px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {column_phones.map((column) => (
                        <TableCell>{column.header}</TableCell>
                      ))}
                      <TableCell>
                        {" "}
                        <Typography
                          sx={{
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "green",
                            width: "fit-content",
                            padding: "10px",
                          }}
                          onClick={() => {
                            setPhones((old) => [
                              ...old,
                              {
                                phone: "",
                                name: "",
                                position: "",
                                comment: "",
                                code: "",
                              },
                            ]);
                          }}
                        >
                          أضف هاتف
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {phones.map((phoneObj, index) => (
                      <TableRow>
                        <TableCell>
                          <InputField
                            placeholder={globalLang.name[lang]}
                            onChange={(e) => {
                              let phones_data = [...phones];
                              phones_data[index]["name"] = e.target.value;

                              setPhones(phones_data);
                              if (!phoneObj.name) {
                                let Errors = [...errors];
                                Errors[index] = {
                                  ...Errors[index],
                                  name: "هذا الحقل إلزامي",
                                };
                                console.log(Errors);
                                setErrors(Errors);
                              } else if (
                                phoneObj?.name &&
                                errors[index]?.name
                              ) {
                                let Errors = [...errors];
                                delete Errors[index]["name"];
                                setErrors(Errors);
                              }
                            }}
                            value={phoneObj?.name || ""}
                            required={Boolean(phoneObj?.name)}
                            error={Boolean(errors[index]?.name)}
                            helperText={
                              <Typography sx={{ fontSize: "12px" }}>
                                {errors[index]?.name}
                              </Typography>
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <PhoneField
                            placeholder={globalLang.phone[lang]}
                            requiredCode
                            selectProps={{
                              value: phoneObj.code,
                              onChange: (e) => {
                                let phones_data = [...phones];
                                phones_data[index]["code"] = e.target.value;
                                setPhones(phones_data);
                              },
                            }}
                            onChange={(e) => {
                              let phones_data = [...phones];
                              phones_data[index]["phone"] = e.target.value;
                              setPhones(phones_data);
                              if (!phoneObj.phone) {
                                let Errors = [...errors];
                                Errors[index] = {
                                  ...Errors[index],
                                  phone: "هذا الحقل إلزامي",
                                };
                                console.log(Errors);
                                setErrors(Errors);
                              } else if (
                                phoneObj?.phone &&
                                errors[index]?.phone
                              ) {
                                let Errors = [...errors];
                                delete Errors[index]["phone"];
                                setErrors(Errors);
                              }
                            }}
                            value={phoneObj?.phone}
                            required={Boolean(phoneObj?.phone)}
                            error={Boolean(errors[index]?.phone)}
                            helperText={
                              <Typography sx={{ fontSize: "12px" }}>
                                {errors[index]?.phone}
                              </Typography>
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <InputField
                            placeholder={globalLang.job[lang]}
                            onChange={(e) => {
                              let phones_data = [...phones];
                              phones_data[index]["position"] = e.target.value;
                              setPhones(phones_data);
                              if (!phoneObj.position) {
                                let Errors = [...errors];
                                Errors[index] = {
                                  ...Errors[index],
                                  position: "هذا الحقل إلزامي",
                                };
                                console.log(Errors);
                                setErrors(Errors);
                              } else if (
                                phoneObj?.position &&
                                errors[index]?.position
                              ) {
                                let Errors = [...errors];
                                delete Errors[index]["position"];
                                setErrors(Errors);
                              }
                            }}
                            value={phoneObj?.position || ""}
                            required={Boolean(phoneObj?.position)}
                            error={Boolean(errors[index]?.position)}
                            helperText={
                              <Typography sx={{ fontSize: "12px" }}>
                                {errors[index]?.position}
                              </Typography>
                            }
                          />
                        </TableCell>

                        <TableCell>
                          <InputField
                            placeholder={globalLang.notes[lang]}
                            onChange={(e) => {
                              let phones_data = [...phones];
                              phones_data[index]["note"] = e.target.value;
                              setPhones(phones_data);
                            }}
                            value={phoneObj.note || ""}
                            required={false}
                            helperText={
                              <Typography sx={{ fontSize: "12px" }}>
                                {errors[index]?.note || ""}
                              </Typography>
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <RemoveIcon
                            htmlColor="red"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleRemovePhone(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </Stack>
      </Form>
      {phonesError && (
        <Snackbar
          open={state.open}
          onClose={handleClose}
          TransitionComponent={state.Transition}
          key={state?.Transition?.name}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity="error" variant="filled">
            {phonesError}
          </Alert>
        </Snackbar>
      )}
      {traderPostResponse.successAlert}
      {traderPostResponse.failAlert}
    </>
  );
};

export default AddMerchant;

const contactMeans = [
  {
    title: "الهاتف",
    value: "phone",
  },
  {
    title: "البريد الإلكتروني",
    value: "email",
  },
  {
    title: "الواتساب",
    value: "whats app",
  },
];

import React, { useEffect, useState } from "react";

import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import AddProduct from "./Addproduct";
import ViewProducts from "./ViewProducts";

const ProductsView = () => {


  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factoryproduct"]}>
        <AddProduct />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factoryproduct"]}>
        <ViewProducts />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ProductsView;

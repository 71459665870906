import { Alert, Box, Button } from "@mui/material";
import React,{useRef} from "react";
import { useReactToPrint } from "react-to-print";
const Print =({title,children,sx})=>{

const componentRef=useRef()
const pageStyle = `

 
  @media all {
    // .pagebreak {
    //   display: none;
    // }
   
      
  }

  @media print {
  
    .pagebreak {
      page-break-before: always;   
        page-break-after: always; 
     
    }
    body,html{  
      direction:rtl;
      
    }
 

  }
`;
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: title,
  onAfterPrint: () => <Alert severity="success">Printed Successfully</Alert>,
  onPrintError: () => <Alert everity="error">There's a Problem</Alert>,
  pageStyle:pageStyle
  
});

return (
  <>
    <Box ref={componentRef} >{children}</Box>
    <Box sx={{display:"flex",justifyContent:'center',alignItems:"center"}}>
      <Button
        onClick={handlePrint}
        variant="contained"
        sx={{
          width: "6rem",
          margin: "20px",

          height: "2.3rem",
          fontSize: "14.7px",
          padding: "2px 12px",

          ...sx,
        }}
      >
        طباعة
      </Button>
    </Box>
  </>
);
}
export default Print;
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import Print from "../../../components/PrintComponent";
import {

  PRODUCTS,
  STATIONBILL,

} from "../../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogNumberField,
  DialogTable,
} from "../../../features/dialog";
import { InputField, NumberField, SelectField } from "../../../features/form";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";


import {
  Stack,
  Typography,
} from "@mui/material";
import EditReceiptDialog from "../../../components/EditReceiptDialog";


const ViewShipping = () => {
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);
  const [editData, setEditData] = useState(null);

  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [StationBillDetails, setStationBillDetails] = useState({
    details: null,
    tab: "details",
  });
  ///////////////////////////////

  const StationBillStore = useSelector((state) => state.station_bill.value);


  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.fill_number[lang],
      customContent: ({ bill_uuid }) => bill_uuid===0 ? "0" : bill_uuid ? bill_uuid :"-",
    },
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => bill_date ? format(bill_date):"-",
    },
   
    {
      field: "permit_uuid",
      headerName: globalLang.Enter_number[lang],
    },
    // {
    //   field: "fill_uuid",
    //   headerName: globalLang.fill_number[lang],
    // },
    {
      field: "driver",
      headerName: globalLang.driver[lang],
      customContent: ({ driver }) => driver ? driver :"-",
    },
    {
      field: "transport",
      headerName: globalLang.transportation[lang],
      customContent: (params) => {
        return `${params?.transport ? params?.transport : "-"}`;
      },
    },

    {
      field: "exit_date",
      headerName: globalLang.exit_date[lang],
      customContent: ({ exit_date }) => format(exit_date),
      customEmpty: "-",
    },
    {
      field: "enter_date",
      headerName: globalLang.enter_date[lang],
      customContent: ({ enter_date }) =>
        enter_date !== null ? format(enter_date) : "-",
      customEmpty: "-",
    },
  ];

  const dispatch = useDispatch();
  const handleCloseDetailsDialog = () => {
    setStationBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };
  const [StationBillGetRequest, StationBillGetResponse] = useRequest({
    path: STATIONBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getStationBill = () => {
    urlParams.append('completed',true)
    StationBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "station_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object?.values(requestParams)?.map((item) =>
      item?.map(([key, value]) => urlParams?.append(key, value))
    );

    getStationBill();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: STATIONBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "station_bill/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });



  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const filters = [
    {
      name: globalLang.fill_number[lang],
      component: <UUIDFilter />,
    },
  ];

  useAfterEffect(() => {
    if (!StationBillDetails.details) return;
    setStationBillDetails((old) => ({
      ...old,
      details: StationBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [StationBillStore]);

  const isPermitted = useIsPermitted();

  return (
    <>
      <Breadcrumbs
        path={[globalLang.shipreceipt[lang], globalLang.view[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={StationBillStore.results}
        total={StationBillStore.count}
        isPending={StationBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factorystationbill']) }
        onEdit={isPermitted((e, row) => setEditData(row),['change_factorystationproduct','change_factorystationbill']) }
        onView={isPermitted( (e, row) => {
          setStationBillDetails((old) => ({
            ...old,
            details: row,
          }));
        },['view_factorystationproduct'])}
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(
          StationBillDetails.details && StationBillDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={Boolean(StationBillDetails.details) && StationBillDetails.details}
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewShipping;

export const InfoDialog = ({
  data,

  open,

  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  //   {
  //     name: globalLang.product[lang],
  //     // value: `${data?.station_bills.product}`,
  //   },
  //   {
  //     name: globalLang.driver[lang],
  //     value: `${data?.driver ? data?.driver.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.AwayDate[lang],
  //     value: data?.away_date ? format(data?.away_date) : "-",
  //   },
  //   {
  //     name: globalLang.transportation[lang],
  //     value: data?.transport ? data?.transport?.name : "-",
  //   },
  //   {
  //     name: globalLang.product[lang],
  //     value: `${data?.product ? data?.product?.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.product_type[lang],
  //     value: `${data?.product_type ? data?.product_type : "-"}`,
  //   },
  //   {
  //     name: globalLang.away_quantity[lang],
  //     value: `${data?.away_quantity ? data?.away_quantity : "-"}`,
  //   },
  //   {
  //     name: globalLang.BackDate[lang],
  //     value: `${data?.home_date ? format(data?.home_date) : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_empty[lang],
  //     value: `${data?.home_quantity_empty ? data?.home_quantity_empty : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_filled[lang],
  //     value: `${data?.home_quantity_filled ? data?.home_quantity_filled : "-"}`,
  //   },
  //   {
  //     name: globalLang.total_price[lang],
  //     value: `${data?.total_price ? data?.total_price : "-"}`,
  //   },

  // ];

  const columns = [
    // {
    //   field: "id",
    //   headerName: globalLang.receiptnumber[lang],

    // },
    {
      field: "station",
      headerName: globalLang.station[lang],
      customContent: ({ rowData }) =>
        rowData.station ? `${rowData.station?.name} ` : "-",
    },
    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
      customContent: ({ rowData }) =>
        rowData.fill_type ? rowData.fill_type : "-",
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ rowData }) =>
        rowData.product ? `${rowData.product?.name} ` : "-",
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
      customContent: ({ rowData }) =>
        rowData?.volume === 0 ? "0" : rowData?.volume ? rowData?.volume : "-",
    },

    {
      field: "quantityout",
      headerName: globalLang.exit_quantity[lang],
      customContent: ({ rowData }) =>
        rowData.quantityout === 0
          ? "0"
          : rowData.quantityout
          ? rowData.quantityout
          : "-",
    },
    {
      field: "quantityin",
      headerName: globalLang.enter_quantity[lang],
      customContent: ({ rowData }) =>
        rowData.quantityin === 0
          ? "0"
          : rowData.quantityin
          ? rowData.quantityin
          : "-",
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
      customContent: ({ rowData }) =>
        rowData?.quantityreturned === 0
          ? "0"
          : rowData?.quantityreturned
          ? rowData?.quantityreturned
          : "-",
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData?.store === 0 ? "0" : rowData?.store ? rowData.store : "-",
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ rowData }) =>
        rowData?.fill === 0 ? "0" : rowData?.fill ? rowData?.fill : "-",
    },

    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ rowData }) =>
        rowData?.price === 0 ? "0" : rowData?.price ? rowData?.price : "-",
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ rowData }) =>
        rowData?.fillprice === 0
          ? "0"
          : rowData?.fillprice
          ? rowData?.fillprice
          : "-",
    },

    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ rowData }) =>
        rowData?.paid === 0 ? "0" : rowData?.paid ? rowData?.paid : "-",
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ rowData }) =>
        rowData?.remain_price === 0
          ? "0"
          : rowData?.remain_price
          ? rowData?.remain_price
          : "-",
    },

    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ rowData }) =>
        rowData?.dokhan === 0 ? "0" : rowData?.dokhan ? rowData?.dokhan : "-",
    },
    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ rowData }) =>
        rowData?.safi === 0 ? "0" : rowData?.safi ? rowData?.safi : "-",
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading> */}
      {/* <DialogInfoWindow information={info} /> */}

      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>

        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <Stack sx={{ gap: 2 }}>
            <Typography  component="span" variant="p" sx={{ marginLeft: "20px" }}>
              {data?.bills ? data?.bills[0]?.permit_uuid : ""}
            </Typography>
            <DialogTable
              rows={data?.bills?.filter(
                (bill) => bill?.product_type === "cylinder"
              )}
              columns={columns}
            />
          </Stack>
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [FormData, setFormData] = useState([]);
  const getFormData = (data) => {
    setFormData([...data]);
  };
  ///////////////////////////////
  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "quantityout",
      headerName: globalLang.exit_quantity[lang],
    },
    {
      field: "quantityin",
      headerName: globalLang.enter_quantity[lang],
    },

    {
      field: "product",
      headerName: globalLang.product[lang],
    },

    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
    },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control:"fill_uuid",
        value:data?.bill_uuid,
        isRequired:false,
      },
      {
        control:"bill_date",
        value:data?.bill_date,
        isRequired:false,
      },
      {
        control: "bill_id",
        value: "",
        isRequired: false,
      },
      {
        control: "bills",
        value: [],
        isRequired: false,
      },
    ],
    [data]
  );
  const EditableColumns=["price",'paid','dokhan']
  const [station_data, setData] = useState(data);
  useEffect(() => {
    data?.bills.map((bill, billindex) => {
      setControl("bills", (old) => [
        ...old,
        {
          price: bill["price"],
          paid: bill["paid"],
          dokhan: bill["dokhan"],
        },
      ]);
    });
  }, [data?.bills]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);


  const handleEditBill = (id, index) => {
    const requestBody = filter({
      obj: {
        bill_id: id,
        bills: [FormData[index]],
      },
      output: "object",
    });

    stationBillPatchRequest({
      id: station_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "station_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };
  console.log(controls.bills);

  const [inform, setinform] = useState(false);
  const [stationBillPatchRequest, stationBillPatchResponse] = useRequest({
    path: STATIONBILL,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output)
      if (!output.isOk) return;
      const isThereChange = !compare(
        [[controls.bill_date, data.bill_date],[controls.fill_uuid, data.bill_uuid]],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
           
          },
          output: "object",
        });
        controls.fill_uuid !== data.bill_uuid
          ? (requestBody["bill_uuid"] = controls.fill_uuid)
          : "";
        controls.bill_date !== data.bill_date ?  (requestBody["bill_date"] = controls.bill_date.toISOString())
        : "";

        stationBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "station_bill/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
            <DialogNumberField
          placeholder={globalLang.fill_number[lang]}
          label={globalLang.fill_number[lang]}
          value={controls.fill_uuid}
          onChange={(e) => setControl("fill_uuid", e.target.value)}
          error={Boolean(invalid.fill_uuid)}
          helperText={invalid.fill_uuid}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={globalLang.billDate[lang]}
              value={controls.bill_date}
              onChange={(newValue) => {
                setControl("bill_date", newValue);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      // borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      fontWeight: "400",
                      background: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                  required={required.includes("exit_date")}
                  error={Boolean(invalid.exit_date)}
                  helperText={invalid.exit_date}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <EditReceiptDialog
            data={data?.bills}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />

        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {stationBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};
export const UUIDFilter = ({ value, onChange }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["bill_uuid", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <NumberField
      placeholder={globalLang.fill_number[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};
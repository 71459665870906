import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import Jobs from "../../assets/Jobs.png";
import Employees from "../../assets/Employees.png";
import ReceiptBox from "../../components/ReceiptBox";
import { useSelector } from "react-redux";

const MainEmployees = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const boxes = [
    {
      image: Jobs,
      title: "الوظائف",
      perm: ["view_factoryjob"],
      type: "employees",
      path: "/view/jobs",
    },
    {
      image: Employees,
      title: "الموظفين",
      perm: ["view_factoryemployee"],
      type: "employees",
      path: "/view/employees",
    },
  ];

  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default MainEmployees;

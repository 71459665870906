import {
  Box,
  Button,
  FormLabel,
  MenuItem,
  Popper,
  FormControl,
  InputLabel,
  Select,
  CardContent,
  CardActions,
  Card,
  CardHeader,
  Stack,
  Item,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Wrapper from "../../components/Wrapper";
import {
  STATIONS,
  CLIENTS,
  COUNTRY_FILTER,
  PRODUCTS,
  STATES,
  STOCK,
  STOCK_PRACTICAL,
  TRADERS,
  STOCK_OUTS,
  FACTORY_POWER,
} from "../../data/APIs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
  TextareaField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import format from "../../utils/ISOToReadable";
import { width } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers";
import OutsStock from "./outsStock";
const AddStock = () => {
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };

  const lang = useSelector((state) => state.lang.value.lang);
  const volumes = [
    { name: "40", value: 40 },
    { name: "50", value: 50 },
    { name: "20", value: 20 },
    { name: "15", value: 15 },
    { name: "10", value: 10 },
  ];
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [responsebody, setResponse] = useState([]);
  const productsStore = useSelector((state) => state.products.value);
  const [preview, setpreview] = useState("");
  // get data for client with dispatch

  ///////////////////////////////
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "product",
      value: "",
      isRequired: true,
    },
    {
      control: "to_product",
      value: "",
      isRequired: false,
    },
    {
      control: "quantity",
      value: "",
      isRequired: true,
    },
    {
      control: "procedure",
      value: "",
      isRequired: true,
    },
    {
      control: "price",
      value: "",
      isRequired: false,
    },
    {
      control: "statement",
      value: "",
      isRequired: false,
    },
    {
      control: "volumes",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
    {
      control: "date",
      value: getDate(),
      isRequired: true,
    },
  ]);

  const [stockPostRequest, stockPostResponse] = useRequest({
    path: FACTORY_POWER,
    method: "post",
    successMessage: "تم إضافة المنتج بنجاح",
  });

  // products data connection to api

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  const [stocksGetRequest, stocksGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getStocks = () => {
    stocksGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };
  const product_type = [
    { name: "أسطوانة", value: "cylinder" },
    { name: "دوار", value: "dewar" },
  ];
  // const location = useLocation();
  const procedure = [
    { name: "بيع", value: "sell" },
    { name: "شراء", value: "buy" },
    { name: "تحويل", value: "transform" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then((data) => {
      console.log(data);
      if (!data.isOk) return;

      //requst body of stock
      const requestBody = filter({
        obj: {
          date: controls.date,
          product: controls.product,
          to_product:controls.to_product,
          quantity: controls.quantity,
          procedure: controls.procedure,
          price: controls.price,
          statement: controls.statement,
          volume: controls.volumes,
          product_type: controls.product_type,
        },

        output: "object",
      });
      if (
        Object.keys(
          filter({
            obj: {
              date: controls.date,
              product: controls.product,
              to_product:controls.to_product,
              quantity: controls.quantity,
              procedure: controls.procedure,
              price: controls.price,
              statement: controls.statement,
              volume: controls.volumes,
              product_type: controls.product_type,
            },
          })
        ).length <= 1
      )
        return;

      stockPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          dispatch({ type: "stock-balance/addItem", payload: res.data });
          resetControls();
        },
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            id: res?.id,
            date: res?.date,
            product: res?.product,
            to_product:res?.to_product,
            quantity: res?.quantity,
            procedure: res?.procedure,
            price: res?.price,
            statement: res?.statement,
            volume: res?.volumes,
            product_type: res?.product_type,
          },
        });

        if (res?.id) {
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: stockPostResponse.isPending,
          },
          closeBtn: {
            disabled: stockPostResponse.isPending,
          },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={globalLang.DateAdded[lang]}
            value={controls.date}
            format="YYYY/MM/DD"
            onChange={(newValue) => {
              const new_date = `${newValue["$y"]}-${
                parseInt(newValue["$M"]) + 1
              }-${newValue["$D"]}`;

              setControl("date", new_date);
            }}
            renderInput={(params) => (
              <InputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderColor: "#F0F0F8",
                    paddingRight: "5px",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#666666",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <SelectField
          label={globalLang.procedure[lang]}
          placeholder={globalLang.procedure[lang]}
          renderValue={(selected) => {
            return procedure.find((type) => type.value === selected).name;
          }}
          onChange={(e) => {
            setControl("procedure", e.target.value);
          }}
          value={controls.procedure}
          required={required.includes("procedure")}
          error={Boolean(invalid.procedure)}
          helperText={invalid.procedure}
        >
          {procedure.map((procedure, index) => (
            <MenuItem
              value={procedure.value}
              key={`${procedure.name} ${index}`}
            >
              {procedure.name}
            </MenuItem>
          ))}
        </SelectField>
        
        {controls.procedure !== "transform" && (
        <SelectField
          label={globalLang.Gas[lang]}
          placeholder={globalLang.Gas[lang]}
          renderValue={(selected) => {
            return productsStore.results.find(
              (product) => product.id === selected
            ).name;
          }}
          isPending={productsGetResponse.isPending}
          onOpen={getProducts}
          onChange={(e) => {
            setControl("product", e.target.value);
          }}
          value={controls.product}
          required={required.includes("product")}
          error={Boolean(invalid.product)}
          helperText={invalid.product}
        >
          {productsStore?.results?.map((product, index) => (
            <MenuItem value={product.id} key={`${product.name} ${index}`}>
              {product.name}
            </MenuItem>
          ))}
        </SelectField>
        )}

{controls.procedure === "transform" && (
          <>
            <SelectField
              label={globalLang.fromgas[lang]}
              placeholder={globalLang.fromgas[lang]}
              renderValue={(selected) => {
                return productsStore.results.find(
                  (product) => product.id === selected
                ).name;
              }}
              isPending={productsGetResponse.isPending}
              onOpen={getProducts}
              onChange={(e) => {
                setControl("product", e.target.value);
              }}
              value={controls.product}
              required={required.includes("product")}
              error={Boolean(invalid.product)}
              helperText={invalid.product}
            >
              {productsStore?.results?.map((product, index) => (
                <MenuItem value={product.id} key={`${product.name} ${index}`}>
                  {product.name}
                </MenuItem>
              ))}
            </SelectField>
            <SelectField
              label={globalLang.togas[lang]}
              placeholder={globalLang.togas[lang]}
              renderValue={(selected) => {
                return productsStore.results.find(
                  (product) => product.id === selected
                ).name;
              }}
              isPending={productsGetResponse.isPending}
              onOpen={getProducts}
              onChange={(e) => {
                setControl("to_product", e.target.value);
              }}
              value={controls.to_product}
              required={required.includes("to_product")}
              error={Boolean(invalid.to_product)}
              helperText={invalid.to_product}
            >
              {productsStore?.results?.map((product, index) => (
                <MenuItem value={product.id} key={`${product.name} ${index}`}>
                  {product.name}
                </MenuItem>
              ))}
            </SelectField>
          </>
        )}
        <SelectField
          label={globalLang.package[lang]}
          placeholder={globalLang.package[lang]}
          renderValue={(selected) => {
            return product_type.find((type) => type.value === selected).name;
          }}
          onChange={(e) => {
            setControl("product_type", e.target.value);
          }}
          value={controls.product_type}
          required={required.includes("product_type")}
          error={Boolean(invalid.product_type)}
          helperText={invalid.product_type}
        >
          {product_type.map((product, index) => (
            <MenuItem value={product.value} key={`${product.name} ${index}`}>
              {product.name}
            </MenuItem>
          ))}
        </SelectField>
        
        <SelectField
          label={globalLang.volume[lang]}
          placeholder={globalLang.volume[lang]}
          renderValue={(selected) => {
            return volumes.find((type) => type.value === selected).name;
          }}
          onChange={(e) => {
            setControl("volumes", e.target.value);
          }}
          value={controls.volumes}
          DefualtValue="40"
          required={required.includes("volumes")}
          error={Boolean(invalid.volumes)}
          helperText={invalid.volumes}
        >
          {volumes.map((volume, index) => (
            <MenuItem value={volume.value} key={`${volume.name} ${index}`}>
              {volume.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.quantity_balance[lang]}
          placeholder={globalLang.quantity_balance[lang]}
          onChange={(e) => setControl("quantity", e.target.value)}
          value={controls.quantity}
          required={required.includes("quantity")}
          error={Boolean(invalid.number)}
          helperText={invalid.number}
        ></NumberField>
        {/* add empty and full input field ,popper for outputs */}
        {controls.procedure !== "transform" && (
        <NumberField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          onChange={(e) => setControl("price", e.target.value)}
          value={controls.price}
          required={required.includes("price")}
          error={Boolean(invalid.price)}
          helperText={invalid.price}
        ></NumberField>
        )}
        <TextareaField
          label={globalLang.statement[lang]}
          placeholder={globalLang.statement[lang]}
          onChange={(e) => setControl("statement", e.target.value)}
          value={controls.statement}
          required={required.includes("statement")}
          error={Boolean(invalid.statement)}
          helperText={invalid.statement}
        ></TextareaField>
      </Form>

      {stockPostResponse.successAlert}
      {stockPostResponse.failAlert}
    </>
  );
};

export default AddStock;

import { Box } from "@mui/material";

import React from "react";
import Charging from "../../assets/Charging.png";
import ReceiptBox from "../../components/ReceiptBox";
import Distribution from "../../assets/Distribution.png"
import Filling from "../../assets/Filling.png"
import TankLevel from "../../assets/TankLevel.png"
import Expenses from "../../assets/Expenses.png"
import { useSelector } from "react-redux";

const MainReceipts = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const boxes = [
    {
      image: Filling,
      title: "تعبئة",
      index: 0,
      perm: ["view_factorytraderbill"],
      type: "bills",
    },
    {
      image: Charging,
      title: "شحن",
      index: 1,
      perm: ["view_factorystationbill"],
      type: "bills",
    },

    {
      image: Distribution,
      title: "توزيع",
      index: 2,
      perm: ["view_factoryclientdistribution"],
      type: "bills",
    },
    {
      image: TankLevel,
      title: "شحن التانك",
      index: 3,
      perm: ["view_factorystationtankbill"],
      type: "bills",
    },
    {
      image: Expenses,
      title: "مصروفات",
      index: 4,
      perm: ["view_factoryexpensesbill"],
      type: "bills",
    },
    {
      image: Expenses,
      title: "فواتير",
      index: 5,
      perm: ["view_factoryfinancedepositbills","view_factorypurchasebills","view_factorysalesbills"],
      type: "bills",
    },
  ];

  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default MainReceipts;

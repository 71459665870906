import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { PRODUCTS } from "../../data/APIs";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import { useState } from "react";
import { useEffect } from "react";
import format from "../../utils/ISOToReadable";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Stack, TextField } from "@mui/material";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import { InputField } from "../../features/form";
import { LteMobiledataOutlined } from "@mui/icons-material";

const ViewProducts = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewProductsLang = useSelector(
    (state) => state.lang.value.viewProducts
  );
  const globalLang = useSelector((state) => state.lang.value.global);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.ProductMessage
  );
  ///////////////////////////////
  const productsStore = useSelector((state) => state.products.value);

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.createdAt[lang],
      component: <DateFilter />,
    },
  ];

  const columns = [

    {
      field: "name",
      headerName: viewProductsLang.productName[lang],
      customContent: (params) =>
        params?.name ? params?.name : globalLang.unknown[lang],
    },
       {
      field: "rate",
      headerName: globalLang.rate[lang],
      customContent: (params) =>
        params.rate ? params?.rate===0 ? "0" : params?.rate: "-"
    },
    {
      field: "unit",
      headerName: globalLang.unit[lang],
 
    },
    {
      field: "molecular_weight",
      headerName: globalLang.molecular_weight[lang],
      customContent: (params) =>
      params.molecular_weight ? params?.molecular_weight===0 ? "0" : params?.molecular_weight: "-"
   
    },
    {
      field: "color",
      headerName: globalLang.color[lang],
      customContent: (params) =>
      
      params?.color ? params?.color && <Box bgcolor={params.color} sx={{height:"20px",width:"20px",borderRadius:"4px"}}/> :"-"
   
    },
    {
      field: "created_at",
      headerName: globalLang.createdAt[lang],
      customContent: (params) =>
        params?.created_at ? format(params?.created_at) : "-",
    },
  ];

  const dispatch = useDispatch();

  const handleDeleteProduct = (e, row) => {
    productsDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "products/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDelete, deleteProductConfirmDialog] = useConfirmMessage({
    onConfirm: handleDeleteProduct,
    text: confirmMessage[lang],
  });

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const [productsDeleteRequest] = useRequest({
    path: PRODUCTS,
    method: "delete",
  });

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    productsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      ...old,
      filters: filters.map(({ query }) => query),
    }));
  };

  const isPermitted = useIsPermitted();

  return (
    <>
      <Breadcrumbs
        path={[globalLang.products[lang], viewProductsLang.viewProducts[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={productsStore.results}
        isPending={productsGetResponse.isPending}
        total={productsStore.count}
        onDelete={isPermitted(handleDelete, ["delete_factoryproduct"])}
        onFilter={handleFilter}
        onAmountChange={handleChangeAmount}
        onPaginate={handlePaginate}
        filters={filters}
      />
      {deleteProductConfirmDialog}
    </>
  );
};

export default ViewProducts;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};

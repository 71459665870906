const countriesCodes = [
  {
    dial: "+20",
    name: "Egypt",
    code: "EG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
    emoji: "🇪🇬",
    unicode: "U+1F1EA U+1F1EC",
  },
  {
    dial: "+93",
    name: "Afghanistan",
    code: "AF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg",
    emoji: "🇦🇫",
    unicode: "U+1F1E6 U+1F1EB",
  },
  {
    dial: "+355",
    name: "Albania",
    code: "AL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
    emoji: "🇦🇱",
    unicode: "U+1F1E6 U+1F1F1",
  },
  {
    dial: "+213",
    name: "Algeria",
    code: "DZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
    emoji: "🇩🇿",
    unicode: "U+1F1E9 U+1F1FF",
  },
  {
    dial: "+1-684",
    name: "American Samoa",
    code: "AS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
    emoji: "🇦🇸",
    unicode: "U+1F1E6 U+1F1F8",
  },
  {
    dial: "+376",
    name: "Andorra",
    code: "AD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
    emoji: "🇦🇩",
    unicode: "U+1F1E6 U+1F1E9",
  },
  {
    dial: "+244",
    name: "Angola",
    code: "AO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
    emoji: "🇦🇴",
    unicode: "U+1F1E6 U+1F1F4",
  },
  {
    dial: "+1-264",
    name: "Anguilla",
    code: "AI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
    emoji: "🇦🇮",
    unicode: "U+1F1E6 U+1F1EE",
  },
  {
    dial: "+672",
    name: "Antarctica",
    code: "AQ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
    emoji: "🇦🇶",
    unicode: "U+1F1E6 U+1F1F6",
  },
  {
    dial: "+1-268",
    name: "Antigua and Barbuda",
    code: "AG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
    emoji: "🇦🇬",
    unicode: "U+1F1E6 U+1F1EC",
  },
  {
    dial: "+54",
    name: "Argentina",
    code: "AR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
    emoji: "🇦🇷",
    unicode: "U+1F1E6 U+1F1F7",
  },
  {
    dial: "+374",
    name: "Armenia",
    code: "AM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
    emoji: "🇦🇲",
    unicode: "U+1F1E6 U+1F1F2",
  },
  {
    dial: "+297",
    name: "Aruba",
    code: "AW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
    emoji: "🇦🇼",
    unicode: "U+1F1E6 U+1F1FC",
  },
  {
    dial: "+61",
    name: "Australia",
    code: "AU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
    emoji: "🇦🇺",
    unicode: "U+1F1E6 U+1F1FA",
  },
  {
    dial: "+43",
    name: "Austria",
    code: "AT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
    emoji: "🇦🇹",
    unicode: "U+1F1E6 U+1F1F9",
  },
  {
    dial: "+994",
    name: "Azerbaijan",
    code: "AZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
    emoji: "🇦🇿",
    unicode: "U+1F1E6 U+1F1FF",
  },
  {
    dial: "+1-242",
    name: "Bahamas",
    code: "BS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
    emoji: "🇧🇸",
    unicode: "U+1F1E7 U+1F1F8",
  },
  {
    dial: "+973",
    name: "Bahrain",
    code: "BH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
    emoji: "🇧🇭",
    unicode: "U+1F1E7 U+1F1ED",
  },
  {
    dial: "+880",
    name: "Bangladesh",
    code: "BD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
    emoji: "🇧🇩",
    unicode: "U+1F1E7 U+1F1E9",
  },
  {
    dial: "+1-246",
    name: "Barbados",
    code: "BB",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
    emoji: "🇧🇧",
    unicode: "U+1F1E7 U+1F1E7",
  },
  {
    dial: "+375",
    name: "Belarus",
    code: "BY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
    emoji: "🇧🇾",
    unicode: "U+1F1E7 U+1F1FE",
  },
  {
    dial: "+32",
    name: "Belgium",
    code: "BE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
    emoji: "🇧🇪",
    unicode: "U+1F1E7 U+1F1EA",
  },
  {
    dial: "+501",
    name: "Belize",
    code: "BZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
    emoji: "🇧🇿",
    unicode: "U+1F1E7 U+1F1FF",
  },
  {
    dial: "+229",
    name: "Benin",
    code: "BJ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
    emoji: "🇧🇯",
    unicode: "U+1F1E7 U+1F1EF",
  },
  {
    dial: "+1-441",
    name: "Bermuda",
    code: "BM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
    emoji: "🇧🇲",
    unicode: "U+1F1E7 U+1F1F2",
  },
  {
    dial: "+975",
    name: "Bhutan",
    code: "BT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
    emoji: "🇧🇹",
    unicode: "U+1F1E7 U+1F1F9",
  },
  {
    dial: "+591",
    name: "Bolivia",
    code: "BO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
    emoji: "🇧🇴",
    unicode: "U+1F1E7 U+1F1F4",
  },
  {
    dial: "+387",
    name: "Bosnia and Herzegovina",
    code: "BA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
    emoji: "🇧🇦",
    unicode: "U+1F1E7 U+1F1E6",
  },
  {
    dial: "+267",
    name: "Botswana",
    code: "BW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
    emoji: "🇧🇼",
    unicode: "U+1F1E7 U+1F1FC",
  },
  {
    dial: "+55",
    name: "Brazil",
    code: "BR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
    emoji: "🇧🇷",
    unicode: "U+1F1E7 U+1F1F7",
  },
  {
    dial: "+246",
    name: "British Indian Ocean Territory",
    code: "IO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
    emoji: "🇮🇴",
    unicode: "U+1F1EE U+1F1F4",
  },
  {
    dial: "+1-284",
    name: "British Virgin Islands",
    code: "VG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
    emoji: "🇻🇬",
    unicode: "U+1F1FB U+1F1EC",
  },
  {
    dial: "+673",
    name: "Brunei",
    code: "BN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
    emoji: "🇧🇳",
    unicode: "U+1F1E7 U+1F1F3",
  },
  {
    dial: "+359",
    name: "Bulgaria",
    code: "BG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
    emoji: "🇧🇬",
    unicode: "U+1F1E7 U+1F1EC",
  },
  {
    dial: "+226",
    name: "Burkina Faso",
    code: "BF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
    emoji: "🇧🇫",
    unicode: "U+1F1E7 U+1F1EB",
  },
  {
    dial: "+257",
    name: "Burundi",
    code: "BI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
    emoji: "🇧🇮",
    unicode: "U+1F1E7 U+1F1EE",
  },
  {
    dial: "+855",
    name: "Cambodia",
    code: "KH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
    emoji: "🇰🇭",
    unicode: "U+1F1F0 U+1F1ED",
  },
  {
    dial: "+237",
    name: "Cameroon",
    code: "CM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
    emoji: "🇨🇲",
    unicode: "U+1F1E8 U+1F1F2",
  },
  {
    dial: "+1",
    name: "Canada",
    code: "CA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
    emoji: "🇨🇦",
    unicode: "U+1F1E8 U+1F1E6",
  },
  {
    dial: "+238",
    name: "Cape Verde",
    code: "CV",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
    emoji: "🇨🇻",
    unicode: "U+1F1E8 U+1F1FB",
  },
  {
    dial: "+1-345",
    name: "Cayman Islands",
    code: "KY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
    emoji: "🇰🇾",
    unicode: "U+1F1F0 U+1F1FE",
  },
  {
    dial: "+236",
    name: "Central African Republic",
    code: "CF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
    emoji: "🇨🇫",
    unicode: "U+1F1E8 U+1F1EB",
  },
  {
    dial: "+235",
    name: "Chad",
    code: "TD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
    emoji: "🇹🇩",
    unicode: "U+1F1F9 U+1F1E9",
  },
  {
    dial: "+56",
    name: "Chile",
    code: "CL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
    emoji: "🇨🇱",
    unicode: "U+1F1E8 U+1F1F1",
  },
  {
    dial: "+86",
    name: "China",
    code: "CN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
    emoji: "🇨🇳",
    unicode: "U+1F1E8 U+1F1F3",
  },
  {
    dial: "+61",
    name: "Christmas Island",
    code: "CX",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
    emoji: "🇨🇽",
    unicode: "U+1F1E8 U+1F1FD",
  },
  {
    dial: "+61",
    name: "Cocos Islands",
    code: "CC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
    emoji: "🇨🇨",
    unicode: "U+1F1E8 U+1F1E8",
  },
  {
    dial: "+57",
    name: "Colombia",
    code: "CO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
    emoji: "🇨🇴",
    unicode: "U+1F1E8 U+1F1F4",
  },
  {
    dial: "+269",
    name: "Comoros",
    code: "KM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
    emoji: "🇰🇲",
    unicode: "U+1F1F0 U+1F1F2",
  },
  {
    dial: "+682",
    name: "Cook Islands",
    code: "CK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
    emoji: "🇨🇰",
    unicode: "U+1F1E8 U+1F1F0",
  },
  {
    dial: "+506",
    name: "Costa Rica",
    code: "CR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
    emoji: "🇨🇷",
    unicode: "U+1F1E8 U+1F1F7",
  },
  {
    dial: "+385",
    name: "Croatia",
    code: "HR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
    emoji: "🇭🇷",
    unicode: "U+1F1ED U+1F1F7",
  },
  {
    dial: "+53",
    name: "Cuba",
    code: "CU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
    emoji: "🇨🇺",
    unicode: "U+1F1E8 U+1F1FA",
  },
  {
    dial: "+599",
    name: "Curacao",
    code: "CW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
    emoji: "🇨🇼",
    unicode: "U+1F1E8 U+1F1FC",
  },
  {
    dial: "+357",
    name: "Cyprus",
    code: "CY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
    emoji: "🇨🇾",
    unicode: "U+1F1E8 U+1F1FE",
  },
  {
    dial: "+420",
    name: "Czech Republic",
    code: "CZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
    emoji: "🇨🇿",
    unicode: "U+1F1E8 U+1F1FF",
  },
  {
    dial: "+243",
    name: "Democratic Republic of the Congo",
    code: "CD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
    emoji: "🇨🇩",
    unicode: "U+1F1E8 U+1F1E9",
  },
  {
    dial: "+45",
    name: "Denmark",
    code: "DK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
    emoji: "🇩🇰",
    unicode: "U+1F1E9 U+1F1F0",
  },
  {
    dial: "+253",
    name: "Djibouti",
    code: "DJ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
    emoji: "🇩🇯",
    unicode: "U+1F1E9 U+1F1EF",
  },
  {
    dial: "+1-767",
    name: "Dominica",
    code: "DM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
    emoji: "🇩🇲",
    unicode: "U+1F1E9 U+1F1F2",
  },
  {
    dial: "+1-809, 1-829, 1-849",
    name: "Dominican Republic",
    code: "DO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
    emoji: "🇩🇴",
    unicode: "U+1F1E9 U+1F1F4",
  },
  {
    dial: "+670",
    name: "East Timor",
    code: "TL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
    emoji: "🇹🇱",
    unicode: "U+1F1F9 U+1F1F1",
  },
  {
    dial: "+593",
    name: "Ecuador",
    code: "EC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
    emoji: "🇪🇨",
    unicode: "U+1F1EA U+1F1E8",
  },
  {
    dial: "+503",
    name: "El Salvador",
    code: "SV",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
    emoji: "🇸🇻",
    unicode: "U+1F1F8 U+1F1FB",
  },
  {
    dial: "+240",
    name: "Equatorial Guinea",
    code: "GQ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
    emoji: "🇬🇶",
    unicode: "U+1F1EC U+1F1F6",
  },
  {
    dial: "+291",
    name: "Eritrea",
    code: "ER",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
    emoji: "🇪🇷",
    unicode: "U+1F1EA U+1F1F7",
  },
  {
    dial: "+372",
    name: "Estonia",
    code: "EE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
    emoji: "🇪🇪",
    unicode: "U+1F1EA U+1F1EA",
  },
  {
    dial: "+251",
    name: "Ethiopia",
    code: "ET",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
    emoji: "🇪🇹",
    unicode: "U+1F1EA U+1F1F9",
  },
  {
    dial: "+500",
    name: "Falkland Islands",
    code: "FK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
    emoji: "🇫🇰",
    unicode: "U+1F1EB U+1F1F0",
  },
  {
    dial: "+298",
    name: "Faroe Islands",
    code: "FO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
    emoji: "🇫🇴",
    unicode: "U+1F1EB U+1F1F4",
  },
  {
    dial: "+679",
    name: "Fiji",
    code: "FJ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
    emoji: "🇫🇯",
    unicode: "U+1F1EB U+1F1EF",
  },
  {
    dial: "+358",
    name: "Finland",
    code: "FI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
    emoji: "🇫🇮",
    unicode: "U+1F1EB U+1F1EE",
  },
  {
    dial: "+33",
    name: "France",
    code: "FR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
    emoji: "🇫🇷",
    unicode: "U+1F1EB U+1F1F7",
  },
  {
    dial: "+689",
    name: "French Polynesia",
    code: "PF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
    emoji: "🇵🇫",
    unicode: "U+1F1F5 U+1F1EB",
  },
  {
    dial: "+241",
    name: "Gabon",
    code: "GA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
    emoji: "🇬🇦",
    unicode: "U+1F1EC U+1F1E6",
  },
  {
    dial: "+220",
    name: "Gambia",
    code: "GM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
    emoji: "🇬🇲",
    unicode: "U+1F1EC U+1F1F2",
  },
  {
    dial: "+995",
    name: "Georgia",
    code: "GE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
    emoji: "🇬🇪",
    unicode: "U+1F1EC U+1F1EA",
  },
  {
    dial: "+49",
    name: "Germany",
    code: "DE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
    emoji: "🇩🇪",
    unicode: "U+1F1E9 U+1F1EA",
  },
  {
    dial: "+233",
    name: "Ghana",
    code: "GH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
    emoji: "🇬🇭",
    unicode: "U+1F1EC U+1F1ED",
  },
  {
    dial: "+350",
    name: "Gibraltar",
    code: "GI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
    emoji: "🇬🇮",
    unicode: "U+1F1EC U+1F1EE",
  },
  {
    dial: "+30",
    name: "Greece",
    code: "GR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
    emoji: "🇬🇷",
    unicode: "U+1F1EC U+1F1F7",
  },
  {
    dial: "+299",
    name: "Greenland",
    code: "GL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
    emoji: "🇬🇱",
    unicode: "U+1F1EC U+1F1F1",
  },
  {
    dial: "+1-473",
    name: "Grenada",
    code: "GD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
    emoji: "🇬🇩",
    unicode: "U+1F1EC U+1F1E9",
  },
  {
    dial: "+1-671",
    name: "Guam",
    code: "GU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
    emoji: "🇬🇺",
    unicode: "U+1F1EC U+1F1FA",
  },
  {
    dial: "+502",
    name: "Guatemala",
    code: "GT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
    emoji: "🇬🇹",
    unicode: "U+1F1EC U+1F1F9",
  },
  {
    dial: "+44-1481",
    name: "Guernsey",
    code: "GG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
    emoji: "🇬🇬",
    unicode: "U+1F1EC U+1F1EC",
  },
  {
    dial: "+224",
    name: "Guinea",
    code: "GN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
    emoji: "🇬🇳",
    unicode: "U+1F1EC U+1F1F3",
  },
  {
    dial: "+245",
    name: "Guinea-Bissau",
    code: "GW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
    emoji: "🇬🇼",
    unicode: "U+1F1EC U+1F1FC",
  },
  {
    dial: "+592",
    name: "Guyana",
    code: "GY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
    emoji: "🇬🇾",
    unicode: "U+1F1EC U+1F1FE",
  },
  {
    dial: "+509",
    name: "Haiti",
    code: "HT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
    emoji: "🇭🇹",
    unicode: "U+1F1ED U+1F1F9",
  },
  {
    dial: "+504",
    name: "Honduras",
    code: "HN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
    emoji: "🇭🇳",
    unicode: "U+1F1ED U+1F1F3",
  },
  {
    dial: "+852",
    name: "Hong Kong",
    code: "HK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
    emoji: "🇭🇰",
    unicode: "U+1F1ED U+1F1F0",
  },
  {
    dial: "+36",
    name: "Hungary",
    code: "HU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
    emoji: "🇭🇺",
    unicode: "U+1F1ED U+1F1FA",
  },
  {
    dial: "+354",
    name: "Iceland",
    code: "IS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
    emoji: "🇮🇸",
    unicode: "U+1F1EE U+1F1F8",
  },
  {
    dial: "+91",
    name: "India",
    code: "IN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
    emoji: "🇮🇳",
    unicode: "U+1F1EE U+1F1F3",
  },
  {
    dial: "+62",
    name: "Indonesia",
    code: "ID",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
    emoji: "🇮🇩",
    unicode: "U+1F1EE U+1F1E9",
  },
  {
    dial: "+98",
    name: "Iran",
    code: "IR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
    emoji: "🇮🇷",
    unicode: "U+1F1EE U+1F1F7",
  },
  {
    dial: "+964",
    name: "Iraq",
    code: "IQ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
    emoji: "🇮🇶",
    unicode: "U+1F1EE U+1F1F6",
  },
  {
    dial: "+353",
    name: "Ireland",
    code: "IE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
    emoji: "🇮🇪",
    unicode: "U+1F1EE U+1F1EA",
  },
  {
    dial: "+44-1624",
    name: "Isle of Man",
    code: "IM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
    emoji: "🇮🇲",
    unicode: "U+1F1EE U+1F1F2",
  },
  {
    dial: "+39",
    name: "Italy",
    code: "IT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
    emoji: "🇮🇹",
    unicode: "U+1F1EE U+1F1F9",
  },
  {
    dial: "+225",
    name: "Ivory Coast",
    code: "CI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
    emoji: "🇨🇮",
    unicode: "U+1F1E8 U+1F1EE",
  },
  {
    dial: "+1-876",
    name: "Jamaica",
    code: "JM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
    emoji: "🇯🇲",
    unicode: "U+1F1EF U+1F1F2",
  },
  {
    dial: "+81",
    name: "Japan",
    code: "JP",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
    emoji: "🇯🇵",
    unicode: "U+1F1EF U+1F1F5",
  },
  {
    dial: "+44-1534",
    name: "Jersey",
    code: "JE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
    emoji: "🇯🇪",
    unicode: "U+1F1EF U+1F1EA",
  },
  {
    dial: "+962",
    name: "Jordan",
    code: "JO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
    emoji: "🇯🇴",
    unicode: "U+1F1EF U+1F1F4",
  },
  {
    dial: "+7",
    name: "Kazakhstan",
    code: "KZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
    emoji: "🇰🇿",
    unicode: "U+1F1F0 U+1F1FF",
  },
  {
    dial: "+254",
    name: "Kenya",
    code: "KE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
    emoji: "🇰🇪",
    unicode: "U+1F1F0 U+1F1EA",
  },
  {
    dial: "+686",
    name: "Kiribati",
    code: "KI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
    emoji: "🇰🇮",
    unicode: "U+1F1F0 U+1F1EE",
  },
  {
    dial: "+383",
    name: "Kosovo",
    code: "XK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
    emoji: "🇽🇰",
    unicode: "U+1F1FD U+1F1F0",
  },
  {
    dial: "+965",
    name: "Kuwait",
    code: "KW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
    emoji: "🇰🇼",
    unicode: "U+1F1F0 U+1F1FC",
  },
  {
    dial: "+996",
    name: "Kyrgyzstan",
    code: "KG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
    emoji: "🇰🇬",
    unicode: "U+1F1F0 U+1F1EC",
  },
  {
    dial: "+856",
    name: "Laos",
    code: "LA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
    emoji: "🇱🇦",
    unicode: "U+1F1F1 U+1F1E6",
  },
  {
    dial: "+371",
    name: "Latvia",
    code: "LV",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
    emoji: "🇱🇻",
    unicode: "U+1F1F1 U+1F1FB",
  },
  {
    dial: "+961",
    name: "Lebanon",
    code: "LB",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
    emoji: "🇱🇧",
    unicode: "U+1F1F1 U+1F1E7",
  },
  {
    dial: "+266",
    name: "Lesotho",
    code: "LS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
    emoji: "🇱🇸",
    unicode: "U+1F1F1 U+1F1F8",
  },
  {
    dial: "+231",
    name: "Liberia",
    code: "LR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
    emoji: "🇱🇷",
    unicode: "U+1F1F1 U+1F1F7",
  },
  {
    dial: "+218",
    name: "Libya",
    code: "LY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
    emoji: "🇱🇾",
    unicode: "U+1F1F1 U+1F1FE",
  },
  {
    dial: "+423",
    name: "Liechtenstein",
    code: "LI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
    emoji: "🇱🇮",
    unicode: "U+1F1F1 U+1F1EE",
  },
  {
    dial: "+370",
    name: "Lithuania",
    code: "LT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
    emoji: "🇱🇹",
    unicode: "U+1F1F1 U+1F1F9",
  },
  {
    dial: "+352",
    name: "Luxembourg",
    code: "LU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
    emoji: "🇱🇺",
    unicode: "U+1F1F1 U+1F1FA",
  },
  {
    dial: "+853",
    name: "Macau",
    code: "MO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
    emoji: "🇲🇴",
    unicode: "U+1F1F2 U+1F1F4",
  },
  {
    dial: "+389",
    name: "Macedonia",
    code: "MK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
    emoji: "🇲🇰",
    unicode: "U+1F1F2 U+1F1F0",
  },
  {
    dial: "+261",
    name: "Madagascar",
    code: "MG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
    emoji: "🇲🇬",
    unicode: "U+1F1F2 U+1F1EC",
  },
  {
    dial: "+265",
    name: "Malawi",
    code: "MW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
    emoji: "🇲🇼",
    unicode: "U+1F1F2 U+1F1FC",
  },
  {
    dial: "+60",
    name: "Malaysia",
    code: "MY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
    emoji: "🇲🇾",
    unicode: "U+1F1F2 U+1F1FE",
  },
  {
    dial: "+960",
    name: "Maldives",
    code: "MV",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
    emoji: "🇲🇻",
    unicode: "U+1F1F2 U+1F1FB",
  },
  {
    dial: "+223",
    name: "Mali",
    code: "ML",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
    emoji: "🇲🇱",
    unicode: "U+1F1F2 U+1F1F1",
  },
  {
    dial: "+356",
    name: "Malta",
    code: "MT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
    emoji: "🇲🇹",
    unicode: "U+1F1F2 U+1F1F9",
  },
  {
    dial: "+692",
    name: "Marshall Islands",
    code: "MH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
    emoji: "🇲🇭",
    unicode: "U+1F1F2 U+1F1ED",
  },
  {
    dial: "+222",
    name: "Mauritania",
    code: "MR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
    emoji: "🇲🇷",
    unicode: "U+1F1F2 U+1F1F7",
  },
  {
    dial: "+230",
    name: "Mauritius",
    code: "MU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
    emoji: "🇲🇺",
    unicode: "U+1F1F2 U+1F1FA",
  },
  {
    dial: "+262",
    name: "Mayotte",
    code: "YT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
    emoji: "🇾🇹",
    unicode: "U+1F1FE U+1F1F9",
  },
  {
    dial: "+52",
    name: "Mexico",
    code: "MX",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
    emoji: "🇲🇽",
    unicode: "U+1F1F2 U+1F1FD",
  },
  {
    dial: "+691",
    name: "Micronesia",
    code: "FM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
    emoji: "🇫🇲",
    unicode: "U+1F1EB U+1F1F2",
  },
  {
    dial: "+373",
    name: "Moldova",
    code: "MD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
    emoji: "🇲🇩",
    unicode: "U+1F1F2 U+1F1E9",
  },
  {
    dial: "+377",
    name: "Monaco",
    code: "MC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
    emoji: "🇲🇨",
    unicode: "U+1F1F2 U+1F1E8",
  },
  {
    dial: "+976",
    name: "Mongolia",
    code: "MN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
    emoji: "🇲🇳",
    unicode: "U+1F1F2 U+1F1F3",
  },
  {
    dial: "+382",
    name: "Montenegro",
    code: "ME",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
    emoji: "🇲🇪",
    unicode: "U+1F1F2 U+1F1EA",
  },
  {
    dial: "+1-664",
    name: "Montserrat",
    code: "MS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
    emoji: "🇲🇸",
    unicode: "U+1F1F2 U+1F1F8",
  },
  {
    dial: "+212",
    name: "Morocco",
    code: "MA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
    emoji: "🇲🇦",
    unicode: "U+1F1F2 U+1F1E6",
  },
  {
    dial: "+258",
    name: "Mozambique",
    code: "MZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
    emoji: "🇲🇿",
    unicode: "U+1F1F2 U+1F1FF",
  },
  {
    dial: "+95",
    name: "Myanmar",
    code: "MM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
    emoji: "🇲🇲",
    unicode: "U+1F1F2 U+1F1F2",
  },
  {
    dial: "+264",
    name: "Namibia",
    code: "NA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
    emoji: "🇳🇦",
    unicode: "U+1F1F3 U+1F1E6",
  },
  {
    dial: "+674",
    name: "Nauru",
    code: "NR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
    emoji: "🇳🇷",
    unicode: "U+1F1F3 U+1F1F7",
  },
  {
    dial: "+977",
    name: "Nepal",
    code: "NP",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
    emoji: "🇳🇵",
    unicode: "U+1F1F3 U+1F1F5",
  },
  {
    dial: "+31",
    name: "Netherlands",
    code: "NL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
    emoji: "🇳🇱",
    unicode: "U+1F1F3 U+1F1F1",
  },
  {
    dial: "+687",
    name: "New Caledonia",
    code: "NC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
    emoji: "🇳🇨",
    unicode: "U+1F1F3 U+1F1E8",
  },
  {
    dial: "+64",
    name: "New Zealand",
    code: "NZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
    emoji: "🇳🇿",
    unicode: "U+1F1F3 U+1F1FF",
  },
  {
    dial: "+505",
    name: "Nicaragua",
    code: "NI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
    emoji: "🇳🇮",
    unicode: "U+1F1F3 U+1F1EE",
  },
  {
    dial: "+227",
    name: "Niger",
    code: "NE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
    emoji: "🇳🇪",
    unicode: "U+1F1F3 U+1F1EA",
  },
  {
    dial: "+234",
    name: "Nigeria",
    code: "NG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
    emoji: "🇳🇬",
    unicode: "U+1F1F3 U+1F1EC",
  },
  {
    dial: "+683",
    name: "Niue",
    code: "NU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
    emoji: "🇳🇺",
    unicode: "U+1F1F3 U+1F1FA",
  },
  {
    dial: "+850",
    name: "North Korea",
    code: "KP",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
    emoji: "🇰🇵",
    unicode: "U+1F1F0 U+1F1F5",
  },
  {
    dial: "+1-670",
    name: "Northern Mariana Islands",
    code: "MP",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
    emoji: "🇲🇵",
    unicode: "U+1F1F2 U+1F1F5",
  },
  {
    dial: "+47",
    name: "Norway",
    code: "NO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
    emoji: "🇳🇴",
    unicode: "U+1F1F3 U+1F1F4",
  },
  {
    dial: "+968",
    name: "Oman",
    code: "OM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
    emoji: "🇴🇲",
    unicode: "U+1F1F4 U+1F1F2",
  },
  {
    dial: "+92",
    name: "Pakistan",
    code: "PK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
    emoji: "🇵🇰",
    unicode: "U+1F1F5 U+1F1F0",
  },
  {
    dial: "+680",
    name: "Palau",
    code: "PW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
    emoji: "🇵🇼",
    unicode: "U+1F1F5 U+1F1FC",
  },
  {
    dial: "+970",
    name: "Palestine",
    code: "PS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
    emoji: "🇵🇸",
    unicode: "U+1F1F5 U+1F1F8",
  },
  {
    dial: "+507",
    name: "Panama",
    code: "PA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
    emoji: "🇵🇦",
    unicode: "U+1F1F5 U+1F1E6",
  },
  {
    dial: "+675",
    name: "Papua New Guinea",
    code: "PG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
    emoji: "🇵🇬",
    unicode: "U+1F1F5 U+1F1EC",
  },
  {
    dial: "+595",
    name: "Paraguay",
    code: "PY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
    emoji: "🇵🇾",
    unicode: "U+1F1F5 U+1F1FE",
  },
  {
    dial: "+51",
    name: "Peru",
    code: "PE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
    emoji: "🇵🇪",
    unicode: "U+1F1F5 U+1F1EA",
  },
  {
    dial: "+63",
    name: "Philippines",
    code: "PH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
    emoji: "🇵🇭",
    unicode: "U+1F1F5 U+1F1ED",
  },
  {
    dial: "+64",
    name: "Pitcairn",
    code: "PN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
    emoji: "🇵🇳",
    unicode: "U+1F1F5 U+1F1F3",
  },
  {
    dial: "+48",
    name: "Poland",
    code: "PL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
    emoji: "🇵🇱",
    unicode: "U+1F1F5 U+1F1F1",
  },
  {
    dial: "+351",
    name: "Portugal",
    code: "PT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
    emoji: "🇵🇹",
    unicode: "U+1F1F5 U+1F1F9",
  },
  {
    dial: "+1-787, 1-939",
    name: "Puerto Rico",
    code: "PR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
    emoji: "🇵🇷",
    unicode: "U+1F1F5 U+1F1F7",
  },
  {
    dial: "+974",
    name: "Qatar",
    code: "QA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
    emoji: "🇶🇦",
    unicode: "U+1F1F6 U+1F1E6",
  },
  {
    dial: "+242",
    name: "Republic of the Congo",
    code: "CG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
    emoji: "🇨🇬",
    unicode: "U+1F1E8 U+1F1EC",
  },
  {
    dial: "+262",
    name: "Reunion",
    code: "RE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
    emoji: "🇷🇪",
    unicode: "U+1F1F7 U+1F1EA",
  },
  {
    dial: "+40",
    name: "Romania",
    code: "RO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
    emoji: "🇷🇴",
    unicode: "U+1F1F7 U+1F1F4",
  },
  {
    dial: "+7",
    name: "Russia",
    code: "RU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
    emoji: "🇷🇺",
    unicode: "U+1F1F7 U+1F1FA",
  },
  {
    dial: "+250",
    name: "Rwanda",
    code: "RW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
    emoji: "🇷🇼",
    unicode: "U+1F1F7 U+1F1FC",
  },
  {
    dial: "+590",
    name: "Saint Barthelemy",
    code: "BL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
    emoji: "🇧🇱",
    unicode: "U+1F1E7 U+1F1F1",
  },
  {
    dial: "+290",
    name: "Saint Helena",
    code: "SH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
    emoji: "🇸🇭",
    unicode: "U+1F1F8 U+1F1ED",
  },
  {
    dial: "+1-869",
    name: "Saint Kitts and Nevis",
    code: "KN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
    emoji: "🇰🇳",
    unicode: "U+1F1F0 U+1F1F3",
  },
  {
    dial: "+1-758",
    name: "Saint Lucia",
    code: "LC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
    emoji: "🇱🇨",
    unicode: "U+1F1F1 U+1F1E8",
  },
  {
    dial: "+590",
    name: "Saint Martin",
    code: "MF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
    emoji: "🇲🇫",
    unicode: "U+1F1F2 U+1F1EB",
  },
  {
    dial: "+508",
    name: "Saint Pierre and Miquelon",
    code: "PM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
    emoji: "🇵🇲",
    unicode: "U+1F1F5 U+1F1F2",
  },
  {
    dial: "+1-784",
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
    emoji: "🇻🇨",
    unicode: "U+1F1FB U+1F1E8",
  },
  {
    dial: "+685",
    name: "Samoa",
    code: "WS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
    emoji: "🇼🇸",
    unicode: "U+1F1FC U+1F1F8",
  },
  {
    dial: "+378",
    name: "San Marino",
    code: "SM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
    emoji: "🇸🇲",
    unicode: "U+1F1F8 U+1F1F2",
  },
  {
    dial: "+239",
    name: "Sao Tome and Principe",
    code: "ST",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
    emoji: "🇸🇹",
    unicode: "U+1F1F8 U+1F1F9",
  },
  {
    dial: "+966",
    name: "Saudi Arabia",
    code: "SA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
    emoji: "🇸🇦",
    unicode: "U+1F1F8 U+1F1E6",
  },
  {
    dial: "+221",
    name: "Senegal",
    code: "SN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
    emoji: "🇸🇳",
    unicode: "U+1F1F8 U+1F1F3",
  },
  {
    dial: "+381",
    name: "Serbia",
    code: "RS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
    emoji: "🇷🇸",
    unicode: "U+1F1F7 U+1F1F8",
  },
  {
    dial: "+248",
    name: "Seychelles",
    code: "SC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
    emoji: "🇸🇨",
    unicode: "U+1F1F8 U+1F1E8",
  },
  {
    dial: "+232",
    name: "Sierra Leone",
    code: "SL",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
    emoji: "🇸🇱",
    unicode: "U+1F1F8 U+1F1F1",
  },
  {
    dial: "+65",
    name: "Singapore",
    code: "SG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
    emoji: "🇸🇬",
    unicode: "U+1F1F8 U+1F1EC",
  },
  {
    dial: "+1-721",
    name: "Sint Maarten",
    code: "SX",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
    emoji: "🇸🇽",
    unicode: "U+1F1F8 U+1F1FD",
  },
  {
    dial: "+421",
    name: "Slovakia",
    code: "SK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
    emoji: "🇸🇰",
    unicode: "U+1F1F8 U+1F1F0",
  },
  {
    dial: "+386",
    name: "Slovenia",
    code: "SI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
    emoji: "🇸🇮",
    unicode: "U+1F1F8 U+1F1EE",
  },
  {
    dial: "+677",
    name: "Solomon Islands",
    code: "SB",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
    emoji: "🇸🇧",
    unicode: "U+1F1F8 U+1F1E7",
  },
  {
    dial: "+252",
    name: "Somalia",
    code: "SO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
    emoji: "🇸🇴",
    unicode: "U+1F1F8 U+1F1F4",
  },
  {
    dial: "+27",
    name: "South Africa",
    code: "ZA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
    emoji: "🇿🇦",
    unicode: "U+1F1FF U+1F1E6",
  },
  {
    dial: "+82",
    name: "South Korea",
    code: "KR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
    emoji: "🇰🇷",
    unicode: "U+1F1F0 U+1F1F7",
  },
  {
    dial: "+211",
    name: "South Sudan",
    code: "SS",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
    emoji: "🇸🇸",
    unicode: "U+1F1F8 U+1F1F8",
  },
  {
    dial: "+34",
    name: "Spain",
    code: "ES",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
    emoji: "🇪🇸",
    unicode: "U+1F1EA U+1F1F8",
  },
  {
    dial: "+94",
    name: "Sri Lanka",
    code: "LK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
    emoji: "🇱🇰",
    unicode: "U+1F1F1 U+1F1F0",
  },
  {
    dial: "+249",
    name: "Sudan",
    code: "SD",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
    emoji: "🇸🇩",
    unicode: "U+1F1F8 U+1F1E9",
  },
  {
    dial: "+597",
    name: "Suriname",
    code: "SR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
    emoji: "🇸🇷",
    unicode: "U+1F1F8 U+1F1F7",
  },
  {
    dial: "+47",
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
    emoji: "🇸🇯",
    unicode: "U+1F1F8 U+1F1EF",
  },
  {
    dial: "+268",
    name: "Swaziland",
    code: "SZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
    emoji: "🇸🇿",
    unicode: "U+1F1F8 U+1F1FF",
  },
  {
    dial: "+46",
    name: "Sweden",
    code: "SE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
    emoji: "🇸🇪",
    unicode: "U+1F1F8 U+1F1EA",
  },
  {
    dial: "+41",
    name: "Switzerland",
    code: "CH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
    emoji: "🇨🇭",
    unicode: "U+1F1E8 U+1F1ED",
  },
  {
    dial: "+963",
    name: "Syria",
    code: "SY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
    emoji: "🇸🇾",
    unicode: "U+1F1F8 U+1F1FE",
  },
  {
    dial: "+886",
    name: "Taiwan",
    code: "TW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
    emoji: "🇹🇼",
    unicode: "U+1F1F9 U+1F1FC",
  },
  {
    dial: "+992",
    name: "Tajikistan",
    code: "TJ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
    emoji: "🇹🇯",
    unicode: "U+1F1F9 U+1F1EF",
  },
  {
    dial: "+255",
    name: "Tanzania",
    code: "TZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
    emoji: "🇹🇿",
    unicode: "U+1F1F9 U+1F1FF",
  },
  {
    dial: "+66",
    name: "Thailand",
    code: "TH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
    emoji: "🇹🇭",
    unicode: "U+1F1F9 U+1F1ED",
  },
  {
    dial: "+228",
    name: "Togo",
    code: "TG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
    emoji: "🇹🇬",
    unicode: "U+1F1F9 U+1F1EC",
  },
  {
    dial: "+690",
    name: "Tokelau",
    code: "TK",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
    emoji: "🇹🇰",
    unicode: "U+1F1F9 U+1F1F0",
  },
  {
    dial: "+676",
    name: "Tonga",
    code: "TO",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
    emoji: "🇹🇴",
    unicode: "U+1F1F9 U+1F1F4",
  },
  {
    dial: "+1-868",
    name: "Trinidad and Tobago",
    code: "TT",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
    emoji: "🇹🇹",
    unicode: "U+1F1F9 U+1F1F9",
  },
  {
    dial: "+216",
    name: "Tunisia",
    code: "TN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
    emoji: "🇹🇳",
    unicode: "U+1F1F9 U+1F1F3",
  },
  {
    dial: "+90",
    name: "Turkey",
    code: "TR",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
    emoji: "🇹🇷",
    unicode: "U+1F1F9 U+1F1F7",
  },
  {
    dial: "+993",
    name: "Turkmenistan",
    code: "TM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
    emoji: "🇹🇲",
    unicode: "U+1F1F9 U+1F1F2",
  },
  {
    dial: "+1-649",
    name: "Turks and Caicos Islands",
    code: "TC",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
    emoji: "🇹🇨",
    unicode: "U+1F1F9 U+1F1E8",
  },
  {
    dial: "+688",
    name: "Tuvalu",
    code: "TV",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
    emoji: "🇹🇻",
    unicode: "U+1F1F9 U+1F1FB",
  },
  {
    dial: "+1-340",
    name: "U.S. Virgin Islands",
    code: "VI",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
    emoji: "🇻🇮",
    unicode: "U+1F1FB U+1F1EE",
  },
  {
    dial: "+256",
    name: "Uganda",
    code: "UG",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
    emoji: "🇺🇬",
    unicode: "U+1F1FA U+1F1EC",
  },
  {
    dial: "+380",
    name: "Ukraine",
    code: "UA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
    emoji: "🇺🇦",
    unicode: "U+1F1FA U+1F1E6",
  },
  {
    dial: "+971",
    name: "United Arab Emirates",
    code: "AE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
    emoji: "🇦🇪",
    unicode: "U+1F1E6 U+1F1EA",
  },
  {
    dial: "+44",
    name: "United Kingdom",
    code: "GB",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
    emoji: "🇬🇧",
    unicode: "U+1F1EC U+1F1E7",
  },
  {
    dial: "+1",
    name: "United States",
    code: "US",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
    emoji: "🇺🇸",
    unicode: "U+1F1FA U+1F1F8",
  },
  {
    dial: "+598",
    name: "Uruguay",
    code: "UY",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
    emoji: "🇺🇾",
    unicode: "U+1F1FA U+1F1FE",
  },
  {
    dial: "+998",
    name: "Uzbekistan",
    code: "UZ",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
    emoji: "🇺🇿",
    unicode: "U+1F1FA U+1F1FF",
  },
  {
    dial: "+678",
    name: "Vanuatu",
    code: "VU",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
    emoji: "🇻🇺",
    unicode: "U+1F1FB U+1F1FA",
  },
  {
    dial: "+379",
    name: "Vatican",
    code: "VA",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
    emoji: "🇻🇦",
    unicode: "U+1F1FB U+1F1E6",
  },
  {
    dial: "+58",
    name: "Venezuela",
    code: "VE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
    emoji: "🇻🇪",
    unicode: "U+1F1FB U+1F1EA",
  },
  {
    dial: "+84",
    name: "Vietnam",
    code: "VN",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
    emoji: "🇻🇳",
    unicode: "U+1F1FB U+1F1F3",
  },
  {
    dial: "+681",
    name: "Wallis and Futuna",
    code: "WF",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
    emoji: "🇼🇫",
    unicode: "U+1F1FC U+1F1EB",
  },
  {
    dial: "+212",
    name: "Western Sahara",
    code: "EH",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
    emoji: "🇪🇭",
    unicode: "U+1F1EA U+1F1ED",
  },
  {
    dial: "+967",
    name: "Yemen",
    code: "YE",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
    emoji: "🇾🇪",
    unicode: "U+1F1FE U+1F1EA",
  },
  {
    dial: "+260",
    name: "Zambia",
    code: "ZM",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
    emoji: "🇿🇲",
    unicode: "U+1F1FF U+1F1F2",
  },
  {
    dial: "+263",
    name: "Zimbabwe",
    code: "ZW",
    flag: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
    emoji: "🇿🇼",
    unicode: "U+1F1FF U+1F1FC",
  },
];

export default countriesCodes;
import { Button, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PermissionsGate from "../features/permissions/components/PermissionsGate";
import OverViewReceipts from "../pages/BillsOverView/OverViewReceipts";
import ClientsPanel from "../pages/clients/ClientsPanel";
import ControlsPanel from "../pages/control/ControlsPanel";
import PermissionPanelٌReadings from "../pages/Readings/PermissionsPanelReadings";
import PermissionPanel from "../pages/Receipts/PermissionsPanel";
import ReceiptsPanel from "../pages/Receipts/ReceiptsPanel";

const ReceiptBox = ({ boxes }) => {
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);
  const [index, setIndex] = useState("");
  const [type, setType] = useState("");
  const handleNavigation = (path) => {
    navigate(path);
  };

  return clicked ? (
    type === "overview" ? (
      <OverViewReceipts boxes={boxes} index={index} />
    ) : type === "bills" ? (
      <ReceiptsPanel boxes={boxes} index={index} />
    ) : type === "perms" ? (
      // <PermissionPanel index={index} />
      <PermissionPanel boxes={boxes} index={index}/>
    ) : type === "clients" ? (
      <ClientsPanel boxes={boxes} index={index} />
    ) : type === "adds" ? (
      <ControlsPanel boxes={boxes} index={index} />
    ): type === "readings" ? (
      <PermissionPanelٌReadings boxes={boxes} index={index} />
    ) : (
      ""
    )
  ) : (
    <Box
      sx={{
        display: "flex",
        "& > :not(style)": {
          m: 1,
          width: "auto",
          background: "white",
          
        },
 
    margin:"10%",

        flexWrap: "wrap",

        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {boxes.map((box,index) => (
        <PermissionsGate permissions={box?.perm} key={index}>
          <Button
           
            onClick={() => {
              setClicked(true);
              setType(box.type);
              setIndex(index)
              // if (box.index === 0 || box.index) {
              //   setIndex(box.index);
              // }
              if (box.path) {
                handleNavigation(box?.path);
              }
            }}
          >
            <Paper
              variant="elevation"
              elevation={1}
              sx={{
                background: "#233975",
                borderRadius: "12px",
            
                

                "&:hover": {
                  transform: "scale(1.1)",
                  // boxShadow:
                  //   "rgba(0, 0, 0, 0.05) 0px 0px 0px 0.3px !important",
                },
              }}
            >
              <Stack>
                <Box sx={{width:'17rem',height:'160px',padding:'10px',marginTop:"20%"}} >
                  <img src={box.image} height="112px" width="112px"  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #233975",
                    borderEndStartRadius:"12px",
                    borderEndEndRadius:"12px",
               
                  }}
                >
                  <Typography sx={{ padding: "10px",fontWeight:"bold",fontSize:"16px" }} color="#233975">
                    {box.title}
                  </Typography>
                </Box>
              </Stack>
            </Paper>
          </Button>
        </PermissionsGate>
      ))}
    </Box>
  );
};
export default ReceiptBox;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Wrapper from "../../../components/Wrapper";

import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

import AddTankReceipt from "./addTankReceipt";
import ViewTankReceipts from "./ViewTankReceipts";
const TankReceiptsView = () => {


  return (
    <Wrapper>
     
        <AddTankReceipt />

   
        <ViewTankReceipts />

    </Wrapper>
  );
};
export default TankReceiptsView;

import React, { useEffect, useRef, useState } from "react";
import Form, { InputField, PasswordField, PhoneField } from "../features/form";
import useControls from "../hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import Compress from "react-image-file-resizer";
import filter from "../utils/ClearNull";
import useRequest from "../hooks/useRequest";
import { OWNERPERMS, REGISTER, USER_INFO } from "../data/APIs";
import Chip from "@mui/material/Chip";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import logo from '../assets/cardlogo.png'
import { useNavigate } from "react-router-dom";

function Registeration() {
  const userInfo = useSelector((state) => state.userInfo.value);
  const token = useSelector((state) => state.userInfo.value.token);
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const global = useSelector((state) => state.lang.value.global);
  const dispatch = useDispatch();
  const [imgProfile, setImageProfile] = useState({});
  const [Profile, setProfile] = useState([]);
  const navigate =useNavigate()
  const isAvatarPending = false;
  const pictureFileInput = useRef();
  
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "company_name",
      value: "",
      isRequired: true,
    },
    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "first_name",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
    {
      control: "code_admin",
      value: "",
      isRequired: false,
    },
    {
      control: "phone_admin",
      value:"",
      isRequired: false,
    },
    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "website",
      value: "",
      isRequired: false,
    },
    {
      control: "last_name",
      value: "",
      isRequired: false,
    },
    {
      control: "phone",
      value: "",
      isRequired: true,
    },

    {
      control: "address",
      value: "",
      isRequired: false,
    },

    {
      control: "email",
      value: "",
      isRequired: false,
      validations: [
        {
          test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "البريد غير صالح",
        },
      ],
    },
  ]);
  const [registerPostRequest, reigsterPostResponse] = useRequest({
    path: REGISTER,
    method: "post",
    successMessage: "تم تسجيل الحساب بنجاح",
  });
  const [OwnerPermissionGetRequest] = useRequest({
    path: OWNERPERMS,
    method: "get",
  });

  const resize = (file) => {
    return new Promise((resolve) => {
      Compress.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => resolve(uri),
        "file"
      );
    });
  };
  const changeAavatar = async (file) => {
    setImageProfile(URL.createObjectURL(file));
   setProfile(file)
    
  };

  const handleRegister= (e) => {
   
    //  e.preventDefault();
    validate().then((value) => {
      console.log(value)
      if (!value.isOk) return;
     
      const requestBody = filter({
        obj: {
          owner: JSON.stringify({
            first_name: controls.first_name,
            last_name: controls.last_name,
            username:controls.username,
            password:controls.password,
            email:controls.email,
            phone:controls.code + controls.phone
          }),
          
          logo: Profile,
          name:controls.company_name,
          website:controls.website,
          location:controls.address,  
          // phone:controls.code + controls.phone
        },
        output: "formData",
      });
      registerPostRequest({
        body: requestBody,
        noHeaders: true,
        onSuccess: (res) => {
          setTimeout(() => navigate("/signin"), 1500);
          resetControls();
          setImageProfile()
          setProfile()

        
          
        },
      });
    });
  };


  
  return (
    <Stack sx={{display:"flex",flexDirection:{xl:"row",lg:"row",md:"row",sm:"column",xs:"column"},width:"100%",flexWrap:"nowrap"}}>
      <Stack sx={{width:"20%",display:{xl:"block",lg:"block",md:"block",sm:"none",xs:"none"},position:"static"}}>
      <Image  src={logo}/>
    </Stack>
    <Stack sx={{width:{xl:"80%",lg:"80%",md:"100%",sm:"100%",xs:"100%"}}}>
      <Stack sx={{ textAlign: "center", mx: {xl:"25%",lg:"25%",md:"15%",sm:"10%"} }}>
        <Typography
          variant="h2"
          sx={{
            fontSize: {xl:"56px",sm:"32px",xs:"24px"},
            fontWeight: 700,
            lineHeight: {xl:"68px",sm:"34px",xs:"34px"},
            color: "#090979",
            mt: "36px",
            mb: {xl:"12px",xs:"8px"},
          }}
        >
          {global.create_acc[lang]}
        </Typography>
        <Typography
          sx={{
            fontSize: {xl:"18px",sm:"14px",xs:"14px"},
            fontWeight: 400,
            lineHeight: "22px",
            color: "#252324",
            mb: {xl:"12px",xs:"8px"},
          }}
        >
          لتكون جزءًا من المجتمع
        </Typography>
        <Typography
          sx={{
            fontSize: {xl:"28px",sm:"18px",xs:"18px"},
            fontWeight: 500,
            lineHeight: {xl:"36px",sm:"24px",xs:"24px"},
            color: "#343D42",
            mb: "12px",
          }}
        >
          املأ المعلومات بعناية
        </Typography>
      </Stack>
      <Form hideFooter hideHeader sx={{ mt: "32px" }}   >
        <Stack sx={{ gap: 1 }}>
          <Box
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"90%",xs:"90%"},
              mx: "auto",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Avatar
              sx={{ height: "70px", width: "70px", mt: 1,mr:{sm:1,sx:1} }}
              src={imgProfile}
            />
            <InputField
              label={globalLang.company_name[lang]}
              placeholder={globalLang.company_name[lang]}
              onChange={(e) => setControl("company_name", e.target.value)}
              value={controls.company_name}
              required={required.includes("company_name")}
              error={Boolean(invalid.company_name)}
              helperText={invalid.company_name}
              sx={{
                width: "80%",
                mx: "auto",
                my: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <Box sx={{ mt: 4, backgroundColor: "#fff" }}>
              <IconButton
                sx={{
                  bgcolor: "white",
                  color: "#8D9396",
                  boxShadow: "0 0 10px #fff",
                  "&:hover": {
                    bgcolor: "white",
                  },
                }}
                disabled={isAvatarPending}
                onClick={(e) => {
                  pictureFileInput.current.click();
                }}
              >
                <CloudDownloadIcon />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    changeAavatar(e.target.files[0]);
                    setProfile(e.target.files[0])
                    console.log(Profile)
                  }}
                  ref={pictureFileInput}
                  
                />
              </IconButton>
            </Box>
          </Box>
          <InputField
            label={globalLang.email[lang]}
            placeholder={globalLang.email[lang]}
            onChange={(e) => {
              setControl("email", e.target.value);
            }}
            value={controls.email}
            required={required.includes("email")}
            error={Boolean(invalid.email)}
            helperText={invalid.email}
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              mb: "12px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />
          <InputField
            label={globalLang.address[lang]}
            placeholder={globalLang.address[lang]}
            required={required.includes("address")}
            value={controls.address}
            onChange={(e) => setControl("address", e.target.value)}
            error={Boolean(invalid.address)}
            helperText={invalid.address}
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              mb: "12px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />
          {/* <PhoneField
            label={global.phone_number[lang]}
            placeholder={global.phone_number[lang]}
            required={required.includes("phone")}
            requiredCode
            selectProps={{
              value: controls.code,
              onChange: (e) => setControl("code", e.target.value),
            }}
            value={controls.phone}
            onChange={(e) => setControl("phone", e.target.value)}
            error={Boolean(invalid.phone)}
            helperText={invalid.phone}
            sx={{
              width: "60%",
              mx: "auto",
              mb: "12px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />  */}
          <InputField
            label={globalLang.website[lang]}
            placeholder={"https://www.Example.com"}
            required={required.includes("website")}
            value={controls.website}
            onChange={(e) => setControl("website", e.target.value)}
            error={Boolean(invalid.website)}
            helperText={invalid.website}
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              mb: "12px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />
          <Stack
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              display: "flex",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <InputField
              label={global.admin_name[lang]}
              placeholder={global.first_name[lang]}
              required={required.includes("first_name")}
              value={controls.first_name}
              onChange={(e) => setControl("first_name", e.target.value)}
              error={Boolean(invalid.first_name)}
              helperText={invalid.first_name}
              sx={{
                width: {
                  xl: "48%",
                  lg: "48%",
                  md: "100%",
                  ms: "100%",
                  xs: "100%",
                },
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <InputField
              label={"  "}
              placeholder={global.last_name[lang]}
              required={required.includes("last_name")}
              value={controls.last_name}
              onChange={(e) => setControl("last_name", e.target.value)}
              error={Boolean(invalid?.last_name)}
              helperText={invalid?.last_name}
              sx={{
                width: {
                  xl: "48%",
                  lg: "48%",
                  md: "100%",
                  ms: "100%",
                  xs: "100%",
                },
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
          </Stack>

          <PhoneField
            label={global.phone_number[lang]}
            placeholder={global.phone_number[lang]}
            required={required.includes("phone")}
            requiredCode
            selectProps={{
              value: controls.code,
              onChange: (e) => setControl("code", e.target.value),
            }}
            value={controls.phone}
            onChange={(e) => setControl("phone", e.target.value)}
            error={Boolean(invalid.phone)}
            helperText={invalid.phone}
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              mb: "12px",
              ".MuiInput-root": { padding: {xl:"8px 14px 8px 8px", sm:"8px 3px 8px 8px",xs:"8px 3px 8px 0px"} },
            }}
          />
          <InputField
              label={global.username[lang]}
              placeholder={global.username_en[lang]}
              required={required.includes("username")}
              value={controls.username}
              onChange={(e) => setControl("username", e.target.value)}
              error={Boolean(invalid.username)}
              helperText={invalid.username}
              sx={{
                width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
                mx:"auto",
                mb: "12px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <PasswordField
            label={global.password[lang]}
            placeholder={global.password[lang]}
            required={required.includes("password")}
            value={controls.password}
            onChange={(e) => setControl("password", e.target.value)}
            error={Boolean(invalid.password)}
            helperText={invalid.password}
            sx={{
              width: {xl:"60%" ,lg:"60%",md:"60%",sm:"80%",xs:"80%"},
              mx: "auto",
              mb: "24px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />
          <Divider width="60%" color="#ccc" sx={{mx:"auto"}}/>
              <Typography color='#ccc' sx={{ml:"20%"}}>  لديك حساب بالفعل؟ <Button onClick={()=>navigate('/signin')} sx={{color:'#090979','&:hover':{
                color:'GrayText'
              }}}>{global.login[lang]}</Button> </Typography>
          <Button
            sx={{
              backgroundColor: "#090979",
              borderRadius: "8px",
              height: "44px",
              p: "10px",
              mx: "auto",
              my:"32px",
              justifyContent: "center",
              color: "#fff",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              width: {xl:"20%",lg:"20%",md:"20%",sm:"50%",xs:"50%"},
              ":hover":{
                backgroundColor:"#090979"
              }
            }}
            onClick={(e)=>handleRegister()}
            ispending={reigsterPostResponse.isPending}
          >
            {global.create_acc[lang]}
          </Button>
        </Stack>
        
      </Form>
    </Stack>
    
    </Stack>
  );
}
export default Registeration;

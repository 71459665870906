import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
  CLIENTOVERVIEW,
  CLIENTS,
} from "../../data/APIs";

import Form from "../../features/form";
import { Stack, TextField } from "@mui/material";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import Breadcrumbs from "../../components/Breadcrumbs";
import format from "../../utils/ISOToReadable";
// import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

const FilterClients = () => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const ClientOverViewStore = useSelector((state) => state.clientOverview.value);
  const overviewLang = useSelector((state) => state.lang.value.overview);
  const clientsStore = useSelector((state) => state.clients.value)
  const [filterdata, setfilterdata] = useState({
    client: "",
    startDate: "",
    endDate: "",
  });
  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  ///////////////////////////////


  //columns names
  const columns = [
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => (bill_date ? format(bill_date) : "-"),
    },
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
    },

    {
      field: "driven",
      headerName: globalLang.driven[lang],
      customContent: ({ driven }) =>
        driven === 0 ? "0" : driven ? driven : "-",
    },
    {
      field: "taken",
      headerName: globalLang.taken[lang],
      customContent: ({ taken }) => (taken === 0 ? "0" : taken ? taken : "-"),
    },
    {
      field: "returned",
      headerName: globalLang.returned[lang],
      customContent: ({ returned }) =>
        returned === 0 ? "0" : returned ? returned : "-",
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ fill }) => (fill === 0 ? "0" : fill ? fill : "-"),
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) => (price === 0 ? "0" : price ? price : "-"),
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ fillprice }) =>
        fillprice === 0 ? "0" : fillprice ? fillprice : "-",
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid === 0 ? "0" : paid ? paid : "-"),
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ remain_price }) =>
        remain_price === 0 ? "0" : remain_price ? remain_price : "-",
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ dokhan }) =>
        dokhan === 0 ? "0" : dokhan ? dokhan : "-",
    },
    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ safi }) => (safi === 0 ? "0" : safi ? safi : "-"),
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ store }) => (store === 0 ? "0" : store ? store : "-"),
    },
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ client }) => (client ? client : "-"),
    },
    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ trader }) =>(
        trader ? trader: "-"),
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) => 
      (
        statement? statement : "-"
      ),
    },
   
    
  ];
  
  //requests
  const dispatch = useDispatch();
  const [clientsgetRequest, clientsgetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    clientsgetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
        console.log(res.data)
      },
    });
  };


  const [ClientOverviewgetRequest, ClientOverviewGetResponse] = useRequest({
    path: CLIENTOVERVIEW,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getClientBills = () => {
    if (Object.values(filterdata).filter((val) => val !== '').length > 0) {
      urlParams.delete('page')
    }

    filterdata.client && urlParams.append("client", filterdata.client)

    filterdata.startDate && filterdata.endDate
      ? urlParams.append(
        "date",
        `${formatDate(String(filterdata.startDate))}-${formatDate(
          String(filterdata.endDate)
        )}`
      )
      : "";



    ClientOverviewgetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "client-overview/set", payload: res.data });
        console.log(res.data)
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],

  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getClientBills();
  }, [requestParams, filterdata]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };


  const handleFilter = (filters) => {

    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const handleResetFilter = () => {
    // resetControls();
    setfilterdata({
      client: "",
      startDate: "",
      endDate: "",
    });
    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  // const isPermitted = useIsPermitted();

  return (
    <>
      <Form
        childrenProps={{
          saveBtn: {
            sx: { display: "none" },
          },
          closeBtn: {
            onClick: handleResetFilter,
          },
        }}
      >
        <Stack spacing={3}>
          <AutocompleteField
            label={globalLang.client[lang]}
            sx={{
              width: {
                lg: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
                xs: "100%",
              },
            }}
            isPending={clientsgetResponse.isPending}
            onOpen={getClients}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata((old) => ({ ...old, client: options.value }));
                  break;
                case "clear":
                  setfilterdata((old) => ({ ...old, client: "" }));
              }
            }}
            data={[
              ...clientsStore.results.map((c, index) => ({
                label: c.user.first_name + " " + c.user.last_name,
                value: c.id,
              })),
            ]}
            value={filterdata.id}

          />
          <Stack spacing={2}>
            <TextField
              variant="standard"
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              type="date"
              label={globalLang.from[lang]}
              value={filterdata.startDate}
              onChange={(e) =>
                setfilterdata((old) => ({ ...old, startDate: e.target.value }))
              }
            />
            <TextField
              variant="standard"
              type="date"
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              label={globalLang.to[lang]}
              value={filterdata.endDate}
              disabled={filterdata.startDate ? false : true}
              onChange={(e) =>
                setfilterdata((old) => ({ ...old, endDate: e.target.value }))
              }
            />
          </Stack>
        </Stack>
      </Form>
      <Breadcrumbs
        path={[overviewLang.allClients[lang], globalLang.view[lang]]}
      />
      
      <DataGrid
        columns={columns}
        extraData={ClientOverViewStore.extra_bills}
        rows={ClientOverViewStore?.results}
        total={ClientOverViewStore?.count}
        sum={ClientOverViewStore.total}
        isPending={ClientOverviewGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}

      />


    </>
  );
};

export default FilterClients;


import React from "react";
import FinanceAndDeposite from "./FinanceAndDeposite";
import FinanceView from "./FinanceView";
function FinanceOverView(){
    return(
        <>
        <FinanceAndDeposite/>
        <FinanceView/>
        </>
    )
}
export default FinanceOverView
import React, { useEffect, useRef, useState } from "react";
import {
  DialogContent,
  IconButton,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PendingBackdrop from "../../../components/PendingBackdrop";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Print from "../../../components/PrintComponent";
import useIsPermitted from "../../permissions/hook/useIsPermitted";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { NumberField } from "../../form";
import onClickOutside from "react-onclickoutside";
import useRequest from "../../../hooks/useRequest";
import { UPDATE_STOCK } from "../../../data/APIs";
import useAfterEffect from "../../../hooks/useAfterEffect";
import CheckIcon from '@mui/icons-material/Check';
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
const dummyColumns = [
  {
    field: "column1",
    headerName: "dummy column 1",
  },
  {
    field: "column2",
    headerName: "dummy column 2",
  },
  {
    field: "column3",
    headerName: "dummy column 3",
  },
  {
    field: "column4",
    headerName: "dummy column 4",
  },
];

const dummyRows = [
  {
    column1: "dummy data",
    column2: "dummy data",
    column3: "dummy data",
    column4: "dummy data",
  },
  {
    column1: "dummy data",
    column2: "dummy data",
    column3: "dummy data",
    column4: "dummy data",
  },
  {
    column1: "dummy data",
    column2: "dummy data",
    column3: "dummy data",
    column4: "dummy data",
  },
  {
    column1: "dummy data",
    column2: "dummy data",
    column3: "dummy data",
    column4: "dummy data",
  },
  {
    column1: "dummy data",
    column2: "dummy data",
    column3: "dummy data",
    column4: "dummy data",
  },
];

const DialogTable = ({
  rows = dummyRows,
  columns = dummyColumns,
  isPending = false,
  onDelete = null,
  onEdit = null,
  edit = false,
  editData = null,
  type,
  stockDetails,
  ...props
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [updateValue,setUpdateValue] =useState(0);
  const [editState,setEditState]=useState(true)

 const dispatch=useDispatch()

  
  const renderProcedures = Boolean(onDelete || onEdit);

  const [stocksUpdateRequest, stocksUpdateResponse] = useRequest({
    path: UPDATE_STOCK  ,
    method: "patch",
  });

  
  const [requestParams, setRequestParams] = useState({
    
  });
  const updatStock=()=>{   
    
      const requestBody = filter({
        obj: {
          owned_number: updateValue,
        },
        output: "object",
      });
   
    if (type) {
      urlParams.append("type", type);
    }
    stocksUpdateRequest({
      params: urlParams,
      id:stockDetails.id + "/loans/" + editData.id,
      body:requestBody,
      onSuccess: (res) => {
        console.log(updateValue)
        dispatch({ type: "loans-stock/patchItem", payload: { id: editData.id, item:updateValue,type:type }, })
 
      },
    });
   
    setEditState(true)
  }
  
  const urlParams = new URLSearchParams();
  
  useEffect(() => {
    Object.values(requestParams).map((item) =>
    item.map(([key, value]) => urlParams.append(key, value))
  );
  }, [updateValue]);
  
  ///////////////////////////////
  return (
    <DialogContent
    >
      <Box
        sx={{
          width: "100%",
          border: "1px solid #ffffff6e",
          borderRadius: 2,
          p: 2,
          boxShadow: "0 0 20px #ffffff6e",
          boxSizing: "border-box",
          "& .MuiTableCell-root": {
            border: "none",
          },
          overflow: "auto",
          position: "relative",
        }}
        {...props}
      >
        {isPending && <PendingBackdrop />}

        <Box sx={{ maxHeight: "300px", height: "100vmax" }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((columnData, index) => (
                  <TableCell
                    sx={{
                      color: "white",
                      position: "sticky",
                      top: 0,
                      bgcolor: (theme) => theme.palette.primary.main,
                    }}
                    key={`column ${index}`}
                  >
                    {columnData?.headerName
                      ? columnData?.headerName
                      : columnData?.field}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((rowData, index) => (
                <TableRow key={`row ${index}`}>
                  {columns.map((columnData, index) => {
                    if (columnData.customContent) {
                      return (
                        <TableCell
                          sx={{ color: "white" }}
                          key={`cell ${index}`}
                        >
                          {columnData.customContent({
                            rowData,
                          })
                            ? columnData.customContent({
                                rowData,
                              })
                            : globalLang.unknown[lang]}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell sx={{ color: "white" }} key={`cell ${index}`}>
                        
                        {editState!==true && rowData?.id === editData?.id? (
                          
                            <NumberField
                            type="text"
                            value={editData?.owned_number}
                            sx={{
                              width:"50px",
                              background: "#fff",
                              borderRadius:"6px"
                            }}
                            onChange={(e)=>{
                              setUpdateValue(e.target.value)
                            }}
                          />
                          
                        ) : (
                          
                          rowData[columnData.field] 
                        )}
                      </TableCell>
                    );
                  })}

                  {renderProcedures && (
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        {onDelete && (
                          <IconButton
                            color="#ffffff"
                            iconcolor="#ffffff"
                            onClick={(event) => {
                              event.stopPropagation();
                              onDelete(event, rowData);
                            }}
                          >
                            <Tooltip arrow title="حذف" placement="top-start">
                              <DeleteIcon sx={{ width: 20, height: 20 }} />
                            </Tooltip>
                          </IconButton>
                        )}

                        {editState?
                        onEdit &&(
                          <IconButton
                            color="#fff"
                            onClick={(event) => {
                              event.stopPropagation();
                              onEdit(event, rowData);
                              setEditState(!editState)

                            }}
                          >
                            <Tooltip arrow title="تعديل" placement="top-start">
                              <EditIcon sx={{ width: 20, height: 20 }} />
                            </Tooltip>
                            </IconButton>
                          
                        ): <Tooltip arrow title="حفظ" placement="top-start">
                        <CheckIcon onClick={updatStock} sx={{ width: 20, height: 20 ,bgColor:"white"}}/>
                        </Tooltip>
                       
                        }
                        
                      </Stack>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </DialogContent>
  );
};

export default DialogTable;

DialogTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  isPending: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};




  const BASEURL = "https://factory-back-9666l.ondigitalocean.app/"


// const BASEURL = "http://192.168.1.21:8000"



const REGISTER = "api/register/";
const OWNERPERMS = "api/owner-permissions/";
const NOTIFICATIONS = "api/notification/";
const POSTS = "api/router/Post/";
const CLIENTS = "main/api/router/client/";
const TANK = "main/api/router/tank/";
const SINGLE_TANK = "main/tank/";
const STOCK_REVIEW = "stock/review/";
const TRADERS = "main/api/router/trader/";
const STATIONS = "main/api/router/station/";
const EXPENSES = "main/api/router/expenses/";
const TRANSPORT = "main/api/router/transport/";
const TANKSHIPRECETPI = "main/api/router/station-tank-bill/";
const DISTRIBUTIONBILL = "main/api/router/client-distribution-bill/";
const STATIONEXITBILL = "main/api/router/station-exit-bill/";
const STATIONENTERBILL = "main/api/router/station-enter-bill/";
const STATIONBILL = "main/api/router/station-bill/";
const STATIONHISTORY = "main/api/router/station-history";
const CLIENTAWAYBILL = "main/api/router/client-away-bill/";
const CLIENTHOMEBILL = "main/api/router/client-home-bill/";
const TRADERENTERBILL = "main/api/router/trader-enter-bill/";
const TRADEREXITBILL = "main/api/router/trader-exit-bill/";
const TRDAERBILL = "main/api/router/trader-bill/";
const Commission = "main/api/router/commission-bill-from/";
const CommissionTo = "main/api/router/commission-bill-to/";

const SELFFILL = "main/api/router/self-fill/";

const EXPENSESRECEIPT = "main/api/router/expenses-bill/";
const BACKANDFORTHRECEIPT = "main/api/router/home-away-bill/";
const BACKANDFORTHRHISTORYECEIPT = "main/api/router/history-home-away-bill/";
const DELETED_CLIENTS = "main/api/router/restore-client/";
const CLIENTS_HISTORY = "main/api/router/client-history";
const DELETED_TRADERS = "main/api/router/restore-trader/";
const TRADERS_HISTORY = "main/api/router/trader-history";
const EXPENSES_BILL_HISTORY = "main/api/router/history-expenses-bill/";


const CLIENTS_COMMENTS = "api/router/CommentClient/";
const CLIENTS_TRANSFER = "api/transfer/";
const CLIENTS_TRANSFER_PROJECT = "api/transferclientproject/";

const CLIENTS_SELECTED_DELETE = "api/ClientGroupDelete/";
const STOCK = "main/api/router/stock/";
const STOCK_PRACTICAL = "stock/practical/";
const UPDATE_STOCK_PRACTICAL = "stock/practical/update/";
const STOCK_OUTS = "stock/loans/";
const UPDATE_STOCK = "stock/";
const FACTORY_POWER = "stock/factory/power/";
const TRANSFERSTOCK = "practical/theoretical/add/";
const DELETE_FACTORY_POWER = "stock/factory/power/";
const FACTORY_Safe = "safe/overview/";
const FACTORY_Safe_statics = "statistics/safe";
const cASTOM_FACTORY_Safe = "safe/overview/range";
const FIRST_FACTORY_Safe = "safe/";
const Owner_Permessions = "api/owner-permissions/";
const Single_Product = "main/product/";
const Sales_Bill = "bills/sale/";
const FINANCEANDDEPOSITE = "bills/deposit/finance/";
const VIEW_COUNTER_READINGS = "tank/readings/";
const COUNTER_READINGS = "tank/readings/";
const Purchases_BILL = "bills/purchase/";
const STATUS = "api/router/Status/";
const EMPLOYEES = "main/api/router/employee/";
const EMPLOYEES_PERMISSIONS = "api/router/EmployeePermission/";
const BLOCK_EMPLOYEE = "api/BlockEmployee/";
const PRODUCTS = "main/api/router/product/";
const CHANNELS = "main/api/router/channel/";
const JOBS = "main/api/router/job/";
const TEAMS = "api/router/Team/";
const UNITS = "api/router/Unit/";
const JOB_PERMISSIONS = "main/api/router/job-permissions/";
const USER_PERMISSIONS = "main/api/router/user-permissions/";
const PERMISSIONS = "api/get-permissions/";
const CHANGE_PASSWORD = "api/ChangePassword/";
const USER_INFO = "api/profile/";
const COMPANY = "api/router/Company/";
const FILES_HISTORY = "api/router/HistoryFile/";
const SUMMARY = "api/summary/";
const SUMMARYTRADER = "api/summary/trader/";
const SUMMARYCLIENT = "api/summary/client/";
const CLIENTOVERVIEW = "api/client-overview/";
const TRADEROVERVIEW = "api/trader-overview/";
const STATIONOVERVIEW = "api/station-overview/";
const SUMMARYSTATION = "api/summary/station/";
const SUMMARYEXPENSES = "api/summary/expenses/";
const STOCKSUMMARY = "api/stock-store-summary/";
const STOCKHISTORY = "main/api/router/stock-history/";
const SUMMARYTRADERLIQUID = "api/summary-trader-liquid-bill/";
const EXPORT_CLIENTS = "api/import/";
const IMPORT_EXPORT_CLIENTS = "import/export/clients/";
const LOGIN = "api/login/";
const ProductsGas = "statistics/products";

const Total_production = "statistics/fill/price";
const Logout = "api/logout/";
const COUNTRY_FILTER = "api/countries/";
const STATES = "api/states/";
const STATE_CITIES =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

export default BASEURL;

export {
  REGISTER,
  LOGIN,
  Logout,
  OWNERPERMS,
  NOTIFICATIONS,
  POSTS,
  CLIENTS,
  TANK,
  ProductsGas,
  SINGLE_TANK,
  STOCK_REVIEW,
  DELETED_CLIENTS,
  EXPENSES,
  STATIONS,
  CLIENTS_HISTORY,
  CLIENTS_COMMENTS,
  CLIENTS_TRANSFER,
  CLIENTS_TRANSFER_PROJECT,
  BACKANDFORTHRECEIPT,
  BACKANDFORTHRHISTORYECEIPT,
  STATUS,
  Commission,
  CommissionTo,
  FACTORY_Safe_statics,
  STATIONHISTORY,
  STATIONEXITBILL,
  STATIONENTERBILL,
  CLIENTOVERVIEW,
  TRADEROVERVIEW,
  STATIONOVERVIEW,
  STATIONBILL,
  Total_production,
  STOCK,
  STOCK_PRACTICAL,
  TRANSFERSTOCK,
  STOCK_OUTS,
  UPDATE_STOCK,
  FACTORY_POWER,
  DELETE_FACTORY_POWER,
  FACTORY_Safe,
  cASTOM_FACTORY_Safe,
  FIRST_FACTORY_Safe,
  Owner_Permessions,
  Single_Product,
  UPDATE_STOCK_PRACTICAL,
  Sales_Bill,
  FINANCEANDDEPOSITE,
  Purchases_BILL,
  VIEW_COUNTER_READINGS,
  COUNTER_READINGS,
  STOCKHISTORY,
  TRANSPORT,
  TANKSHIPRECETPI,
  DELETED_TRADERS,
  CLIENTAWAYBILL,
  CLIENTHOMEBILL,
  DISTRIBUTIONBILL,
  TRADERENTERBILL,
  TRADEREXITBILL,
  TRDAERBILL,
  TRADERS_HISTORY,
  TRADERS,
  SELFFILL,
  EXPENSESRECEIPT,
  EXPENSES_BILL_HISTORY,
  EMPLOYEES,
  EMPLOYEES_PERMISSIONS,
  BLOCK_EMPLOYEE,
  PRODUCTS,
  CHANNELS,
  JOBS,
  TEAMS,
  UNITS,
  JOB_PERMISSIONS,
  PERMISSIONS,
  CHANGE_PASSWORD,
  USER_INFO,
  COMPANY,
  FILES_HISTORY,
  SUMMARY,
  EXPORT_CLIENTS,
  IMPORT_EXPORT_CLIENTS,
  CLIENTS_SELECTED_DELETE,
  COUNTRY_FILTER,
  STATES,
  STATE_CITIES,
  SUMMARYTRADER,
  SUMMARYSTATION,
  SUMMARYEXPENSES,
  STOCKSUMMARY,
  SUMMARYTRADERLIQUID,
  SUMMARYCLIENT,
  USER_PERMISSIONS,
};

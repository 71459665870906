import React from "react";
import { Box } from "@mui/material";

const Wrapper = ({ children, sx = {}, ...props }) => {
  return (
    <Box
      sx={{
        width:"100%",
        paddingInline: {xl:5,lg:5,md:5,sm:0,sx:0},
        paddingBottom: 5,
        minHeight: "100%",
        boxSizing: "border-box",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default Wrapper;

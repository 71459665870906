import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import { PRODUCTS, Single_Product, TANK } from "../../data/APIs";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { InputField, SelectField } from "../../features/form";
import { DialogButton, DialogButtonsGroup } from "../../features/dialog";

function GasesByID() {
  const lang = useSelector((state) => state.lang.value.lang);
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const productsStore = useSelector((state) => state.products.value);
  const [viewGas, setViewGas] = useState({});
  const getStartDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };
  const getEndtDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "product",
      value: "",
      isRequired: true,
    },
    {
      control: "startDate",
      value: getStartDate(),
      isRequired: true,
    },
    {
      control: "endDate",
      value: getEndtDate(),
      isRequired: true,
    },
  ]);
  const urlParams = new URLSearchParams();
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };

  const [ViewproductsGetRequest, ViewproductsGetResponse] = useRequest({
    path: Single_Product,
    method: "get",
  });

  const handleView = () => {
    // if (Boolean(factorySafeStore.results.length)) return;
    if (controls.product) {
      urlParams.append("start", controls.startDate);
      urlParams.append("end", controls.endDate);
   
    ViewproductsGetRequest({
      id: controls.product + "/overview",
      params: urlParams,

      onSuccess: (res) => {
        dispatch({ type: "product/addItem", payload: { id: res.data.id } });
        setViewGas(res.data);
        console.log(viewGas);
      },
    });
  }
  };
  const [requestParams, setRequestParams] = useState({});

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    handleView();
  }, [controls.date]);

  return (
    <>
      <Card>
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection:{
                xl:"row",
                lg:"row",
                md:"row",
                sm:"column",
                sx:"column"
            },
            // justifyContent: {xl:"space-between",lg:"space-between",md:"center",sm:"center",sx:"center"},
            flexWrap:"wrap",
            m: "auto",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.periodFrom[lang]}
              value={controls.startDate}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("startDate", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "40%",
                      lg: "40%",
                      md: "90%",
                      sm: "90%",
                      sx: "90%",
                    },
                    m: 2,
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.to[lang]}
              value={controls.endDate}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("endDate", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "40%",
                      lg: "40%",
                      md: "90%",
                      sm: "90%",
                      sx: "90%",
                    },
                    m: 2,
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <SelectField
            label={globalLang.Gas[lang]}
            placeholder={globalLang.Gas[lang]}
            renderValue={(selected) => {
              return productsStore.results.find(
                (product) => product.id === selected
              ).name;
            }}
            isPending={productsGetResponse.isPending}
            onOpen={getProducts}
            onChange={(e) => {
              setControl("product", e.target.value);
            }}
            value={controls.product}
            required={required.includes("product")}
            error={Boolean(invalid.product)}
            helperText={invalid.product}
            sx={{
                width: {
                xl: "40%",
                lg: "40%",
                md: "90%",
                sm: "90%",
                sx: "90%",
              },
              m: 2,
            }}
          >
            {productsStore?.results?.map((product, index) => (
              <MenuItem value={product.id} key={`${product.name} ${index}`}>
                {product.name}
              </MenuItem>
            ))}
          </SelectField>

            <Button
              sx={{
                backgroundColor: "#233975",
                color:"white",
                maxWidth: "150px",
                width: "100vmax",
                height: "50px",
                fontWeight: "bold",
                m: 3,
                ":hover":{
                  backgroundColor: "#233975",
                }
                }} 
              onClick={handleView}
              disabled={ViewproductsGetResponse.isPending}
            >
              {globalLang.view[lang]}
            </Button>
          
        </Box>
        {"clients" in viewGas && (
          <Box
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "block",
                sx: "block",
              },
              flexWrap: "wrap",
              mt:3
            }}
          >
            <Table
              sx={{
                width: {
                  xl: "33%",
                  lg: "33%",
                  md: "33%",
                  sm: "100%",
                  sx: "100%",
                },
                justifyContent: "center",
                p: 1,
                ".MuiTableRow-head": {
                  padding: 2,
                  textAlign: "center",
                },
                ".MuiTableCell-head": {
                  fontWeight: "600",
                  color: "gray",
                  textAlign: "center",
                  fontSize: "12px",
                  height:"40px",
                  backgroundColor:"#e9ecef",
                  cursor:"pointer"
                },
                ".MuiTableCell-body": {
                  textAlign: "center",
                  fontSize: "12px",
                  p: 2,
                  height:"30px",
                  cursor: "pointer",
                },
              }}
            >
              <TableHead>
                <TableRow>
                <TableCell align="center" colSpan={2}> {globalLang.charged_gas[lang]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{globalLang.number[lang]}</TableCell>
                  <TableCell>{globalLang.the_amount[lang]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.stations?.filled}</TableCell>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.stations?.price}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                width: {
                  xl: "33%",
                  lg: "33%",
                  md: "33%",
                  sm: "100%",
                  sx: "100%",
                },
                justifyContent: "center",
                p: 1,
                ".MuiTableRow-head": {
                  padding: 2,
                  textAlign: "center",
                },
                ".MuiTableCell-head": {
                  fontWeight: "600",
                  color: "gray",
                  textAlign: "center",
                  fontSize: "12px",
                  height:"40px",
                  backgroundColor:"#e9ecef",
                  cursor:"pointer"
                },
                ".MuiTableCell-body": {
                  textAlign: "center",
                  p: 2,
                  fontSize: "12px",
                  height:"30px",
                  cursor:"pointer"
                },
              }}
            >
              <TableHead>
                <TableRow>
                <TableCell align="center" colSpan={2}>{globalLang.distributor[lang]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{globalLang.number[lang]}</TableCell>
                  <TableCell>{globalLang.the_amount[lang]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.clients?.filled}</TableCell>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.clients?.price}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              sx={{
                width: {
                  xl: "33%",
                  lg: "33%",
                  md: "33%",
                  sm: "100%",
                  sx: "100%",
                },
                justifyContent: "center",
                p: 1,
                ".MuiTableRow-head": {
                  padding: 2,
                  textAlign: "center",
                },
                ".MuiTableCell-head": {
                  fontWeight: "600",
                  color: "gray",
                  textAlign: "center",
                  fontSize: "12px",
                  height:"40px",
                  backgroundColor:"#e9ecef",
                  cursor:"pointer"
                },
                ".MuiTableCell-body": {
                  textAlign: "center",
                  p: 2,
                  fontSize: "12px",
                  height:"30px",
                  cursor:"pointer"
                },
              }}
            >
              <TableHead>
                <TableRow>
                <TableCell align="center" colSpan={2}>{globalLang.filled_number[lang]}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{globalLang.number[lang]}</TableCell>
                  <TableCell>{globalLang.the_amount[lang]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.traders?.filled}</TableCell>
                  <TableCell sx={{":hover": {
                    backgroundColor: "#f8f9fa",
                  },}}>{viewGas?.traders?.price}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            
          </Box>
        )}
      </Card>
    </>
  );
}
export default GasesByID;

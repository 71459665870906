import React, { useEffect, useState } from "react";

import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { PRODUCTS } from "../../data/APIs";
import { InputField, SelectField } from "../../features/form";
import { Box, MenuItem } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { STATIONS, SUMMARYSTATION } from "../../data/APIs";
import format from "../../utils/ISOToReadable";

const ViewStationBillOverView = ({ period }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);


  //format Date
  const getDate = () => {
    var today = new Date();
    let date = "";
    if (period === "d") {
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    } else if (period === "m") {
      date = today.getFullYear() + "-" + (today.getMonth() + 1);
    } else if (period === "a") {
      date = today.getFullYear() ;
    } else {
      date = today.getFullYear();
    }

    return date;
  };
  const [date, setdate] = useState(getDate());
  const [params, setparams] = useState({ date: date, page: 1, size: period==='m'?50:8||period==="a"?12:30 });
  ///////////////////////////////

  const dispatch = useDispatch();
  const SummaryStationBillStore = useSelector(
    (state) => state.summarystation.value
  );

  const [summaryStationBillGetRequest, summaryStationBillGetResponse] =
    useRequest({
      path: SUMMARYSTATION,
      method: "get",
    });
  // Filters
  let filters = [
    {
      name: globalLang.station[lang],
      component: <StationFilter />,
    },
    // {
    //   name: globalLang.receiptnumber[lang],
    //   component: <UUIDFilter />,
    // },
    {
      name: globalLang.product[lang],
      component: <ProductFilter />,
    },
  ];
  useEffect(() => {
    const urlParams = new URLSearchParams();
    Object.keys(params).map((key) => urlParams.append(key, params[key]));
    summaryStationBillGetRequest({
      id: period,
      params: urlParams,

      onSuccess: (res) => {
        dispatch({ type: "summary-station/set", payload: res.data });
      },
    });
  }, [params]);

  
  /////////////////
  const handleChangeAmount = ({ value }) => {
    setparams((old) => ({
      ...old,
      page: 1,
      size: value,
    }));
  };
  const handlePaginate = (params) => {
    console.log(params);
    setparams((old) => ({
      ...old,
      page: params.current,
    }));
  };
  const handleFilter = (filters) => {
    const filter = filters.map(({ query }) => query);
    let values = Object.values(filter).map(([key, value]) => {
      return { [key]: value };
    });
    const object = Object.assign({}, ...values);

    setparams({
      date: params.date,
      page: params.page,
      size: params.size,
      ...object,
    });
  };


  //filter using date
  const handleChangeFilter = (date) => {
    let newDate = "";

    if (period === "d") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}-${date["$D"]}`;
    } else if (period === "m") {
      newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}`;
    } else if (period === "a") {
      newDate = `${date["$y"]}`;
    } else {
      newDate = date["$y"];
    }

    setparams((old) => ({
      ...old,
      date: newDate,
    }));
  };

  // Columns name
  let columns = [
    {
      field: "bill_date",
      headerName:period ==="m"? globalLang.date[lang]:globalLang.month[lang],
      customContent: ({ bill_date }) =>
      bill_date  ? bill_date : "-",
    },{
      field: "bill_uuid",
      headerName: globalLang.bill_uuid[lang],
      customContent: ({ bill_uuid }) =>
      bill_uuid  ? bill_uuid  : "-",
    },
    {
      field: "station",
      headerName: globalLang.station[lang],
      customContent: ({ station }) =>
      station  ? station : "-",
    },
    {
      field:  "product_name",
      headerName: globalLang.product[lang],
      customContent: ({ product_name }) =>
      product_name ? product_name : "-",
    },
    {
      field: "quantityin",
      headerName: globalLang.input[lang],
      customContent: ({ quantityin }) => (quantityin === 0 ? "0" : quantityin),
    },
    {
      field: "quantityout",
      headerName: globalLang.output[lang],
      customContent: ({ quantityout }) =>
        quantityout === 0 ? "0" : quantityout,
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
      customContent: ({ quantityreturned }) =>
        quantityreturned === 0 ? "0" : quantityreturned,
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) => (price === 0 ? "0" : price),
    },

    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ fill }) => (fill === 0 ? "0" : fill),
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ fillprice }) => (fillprice === 0 ? "0" : fillprice),
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid === 0 ? "0" : paid),
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ remain_price }) =>
        remain_price === 0 ? "0" : remain_price,
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ dokhan }) => (dokhan === 0 ? "0" : dokhan),
    },

    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ safi }) => (safi === 0 ? "0" : safi),
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ store }) => (store === 0 ? "0" : store),
    },
  ];
  if (period === "a") {
    columns = columns.filter((obj) =>
     obj.field !== "bill_uuid" &&
     obj.field !== "product_name" &&
     obj.field!=="station"
     );
  }
  else if (period === "m") {
    columns = columns.filter(
      (obj) =>
        obj.field !== "bill_uuid" &&
        obj.field !== "product_name" &&
        obj.field !== "client_name" &&
        obj.field !== "price"&&
        obj.field!=="station"
    );
  }else if (period === "d") {
    columns = columns.filter(
      (obj) =>
        obj.field !== "bill_date" 
    );
  }

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          openTo={period === "d" ? "day" : "year"}
          views={
            period === "d"
              ? ["year", "month", "day"]
              : period === "m"
              ? ["year", "month"]
              : ["year"]
          }
          value={params.date.toString()}
          onChange={(newValue) => {
            handleChangeFilter(newValue);
          }}
          renderInput={(params) => (
            <InputField
              sx={{
                width: "20%",
                margin: "15px",
                width: {
                  lg: "auto",
                  xl: "auto",
                  md: "auto",
                  sm: "90%",
                  xs: "90%",
                },
                "& .MuiInputBase-root": {
                  borderColor: "#F0F0F8",
                  paddingRight: "5px",
                },
                "& .MuiFormLabel-root": {
                  color: "#666666",
                },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>

      <DataGrid
        columns={columns}
        sum={SummaryStationBillStore.total}
        rows={SummaryStationBillStore.results}
        total={SummaryStationBillStore.count}
        isPending={summaryStationBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
        remainData={SummaryStationBillStore?.remain_price_details}
        period={period}
      />
    </Box>
  );
};

export default ViewStationBillOverView;

const ProductFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [products, setProducts] = useState([]);

  const handleChange = (e) => {
    // func({ product: e.target.value });
    onChange({
      query: ["product", e.target.value],
      renderedValue: products.find((p) => p.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.product[lang]}
      renderValue={(selected) => products.find((p) => p.id === selected).name}
      onOpen={getProducts}
      isPending={productsGetResponse.isPending}
      onChange={handleChange}
    >
      {products.map((product, index) => (
        <MenuItem value={product.id} key={`${index}`}>
          {product.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
export const StationFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [stations, setStations] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["station", e.target.value],
      renderedValue: stations.find((c) => c.id === e.target.value)?.user
        .username,
      value: e.target.value,
    });
  };
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });

  const getStations = () => {
    stationsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setStations(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.station[lang]}
      renderValue={(selected) =>
        stations.find((c) => c.id === selected)?.user.username
      }
      onOpen={getStations}
      isPending={stationsGetResponse.isPending}
      onChange={handleChange}
    >
      {stations.map((station, index) => (
        <MenuItem value={station.id} key={`${index}`}>
          {station?.user?.username}
        </MenuItem>
      ))}
    </SelectField>
  );
};

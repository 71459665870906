import {
  Box,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormLabel,
  Select,
  InputLabel,
  Button,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../features/form";
import AddIcon from "@mui/icons-material/Add";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import {
  CLIENTS,
  PRODUCTS,
  STATIONS,
  STOCK_OUTS,
  TRADERS,
} from "../../data/APIs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutocompleteField from "../../features/form/components/AutocompleteField";

function OutsStock() {
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);
    return `${year}-${month}-${date}`;
  };
  const clientsStore = useSelector((state) => state.clients.value);
  const stationsStore = useSelector((state) => state.stations.value);
  const tradersStore = useSelector((state) => state.traders.value);

  const [clients, setClients] = useState("");
  const [ownedClient, setOwnClient] = useState(0);

  const [addClient, setAddClient] = useState([{}]);
  const [addStation, setAddStation] = useState([{}]);
  const [addTrader, setAddTader] = useState([{}]);

  let clientCreditobject = {
    client: clients,
    ownedClient: ownedClient,
  };
  const [filterdataSt, setfilterdataSt] = useState({
    station: "",
    Date: getDate(),
  });
  const [filterdataCl, setfilterdataCl] = useState({
    client: "",
    Date: getDate(),
  });
  const [filterdataTr, setfilterdataTr] = useState({
    trader: "",
    Date: getDate(),
  });

  const lang = useSelector((state) => state.lang.value.lang);
  const volumes = [{ name: "40", value: 40 }, { name: "50", value: 50 }, { name: "20", value: 20 }, { name: "15", value: 15 }, { name: "10", value: 10 }]
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const productsStore = useSelector((state) => state.products.value);

  const handleClientchange = (e, index,name) => {
    console.log(name);
    const newclients = [...addClient];
    // clients[index][e.target.name]=e.target.value
    newclients[index] = {
      ...newclients[index],
      [e?.target?.name ? e.target.name : name] : e?.target?.value ? e.target.value : e.value,
    };
    setAddClient([...newclients]);
    console.log(addClient);
   
  };

  const handleStationChange = (e, index,name) => {
    const newStations = [...addStation];
    newStations[index] = {
      ...newStations[index],
      [e?.target?.name ? e.target.name : name] : e?.target?.value ? e.target.value : e.value,
    };
    setAddStation([...newStations]);
    console.log(addStation);
  };

  const handleTraderChange = (e, index,name) => {

    const newTraders = [...addTrader];
    newTraders[index] = {
      ...newTraders[index],
      [e?.target?.name ? e.target.name :name] : e?.target?.value ? e.target.value : e.value,
    };
    setAddTader([...newTraders]);
    console.log(addTrader);
  };

  // add new dropbox client
  const handleAddClient = () => {
    // clientCreditobject=addClient
    const allClients = [...addClient, {}];
    setAddClient(allClients);
   
    
  };

  const handleAddStation = () => {
    const allStation = [...addStation, {}];
    setAddStation(allStation);
  };
  const handleAddTrader = () => {
    const allTrader = [...addTrader, {}];
    setAddTader(allTrader);
  };

  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: true,
    },

    {
      control: "factorypower",
      value: "",
      isRequired: false,
    },

    {
      control: "volume",
      value: "",
      isRequired: true,
    },
    {
      control: "clients",
      value: "",
      isRequired: false,
    },
    {
      control: "stations",
      value: "",
      isRequired: false,
    },
    {
      control: "traders",
      value: "",
      isRequired: false,
    },
    {
      control: "select_client",
      value: "",
      isRequired: false,
    },
    {
      control: "select_station",
      value: "",
      isRequired: false,
    },
    {
      control: "select_trader",
      value: "",
      isRequired: false,
    },
    {
      control: "owned_number",
      value: "",
      isRequired: false,
    },
    {
      control: "date",
      value: getDate(),
      isRequired: true,
    },
  ]);

  const [stockOutsPostRequest, stockOutsPostResponse] = useRequest({
    path: STOCK_OUTS,
    method: "post",
    successMessage: "تم إضافة الخوارج بنجاح",
  });
  //product get data
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };

  const product_type = [
    { name: "أسطوانة", value: "cylinder" },
    { name: "دوار", value: "dewar" },
  ];

  // client data
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    if (Boolean(clientsStore.results.length)) return;
    clientGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  // station data connection to api
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });

  const getStations = () => {
    if (Boolean(stationsStore.results.length)) return;
    stationsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  // trader connection to api
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const getTraders = () => {
    if (Boolean(tradersStore.results.length)) return;
    tradersGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };

  //handle submit data to api
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then((data) => {
      console.log(data);
      if (!data.isOk) return;

      //requet body of outs stock
      let requestOutsBody = filter({
        obj: {
          product: controls.product,
          product_type: controls.product_type,
          factorypower: controls.factorypower,
          volume: controls.volume,
          date: controls.date,

          // clients:filterdataCl.client,
          // stations: filterdataSt.station,
          // traders: filterdataTr.trader,
          // owned_number: controls.owned_number,
        },

        output: "object",
      });
      console.log(Object.keys(addStation[0]).length);
      Object.keys(addClient[0]).length > 0
        ? (requestOutsBody["clients"] = addClient)
        : "";
      Object.keys(addStation[0]).length > 0
        ? (requestOutsBody["stations"] = addStation)
        : "";
      Object.keys(addTrader[0]).length > 0
        ? (requestOutsBody["traders"] = addTrader)
        : "";
      if (
        Object.keys(
          filter({
            obj: {
              product: controls.product,
              product_type: controls.product_type,
              factorypower: controls.factorypower,
              volume: controls.volume,
              date: controls.date,
              clients: addClient,
              stations: addStation,
              traders: addTrader,
              owned_number: controls.owned_number,
            },
          })
        ).length <= 1
      )
        return;

      stockOutsPostRequest({
        body: requestOutsBody,
        onSuccess: (res) => {
          resetControls();
          setAddClient([{}]);
          setAddStation([{}]);
          setAddTader([{}]);
          dispatch({ type: "loans-stock/addItem", payload: res.data });
        },
      }).then((res) => {
        console.log(res);
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            product: res?.product,
            product_type: res?.product_type,
            factorypower: res?.factorypower,
            volume: res?.volume,
            date: res?.date,
            clients: res?.clients,
            stations: res?.stations,
            traders: res?.traders,
            owned_number: res?.owned_number,

            //   owned_number: res?.owned_number
            //   ? res?.owned_number === 0
            //     ? "0"
            //     : res?.owned_number
            //   : "0",

            //  clients: addClient
            //   ? addClient === 0
            //     ? "0"
            //     : addClient
            //   : "0",
            //   stations: addStation
            //   ? addStation === 0
            //     ? "0"
            //     : addStation
            //   : "0",
          },
        });
      });

      if (res?.id) {
      } else {
        setInvalid(res?.message);
      }
    });
  };

  return (
    <>
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: stockOutsPostResponse.isPending,
          },
          closeBtn: {
            disabled: stockOutsPostResponse.isPending,
          },
        }}
      >
        <Box>
          <Box sx={{ display: "block" }}>
            <Card
              sx={{
                my: 2,
                p: 1,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                flexGrow: 1,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={globalLang.DateAdded[lang]}
                  value={controls.date}
                  format="YYYY/MM/DD"
                  onChange={(newValue) => {
                    const new_date = `${newValue["$y"]}-${
                      parseInt(newValue["$M"]) + 1
                    }-${newValue["$D"]}`;

                    setControl("date", new_date);
                  }}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        width: "45%",
                        width: {
                          xl: "45%",
                          lg: "45%",
                          md: "100%",
                          sm: "100%",
                          xs: "100%",
                        },
                        ml: {
                          xl: 1,
                          lg: 1,
                          md: "auto",
                          sm: "auto",
                          xs: "auto",
                        },
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                          mb: 2,
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <SelectField
                label={globalLang.Gas[lang]}
                placeholder={globalLang.Gas[lang]}
                renderValue={(selected) => {
                  return productsStore.results.find(
                    (product) => product.id === selected
                  ).name;
                }}
                isPending={productsGetResponse.isPending}
                onOpen={getProducts}
                onChange={(e) => {
                  setControl("product", e.target.value);
                }}
                value={controls.product}
                required={required.includes("product")}
                error={Boolean(invalid.product)}
                helperText={invalid.product}
                sx={{
                  width: "45%",
                  width: {
                    xl: "45%",
                    lg: "45%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mb: 2,
                }}
              >
                {productsStore?.results?.map((product, index) => (
                  <MenuItem value={product.id} key={`${product.name} ${index}`}>
                    {product.name}
                  </MenuItem>
                ))}
              </SelectField>
              <SelectField
                label={globalLang.package[lang]}
                placeholder={globalLang.package[lang]}
                renderValue={(selected) => {
                  return product_type.find((type) => type.value === selected)
                    .name;
                }}
                onChange={(e) => {
                  setControl("product_type", e.target.value);
                }}
                value={controls.product_type}
                required={required.includes("product_type")}
                error={Boolean(invalid.product_type)}
                helperText={invalid.product_type}
                sx={{
                  width: "45%",
                  width: {
                    xl: "45%",
                    lg: "45%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mb: 2,
                }}
              >
                {product_type.map((product, index) => (
                  <MenuItem
                    value={product.value}
                    key={`${product.name} ${index}`}
                  >
                    {product.name}
                  </MenuItem>
                ))}
              </SelectField>
              <SelectField
              sx={{
                width: "45%",
                width: {
                  xl: "45%",
                  lg: "45%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                mb: 2,
              }}
              label={globalLang.volume[lang]}
              placeholder={globalLang.volume[lang]}
              renderValue={(selected) => {
                return volumes?.find(
                  (v) => v.value === selected
                ).name;
              }}


              onChange={(e) => {
                setControl('volume', e.target.value)
              }}
              value={controls.volume}
              required={required.includes("volume")}
              error={Boolean(invalid.volume)}
              helperText={invalid.volume}
            >
              {volumes?.map((volume, index) => (
                <MenuItem
                  value={volume.value}
                  key={`${volume.name} ${index}`}
                >
                  {volume.name}
                </MenuItem>
              ))}
            </SelectField>
            </Card>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
              flexGrow: 1,
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
            }}
          >
            <Card
              sx={{
                width: "100%",
                m: "auto",
                ml: {
                  xl: 1,
                  lg: 1,
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                },
              }}
            >
              <CardActions sx={{ display: "flex", justifyContent: "end" }}>
                <AddIcon
                  sx={{ color: "success" }}
                  onClick={() => handleAddClient()}
                />
              </CardActions>
              <CardContent>
                <Box sx={{ my: 2, px: 2 }}>
                  <FormLabel>{globalLang.clients[lang]}</FormLabel>
                </Box>
                {addClient.map((obj, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                      key={index}
                    >
                       <FormControl sx={{ml:2,width:"80%"
                             ,}}>
                        <AutocompleteField
                          label={globalLang.select_client[lang]}
                          sx={{
                            width: {
                              lg: "50%",
                              md: "50%",
                              xl: "50%",
                              sm: "100%",
                              xs: "100%",
                            },
                          }}
                          isPending={clientGetResponse.isPending}
                          onOpen={getClients}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            
                            switch (reason) {
                              case "selectOption":
                                handleClientchange(options,index,"client")
                                break;
                              case "clear":
                                handleClientchange((old) => ({
                                  ...old,
                                  client: "",
                                }));
                            }
                          }}
                          data={[
                            ...clientsStore?.results.map((c, index) => ({
                              label: c?.user?.username,
                              value: c.id,
                            })),
                          ]}
                          value={addClient}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
                      </FormControl>
                      <InputField
                        sx={{ width: "15%",mt:2 }}
                        name={"owned_number"}
                        type={"number"}
                        onChange={(e) => handleClientchange(e, index)}
                        value={obj.owned_number || ""}
                      ></InputField>
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "100%",
                m: "auto",
                ml: {
                  xl: 1,
                  lg: 1,
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                },
              }}
            >
              <CardActions sx={{ display: "flex", justifyContent: "end" }}>
                <AddIcon
                  sx={{ color: "success" }}
                  onClick={() => handleAddStation()}
                />
              </CardActions>
              <CardContent>
                <Box sx={{ my: 2, px: 2 }}>
                  <FormLabel>{globalLang.stations[lang]}</FormLabel>
                </Box>
                {addStation.map((obj, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                      key={index}
                    >
                      <FormControl sx={{ml:2,width:"80%"
                             ,}}>
                        <AutocompleteField
                          label={globalLang.stations[lang]}
                          sx={{
                            width: {
                              lg: "50%",
                              md: "50%",
                              xl: "50%",
                              sm: "100%",
                              xs: "100%",
                            },
                          }}
                          isPending={stationsGetResponse.isPending}
                          onOpen={getStations}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            switch (reason) {
                              case "selectOption":
                                handleStationChange(options,index,"station");
                                break;
                              case "clear":
                                handleStationChange((old) => ({
                                  ...old,
                                  station: "",
                                }));
                            }
                          }}
                          data={[
                            ...stationsStore?.results.map((c, index) => ({
                              label: c?.user?.name,
                              value: c.id,
                            })),
                          ]}
                          value={addStation}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
                      </FormControl>
                       
                      <InputField
                        sx={{ width: "15%" ,mt:2}}
                        name={"owned_number"}
                        type={"number"}
                        onChange={(e) => {
                          handleStationChange(e, index);
                        }}
                        value={obj.owned_number || ""}
                      ></InputField>
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
            <Card
              sx={{
                width: "100%",
                m: "auto",
                ml: {
                  xl: 1,
                  lg: 1,
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                },
              }}
            >
              <CardActions sx={{ display: "flex", justifyContent: "end" }}>
                <AddIcon
                  sx={{ color: "success" }}
                  onClick={() => handleAddTrader()}
                />
              </CardActions>
              <CardContent>
                <Box sx={{ my: 2, px: 2 }}>
                  <FormLabel>{globalLang.traders[lang]}</FormLabel>
                </Box>
                {addTrader.map((obj, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                      key={index}
                    >
                      <FormControl sx={{ml:2,width:"80%"
                             ,}}>
                        <AutocompleteField
                          label={globalLang.traders[lang]}
                          sx={{
                            
                            width: {
                              lg: "50%",
                              md: "50%",
                              xl: "50%",
                              sm: "100%",
                              xs: "100%",
                            },
                          }}
                          name="trader"
                          isPending={tradersGetResponse.isPending}
                          onOpen={getTraders}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                           console.log(options);
                            switch (reason) {
                              case "selectOption":
                                handleTraderChange(options,index,"trader");
                                break;
                              case "clear":
                                handleTraderChange((old) => ({
                                  ...old,
                                  trader: "",
                                }));
                            }
                          }}
                          data={[
                            ...tradersStore?.results.map((c, index) => ({
                              label: c?.user?.username,
                              value: c.id,
                            })),
                          ]}
                          value={addTrader}
                          
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
                      </FormControl>
                      
                      <InputField
                        sx={{ width: "15%" ,mt:2}}
                        name={"owned_number"}
                        type={"number"}
                        onChange={(e) => {
                          handleTraderChange(e, index);
                        }}
                        value={obj.owned_number || ""}
                      ></InputField>
                    </Box>
                  );
                })}
              </CardContent>
            </Card>
          
          </Box>
        </Box>
      </Form>
      {stockOutsPostResponse.successAlert}
      {stockOutsPostResponse.failAlert}
    </>
  );
}
export default OutsStock;

import React, { useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Form, {
  InputField,
  MultiSelectField,
  MultiSelectItem,
  NumberField,
  PhoneField,
  SelectField,
} from "../../../features/form";
import ReceiptTable from "../../../components/ReceiptTable";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import {
  EMPLOYEES,
  PRODUCTS,
  STATIONEXITBILL,
  STATIONS,
  STOCK,
  TraderReceiptShipGas,
  TRANSPORT,
} from "../../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Stack, Box, Typography, Tooltip } from "@mui/material";
import filter from "../../../utils/ClearNull";


const ExitPermissionStation = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
 
  const [success, setSucess] = useState(false);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (gas) => {
    setFormData([...gas]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };
  //////////////////////////////////
  //----store----

  const employeesStore = useSelector((state) => state.employees.value);
  const [required_errors, setRequiredErrors] = useState("");
  const transportStore = useSelector((state) => state.transport.value);
  const dispatch = useDispatch();

  const [rows, setrows] = useState([
    {
      product: "",
      quantityout: "",
      volume: "",
    },
  ]);
  const columns = [
    {
      name: "quantityout",
      value: "exit_quantity",
    },
    {
      name: "product",
      value: "product",
    },
    {
      name: "volume",
      value: "volume",
    },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "number",
      value: "",
      isRequired: true,
    },
    {
      control: "transport",
      value: "",
      isRequired: true,
    },
    {
      control: "driver",
      value: "",
      isRequired: true,
    },
    {
      control: "quantityout_gas",
      value: "",
      isRequired: false,
    },
    {
      control: "quantityout_liquid",
      value: "",
      isRequired: false,
    },
    {
      control: "Date",
      value: new Date(),
      isRequired: false,
    },
    {
      control: "bills",
      value: [],
      isRequired: true,
    },
  ]);

  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });
  const getStations = () => {
    stationsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };

  //----request hooks----
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const [transportGetRequest, transportGetResponse] = useRequest({
    path: TRANSPORT,
    method: "get",
  });

  const [stationExitBillPostRequest, stationExitBillPostResponse] = useRequest({
    path: STATIONEXITBILL,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });

  //----functions----

  const getEmployees = () => {
    if (Boolean(employeesStore.results.length)) return;
    employeesGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "employees/set", payload: res.data });
      },
    });
  };

  const getTransportation = () => {
    if (Boolean(transportStore.results.length)) return;
    transportGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "transport/set", payload: res.data });
      },
    });
  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          permit_uuid: controls.number,
          exit_date: controls.Date.toISOString(),
          driver: controls.driver,
          transport: controls.transport,
          bills: [
            ...FormData.filter((object) => Object.keys(object).length !== 0),
            ...LiquidFormData.filter(
              (object) => Object.keys(object).length !== 0
            ),
          ],
        },
        output: "object",
      });
      stationExitBillPostRequest({
        body: requestBody,
        onSuccess: () => {
          resetControls();
          setSucess(true);
        },
      }).then((res) => {
        if (res?.id) {
          dispatch({
            type: "station_exit_bill/addItem",
            payload: res,
          });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>
      <Form
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: stationExitBillPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: stationExitBillPostResponse.isPending,
          },
        }}
      >
        <Stack
          sx={{ gap: 2 }}
        >
          <Stack
            sx={{
              gap: 2,
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
              padding: "10px",
              "& .MuiTextField-root": {
                minWidth: "16px",
                width: "100%",
              },
            }}
          >
            <NumberField
              label={globalLang.Exit_number[lang]}
              placeholder={globalLang.Exit_number[lang]}
              onChange={(e) => setControl("number", e.target.value)}
              value={controls.number}
              required={required.includes("number")}
              error={Boolean(invalid.number)}
              helperText={invalid.number}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={globalLang.Date[lang]}
                value={controls.Date}
                onChange={(newValue) => {
                  setControl("Date", newValue);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <SelectField
              label={globalLang.driver[lang]}
              placeholder={globalLang.driver[lang]}
              isPending={employeesGetResponse.isPending}
              onOpen={getEmployees}
              renderValue={(selected) => {
                let employee = employeesStore.results.find(
                  (employee) => employee.id === selected
                ).user;

                return employee.first_name + " " + employee.last_name;
              }}
              onChange={(e) => {
                setControl("driver", e.target.value);
              }}
              value={controls.driver}
              required={required.includes("driver")}
              error={Boolean(invalid.driver)}
              helperText={invalid.driver}
            >
              {employeesStore.results?.map(
                (employee, index) =>
                  employee?.job.title === "سائق" && (
                    <MenuItem value={employee.id} key={` ${index}`}>
                      {employee.user.first_name}  {employee.user.last_name}
                    </MenuItem>
                  )
              )}
            </SelectField>
            <SelectField
              label={globalLang.transportation[lang]}
              placeholder={globalLang.transportation[lang]}
              renderValue={(selected) => {
                return transportStore.results.find(
                  (transport) => transport.id === selected
                ).name;
              }}
              isPending={transportGetResponse.isPending}
              onOpen={getTransportation}
              onChange={(e) => {
                setControl("transport", e.target.value);
              }}
              value={controls.transport}
              required={required.includes("transport")}
              error={Boolean(invalid.transport)}
              helperText={invalid.transport}
            >
              {transportStore?.results?.map((transport, index) => (
                <MenuItem
                  value={transport.id}
                  key={`${transport.name} ${index}`}
                >
                  {transport.name}
                </MenuItem>
              ))}
            </SelectField>
          </Stack>

          <Box>
            <Stack
              sx={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
              }}
            >
              <ReceiptTable
                type="cylinder"
                columns={columns}
                rows={rows}
                getformData={getFormData}
                success={success}
              />
              <ReceiptTable
                type="dewar"
                columns={columns}
                rows={rows}
                getformData={getLiquidFormData}
                success={success}
              />
           
            </Stack>
          </Box>
        </Stack>
      </Form>
      {stationExitBillPostResponse.successAlert}
      {stationExitBillPostResponse.failAlert}
    </>
  );
};

export default ExitPermissionStation;

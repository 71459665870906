import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {

  CLIENTHOMEBILL,
  CLIENTS,
  DISTRIBUTIONBILL,
  PRODUCTS,

} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const DistributionBill = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const tableValues = [
    "product",
    "volume",
    "driven",
    "taken",
    "returned",
    "price",
    "paid",
  ];
  const clientsStore = useSelector((state) => state.clients.value);


  const ClientHomeBillStore = useSelector(
    (state) => state.client_home_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [CLientBillPostRequest, ClientBillPostResponse] = useRequest({
    path: DISTRIBUTIONBILL,
    method: "post",
    successMessage: "تم التعديل بنجاح",
  });

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    client_home_bill: "",
  });

  const [ClientHomeBillData, setClientHomeBillData] = useState({});

  const [Products, setProducts] = useState([]);
  const [LiquidProducts, setLiquidProducts] = useState([]);
  
  const handleAddRow = () => {
    setProducts([
      ...Products,
      {
        product: "",
        product_type: "",
        volume: "",
        driven: "",
        taken: "",
        returned: "",
        price: "",
        paid: "",
      },
    ]);
  };
  const handleAddLiquidRow = () => {
    setLiquidProducts([
      ...LiquidProducts,
      {
        product: "",
        product_type: "",
        volume: "",
        driven: "",
        taken: "",
        returned: "",
        price: "",
        paid: "",
      },
    ]);
  };
  const hanldeRemoveRow = (index) => {
    let removed_data = [...Products];
    removed_data.splice(index, 1);

    setProducts(removed_data);
  };
  const hanldeRemoveLiquidRow = (index) => {
    let removed_data = [...LiquidProducts];
    removed_data.splice(index, 1);
    setLiquidProducts(removed_data);
  };

  ///////////////////////////////
  const [clientsgetRequest, clientsgetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });
  const getClients = () => {
    clientsgetRequest({
      params: {
        size: 1000
      },
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  const [productsgetRequest, productsgetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    productsgetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "client",
      value: "",
      isRequired: true,
    },
    {
      control: "bill_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "dokhan",
      value: "",
      isRequired: false,
    },
    {
      control: "maintenance",
      value: "",
      isRequired: false,
    },

    {
      control: "bill_date",
      value: new Date(),
      isRequired: true,
    },


  ]);

  const [ClientHomeBillGetRequest, ClientHomeBillGetResponse] = useRequest({
    path: CLIENTHOMEBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getClientHomeBill = () => {
    urlParams.append("size", 1000);
    // urlParams.append("completed", false);
    ClientHomeBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "client_home_bill/set", payload: res.data });
      },
    });
  };

  const handleChange = (event, index, type) => {
    let data = [...Products];
    data[index][event.target.name] = parseFloat(event.target.value);
    data[index]["product_type"] = type;


    console.log(Products);
  };
  const handleLiquidChange = (event, index, type) => {
    let liquiddata = [...LiquidProducts];
    liquiddata[index][event.target.name] = parseFloat(event.target.value);
    liquiddata[index]["product_type"] = type;


  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      console.log(output);

      const requestBody = filter({
        obj: {
          home_away_permit: parseInt(
            ClientHomeBillStore.results?.find(
              (bf) => bf?.permit_uuid === filterdata?.client_home_bill
            )?.id
          ),

          client: controls.client,
          bill_date: controls.bill_date.toISOString(),
          bill_uuid: parseInt(controls.bill_uuid),
          dokhan: controls.dokhan,
          products: [...Products, ...LiquidProducts],
        },
        output: "object",
      });

      CLientBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          handleresetFilters();
          resetControls();
          dispatch({
            type: "client_bill/addItem",
            payload: res?.data,
          });
        },
      }).then((res) => {
        let response = res?.response?.data;
        if (!res?.id) {
          setInvalid(res?.message);
        }
      });
    });
  };

  const handleFilterSubmit = () => {

    if (!filterdata.client_home_bill) {
      return;
    }

    ClientHomeBillGetRequest({
      id: ClientHomeBillStore.results?.find(
        (bf) => bf?.permit_uuid === filterdata?.client_home_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setClientHomeBillData(res?.data);
      },
    });
  };
  const handleresetFilters = () => {
    setfilterdata({ client_home_bill: "" });
    setProducts([]);
    setLiquidProducts([]);
    setfiltered(true);

    setIsCleared(uuid());
  };

  return (
    <>
 
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.client_home_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <AutocompleteField
            label={globalLang.BackAndForthreceipt[lang]}
            sx={{ width: "30vw" }}
            placeholder={globalLang.BackAndForthreceipt[lang]}
            isPending={ClientHomeBillGetResponse.isPending}
            onOpen={getClientHomeBill}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({
                    client_home_bill: options.value,
                  });
                  break;
                case "clear":
                  setfilterdata({ client_home_bill: "" });
              }
            }}
            data={[
              ...ClientHomeBillStore.results.map((bf) => ({
                label: String(bf.permit_uuid),
                value: bf.permit_uuid,
              })),
            ]}
            value={filterdata.client_home_bill}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: ClientBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: ClientBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ padding: "20px", gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <Stack sx={{ flexDirection: "column", gap: 3 }}>
                <InputField
                  disabled
                  label={globalLang.homebill[lang]}
                  sx={{
                    "& .MuiInputBase-input": {
                      background: "#E0DCD5",
                    },
                  }}
                  value={filterdata.client_home_bill}
                />

                <InputField
                  disabled
                  label={globalLang.AwayDate[lang]}
                  sx={{
                    "& .MuiInputBase-input": {
                      background: "#E0DCD5",
                    },
                  }}
                  value={format(ClientHomeBillData?.exit_date)}
                />
              </Stack>
              <Stack sx={{ gap: 3 }}>
                <InputField
                  disabled
                  label={globalLang.BackDate[lang]}
                  sx={{
                    "& .MuiInputBase-input": {
                      background: "#E0DCD5",
                    },
                  }}
                  value={format(ClientHomeBillData?.enter_date)}
                />

                <InputField
                  disabled
                  label={globalLang.driver[lang]}
                  placeholder={globalLang.driver[lang]}
                  sx={{
                    "& .MuiInputBase-input": {
                      background: "#E0DCD5",
                    },
                  }}
                  value={ClientHomeBillData?.driver?.name}
                ></InputField>
              </Stack>
              <Stack sx={{ gap: 3 }}>
                {" "}
                <InputField
                  disabled
                  label={globalLang.transportation[lang]}
                  sx={{
                    "& .MuiInputBase-input": {
                      background: "#E0DCD5",
                    },
                  }}
                  placeholder={globalLang.transportation[lang]}
                  value={ClientHomeBillData?.transport?.name}
                ></InputField>
                <SelectField
                  placeholder={globalLang.client[lang]}
                  label={globalLang.client[lang]}
                  renderValue={(selected) => {
                    let client = clientsStore?.results?.find(
                      (client) => client?.id === selected
                    )?.user;
                    return client.first_name + " " + client.last_name;
                  }}
                  name="client"
                  isPending={clientsgetResponse.isPending}
                  onOpen={getClients}
                  onChange={(e) => {
                    setControl("client", e.target.value);
                  }}
                  value={controls.client}
                  error={Boolean(invalid.client)}
                  helperText={invalid.client}
                  required={required.includes("client")}
                >
                  {clientsStore?.results?.map((client, index) => (
                    <MenuItem
                      value={client?.id}
                      key={`${client?.user?.username} ${index}`}
                    >
                      {client?.user?.first_name} {client?.user?.last_name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Stack>
              <Stack sx={{ gap: 3 }}>
                <NumberField
                  label={globalLang.bill_number[lang]}
                  placeholder={globalLang.bill_number[lang]}
                  onChange={(e) => {
                    setControl("bill_uuid", e.target.value);
                  }}
                  value={controls.bill_uuid}
                  error={Boolean(invalid.bill_uuid)}
                  helperText={invalid.bill_uuid}
                  required={required.includes("bill_uuid")}
                ></NumberField>
                <NumberField
                  label={globalLang.tips[lang]}
                  placeholder={globalLang.tips[lang]}
                  onChange={(e) => {
                    setControl("dokhan", e.target.value);
                  }}
                  value={controls.dokhan}
                  error={Boolean(invalid.dokhan)}
                  helperText={invalid.dokhan}
                  required={required.includes("dokhan")}
                ></NumberField>

              </Stack>
              <Stack sx={{ gap: 3 }}>
   
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={globalLang.billDate[lang]}
                    value={controls.bill_date}
                    onChange={(newValue) => {
                      setControl("bill_date", newValue);
                    }}
                    renderInput={(params) => (
                      <InputField
                        sx={{
                          "& .MuiInputBase-root": {
                            borderColor: "#F0F0F8",
                            paddingRight: "5px",
                          },
                          "& .MuiFormLabel-root": {
                            color: "#666666",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            </Stack>

            <Box>
              <Stack sx={{ gap: 2 }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    padding: "10px",
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "100%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography component={'span'} variant={'h6'} sx={{ flexGrow: "1" }}>
                      الغازات
                    </Typography>
                    <AddIcon
                      htmlColor="green"
                      sx={{ cursor: "pointer" }}
                      onClick={handleAddRow}
                    />
                  </Stack>

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableValues.map((value,index) => (
                          <TableCell key={index}>
                            {globalLang[value][lang]}
                          </TableCell>
                        ))}
                        <TableCell ></TableCell>
                     
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Products?.map((Product, Gasindex) => (
                        <TableRow
                          key={`${Gasindex}`}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>
                            <SelectField
                              placeholder={globalLang.product[lang]}
                              renderValue={(selected) => {
                                return ClientHomeBillData?.distribution_products?.find(
                                  (product) => product?.product?.id === selected
                                )?.product?.name;
                              }}
                              name="product"
                              isPending={productsgetResponse.isPending}
                              onOpen={getProducts}
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                           
                              }}

                  
                            >
                              {ClientHomeBillData?.distribution_products?.map(
                                (product, index) =>
                                  product?.product_type === "cylinder" && (
                                    <MenuItem
                                      value={product?.product?.id}
                                      key={`${product?.product?.name} ${index}`}
                                    >
                                      {product?.product?.name}
                                    </MenuItem>
                                  )
                              )}
                            </SelectField>
                          </TableCell>

                          <TableCell>
                            <InputField
                              placeholder={globalLang.volume[lang]}
                              name="volume"
                              type="number"
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
                    
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.driven[lang]}
                              name="driven"
                              type="number"
                              onChange={(e) => {
                              
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
              
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.taken[lang]}
                              name="taken"
                              type="number"
                              onChange={(e) => {
                             
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
                       
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.returned[lang]}
                              name="returned"
                              type="number"
                              onChange={(e) => {
                          
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
                          
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.price[lang]}
                              name="price"
                              type="number"
                              onChange={(e) => {
                                setControl("price_gas", e.target.value);
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
                           
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.paid[lang]}
                              name="paid"
                              type="number"
                              onChange={(e) => {
                                handleChange(
                                  e,
                                  Gasindex,

                                  "cylinder"
                                );
                              }}
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="حذف">
                              <RemoveIcon
                                htmlColor="red"
                                sx={{ cursor: "pointer" }}
                                onClick={() => hanldeRemoveRow(Gasindex)}
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  component={Paper}
                  sx={{
                    padding: "10px",
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "100%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography  component={'span'} variant={'h6'} sx={{ flexGrow: "1" }}>
                      السوائل
                    </Typography>
                    <Tooltip title="أضف منتج ">
                      <AddIcon
                        htmlColor="green"
                        sx={{ cursor: "pointer" }}
                        onClick={handleAddLiquidRow}
                      />
                    </Tooltip>
                  </Stack>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {tableValues.map((value,index) => (
                          <TableCell key={index}>
                            {globalLang[value][lang]}
                          </TableCell>
                        ))}
                        <TableCell ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {LiquidProducts?.map((Product, Liquidindex) => (
                        <TableRow
                          key={`${Liquidindex}`}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell>
                            <SelectField
                              placeholder={globalLang.product[lang]}
                              renderValue={(selected) => {
                                return ClientHomeBillData?.distribution_products?.find(
                                  (product) => product?.product?.id === selected
                                )?.product?.name;
                              }}
                              name="product"
                              isPending={productsgetResponse.isPending}
                              onOpen={getProducts}
                              onChange={(e) => {
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                            >
                              {ClientHomeBillData?.distribution_products?.map(
                                (product, index) =>
                                  product?.product_type === "dewar" && (
                                    <MenuItem
                                      value={product?.product?.id}
                                      key={`${product?.product?.name} ${index}`}
                                    >
                                      {product?.product?.name}
                                    </MenuItem>
                                  )
                              )}
                            </SelectField>
                          </TableCell>

                          <TableCell>
                            <InputField
                              placeholder={globalLang.volume[lang]}
                              name="volume"
                              type="number"
                              onChange={(e) => {
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                 
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.driven[lang]}
                              name="driven"
                              type="number"
                              onChange={(e) => {
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                  
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.taken[lang]}
                              name="taken"
                              type="number"
                              onChange={(e) => {
                         
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                   
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.returned[lang]}
                              name="returned"
                              type="number"
                              onChange={(e) => {
                   
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                   
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.price[lang]}
                              name="price"
                              type="number"
                              onChange={(e) => {
                          
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                          
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <InputField
                              placeholder={globalLang.paid[lang]}
                              name="paid"
                              type="number"
                              onChange={(e) => {
                            
                                handleLiquidChange(
                                  e,
                                  Liquidindex,

                                  "dewar"
                                );
                              }}
                     
                            ></InputField>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="حذف">
                              <RemoveIcon
                                htmlColor="red"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  hanldeRemoveLiquidRow(Liquidindex)
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Box>
          </Stack>
        </Form>
      )}

      {ClientBillPostResponse.successAlert}
      {ClientBillPostResponse.failAlert}
    </>
  );
};
export default DistributionBill;

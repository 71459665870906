import { Box, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import PicturePicker from "../../components/PicturePicker";
import Wrapper from "../../components/Wrapper";
import {
  CLIENTS,
  COUNTRY_FILTER,
  PRODUCTS,
  STATES,
  STOCK,
  TRANSPORT,
} from "../../data/APIs";
import Form, {
  InputField,
  PhoneField,
  PictureField,
  SelectField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";

const AddTransport = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const addTransportkLang = useSelector(
    (state) => state.lang.value.addTransport
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  const productsStore = useSelector((state) => state.products.value);
  const [preview, setpreview] = useState("");
  ///////////////////////////////
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "name",
      value: "",
      isRequired: false,
    },
 
  
  ]);

  const [transportPostRequest, transportPostResponse] = useRequest({
    path: TRANSPORT,
    method: "post",
    successMessage: "تم إضافة وسيلة نقل بنجاح",
  });
 const [transportGetRequest, transportGetResponse] = useRequest({
   path: TRANSPORT,
   method: "get",
 });
 const urlParams = new URLSearchParams();
 const getTransports = () => {
   transportGetRequest({
     params: {
       size: 1000,
     },
     params: urlParams,
     onSuccess: (res) => {
       dispatch({ type: "transport/set", payload: res.data });
     },
   });
 };

  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          name: controls.name,
        },
      
      });

      // if (
      //   Object.keys(
      //     filter({
      //       obj: {
      //         name: controls.name,               
      //     },
      //     })
      //   ).length <= 1
      // )
      //   return;

      transportPostRequest({
        body: requestBody,
        onSuccess: () => {
          resetControls();
          getTransports()
        },
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            name: response?.name,
            
          },
        });

        setInvalid(responseBody);
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.transportation[lang],
          addTransportkLang.addTransport[lang],
        ]}
      /> */}
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: transportPostResponse.isPending,
          },
          closeBtn: {
            disabled: transportPostResponse.isPending,
          },
        }}
      >
   
        <InputField
          placeholder={globalLang.name[lang]}
          label={globalLang.name[lang]}
          value={controls.name}
          sx={{width:'40%'}}
          onChange={(e) => setControl("name", e.target.value)}
          required={required.includes("name")}
          error={Boolean(invalid.name)}
          helperText={invalid.name}
        />
      </Form>
      {transportPostResponse.successAlert}
      {transportPostResponse.failAlert}
    </>
  );
};

export default AddTransport;

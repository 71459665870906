import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StationExitBillView from "./Shipping/StationExitBillView";
import StationEnterBillView from "./Shipping/StationEnterBillView";
import EnterFillingView from "./Filling/EnterFillingView";
import ExitFillingView from "./Filling/ExitFillingView";
import AwayDistributionView from "./Distribution/AwayDisributionView";
import HomeDistributionView from "./Distribution/HomeDistributionView";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import { useSelector } from "react-redux";
import ViewMediationFrom from "./mediation/viewCommissionFrom";
import ViewMediationTo from "./mediation/viewCommissionTo";
import ViewCommissionFrom from "./mediation/viewCommissionFrom";
import ViewCommissionTo from "./mediation/viewCommissionTo";
const PermissionPanel = ({ boxes, index }) => {
  const [value, setValue] = React.useState(index);
  console.log(index);
  const [subValue, setSub] = useState(0);
  const userInfo = useSelector((state) => state.userInfo.value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSub = (event, newValue) => {
    setSub(newValue);
  };
  console.log(boxes);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            {boxes.map((box, index) => {
              return <Tab label={box.title} {...a11yProps(index)} />;
            })}
            {/* <PermissionsGate permissions={['view_factorytraderpermit','view_factorystationpermit','view_factoryhomeawaypermit']}>
            <Tab label="تعبئة" {...a11yProps(0)} />
            {/* </PermissionsGate> */}
            {/* <PermissionsGate permissions={['view_factorystationpermit']}> */}
            {/* <Tab label="شحن" {...a11yProps(1)} /> */}
            {/* </PermissionsGate> */}
            {/* <PermissionsGate permissions={['view_factoryhomeawaypermit']}> */}
            {/* <Tab label="توزيع" {...a11yProps(2)} /> */}
            {/* </PermissionsGate> } */}
          </Tabs>
        </Box>
        {boxes.map((box, index) => (
          <TabPanel value={value} key={index} index={index}>
            {box.title == "تعبئة" ? (
              <>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  {" "}
                  <Tab label="دخول" {...a11yProps(0)}></Tab>
                  <Tab label="خروج" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <EnterFillingView />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ExitFillingView />
                </TabPanel>
              </>
            ) : box.title == "شحن" ? (
              <>
                {" "}
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  {" "}
                  <Tab label="خروج" {...a11yProps(0)}></Tab>
                  <Tab label="دخول" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <StationExitBillView />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <StationEnterBillView />
                </TabPanel>
              </>
            ) : box.title=="توزيع"?(
              <>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  {" "}
                  <Tab label="ذهاب" {...a11yProps(0)}></Tab>
                  <Tab label="عودة" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <AwayDistributionView />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <HomeDistributionView />
                </TabPanel>
              </>
            ):(
              <>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  {" "}
                  <Tab label="من " {...a11yProps(0)}></Tab>
                  <Tab label="الى" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                <ViewCommissionTo />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  
                  <ViewCommissionFrom />
                </TabPanel>
              </>
            )}
          </TabPanel>
        ))}

        {/* <PermissionsGate permissions={['view_factorystationpermit']}>
        <TabPanel value={value} index={0}>
          <Tabs
            value={subValue}
            onChange={handleSub}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            <Tab label="خروج" {...a11yProps(0)}></Tab>
            <Tab label="دخول" {...a11yProps(1)}></Tab>
          </Tabs>
          <TabPanel value={subValue} index={0}>
            <StationExitBillView />
          </TabPanel>
          <TabPanel value={subValue} index={1}>
            <StationEnterBillView />
          </TabPanel>
        </TabPanel>
        </PermissionsGate> */}
        {/* <PermissionsGate permissions={['view_factorytraderpermit']}>
        <TabPanel value={value} index={0}>
          <Tabs
            value={subValue}
            onChange={handleSub}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            <Tab label="دخول" {...a11yProps(0)}></Tab>
            <Tab label="خروج" {...a11yProps(1)}></Tab>
          </Tabs>
          <TabPanel value={subValue} index={0}>
            <EnterFillingView />
          </TabPanel>
          <TabPanel value={subValue} index={1}>
            <ExitFillingView />
          </TabPanel>
        </TabPanel>
        </PermissionsGate> */}
        {/* <PermissionsGate permissions={['view_factoryhomeawaypermit']}>
        <TabPanel value={value} index={2}>
          <Tabs
            value={subValue}
            onChange={handleSub}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            <Tab label="ذهاب" {...a11yProps(0)}></Tab>
            <Tab label="عودة" {...a11yProps(1)}></Tab>
          </Tabs>
          <TabPanel value={subValue} index={0}>
            <AwayDistributionView />
          </TabPanel>
          <TabPanel value={subValue} index={1}>
            <HomeDistributionView />
          </TabPanel>
        </TabPanel>
        </PermissionsGate> */}
      </Box>
    </>
  );
};
export default PermissionPanel;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

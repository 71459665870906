import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  BACKANDFORTHRECEIPT,
  CLIENTAWAYBILL,
  CLIENTHOMEBILL,
  CLIENTS,
  Commission,
  CommissionTo,
  STATIONENTERBILL,
  STATIONEXITBILL,
  STATIONS,
  TRADERS,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import Wrapper from "../../../components/Wrapper";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomTable from "../../../components/CustomTable";


const CommissionFrom = () => {
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);
  
    return `${year}-${month}-${date}`;
  };
  const [filterdataSt, setfilterdataSt] = useState({
    station: "",
    Date: getDate(),
  });
  const [filterdataCl, setfilterdataCl] = useState({
    client: "",
    Date: getDate(),
  });
  const [filterdataTr, setfilterdataTr] = useState({
    trader: "",
    Date: getDate(),
  });
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const backandforthreceiptLang = useSelector(
    (state) => state.lang.value.addhomereceipt
  );
  const commissionStore = useSelector((state) => state.commission.value);
  const stationsStore = useSelector((state) => state.stations.value);
  const clientsStore = useSelector((state) => state.clients.value);
  const tradersStore = useSelector((state) => state.traders.value);
  const transportStore = useSelector((state) => state.transport.value);
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  
  const [CommissionToPostRequest, CommissionToPostResponse] =
    useRequest({
      path: CommissionTo,
      method: "post",
      successMessage: "تم التعديل بنجاح",
    });

    const [FormData, setFormData] = useState([]);
    const [LiquidFormData, setLiquidFormData] = useState([]);
    const getFormData = (data) => {
      setFormData([...data]);
    };
    const getLiquidFormData = (liquid) => {
      setLiquidFormData([...liquid]);
    };

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    commissionTo: "",
  });
  const [commissionToData, setCommissionToData] = useState({});
  const [Products, setProducts] = useState([{}]);


  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "delivery_date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "thetype",
      value: "",
      isRequired: false,
    },
    {
      control: "delivery_uuid",
      value: "",
      isRequired: true,
    },

  ]);

  const [CommissionToGetRequest, CommissionToGetResponse] = useRequest({
    path:Commission ,
    method: "get",
  });
  const urlParams = new URLSearchParams();

  const getCommissionTo = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed", false);
    CommissionToGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "commission/set", payload: res.data });
      },
    });
  };
  


  const handleSubmit = () => {
    validate().then((output) => {
        console.log(output)
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          order_uuid: filterdata.commissionTo,
          delivery_date: controls.delivery_date.toISOString(),
          delivery_uuid:controls.delivery_uuid,
          to_location_type:controls.thetype==="S"&&"S"||controls.thetype==="T"&&"T" ||controls.thetype==="C"&&"C",
          to_location_object_id:controls.thetype==="S"&&filterdata.station||controls.thetype==="T"&&filterdataTr.trader ||controls.thetype==="C"&&filterdataCl.client,
          products: [
            ...FormData.filter((object) => Object.keys(object).length !== 0), 
            ...LiquidFormData.filter(
            (object) => typeof object !== 'undefined' && Object.keys(object).length !== 0 ? Object.keys(object): ""
          )],
        },
        output: "object",
      });

      CommissionToPostRequest({
        body: requestBody,
        onSuccess: (res) => {
         handleresetFilters()
          resetControls();
          dispatch({
            type: "commissionTo/addItem",
            payload: res?.data,
          });
        },
      }).then((res) => {
        let response = res?.response?.data;
      
       if(!res.id){
        setInvalid(res?.message);
       }
      });
    });
  };

  const handleFilterSubmit = () => {
    if (!filterdata.commissionTo) {
      return;
    }

    CommissionToGetRequest({
      id: commissionStore.results?.find(
        (bf) => bf?.order_uuid === filterdata?.commissionTo
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setCommissionToData(res?.data);
      }
    });
  };

 const handleresetFilters = () => {
   setfilterdata({ commissionTo: "" });
   setProducts([]);
   setfiltered(true);

   setIsCleared(uuid());
 };

 const text = [
  {text: globalLang.driven[lang]},
  {text: globalLang.product[lang]},
  {text: globalLang.volume[lang]},
  {text: globalLang.taken_empty[lang]},
  {text: globalLang.taken_filled[lang]},
]
const columns = [
  {
    name: "driven",
    value: globalLang.driven[lang],
  },
  {
    name: "product",
    value: globalLang.product[lang],
  },
  {
    name: "volume",
    value: globalLang.volume[lang],
  },
  {name: 'taken', value: globalLang.taken[lang]},
];
const thetype = [
  {name: "محطة", value: "S"},
  {name: "تاجر", value: "T"},
  {name: "عميل", value: "C"},
];

const [tradersGetRequest, tradersGetResponse] = useRequest({
  path: TRADERS,
  method: "get",
});

const getTraders = () => {
  if (Boolean(tradersStore.results.length)) return;
  tradersGetRequest({
    params: {
      size: 1000,
    },
    onSuccess: (res) => {
      dispatch({ type: "traders/set", payload: res.data });
    },
  });
};

const [clientGetRequest, clientGetResponse] = useRequest({
  path: CLIENTS,
  method: "get",
});

const getClients = () => {
  if (Boolean(clientsStore.results.length)) return;
  clientGetRequest({
    params: {
      size: 1000,
    },
    onSuccess: (res) => {
      dispatch({ type: "clients/set", payload: res.data });
    },
  });
};
const [stationsGetRequest, stationsGetResponse] = useRequest({
  path: STATIONS,
  method: "get",
});
const getStations = () => {
  stationsGetRequest({
    params: {
      size:100
    },
    onSuccess: (res) => {
      dispatch({ type: "stations/set", payload: res.data });
    },
  });
};

const info = ["driven", "product", "volume"];
  return (
    <>
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.commissionTo),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <AutocompleteField
            label={globalLang.order_uuid[lang]}
            sx={{ width: "30vw" }}
            placeholder={globalLang.order_uuid[lang]}
            isPending={CommissionToGetResponse.isPending}
            onOpen={getCommissionTo}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({
                    commissionTo: options.value,
                  });
                  break;
                case "clear":
                  setfilterdata({ commissionTo: "" });
              }
            }}
            data={[
              ...commissionStore.results.map((bf, index) => ({
                label: String(bf.order_uuid),
                value: bf.order_uuid,
              })),
            ]}
            value={filterdata.commissionTo}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: CommissionToPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: CommissionToPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ padding: "20px", gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.receiptnumber[lang]}
                placeholder={globalLang.receiptnumber[lang]}
                value={filterdata.commissionTo}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.Date[lang]}
                value={format(commissionToData.order_date)}
              />

              <InputField
                disabled
                label={globalLang.driver[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.driver[lang]}
                value={commissionToData.driver}
              ></InputField>
              <InputField
                disabled
                label={globalLang.transportation[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.transportation[lang]}
                value={commissionToData.transport}
              ></InputField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={globalLang.delivery_date[lang]}
                  value={controls.delivery_date}
                  onChange={(newValue) => {
                    setControl("delivery_date", newValue);
                  }}
                  error={Boolean(invalid.delivery_date)}
                  helperText={invalid.delivery_date}
                  required={required.includes("delivery_date")}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <InputField
              label={globalLang.delivery_uuid[lang]}
              placeholder={globalLang.delivery_uuid[lang]}
              value={controls.delivery_uuid}
              required={required.includes("delivery_uuid")}
              error={Boolean(invalid.delivery_uuid)}
              helperText={invalid.delivery_uuid}
              onChange={(e) => setControl("delivery_uuid", e.target.value)}
              // sx={{width:{xl:"48%",lg:"48%",md:"48%",sm:"100%",xs:"100%"},}}
            />
              <SelectField
                label={globalLang.thetype[lang]}
                placeholder={globalLang.thetype[lang]}
                renderValue={(selected) => {
                  return thetype.find((type) => type.value === selected).name;
                }}
                onChange={(e) => {
                  setControl("thetype", e.target.value);
                }}
                value={controls.thetype}
                required={required.includes("thetype")}
                error={Boolean(invalid.thetype)}
                helperText={invalid.thetype}
              >
                {thetype.map((thetype, index) => (
                  <MenuItem value={thetype.value} key={`${thetype.value} ${index}`}>
                    {thetype.name}
                  </MenuItem>
                ))}
              </SelectField>
              {controls.thetype === "T" &&
              
              <AutocompleteField
                        label={globalLang.traders[lang]}
                        sx={{
                          
                          width:"100%"
                            
                        }}
                        isPending={tradersGetResponse.isPending}
                        onOpen={getTraders}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(e, options, reason) => {
                          switch (reason) {
                            case "selectOption":
                              setfilterdataTr((old) => ({
                                ...old,
                                trader: options.value,
                              }));
                              break;
                            case "clear":
                              setfilterdataTr((old) => ({
                                ...old,
                                trader: "",
                              }));
                          }
                        }}
                        data={[
                          ...tradersStore?.results.map((c, index) => ({
                            label: c?.user?.first_name +""+c?.user?.last_name,
                            value: c.id,
                          })),
                        ]}
                        value={filterdataTr.trader}
                        // required={required.includes("Exit_Permission_Station_bill")}
                      />
      
            }
            
                {controls.thetype === "C"
                &&
                <AutocompleteField
                        label={globalLang.select_client[lang]}
                        sx={{
                          width: "100%"
                        }}
                        isPending={clientGetResponse.isPending}
                        onOpen={getClients}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(e, options, reason) => {
                          switch (reason) {
                            case "selectOption":
                              setfilterdataCl((old) => ({
                                ...old,
                                client: options.value,
                              }));
                              break;
                            case "clear":
                              setfilterdataCl((old) => ({
                                ...old,
                                client: "",
                              }));
                          }
                        }}
                        data={[
                          ...clientsStore?.results.map((c, index) => ({
                            label: c?.user.first_name + " " + c?.user?.last_name,
                            value: c.id,
                          })),
                        ]}
                        value={filterdataCl.client}
                        // required={required.includes("Exit_Permission_Station_bill")}
                      />
                 }
                  {controls.thetype==="S"&&
       <AutocompleteField
       label={globalLang.stations[lang]}
       sx={{
         width: "100%"
          
       }}
       isPending={stationsGetResponse.isPending}
       onOpen={getStations}
       isOptionEqualToValue={(option, value) =>
         option.value === value.value
       }
       onChange={(e, options, reason) => {
         switch (reason) {
           case "selectOption":
             setfilterdata((old) => ({ ...old, station: options.value }));
             break;
           case "clear":
             setfilterdata((old) => ({ ...old, station: "" }));
         }
       }}
       data={[
         ...stationsStore?.results.map((c, index) =>
    
            ({
         
          
           label: c?.user?.name,
           value: c.id,
           
         })
       
       ),
       ]}
       value={filterdata.station}
       // required={required.includes("Exit_Permission_Station_bill")}
     /> 
      }
            </Stack>

            <Box>
              <Stack
                sx={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  width:"100%"
                }}
                >
                  
                   <CustomTable 
                      data={commissionToData?.products} 
                      type="cylinder"
                      text={text}
                      columns={columns}
                      getFormData={getFormData}
                      info={info}
                    /> 
                    <CustomTable 
                      data={commissionToData?.products} 
                      type="dewar"
                      text={text}
                      columns={columns}
                      getFormData={getLiquidFormData}
                      info={info}
                    /> 
              </Stack>
            </Box>
          </Stack>
        </Form>
  
      )}

      {CommissionToPostResponse.successAlert}
      {CommissionToPostResponse.failAlert}
    </>
  );
};
export default CommissionFrom;

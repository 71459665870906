import React from "react";
import CounterReadings from "./CounterReading";
import ViewCounterReadings from "./ViewCounterReadings";
import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
function ViewCounterReadingsHistory() {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factorytankreadings']}>
        <CounterReadings />
      </PermissionsGate>
      <PermissionsGate permissions={['view_factorytankreadings']}>
        <ViewCounterReadings />
      </PermissionsGate>
    </Wrapper>
  );
}
export default ViewCounterReadingsHistory;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import Form, { InputField, NumberField, SelectField, TextareaField } from "../../../features/form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MenuItem } from "@mui/material";
import { FINANCEANDDEPOSITE } from "../../../data/APIs";
function FinanceAndDeposite(){
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
    const [responsebody, setResponse] = useState([]);
    ///////////////////////////////
    const dispatch = useDispatch();
    const [
      { controls, invalid, required },
      { setControl, resetControls, setInvalid, validate },
    ] = useControls([
      {
        control: "bill_uuid",
        value: "",
        isRequired: false,
      },
      {
        control: "paid",
        value: "",
        isRequired: false,
      },
      {
        control: "thetype",
        value: "",
        isRequired: false,
      },
      {
        control: "statement",
        value: "",
        isRequired: false,
      },
      {
        control: "date",
        value: new Date(),
        isRequired: false,
      },
    ]);
  
    const [FINANCEANDDEPOSITERequest, FINANCEANDDEPOSITEResponse] = useRequest({
      path: FINANCEANDDEPOSITE,
      method: "post",
      successMessage: "تم إضافة الفاتورة بنجاح",
    });
    
    // products data connection to api
    const thetype = [
      {name: "تمويل", value: "finance"},
      {name: "ايداع", value: "deposit"},
      
    ];
  
   
    const handleSubmit = (e) => {
      e.preventDefault();
      
      validate().then((data) => {
        console.log(data);
        if (!data.isOk) return
       
        const requestBody = filter({
          obj: {
            bill_date: controls.date.toISOString(),
            bill_uuid: controls.bill_uuid,
            paid: controls.paid,
            statement:controls.statement,
            category:controls.thetype
          },
          output: "object",
        });
        FINANCEANDDEPOSITERequest({
          body: requestBody,
          onSuccess: (res) => {
            resetControls();
            dispatch({type:"finance_deposite/addItem", payload:res.data})
          },
        }).then((res) => {
          const response = res?.response?.data;
          const responseBody = filter({
            obj: {
              id: res?.id,
              bill_date: res?.date,
              bill_uuid: res?.bill_uuid,
              paid: res?.paid,
              statement:res?.statement,
            category:res?.category,
            },
          });
          
          if (res?.id) {
          } else {
            setInvalid(res?.message);
          }
        });
       
      });
    };
  
    return (
      <>
        <Form
           component="form"
           onSubmit={handleSubmit}
           childrenProps={{
             saveBtn: {
               disabled: FINANCEANDDEPOSITEResponse.isPending,
             },
             closeBtn: {
               disabled: FINANCEANDDEPOSITEResponse.isPending,
             },
           }}
        >
            <NumberField
                label={globalLang.bill_uuid[lang]}
                placeholder={globalLang.bill_uuid[lang]}
                onChange={(e) => setControl("bill_uuid", e.target.value)}
                value={controls.bill_uuid}
                required={required.includes("bill_uuid")}
                error={Boolean(invalid.bill_uuid)}
                helperText={invalid.bill_uuid}
              ></NumberField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={globalLang.DateAdded[lang]}
                  value={controls.date}
                  
                  onChange={
                    (newValue) => {
                    // const new_date = newValue.toISOString()
                    // `${newValue["$y"]}-${
                    //   parseInt(newValue["$M"]) + 1
                    // }-${newValue["$D"]}`;
        
                    setControl("date", newValue);
                  }}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <SelectField
                label={globalLang.thetype[lang]}
                placeholder={globalLang.thetype[lang]}
                renderValue={(selected) => {
                  return thetype.find((type) => type.value === selected).name;
                }}
                onChange={(e) => {
                  setControl("thetype", e.target.value);
                }}
                value={controls.thetype}
                required={required.includes("thetype")}
                error={Boolean(invalid.thetype)}
                helperText={invalid.thetype}
              >
                {thetype.map((thetype, index) => (
                  <MenuItem value={thetype.value} key={`${thetype.value} ${index}`}>
                    {thetype.name}
                  </MenuItem>
                ))}
              </SelectField>
                  
              <NumberField
                label={globalLang.thevalue[lang]}
                placeholder={globalLang.thevalue[lang]}
                onChange={(e) => setControl("paid", e.target.value)}
                value={controls.paid}
                required={required.includes("paid")}
                error={Boolean(invalid.paid)}
                helperText={invalid.paid}
              ></NumberField>
              {/* add empty and full input field ,popper for outputs */}
              <TextareaField
                label={globalLang.statement[lang]}
                placeholder={globalLang.statement[lang]}
                onChange={(e) => setControl("statement", e.target.value)}
                value={controls.statement}
                required={required.includes("statement")}
                error={Boolean(invalid.statement)}
                helperText={invalid.statement}
              ></TextareaField>
        </Form>
       
        {FINANCEANDDEPOSITEResponse.successAlert}
        {FINANCEANDDEPOSITEResponse.failAlert}
      </>
    );
  };
export default FinanceAndDeposite
import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import ExitFilling from "./ExitFilling";
import ViewExitFilling from "./ViewExitFilling";
const ExitFillingView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorytraderpermit"]}>
      <ExitFilling />
      </PermissionsGate>
        
     
      <PermissionsGate permissions={["view_factorytraderpermit"]}>
        <ViewExitFilling />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ExitFillingView;

import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";


import MediationToView from "./CommissionFromView";
import CommissionTo from "./CommissionTo";
import CommissionToView from "./CommissionToView";
const ViewCommissionTo = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factoryhomeawaypermit']}>
        <CommissionTo />
      </PermissionsGate>

      <PermissionsGate permissions={['view_factoryhomeawaypermit']}>
        <CommissionToView />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ViewCommissionTo;

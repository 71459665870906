import React, { useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Form, {
  InputField,
  MultiSelectField,
  MultiSelectItem,
  NumberField,
  PhoneField,
  SelectField,
} from "../../../features/form";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  CHANNELS,
  CLIENTAWAYBILL,
  CLIENTS,
  EMPLOYEES,
  PRODUCTS,
  STATIONEXITBILL,
  STATIONS,
  STOCK,
  TRADERENTERBILL,
  TRADERS,
  TRANSPORT,
} from "../../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Stack, Box, Typography, Tooltip, Grow, Snackbar, Alert, Fade } from "@mui/material";
import filter from "../../../utils/ClearNull";
import ReceiptTable from "../../../components/ReceiptTable";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
function GrowTransition(props) {
  return <Grow {...props} />;
}
const EnterFilling = () => {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday
  }
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [filterdataTr, setfilterdataTr] = useState({
    trader: "",
    Date: getDate(),
  });
  const [filterdataCl, setfilterdataCl] = useState({
    client: "",
    Date: getDate(),
  });
  const addshipreceiptLang = useSelector(
    (state) => state.lang.value.addshippingreceipt
  );
  const [success, setSuccess] = useState(false)
  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
  });
  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  const globalLang = useSelector((state) => state.lang.value.global);

  const [rows, setrows] = useState([
    {
      product: "",
      quantityin: "",
      volume: "",
    },
  ]);
  const columns = [
    {
      name: "enter_empty",
      value: "enter_empty",
    },
    {
      name: "enter_filled",
      value: "enter_filled",
    },
    {
      name: "product",
      value: "product",
    },
    {
      name: "volume",
      value: "volume",
    },
    {
      name: "pressure",
      value: "pressure",
    },
  ];
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (gas) => {
    setFormData([...gas]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };
  //////////////////////////////////
  //----store----

  const clientsStore = useSelector((state) => state.clients.value);
  const tradersStore = useSelector((state) => state.traders.value);
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "number",
      value: "",
      isRequired: true,
    },
    {
      control: "transport",
      value: "",
      isRequired: false,
    },
    {
      control: "driver",
      value: "",
      isRequired: false,
    },
    {
      control: "trader",
      value: "",
      isRequired: false,
    },
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "permit_type",
      value: "",
      isRequired: true,
    },

    {
      control: "enter_date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "bills",
      value: [],
      isRequired: true,
    },
  ]);




  //----request hooks----

  const [TraderEnterBillPostRequest, TraderEnterBillPostResponse] = useRequest({
    path: TRADERENTERBILL,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const [clientsGetRequest, clientsGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });
  //----functions----

  const gettraders = () => {
    tradersGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };
  const getClients = () => {
    clientsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  const [billsErrors, setBillsErrors] = useState("");
  const handleSubmit = () => {
    let data = [...FormData.filter((object) => Object.keys(object).length !== 0),
    
    ...LiquidFormData.filter(
      (object) => Object.keys(object).length !== 0
    )]
    console.log(data)
    if (data.length) {
      validate().then((output) => {
        console.log(output);
        if (!output.isOk) return;
        const requestBody = filter({
          obj: {
            enter_permit_uuid: parseInt(controls.number),
            enter_date: controls.enter_date.toISOString(),
            driver: controls.driver,
            trader: controls.trader,
            transport: controls.transport,
            permit_type: controls.permit_type,
            bills: [
              ...FormData.filter((object) => Object.keys(object).length !== 0),
              ...LiquidFormData.filter(
                (object) => Object.keys(object).length !== 0
              ),
            ],
          },
          output: "object",
        });
        if (
          data.filter((bill) => !bill.product || !bill.volume || bill.enter_empty==null || bill.enter_filled==null)
            .length !== 0
        ) {
          setBillsErrors(" برجاء إدخال بيانات فاتورة صحيحة");
          setState({
            open: true,
            GrowTransition,
          });
          return;
        }
        TraderEnterBillPostRequest({
          body: requestBody,
          onSuccess: () => {
            setSuccess(true)
            resetControls();
          },
        }).then((res) => {
          let response = res?.response?.data;
  
          if (res?.id) {
            dispatch({
              type: "trader_enter_bill/addItem",
              payload: res,
            });
          } else {
  
            setInvalid(res);
          }
        });
      });
    
  
    }
    else{
      {
        setBillsErrors(" برجاء إدخال منتج واحد على الأقل");
        setState({
          open: true,
          GrowTransition,
        });
        return;
      }
    }

  };
  const permit_type=[{name:"تعبئة",value:"fill"},
{name:"استبدال",value:"replace"}]
  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.fillreceipt[lang],
          globalLang.Enter_Permission[lang],
          globalLang.add[lang],
        ]}
      /> */}
      <Form
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: TraderEnterBillPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: TraderEnterBillPostResponse.isPending,
          },
        }}
      >
        <Stack
          sx={{ gap: 3, alignItems: "flex-start", justifyContent: "start" }}
        >
          <Stack
            sx={{
              gap: 2,
              width:"100%",
              flexWrap:"wrap",
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              padding: "10px",
              "& .MuiInput-input": {
                minWidth: "16px",
                width: "100%",
                
              },
            }}
          >
            <NumberField
              label={globalLang.numberperm[lang]}
              placeholder={globalLang.numberperm[lang]}
              onChange={(e) => setControl("number", e.target.value)}
              value={controls.number}
              required={required.includes("number")}
              error={Boolean(invalid?.number)}
              helperText={invalid?.number}
              sx={{width: {
                xl: "24%",
              lg: "24%",
              md: "48%",
              sm: "100%",
              xs: "100%",
            },}}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={globalLang.enter_date[lang]}
                value={controls.enter_date}
                sx={{width: {
                  xl: "24%",
                lg: "24%",
                md: "48%",
                sm: "100%",
                xs: "100%",
              },}}
                onChange={(newValue) => {
                  setControl("enter_date", newValue);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>

            <InputField
              label={globalLang.driver[lang]}
              placeholder={globalLang.driver[lang]}
              onChange={(e) => setControl("driver", e.target.value)}
              value={controls.driver}
              required={required.includes("driver")}
              error={Boolean(invalid?.driver)}
              helperText={invalid?.driver}
              sx={{width: {
                xl: "24%",
              lg: "24%",
              md: "48%",
              sm: "100%",
              xs: "100%",
            },}}
            ></InputField>

            <InputField
              label={globalLang.transportation[lang]}
              placeholder={globalLang.transportation[lang]}
              onChange={(e) => setControl("transport", e.target.value)}
              value={controls.transport}
              required={required.includes("transport")}
              error={Boolean(invalid?.transport)}
              helperText={invalid?.transport}
              sx={{width: {
                xl: "24%",
              lg: "24%",
              md: "48%",
              sm: "100%",
              xs: "100%",
            },}}
            ></InputField>
            <SelectField
          label={globalLang.thetype[lang]}
          placeholder={globalLang.thetype[lang]}
          sx={{width: {
            xl: "24%",
          lg: "24%",
          md: "48%",
          sm: "100%",
          xs: "100%",
        },}}
          renderValue={(selected) => {
            return permit_type.find((type) => type.value === selected).name;
          }}
          onChange={(e) => {
            setControl("permit_type", e.target.value);
          }}
          value={controls.product_type}
          required={required.includes("permit_type")}
          error={Boolean(invalid.permit_type)}
          helperText={invalid.product_type}
        >
          {permit_type.map((permit, index) => (
            <MenuItem value={permit.value} key={`${permit.name} ${index}`}>
              {permit.name}
            </MenuItem>
          ))}
        </SelectField>
        {controls.permit_type ==="fill"?(
          
           <AutocompleteField
           label={globalLang.traders[lang]}
           sx={{width: {
            xl: "24%",
          lg: "24%",
          md: "48%",
          sm: "100%",
          xs: "100%",
        },}}
           isPending={tradersGetResponse.isPending}
           onOpen={gettraders}
           isOptionEqualToValue={(option, value) =>
             option.value === value.value
           }
           onChange={(e, options, reason) => {
             switch (reason) {
               case "selectOption":
                 setfilterdataTr((old) => ({
                   ...old,
                   trader: options.value,
                 }));
                 break;
               case "clear":
                 setfilterdataTr((old) => ({
                   ...old,
                   trader: "",
                 }));
             }
           }}
           data={[
             ...tradersStore?.results.map((c, index) => ({
               label: c?.user?.first_name +""+c?.user?.last_name,
               value: c.id,
             })),
           ]}
           value={filterdataTr.trader}
           // required={required.includes("Exit_Permission_Station_bill")}
         />
        
            ):controls.permit_type==="replace"&&(
              <AutocompleteField
              label={globalLang.select_client[lang]}
              sx={{width: {
                xl: "24%",
              lg: "24%",
              md: "48%",
              sm: "100%",
              xs: "100%",
            },}}
              isPending={clientsGetResponse.isPending}
              onOpen={getClients}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(e, options, reason) => {
                switch (reason) {
                  case "selectOption":
                    setfilterdataCl((old) => ({
                      ...old,
                      client: options.value,
                    }));
                    break;
                  case "clear":
                    setfilterdataCl((old) => ({
                      ...old,
                      client: "",
                    }));
                }
              }}
              data={[
                ...clientsStore?.results.map((c, index) => ({
                  label: c?.user.first_name + " " + c?.user?.last_name,
                  value: c.id,
                })),
              ]}
              value={filterdataCl.client}
              // required={required.includes("Exit_Permission_Station_bill")}
            />
            )}
          </Stack>

          <Box>
            <Stack
              sx={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
              }}
            >
              <ReceiptTable
                type="cylinder"
                columns={columns}
                rows={rows}
                getformData={getFormData}
                success={success}
                placeholder="120"
              />
              <ReceiptTable
                type="dewar"
                columns={columns.filter((column)=>column.name!=="pressure")}
                rows={rows}
                getformData={getLiquidFormData}
                success={success}
                placeholder="120"
              />

            </Stack>
          </Box>
        </Stack>
      </Form>
      {billsErrors && (
        <Snackbar
          open={state.open}
          onClose={handleClose}
          TransitionComponent={state.Transition}
          key={state?.Transition?.name}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity="error" variant="filled">
            {billsErrors}
          </Alert>
        </Snackbar>
      )}
      {TraderEnterBillPostResponse.successAlert}
      {TraderEnterBillPostResponse.failAlert}
    </>
  );
};

export default EnterFilling;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const PermissionsGate = ({ children, permissions, renderAuthFailed }) => {
  const userInfo = useSelector((state) => state.userInfo.value);

  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  console.log(permissions)
  const isPermitted =
    Boolean(
     
      permissions?.some((permission) => userPermissions?.includes(permission))
    ) || !Boolean(permissions?.length);
   
  return <>{isPermitted ? children : renderAuthFailed ?? <></>}</>;
};

export default PermissionsGate;

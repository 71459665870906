import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { InputField, SelectField } from "../../features/form";
import {
  Box,
  Button,
  Card,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import useRequest from "../../hooks/useRequest";
import { SINGLE_TANK, TANK } from "../../data/APIs";
import useControls from "../../hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import { DialogButton, DialogButtonsGroup } from "../../features/dialog";

function TankByID() {
  const lang = useSelector((state) => state.lang.value.lang);
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const tankStore = useSelector((state) => state.tank.value);
  const [viewTank, setViewTank] = useState({});
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };
  const getEndDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "tanknumber",
      value: "",
      isRequired: true,
    },
    {
      control: "date",
      value: getDate(),
      isRequired: true,
    },
    {
      control: "endDate",
      value: getEndDate(),
      isRequired: true,
    },
  ]);
  const urlParams = new URLSearchParams();
  const [tankgetRequest, tankgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const getTanks = () => {
    tankgetRequest({
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };
  const [ViewtankgetRequest, ViewtankgetResponse] = useRequest({
    path: SINGLE_TANK,
    method: "get",
  });

  const handleView = () => {
    // if (Boolean(factorySafeStore.results.length)) return;
    if (controls.tanknumber) {
      urlParams.append("start", controls.date);
      urlParams.append("end", controls.endDate);
    
    ViewtankgetRequest({
      id: controls.tanknumber + "/filled",
      params: urlParams,

      onSuccess: (res) => {
        dispatch({ type: "tank/addItem", payload: { id: res.data.id } });
        setViewTank(res.data);
        console.log(viewTank);
      },
    });
  }
  };
  const [requestParams, setRequestParams] = useState({});

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    handleView();
  }, []);

  return (
    <>
      <Card
      >
        <Box
            sx={{
              maxWidth: "100%",
              display: "flex",
              flexDirection:{
                  xl:"row",
                  lg:"row",
                  md:"row",
                  sm:"column",
                  sx:"column"
              },
              // justifyContent: {xl:"space-between",lg:"space-between",md:"center",sm:"center",sx:"center"},
              flexWrap:"wrap",
              m: "auto",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.periodFrom[lang]}
              value={controls.date}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("date", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "40%",
                      lg: "40%",
                      md: "90%",
                      sm: "90%",
                      sx: "90%",
                    },
                    m: 2,
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.to[lang]}
              value={controls.endDate}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("endDate", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "40%",
                      lg: "40%",
                      md: "90%",
                      sm: "90%",
                      sx: "90%",
                    },
                    m: 2,
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <SelectField
            label={globalLang.tanknumber[lang]}
            placeholder={globalLang.tanknumber[lang]}
            renderValue={(selected) => {
              return tankStore?.results.find((tank) => tank?.id === selected)
                ?.tank_uuid;
            }}
            isPending={tankgetResponse?.isPending}
            onOpen={getTanks}
            onChange={(e) => {
              setControl("tanknumber", e.target.value);
            }}
            value={controls.tanknumber}
            required={required.includes("tanknumber")}
            error={Boolean(invalid.tanknumber)}
            helperText={invalid.tanknumber}
            sx={{
              width: {
                xl: "40%",
                lg: "40%",
                md: "90%",
                sm: "90%",
                sx: "90%",
              },
              m: 2,
            }}
          >
            {tankStore?.results?.map((tank, index) => (
              <MenuItem value={tank?.id} key={`${tank?.name} ${index}`}>
                {tank?.tank_uuid}
              </MenuItem>
            ))}
          </SelectField>
         
          <Button
             sx={{
              backgroundColor: "#233975",
              color:"white",
              maxWidth: "150px",
              width: "100vmax",
              height: "50px",
              fontWeight: "bold",
              m: 3,
              ":hover":{
                backgroundColor: "#233975",
              }
              }} 
              onClick={handleView}
              disabled={ViewtankgetRequest.isPending}
            >
              {globalLang.view[lang]}
            </Button>
          
            
          
        </Box>
        {"filled_number" in viewTank && (
        <Table
          sx={{
            overflowX:"scroll",
            justifyContent: "center",
            p: 1,
            ".MuiTableRow-head": {
              padding: 2,
              textAlign: "center",
            },
            ".MuiTableCell-head": {
              fontWeight: "600",
              color: "gray",
              textAlign: "center",
              fontSize: "11px",
            },
            ".MuiTableCell-body": {
              textAlign: "center",
              p: 3,
              ":hover": {
                backgroundColor: "#f8f9fa",
                cursor: "pointer",
              },
            },
          }}
        >
          <TableHead>
            
            <TableCell>{globalLang.filled_number[lang]}</TableCell>
          </TableHead>
          <TableBody>
            
            <TableCell>{viewTank?.filled_number}</TableCell>
            
          </TableBody>
        </Table>
         )}
      </Card>
    </>
  );
}
export default TankByID;

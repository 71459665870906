import {
  Box,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { InputField } from "../features/form";
import useControls from "../hooks/useControls";
import balancePic from "../assets/balancePic.gif"
import expencePic from '../assets/money-management.gif'

import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {  FACTORY_Safe_statics, ProductsGas, Total_production } from "../data/APIs";
import useRequest from "../hooks/useRequest";
import Image from "mui-image";
import { isPending } from "@reduxjs/toolkit";
import { renderActiveShape } from "../features/form/components/Progressbar";


const TypographyStyle = {
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  color: "#020024",
  lineHeight: "20px",
  pt: "34px",
  px: "32px",
};
const CardStyle = {
  mx: "32px",
  borderRadius: "10px",
  my: "24px",
  boxShadow: "0px 0px 10px 0px #20506929",
  backdropFilter: blur("50px"),
  backgroundColor: "#f8f8f8",
};
const displayFlex = {
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  flexDirection: {
    xl: "row",
    lg: "row",
    md: "column",
    sm: "column",
    xs: "column",
  },
};

function Home() {

  const data = [
    {
      name: "Page A",
      uv: 4000,
       pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
       pv: 1398,
       amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
     pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
       pv: 4800,
       amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page H",
      uv: 3490,
      pv: 4300,
      // amt: 2100,
    },
    {
      name: "Page I",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page J",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page M",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page V",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
  const dataProgress = [
    { name: '300', value: 10 },]
  const dataProgressClient = [
    { name: '150', value: 10 },]
  const dataProgressStation = [
    { name: '20', value: 10 },]


  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const globalLang = useSelector((state) => state.lang.value.global);
  const ReceiptOverView = useSelector(
    (state) => state.lang.value.ReceiptOverView
  );

  const lang = useSelector((state) => state.lang.value.lang);
  const stocksStore = useSelector((state) => state.stocks.value);
  const [balanceData, setBalanceData] = useState({});
   const [gasData, setGasData] = useState([{}]);
   const [totalProduction, setTotalProduction] = useState([{}]);

  const gettDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}`;
  };
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "Date",
      value: gettDate(),
      isRequired: true,
    },
  ]);

  const urlParams = new URLSearchParams();

  const [safeFactoryGetRequest, safeFactoryGetResponse] = useRequest({
    path: FACTORY_Safe_statics,
    method: "get",
  });
  const getFactorySafe = () => {
    
    if (controls.Date) {
      urlParams.append("date", controls.Date);
    }
    safeFactoryGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "factorySafe/set", payload: res.data });
        setBalanceData(res.data);
       
      },
    });
  };
  const [totalProductionGetRequest, totalProductionGetResponse] = useRequest({
    path: Total_production,
    method: "get",
  });
  const getTotalProduction = () => {
    
    if (controls.Date) {
      urlParams.append("date", controls.Date);
    }
    totalProductionGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "factorySafe/set", payload: res.data });
        setTotalProduction(res.data);
       
      },
    });
  };
  
  const [stocksGetRequest, stocksGetResponse] = useRequest({
    path: ProductsGas,
    method: "get",
  });
  const getStocks = () => {
    stocksGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        resetControls();
       
       setGasData(res.data)
        dispatch({ type: "products/set", payload: res.data });
   
      },
    });
  
  };
  const [requestParams, setRequestParams] = useState({});

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );
   
    getStocks()
    getFactorySafe();
    getTotalProduction()
  }, [controls.Date]);
  // useEffect(()=>{
  //   console.log(stocksStore.results)
  //   // stocksStore.results.map((stock)=>{
  //   //   console.log(stock)
  //   //   const obj=Object.assign({},...stock)
  //   //   console.log(obj)
  //   //   setGasData([obj])
  //   // })
  //   const obj=stocksStore.reduce(((r, c) => Object.assign(r, c)), {})
  //   console.log(obj)
  //   setGasData([obj])
  // },[stocksStore])
  
  // const dat=[...gasData,{}]
  // gasData=dat
  
  return (
    <>
      <Box
        sx={{
          mt: "32px",
          mx: "32px",
          // width: "100%",
          display: {
            xl: "flex",
            lg: "flex",
            md: "block",
            sm: "block",
            xs: "block",
          },
          justifyContent: "space-between",
        }}
      >
        {/* gas diagram */}
        <Box
          sx={{
            height: "437px",
            width: { xl: "52%", lg: "52%", md: "100%", sm: "100%", xs: "100%" },
            borderRadius: "10px",
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px 0px #20506929",
            backdropFilter: blur("50px"),
            // ml: "24px",
            mb: {
              xl: 0,
              lg: 0,
              md: "24px",
              sm: "24px",
              xs: "24px",
            },
          }}
        >
          <Typography sx={TypographyStyle}>
            {globalLang.products[lang]}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#6A6D70",
              lineHeight: "29.98px",
              pl: "32px",
            }}
          >
            {globalLang.gasNumber[lang]}
          </Typography>

          <ResponsiveContainer width="100%" height="80%">
            <BarChart isPending={stocksGetResponse.isPending}  layout="vertical" width={100} barGap={10} height={40} data={[gasData]} margin={{ top: 22, right: 32, left: 32, bottom: 22 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis  type="number" padding={{right:10}} tick={{stroke: '#6A6D70', strokeWidth: 0}}/>
              <YAxis dataKey=" " type="category" hide="true"  />
              <Tooltip />
              <Legend />
              {Object.keys(gasData).map((key)=>(
                key.includes('color') &&
                <Bar dataKey={key.split('_')[0]} fill={gasData[key]} legendType="circle" barSize={20.36} 
                cursor={"pointer"} isAnimationActive={true} animationDuration={2000} unit={" Tube"} /> 
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Box>
        {/* factorySafe balance */}
        <Box
          sx={{
            width: { xl: "48%", lg: "48%", md: "100%", sm: "100%", xs: "100%" },
            m: "auto",
            ml: { xl: "32px", lg: "32px", md: "none", sm: "none", xs: "none" },
            displayFlex,
            
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "210px",
              backgroundColor: "#fff",
              boxShadow: "0px 0px 10px 0px #20506929",
              backdropFilter: blur("50px"),
              borderRadius: "10px",
              mx: { md: "auto", sm: "auto", xs: "auto" },
              mb: "17px",
            }}
          >
            <Box sx={{ p: "32px", display: "flex",
              justifyContent: "space-between", }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "#020024",
                    lineHeight: "33.73px",
                    pt: 4,
                  }}
                >
                  {globalLang.Revenues[lang]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "#020024",
                    lineHeight: "44.98px",
                    pt: 1,
                  }}
                >
                  {balanceData.total_income}EGP
                </Typography>
              </Box>

              <Box sx={{pr:{xl:"58px",lg:"58px",md:"58px",sm:"30px",xs:"30"}
            ,pt:"58px"}}>
              <Image height={"94px"} width={"92px"} src={balancePic} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              minHeight: "210px",
              backgroundColor: "#fff",
              boxShadow: "0px 0px 10px 0px #20506929",
              backdropFilter: "blur(50px)",
              borderRadius: "10px",
              // pl: 5,
              mx: { md: "auto", sm: "auto", xs: "auto" },
              display: "flex",
              justifyContent: "space-between",
              pr: "18px"

            }}
          >
            <Box sx={{ pl: "32px" ,pt:"32px"}} >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#020024",
                  lineHeight: "33.73px",
                  pt: 4,
                }}
              >
                {globalLang.expenses_safe[lang]}
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#020024",
                  lineHeight: "44.98px",
                  letterSpacing: "-0.408px",
                  pt: 1,
                }}
              >
                {balanceData.total_outcome}EGP
              </Typography>
              
            </Box>
            <Box sx={{pr:{xl:"58px",lg:"58px",md:"58px",sm:"30px",xs:"30"}
            ,pt:"58px"
          }}
      >
       <Image src={expencePic} height={"94px"} width={"92px"}/>
      </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "375px",
          // width: "100%",
          mx: "32px",
          borderRadius: "10px",
          my: "24px",
          boxShadow: "0px 0px 10px 0px #20506929",
          backdropFilter: blur("50px"),
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", px: "24px" }}
        >
          <Typography sx={TypographyStyle}>
            {globalLang.total_production[lang]}
            <ErrorOutlineIcon
              color="#D0D1D2"
              height="15px"
              width="15px"
              border="1.5px solid #D0D1D2"
            />
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //   label={globalLang.periodFrom[lang]}
              value={controls.Date}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("startDate", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "20%",
                      lg: "20%",
                      md: "40%",
                      sm: "40%",
                      sx: "40%",
                    },
                    boxSizing: "border-box",
                    height: "30px",
                    mt: "24px",
                    "& .MuiInputBase-root": {
                      borderColor: "##FFFFFF",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box sx={{ mx: "auto" }}>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              data={data}
              margin={{ top: 22, right: 32, left: 32, bottom: 22 }}
              // isPending={totalProductionGetResponse}
            >
              <defs>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ECE9F1" stopOpacity={0.8} />
                 
                </linearGradient>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#422F8A" stopOpacity={0.8} />
                
                </linearGradient>
              </defs>
              <XAxis dataKey="name" />
              <YAxis textAnchor="3" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip cursor={{ stroke: "#422F8A", strokeWidth: 2 }} />
              <Area
                type="natural"
                dataKey="uv"
                stroke="#422F8A"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorUv)"
                legendType="circle"
                
              />
              <Area
                type="natural"
                dataKey="pv"
                stroke="#422F8A"
                fillOpacity={1}
                strokeWidth={2}
                fill="url(#colorPv)"
                legendType="circle"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      {/*client,trader,station */}
        <Box 
        sx={{
          maxWidth:"100%",
          display: "flex",
          
          flexWrap: "wrap",
          justifyContent: "space-between",
          flexDirection: {
            xl: "column",
            lg: "column",
            md: "row",
            sm: "row",
            xs: "row",
          },
          height: "360px",
          mx: "32px",
        }}
      >
        <Box
          sx={{
            height: "360px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px #20506929",
            backdropFilter: blur("50px"),
            width: { xl: "32%", lg: "32%", md: "100%", sm: "100%", xs: "100%" },
            backgroundColor:"#fff"
          }}
        >
          <Box ><Typography sx={TypographyStyle}>
            {globalLang.tradertimeline[lang]}
          </Typography></Box>
          <ResponsiveContainer  width="100%" height="100%">
        <PieChart width={100} height={100}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataProgress}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#F39034"
            dataKey="value"
            onMouseEnter={onPieEnter}
           startAngle={20}
           endAngle={360}
            
            
            isAnimationActive={true}
            animationDuration={2000}
          />
        </PieChart>
      </ResponsiveContainer>
          
        </Box>
        <Box
          sx={{
            height: "360px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px #20506929",
            backdropFilter: blur("50px"),
            mx: { xl: "24px", lg: "24px", md: 0, sm: 0, xs: 0 },
            my: { xl: 0, lg: 0, md: "24px", sm: "24px", xs: "24px" },
            width: { xl: "32%", lg: "32%", md: "100%", sm: "100%", xs: "100%" },
            backgroundColor:"#fff"
          }}
        >
          <Typography sx={TypographyStyle}>
            {globalLang.clinttimeline[lang]}
          </Typography>
          <ResponsiveContainer  width="100%" height="100%">
        <PieChart width={100} height={100}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataProgressClient}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#003AD2"
            dataKey="value"
            onMouseEnter={onPieEnter}
           startAngle={20}
           endAngle={360}
            
            
            isAnimationActive={true}
            animationDuration={2000}
          />
        </PieChart>
      </ResponsiveContainer>
        </Box>
        <Box
          sx={{
            height: "360px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px #20506929",
            backdropFilter: blur("50px"),
            width: { xl: "32%", lg: "32%", md: "100%", sm: "100%", xs: "100%" },
            backgroundColor:"#fff"
          }}
        >
          <Typography sx={TypographyStyle}>
            {globalLang.stationtimeline[lang]}
          </Typography>
          <ResponsiveContainer  width="100%" height="100%">
        <PieChart width={100} height={100}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={dataProgressStation}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#00A843"
            dataKey="value"
            onMouseEnter={onPieEnter}
           startAngle={20}
           endAngle={360}
            
            
            isAnimationActive={true}
            animationDuration={2000}
          />
        </PieChart>
      </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
}
export default Home;

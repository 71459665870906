import React, { useRef } from "react";

import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import AddMerchant from "./AddMerchant";

import ViewMerchants from "./ViewMerchants";

const MerchantsView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorytrader"]}>
        <AddMerchant />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factorytrader"]}>
        <ViewMerchants />
      </PermissionsGate>
    </Wrapper>
  );
};
export default MerchantsView;

import { Box } from "@mui/material";

import React from "react";
import ReceiptBox from "../../components/ReceiptBox";
import addEmployee from "../../assets/addEmployee.png";
import addJob from "../../assets/addJob.png";
import addGas from "../../assets/addGas.png";
import addBalance from "../../assets/addBalance.png"
import addTank from "../../assets/addTank.png"
import addExpenses from "../../assets/addExpenses.png"
import addTransport from "../../assets/addTransport.png"
import { useSelector } from "react-redux";
const MainControls = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const boxes = [
    {
      image: addEmployee,
      title: "إضافة موظف",
      index: 0,
      perm: ["add_factoryemployee"],
      type: "adds",
    },
    {
      image: addJob,
      title: "إضافة وظيفة",
      index: 1,
      perm: ["add_factoryjob"],
      type: "adds",
    },
    {
      image: addGas,
      title: "إضافة غاز",
      index: 2,
      perm: ["add_factoryproduct", "view_factoryproduct"],
      type: "adds",
    },
    {
      image: addBalance,
      title: "إضافة قوة المصنع",
      index: 3,
      perm: ["add_factorypower"],
      type: "adds",
    },
        {
      image: addTank,
      title: "إضافة تانك",
      index: 4,
      perm: ["add_factorytank"],
      type: "adds",
    },
    {
      image: addExpenses,
      title: "إضافة مصروف",
      index: 5,
      perm: ["add_factorystock","view_factoryexpense"],
      type: "adds",
    },
    {
      image: addTransport,
      title: "إضافة نقل",
      index: 6,
      perm: ["add_factorytransport", "view_factorytransport"],
      type: "adds",
    },
  ];

  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default MainControls;

import React from "react";
import AddStock from "./AddStock";
import PoweFactory from "./PowerFactory";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import Wrapper from "../../components/Wrapper";
function ViewPowerFactory() {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorypower"]}>
        <AddStock />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factorypower"]}>
        <PoweFactory />
      </PermissionsGate>
    </Wrapper>
  );
}
export default ViewPowerFactory;

import { createSlice } from "@reduxjs/toolkit";

export const TraderOverviewSlice = createSlice({
  name: "trader-overview",
  initialState: {
    value: {},
  },
  reducers: {
    set: (state, action) => {
      state.value = action.payload;
    },
  },
});

export default TraderOverviewSlice.reducer;

import React, { useEffect, useState } from "react";

import AutocompleteField from "../features/form/components/AutocompleteField";

const FilterForm = ({ data, getData, isPending, label, getFormData }) => {

  const [filterdata, setfilterdata] = useState({
    bill: "",
  });
  useEffect(() => {
    getFormData(filterdata.bill)
  }, [filterdata.bill])

  return (
    <AutocompleteField
      label={label}
      sx={{ width: "30vw" }}
      placeholder={label}
      isPending={isPending}
      onOpen={getData}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(e, options, reason) => {
        switch (reason) {
          case "selectOption":
            setfilterdata({
              bill: options.value,
            });
            break;
          case "clear":
            setfilterdata({ bill: "" });
        }
      }}
      data={[
        ...data.results.map((bf, index) => ({
          label: String(bf.permit_uuid),
          value: bf.permit_uuid,
        })),
      ]}
      value={filterdata.bill}

    />

  );
};
export default FilterForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIEW_COUNTER_READINGS } from "../../data/APIs";
import useControls from "../../hooks/useControls";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import useRequest from "../../hooks/useRequest";
import useAfterEffect from "../../hooks/useAfterEffect";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { InputField } from "../../features/form";
import format from "../../utils/ISOToReadable";

function ViewCounterReadings() {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const counterReadingsStore = useSelector((state) => state.counterReadings.value);
//   const stocksStore = useSelector((state) => state.stocks.value);
//   const [filterdata, setfilterdata] = useState({
//     Date: new Date(),
//   });
  const [counterDetails, setCounterDetails] = useState({
    details: null,
    tab: "details",
  });
  console.log(counterReadingsStore)

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();

  const columns = [
    {
        field: "date",
        headerName: globalLang.Date[lang],
        customContent: ({ date }) =>
      date ? format(date):"-"
      },
      {
        field: "tank_uuid",
        headerName: globalLang.tank_uuid[lang],
        customContent: ({ tank_uuid }) => (tank_uuid ? tank_uuid : "-"),
      },
      {
        field: "reading",
        headerName: globalLang.counter_Reading[lang],
        customContent: ({ reading }) =>
        reading === 0 ? "0" : reading ? reading : "-",
      },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
      customContent: ({ volume }) => ( 
        volume === 0 ? "0" : volume ? volume : "-"),
    },
    {
      field: "tank",
      headerName: globalLang.tank[lang],
      customContent: ({ tank }) =>
      tank === 0 ? "0" : tank ? tank : "-",
    },
  ];
  const [CounterReadingsGetRequest, CounterReadingsGetResponse] = useRequest({
    path: VIEW_COUNTER_READINGS,
    method: "get",
  });

  

  const [{ controls }, { setControl }] = useControls([
    {
      control: "date",
      value: new Date(),
      isRequired: false,
    },
  ]);

  const getCounterReadings = () => {
    // filterdata.stock && urlParams.append("id", filterdata.stock);
    // if (controls.date) {
    //   urlParams.append("date", controls.date);
    // }
    CounterReadingsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "counterreadings/set", payload:res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getCounterReadings();
  }, [requestParams, controls.date]);

  useAfterEffect(() => {
    if (!counterDetails.details) return;
    setCounterDetails((old) => ({
      ...old,
      details: counterReadingsStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [counterReadingsStore]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };

  // const getStockDetails = () => {
  //   PowerFactoryGetRequest({
  //     id: counterDetails.details.id,

  //     onSuccess: (res) => {
  //       dispatch({ type: "stock_summary/set", payload: res.data });
  //       console.log(res.data);
  //     },
  //   });
  // };
  // const handleCloseDetailsDialog = () => {
  //   setCounterDetails((old) => ({ ...old, details: null, tab: "details" }));
  // };

  return (
    <>
      <Box
      sx={{my:3}}>
      <Breadcrumbs
        path={[globalLang.view_readings[lang], globalLang.view[lang]]}
       />
       
      <DataGrid
        columns={columns}
        rows={counterReadingsStore.results}
        total={counterReadingsStore.count}
        isPending={CounterReadingsGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        // onView={(e, row) => {
        //   setPowerDetails((old) => ({
        //     ...old,
        //     details: row,
        //   }));
        // }}
      />
      </Box>
    </>
  );
}
export default ViewCounterReadings;


import { MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { PAYMENT_BILL, Purchases_BILL, STATIONS, TANK, TANKSHIPRECETPI, TRADERS } from "../../../data/APIs";
import useControls from "../../../hooks/useControls";

import useRequest from "../../../hooks/useRequest";
import Form, {
  InputField,
  NumberField,
  SelectField,
  TextareaField,
} from "../../../features/form";
import filter from "../../../utils/ClearNull";
import AutocompleteField from "../../../features/form/components/AutocompleteField";

const PurchasesBill = () => {
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };
  const [filterdata, setfilterdata] = useState({
    station: "",
    Date: getDate(),
   
  })
  const [filterdataTr, setfilterdataTr] = useState({
    trader: "",
    Date: getDate(),
  });
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [responsebody, setResponse] = useState([]);
  const stationsStore = useSelector((state) => state.stations.value);
  const tradersStore = useSelector((state) => state.traders.value);
  const tanksStore = useSelector((state) => state.tank.value);
  ///////////////////////////////
  console.log(tanksStore)
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "bill_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "paid",
      value: "",
      isRequired: true,
    },
    {
      control: "thetype",
      value: "",
      isRequired: false,
    },
    {
      control: "CommissionType",
      value: "",
      isRequired: false,
    },
    {
      control: "station",
      value: "",
      isRequired: false,
    },
    {
      control: "trader",
      value: "",
      isRequired: false,
    },
    {
      control: "tank",
      value: "",
      isRequired: false,
    },
    {
      control: "statement",
      value: "",
      isRequired: false,
    },
    {
      control: "date",
      value: new Date(),
      isRequired: false,
    },
  ]);

  const [paymentBillPostRequest, paymentBillPostResponse] = useRequest({
    path: Purchases_BILL,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });
  const getStations = () => {
    stationsGetRequest({
      params: {
        size:100
      },
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const getTraders = () => {
    if (Boolean(tradersStore.results.length)) return;
    tradersGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };
  
  const [tanksgetRequest, tanksgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  
  const getTanks = () => {
    tanksgetRequest({
      params: {
        size:100,
      },
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };
  // products data connection to api
  const thetype = [
    { name: "شحن", value: "Shipping" },
    { name: "تانك", value: "Tank" },
    { name: "ايداع", value: "Deposit" },
    {name: "وساطة", value: "C"},
    { name: "أخرى", value: "Others" },
  ];
  const CommissionType = [
    { name: "محطة", value: "station" },
    { name: "تاجر", value: "trader" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then((data) => {
      console.log(data);
      if (!data.isOk) return;
      const requestBody = filter({
        obj: {
          bill_date: controls.date.toISOString(),
          bill_uuid: controls.bill_uuid,
          paid: controls.paid,
          statement: controls.statement,
          station: filterdata.station,
          trader: filterdataTr.trader,
          tank: controls.tank,
          type:controls.thetype==="C"?"C":"NC"
        },

        output: "object",
      });
      if (
        Object.keys(
          filter({
            obj: {
              bill_date: controls.date.toISOString(),
              bill_uuid: controls.bill_uuid,
              paid: controls.paid,
              statement: controls.statement,
              station: controls.station,
              tank: controls.tank,
            },
          })
        ).length <= 1
      )
        return;

      paymentBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          resetControls();
          dispatch({ type: "purchasebill/addItem", payload: res.data });
        },
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            id: res?.id,
            bill_date: res?.date,
            bill_uuid: res?.bill_uuid,
            paid: res?.paid,
            statement: res.statement,
            station: res?.station,
            tank: res?.tank,
          },
        });

        if (res?.id) {
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };
  
  return (
    <>
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: paymentBillPostResponse.isPending,
          },
          closeBtn: {
            disabled: paymentBillPostResponse.isPending,
          },
        }}
      >
        <NumberField
          label={globalLang.bill_uuid[lang]}
          placeholder={globalLang.bill_uuid[lang]}
          onChange={(e) => setControl("bill_uuid", e.target.value)}
          value={controls.bill_uuid}
          required={required.includes("bill_uuid")}
          error={Boolean(invalid.bill_uuid)}
          helperText={invalid.bill_uuid}
        ></NumberField>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={globalLang.DateAdded[lang]}
            value={controls.date}
            format="YYYY/MM/DD"
            onChange={(newValue) => {
              // const new_date = `${newValue["$y"]}-${
              //   parseInt(newValue["$M"]) + 1
              // }-${newValue["$D"]}`;

              setControl("date", newValue);
            }}
            renderInput={(params) => (
              <InputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderColor: "#F0F0F8",
                    paddingRight: "5px",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#666666",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <SelectField
          label={globalLang.thetype[lang]}
          placeholder={globalLang.thetype[lang]}
          renderValue={(selected) => {
            return thetype.find((type) => type.value === selected).name;
          }}
          onChange={(e) => {
            setControl("thetype", e.target.value);
          }}
          value={controls.thetype}
          required={required.includes("thetype")}
          error={Boolean(invalid.thetype)}
          helperText={invalid.thetype}
        >
          {thetype.map((thetype, index) => (
            <MenuItem value={thetype.value} key={`${thetype.name} ${index}`}>
              {thetype.name}
            </MenuItem>
          ))}
        </SelectField>
        {controls.thetype==="C" &&
        <SelectField
        label={globalLang.commission_type[lang]}
        placeholder={globalLang.commission_type[lang]}
        renderValue={(selected) => {
          return CommissionType.find((type) => type.value === selected).name;
        }}
        onChange={(e) => {
          setControl("CommissionType", e.target.value);
        }}
        value={controls.CommissionType}
        required={required.includes("CommissionType")}
        error={Boolean(invalid.CommissionType)}
        helperText={invalid.CommissionType}
      >
        {CommissionType.map((thetype, index) => (
          <MenuItem value={thetype.value} key={`${thetype.name} ${index}`}>
            {thetype.name}
          </MenuItem>
        ))}
      </SelectField>
        }
        
        {controls.thetype==="Shipping"&&
       <AutocompleteField
       label={globalLang.stations[lang]}
       sx={{
         width: "100%"
          
       }}
       isPending={stationsGetResponse.isPending}
       onOpen={getStations}
       isOptionEqualToValue={(option, value) =>
         option.value === value.value
       }
       onChange={(e, options, reason) => {
         switch (reason) {
           case "selectOption":
             setfilterdata((old) => ({ ...old, station: options.value }));
             break;
           case "clear":
             setfilterdata((old) => ({ ...old, station: "" }));
         }
       }}
       data={[
         ...stationsStore?.results.map((c, index) =>
    
            ({
         
          
           label: c?.user?.name,
           value: c.id,
           
         })
       
       ),
       ]}
       value={filterdata.station}
       // required={required.includes("Exit_Permission_Station_bill")}
     /> 
      }
        {controls.CommissionType ==="station"&&
       <AutocompleteField
       label={globalLang.stations[lang]}
       sx={{
         width: "100%"
          
       }}
       isPending={stationsGetResponse.isPending}
       onOpen={getStations}
       isOptionEqualToValue={(option, value) =>
         option.value === value.value
       }
       onChange={(e, options, reason) => {
         switch (reason) {
           case "selectOption":
             setfilterdata((old) => ({ ...old, station: options.value }));
             break;
           case "clear":
             setfilterdata((old) => ({ ...old, station: "" }));
         }
       }}
       data={[
         ...stationsStore?.results.map((c, index) =>
    
            ({
         
          
           label: c?.user?.name,
           value: c.id,
           
         })
       
       ),
       ]}
       value={filterdata.station}
       // required={required.includes("Exit_Permission_Station_bill")}
     /> 
      }
        {controls.thetype==="Tank"&&
         <SelectField
         label={globalLang.tank[lang]}
         placeholder={globalLang.tank[lang]}
         renderValue={(selected) => {
           return tanksStore.results.find((tank) => tank.id === selected)
             ?.tank_uuid;
         }}
         isPending={tanksgetResponse.isPending}
         onOpen={getTanks}
         onChange={(e) => {
           setControl("tank", e.target.value);
         }}
         value={controls.tank}
         required={required.includes("tank")}
         error={Boolean(invalid.tank)}
         helperText={invalid.tank}
       >
         {tanksStore?.results?.map((tank, index) => (
           <MenuItem value={tank.id} key={`${tank?.product?.name} ${index}`}>
             {tank?.tank_uuid}
           </MenuItem>
         ))}
       </SelectField>}
       {controls.CommissionType ==="trader"&&
         <AutocompleteField
         label={globalLang.traders[lang]}
         sx={{
           
           width:"100%"
             
         }}
         isPending={tradersGetResponse.isPending}
         onOpen={getTraders}
         isOptionEqualToValue={(option, value) =>
           option.value === value.value
         }
         onChange={(e, options, reason) => {
           switch (reason) {
             case "selectOption":
               setfilterdataTr((old) => ({
                 ...old,
                 trader: options.value,
               }));
               break;
             case "clear":
               setfilterdataTr((old) => ({
                 ...old,
                 trader: "",
               }));
           }
         }}
         data={[
           ...tradersStore?.results.map((c, index) => ({
             label: c?.user?.first_name +""+c?.user?.last_name,
             value: c.id,
           })),
         ]}
         value={filterdataTr.trader}
         // required={required.includes("Exit_Permission_Station_bill")}
       />
       }
        <NumberField
          label={globalLang.thevalue[lang]}
          placeholder={globalLang.thevalue[lang]}
          onChange={(e) => setControl("paid", e.target.value)}
          value={controls.paid}
          required={required.includes("paid")}
          error={Boolean(invalid.paid)}
          helperText={invalid.paid}
        ></NumberField>
        {/* add empty and full input field ,popper for outputs */}
        <TextareaField
          label={globalLang.statement[lang]}
          placeholder={globalLang.statement[lang]}
          onChange={(e) => setControl("statement", e.target.value)}
          value={controls.statement}
          required={required.includes("statement")}
          error={Boolean(invalid.statement)}
          helperText={invalid.statement}
        ></TextareaField>
      </Form>

      {paymentBillPostResponse.successAlert}
      {paymentBillPostResponse.failAlert}
    </>
  );
};

export default PurchasesBill;

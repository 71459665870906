import { MenuItem } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import Wrapper from "../../components/Wrapper";
import {
  COUNTRY_FILTER,
  SELFFILL,
  STOCK,
  STOCKSUMMARY,
  TANK,
} from "../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogNumberField,
  DialogSelectField,
  DialogTable,
} from "../../features/dialog";
import { SelectField } from "../../features/form";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../hooks/useAfterEffect";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import compare from "../../utils/Compare";
import format from "../../utils/ISOToReadable";

const ViewStock = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const ViewStocksLang = useSelector((state) => state.lang.value.ViewStocks);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.StockMessage
  );

  ///////////////////////////////
  const stocksStore = useSelector((state) => state.stocks.value);
  const [stockDetails, setStockDetails] = useState({
    details: null,
    tab: "details",
  });
  const columns = [
    {
      field: "product",
      headerName: globalLang.Gas[lang],
      customContent: ({ product }) => product?.name,
    },
    {
      field: "product_type",
      headerName: globalLang.package[lang],
      customContent: ({ product_type }) => (product_type ? product_type === "cylinder"&&"اسطوانة"||product_type==="dewar"&&"دوار": "-"),
    },
    {
      field: "factorypower",
      headerName: globalLang.factorypower[lang],
      customContent: ({ factorypower }) =>
        factorypower === 0 ? "0" : factorypower,
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
      customContent: ({ volume }) => (volume === 0 ? "0" : volume),
    },
    {
      field: "empty_store",
      headerName: globalLang.empty[lang],
      customContent: ({ empty_store }) =>
        empty_store === 0 ? "0" : empty_store,
    },
    {
      field: "filled_store",
      headerName: globalLang.filled[lang],
      customContent: ({ filled_store }) =>
        filled_store === 0 ? "0" : filled_store,
    },
    {
      field: "total_store",
      headerName: globalLang.raseef[lang],
      customContent: ({ total_store }) => (total_store ? total_store : "0"),
    },
    {
      field: "outside",
      headerName: globalLang.outputs[lang],
      customContent: ({ outside }) => (outside ? outside : "0"),
    },
    // {
    //   field: "created_at",
    //   headerName: globalLang.createdAt[lang],
    //   customContent: (params) => {
    //     return `${params?.created_at ? format(params?.created_at) : "-"}`;
    //   },
    // },
  ];

  const dispatch = useDispatch();

  const [stocksGetRequest, stocksGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });
  const [stockDetailsGetRequest, stockDetailsGetResponse] = useRequest({
    path: STOCKSUMMARY,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getStocks = () => {
    stocksGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getStocks();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [stocksDeleteRequest, stocksDeleteResponse] = useRequest({
    path: STOCK,
    method: "delete",
    successMessage: "تم حذف المنتج بنجاح",
  });

  const deleteStock = (e, row) => {
    stocksDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "stocks/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteStock, deleteStockConfirmDialog] = useConfirmMessage({
    onConfirm: deleteStock,
    text: confirmMessage[lang],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "factory",
      value: "",
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = () => {
    const requestBody = filter({
      obj: {
        name: controls.name,
        logo: controls.logo,
        url: controls.website,
      },
    });

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody),
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);
  const [addselffilldata, setSelfFillData] = useState(null);

  const isPermitted = useIsPermitted();
  useAfterEffect(() => {
    if (!stockDetails.details) return;
    setStockDetails((old) => ({
      ...old,
      details: stocksStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [stocksStore]);

  const getStockDetails = () => {
    stockDetailsGetRequest({
      id: stockDetails.details.id,

      onSuccess: (res) => {
        dispatch({ type: "stock_summary/set", payload: res.data });
      },
    });
  };

  const handleCloseDetailsDialog = () => {
    setStockDetails((old) => ({ ...old, details: null, tab: "details" }));
  };
  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  //   const getCountries = () => {
  //     countriesGetRequest({
  //       onSuccess: (res) => {
  //         setCountriesData(res.data.data);
  //       },
  //     });
  //   };

  //   const [governoratesData, setGovernoratesData] = useState([]);

  //   const [statesGetRequest, statesGetResponse] = useRequest({
  //     path: STATES,
  //     method: "post",
  //   });

  //   const getGovernorates = () => {
  //     statesGetRequest({
  //       body: {
  //         country: controls.country,
  //       },
  //       onSuccess: (res) => {
  //         setGovernoratesData(res.data.data);
  //       },
  //     });
  //   };

  //===End===== Get Countries logic =========

  return (
    <>
      {/* <Breadcrumbs path={[globalLang.wallet[lang], globalLang.view[lang]]} /> */}

      <DataGrid
        columns={columns}
        rows={stocksStore.results}
        total={stocksStore.count}
        isPending={stocksGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onAddSelfFill={(e, row) => setSelfFillData(row)}
        // onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        // onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onDelete={isPermitted(handleDeleteStock, ["delete_factorypower"])}
        onEdit={isPermitted(
          (e, row) => setEditData(row),
          ["change_factorypower"]
        )}
        onView={(e, row) => {
          setStockDetails((old) => ({
            ...old,
            details: row,
          }));
        }}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <AddSelfFillDailog
        open={Boolean(addselffilldata)}
        onClose={() => setSelfFillData(null)}
        data={addselffilldata}
      />
      <InfoDialog
        open={Boolean(stockDetails.details && stockDetails.tab === "details")}
        onOpen={getStockDetails}
        onClose={handleCloseDetailsDialog}
        data={Boolean(stockDetails.details) && stockDetails.details}
        isHistoryPending={stockDetailsGetResponse.isPending}
      />
      {deleteStockConfirmDialog}
      {stocksDeleteResponse.successAlert}
      {stocksDeleteResponse.failAlert}
    </>
  );
};

export default ViewStock;

const AddSelfFillDailog = ({ open = false, onClose = () => {}, data = {} }) => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const tanksStore = useSelector((state) => state.tank.value);
  const stocksStore = useSelector((state) => state.stocks.value);
  ///////////////////////////////
  const [tanksgetRequest, tanksgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const getTanks = () => {
    tanksgetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };
  const [stockgetRequest, stockgetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });
  const getStock = () => {
    stockgetRequest({

      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "bill_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "fill_type",
      value: "",
      isRequired: true,
    },
    {
      control: "tank",
      value: "",
      isRequired: false,
    },
    {
      control: "filled",
      value: "",
      isRequired: true,
    },
  ]);
  const fill_types = [
    {
      name: "تانك",
      value: "tank",
    },
    {
      name: "مصنع",
      value: "factory",
    },
  ];

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [selfFillPostRequest, selfFillPostResponse] = useRequest({
    path: SELFFILL,
    method: "post",
    successMessage: "تم الإضافة بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const requestBody = filter({
      obj: {
        bill_uuid: controls.bill_uuid,
        filled_date: controls.date.toISOString(),
        product: data.product.id,
        fill_type: controls.fill_type,
        product_type: data.product_type,
        volume: data.volume,
        filled: parseInt(controls.filled),
      },
      output: "object",
    });
    console.log(requestBody);
    if (controls.tank) {
      requestBody["tank"] = controls.tank;
    }

    selfFillPostRequest({
      body: requestBody,
      onSuccess: (res) => {
        dispatch({ type: "self_fill/addItem", payload: res.data });
        getStock()
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.add[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.receiptnumber[lang]}
          placeholder={globalLang.receiptnumber[lang]}
          value={controls.bill_uuid}
          onChange={(e) => setControl("bill_uuid", e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={globalLang.Date[lang]}
            value={controls.date}
            onChange={(newValue) => {
              setControl("date", newValue["$d"]);
            }}
            renderInput={(params) => (
              <DialogInputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderColor: "#F0F0F8",
                    paddingRight: "5px",
                    background: "white",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#666666",
                  },
                }}
                required={required.includes("date")}
                error={Boolean(invalid.date)}
                helperText={invalid.date}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <DialogSelectField
          label={globalLang.fill_type[lang]}
          placeholder={globalLang.fill_type[lang]}
          name="fill_type"
          renderValue={(selected) => {
            return fill_types.find((type) => type.value === selected)?.name;
          }}
          onChange={(e) => {
            setControl("fill_type", e.target.value);
          }}
          value={controls.fill_type}
          error={Boolean(invalid.fill_type)}
          helperText={invalid.fill_type}
          required={required.includes("fill_type")}
        >
          {fill_types?.map((type, index) => (
            <MenuItem value={type.value} key={`${type.name} ${index}`}>
              {type.name}
            </MenuItem>
          ))}
        </DialogSelectField>
        {controls.fill_type === "tank" && (
          <DialogSelectField
            label={globalLang.tank[lang]}
            placeholder={globalLang.tank[lang]}
            renderValue={(selected) => {
              return tanksStore.results.find((tank) => tank.id === selected)
                ?.tank_uuid;
            }}
            isPending={tanksgetResponse.isPending}
            onOpen={getTanks}
            onChange={(e) => {
              setControl("tank", e.target.value);
            }}
            value={controls.tank}
            required={required.includes("tank")}
            error={Boolean(invalid.tank)}
            helperText={invalid.tank}
          >
            {tanksStore?.results?.map((tank, index) => (
              <MenuItem value={tank.id} key={`${tank?.product?.name} ${index}`}>
                {tank?.tank_uuid}
              </MenuItem>
            ))}
          </DialogSelectField>
        )}
        <DialogNumberField
          label={globalLang.fill[lang]}
          placeholder={globalLang.fill[lang]}
          value={controls.filled}
          onChange={(e) => setControl("filled", e.target.value)}
        />
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={selfFillPostResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {selfFillPostResponse.failAlert}
    </Dialog>
  );
};
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "factorypower",
        value: data?.factorypower,
        isRequired: false,
      },

      {
        control: "empty_store",
        value: data?.empty_store,
        isRequired: false,
      },
      {
        control: "total_store",
        value: data?.total_store,
        isRequired: false,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [stockPatchRequest, stockPatchResponse] = useRequest({
    path: STOCK,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.factorypower, data.factorypower],
        [controls.empty_store, data.empty_store],
        [controls.total_store, data.total_store],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          factorypower: controls.factorypower,
          empty_store: controls.empty_store,
          total_store: controls.total_store,
        },
        output: "formData",
      });

      stockPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "stocks/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };
  //===Start===== Get Countries logic =========
  // const [countriesData, setCountriesData] = useState([]);

  // const [countriesGetRequest, countriesGetResponse] = useRequest({
  //   path: COUNTRY_FILTER,
  //   method: "get",
  // });

  // const getCountries = () => {
  //   if (countriesData.length) return;
  //   countriesGetRequest({
  //     onSuccess: (res) => {
  //       setCountriesData(res.data.data);
  //     },
  //   });
  // };

  // const [governoratesData, setGovernoratesData] = useState([]);

  // const [statesGetRequest, statesGetResponse] = useRequest({
  //   path: STATES,
  //   method: "post",
  // });

  // const getGovernorates = () => {
  //   if (governoratesData.length) return;
  //   statesGetRequest({
  //     body: {
  //       country: controls.country,
  //     },
  //     onSuccess: (res) => {
  //       setGovernoratesData(res.data.data);
  //     },
  //   });
  // };

  //===End===== Get Countries logic =========

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.editstockData[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.factorypower[lang]}
          placeholder={globalLang.factorypower[lang]}
          value={controls.factorypower}
          onChange={(e) => setControl("factorypower", e.target.value)}
        />
        <DialogNumberField
          label={globalLang.empty[lang]}
          placeholder={globalLang.empty[lang]}
          value={controls.empty_store}
          onChange={(e) => setControl("empty_store", e.target.value)}
        />
        <DialogNumberField
          label={globalLang.raseef[lang]}
          placeholder={globalLang.raseef[lang]}
          value={controls.total_store}
          onChange={(e) => setControl("total_store", e.target.value)}
        />
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={stockPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {stockPatchResponse.failAlert}
    </Dialog>
  );
};
export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewTradersLang = useSelector((state) => state.lang.value.ViewTraders);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const stodkDetailsStore = useSelector((state) => state.stock_summary.value);

  const dispatch = useDispatch();


  //----variables----

  const Tradercolumns = [
    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ rowData }) =>
        rowData.trader ? `${rowData.trader}` : "-",
    },

    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData.store === 0 ? "0" : rowData.store ? rowData.store : "-",
    },
  ];
  const stationColumns = [
    {
      field: "station",
      headerName: globalLang.station[lang],
      customContent: ({ rowData }) =>
        rowData.station ? `${rowData.station}` : "-",
    },

    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData.store === 0 ? "0" : rowData.store ? rowData.store : "-",
    },
  ];
  const clientsColumns = [
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ rowData }) =>
        rowData.client ? `${rowData.client}` : "-",
    },

    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ rowData }) =>
        rowData.store === 0 ? "0" : rowData.store ? rowData.store : "-",
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
    onOpen();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeading>{globalLang.outDetails[lang]}</DialogHeading>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.traders[lang]}</DialogHeading>
        <DialogTable
          rows={stodkDetailsStore?.traders}
          columns={Tradercolumns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.stations[lang]}</DialogHeading>
        <DialogTable
          rows={stodkDetailsStore?.stations}
          columns={stationColumns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.clients[lang]}</DialogHeading>
        <DialogTable
          rows={stodkDetailsStore?.clients}
          columns={clientsColumns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  CircularProgress,
  Avatar,
  Typography,
  Skeleton,
  InputAdornment
} from "@mui/material";
import { NOTIFICATIONS } from "../data/APIs";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MenuIcon from "@mui/icons-material/Menu";
import format from "../utils/ISOToReadable";
import notificationSfx from "../assets/notification sfx.wav";
import playSoundEffect from "../utils/playSoundEffect";
import pages from "../data/pages";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../hooks/useRequest";
import  Logo from "../assets/logoCrm.png"
import { InputField } from "../features/form";
import SearchIcon from '@mui/icons-material/Search';
const NotificationItem = ({ onClick, content, time, unread = false }) => {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{ bgcolor: unread ? "rgba(0, 0, 0, 0.04)" : "initial" }}
    >
      <ListItemText primary={content} secondary={time} />
    </ListItemButton>
  );
};

const Topbar = ({
  showBurger,
  onBurgerClick,
  onClear,
  notifications,
  isNotificationPending,
  loading,
  onNotificationsOpen,
  name,
  avatar,
  onChangeAvatar,
  isAvatarPending = false,
  isNamePending,
}) => {
  //----states----
  
  const globalLang = useSelector((state) => state.lang.value.global);
  const [notificationsState, setNotificationsState] = useState([]);
  const unreadNotifCache = useRef(0);
  const [unreadNotif, setUnreadNotif] = useState(0);
  const [readNotif, setReadNotif] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const pictureFileInput = useRef();
  //store

  const notifictionsStore = useSelector((state) => state.notifications.value);

  //----effects----
console.log(name);
  
  useEffect(() => {
    setUnreadNotif(() => Math.minimumZero(notifications?.length));
    // setNotificationsState(notifications);
 
    if (Boolean(notifications?.length)) {
      playSoundEffect(notificationSfx);
    }
  }, [notifications]);

  useEffect(() => {
 
    if (!open) return;
    // onNotificationsOpen();
    
    setReadNotif(notifications?.length);
    setUnreadNotif(() => {
      unreadNotifCache.current = unreadNotif;
      return 0;
    });
    console.log(unreadNotif);
  }, [open]);

  //----functions----
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    unreadNotifCache.current = 0;
    setAnchorEl(null);
  };
 const [resetNotificationsGetRequest] = useRequest({
   path: NOTIFICATIONS,
   method: "get",
 });
  const dispatch = useDispatch();
  const handleClear = () => {
   
    unreadNotifCache.current = 0;
    setUnreadNotif(0);
    setReadNotif(0);
    resetNotificationsGetRequest({
       customMethod:"delete",
       onSuccess: () => {
         dispatch({
           type: "notifications/reset",
         });
       },
     });


  };

  //===Start======= Notification Redirects Logic ============
  const navigate = useNavigate();

  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);

  ////////////////////

  const getPath = (name, isChild = false) => {
    switch (isChild) {
      case false:
        return pages.find((page) => page?.name[lang] === name)?.path;
      case true:
        const parent = pages.find(
          (page) =>
            page?.subtabs?.filter((subtab) => subtab?.name === name).length > 0
        );
        return (
          parent.path +
          parent.subtabs?.find((subtab) => subtab?.name === name)?.path
        );
    }
  };

  const typesAndPaths = {
    // project: getPath("عرض المنتجات", true),
    // unit: getPath("عرض المحطات", true),
    // followup: getPath("جميع العملاء", true),
    // other: "",
  };

  //===End======= Notification Redirects Logic ============

  return (
    <AppBar
      position="relative"
      sx={{
        boxShadow:"0",
        borderRadius:"0px 0px 20px 0px",
        zIndex: 1,
        width:"100%",
        height:"78px",
        overflow:"hidden",
        backgroundColor:"#ffffff",
        boxShadow: "0px 0px 10px 0px #20506929",
        pt:1
      }}
    >
      <Toolbar sx={{overflow:"hidden"}}>
        {showBurger && (
          <IconButton sx={{ color: "rgb(2,0,36)" }} onClick={() => onBurgerClick()}>
            <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: showBurger ? "flex-end" : "space-between",
            maxWidth: "90%",
            width: "90%",
          }}
        >
          <Box>
            <Tooltip title="Notifications">
              <IconButton sx={{color:'white' ,
            height:"20px",
            width:"18px"}} onClick={handleOpenMenu}>
                <Badge
                  badgeContent={unreadNotif}
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <NotificationsActiveIcon sx={{ color: "rgb(2,0,36)",ps:4 }} />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiList-root": {
                  maxWidth: "400px",
                  width: "100vmax",
                },
              }}
            >
              <List
                sx={{
                  maxWidth: 500,
                  width: "100%",
                  height: "400px",
                  overflowY: "scroll",
                }}
              >
                <ListItem>
                  <ListItemText primary="الإشعارات" />
                </ListItem>
                <Divider variant="middle" />
                {loading ? (
                  <CircularProgress sx={{color:'gray'}}></CircularProgress>
                ) : notifictionsStore?.length ? (
                  notifictionsStore?.map((notification, index) => (
                    <NotificationItem
                      key={`notification ${index}`}
                      onClick={() => {
                        navigate(typesAndPaths[notification.types]);
                        handleCloseMenu();
                      }}
                      content={notification?.message}
                      time={`${format(
                        notification?.date ?? "sad/asdfa/asdfT435:34534:453"
                      )}`}
                      unread={index < unreadNotifCache.current}
                    />
                  ))
                ) : (
                  <MenuItem disabled>فارغ</MenuItem>
                )}
                <Divider variant="middle" />
                <ListItem>
                  <Button onClick={handleClear} color="error">
                    إزالة
                  </Button>
                </ListItem>
              </List>
            </Menu>
          </Box>
          <Box sx={{
            position:"absolute",
          left:"7%"
        }}>
          {/* <InputField 
          sx={{
            width:"344px",display:{
              xl:"block",
              lg:"block",
              md:"none",
              sm:"none",
              xs:"none"
            }

          }}
           placeholder={globalLang.search[lang]}
           InputProps={{
            startAdornment: <InputAdornment position="start" sx={{paddingTop:"2px",paddingLeft:"3px"}}><SearchIcon/></InputAdornment>,
          }}></InputField> */}
        </Box>
        </Box>
        
        <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "center",
          minWidth:"288px",
         
        }}
      >
        
        {Boolean(onChangeAvatar) ? (
          
          <Badge
            overlap="circular"
            // badgeContent={
            //   <IconButton
            //     sx={{
            //       bgcolor: "white",
            //       color: "black",
            //       boxShadow: "0 0 10px #fff",
            //       "&:hover": {
            //         bgcolor: "white",
            //       },
            //     }}
            //     size="small"
            //     disabled={isAvatarPending}
            //     onClick={() => {
            //       pictureFileInput.current.click();
            //     }}
            //   >
            //     <AddAPhotoIcon color="primary" fontSize="small" />
            //   </IconButton>
            // }

            // anchorOrigin={{
            //   vertical: "bottom",
            //   horizontal: "left",
            // }}
          >
            <Box
              sx={{
                position: "relative",
                borderRadius: "50%",
                overflow: "hidden",
                
              }}
            >
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                  onChangeAvatar(e.target.files[0]);
                }}
                ref={pictureFileInput}
              />

              {isAvatarPending ? (
                <Skeleton variant="circle" width={"44px"} height={"44px"} />
              ) : (
                <Avatar sx={{ width: "44px", height: "44px" }} src={avatar} />
              )}
            </Box>
          </Badge>
        ) : (
          <Box
            sx={{
              position: "relative",
              borderRadius: "50%",
              overflow: "hidden",
             
            }}
          >
            {isAvatarPending ? (
              <Skeleton variant="circle" width={60} height={60} />
            ) : (
              <Avatar sx={{ width: 60, height: 60 }} src={avatar} />
            )}
          </Box>
        )}
        <Box sx={{alignItems:"flex-start"}}>
          {isNamePending ? (
            <Skeleton variant="text" width={120} height={25} />
          ) : (
            <Typography sx={{fontFamily:"Cairo",fontWeight:"700",fontSize:"18px",color:"#020024",textAlign:"center",pr:1,pt:1}}>{name}</Typography>
          )}
          {/* {isRolePending ? (
            <Skeleton variant="text" width={80} height={25} />
          ) : (
            <Typography variant="caption">{role}</Typography>
          )} */}
          {/* {isOrganizationPending ? (
            <Skeleton variant="text" width={90} height={25} />
          ) : (
            <Typography variant="caption">{organization}</Typography>
          )} */}
        </Box>
      </Box>
        {/* <IconButton>
          <Avatar 
        alt="Factory CRM" 
        src={Logo}
        sx={{ width: 100, height:100 }}
        />
          </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;

//----typechecking----

NotificationItem.propTypes = {
  onClick: PropTypes.func,
  content: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

Topbar.propTypes = {
  showBurger: PropTypes.bool,
  onBurgerClick: PropTypes.func,
  notifications: PropTypes.arrayOf(PropTypes.object),
  isAvatarPending: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChangeAvatar: PropTypes.func,
  isNamePending: PropTypes.bool,
};

import { Box, Button, Card, MenuItem, Table, TableBody, TableCell, TableHead } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCTS, STOCK_REVIEW } from "../../data/APIs";
import { InputField, SelectField } from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
function ReviewGas() {
  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  };

  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [reviewStock, setReviewStock] = useState([]);
  const productsStore = useSelector((state) => state.products.value);
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "product",
      value: "",
      isRequired: true,
    },
    {
      control: "volumes",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
    {
      control: "date",
      value: getDate(),
      isRequired: true,
    },
  ]);
  const volumes = [
    { name: "40", value: 40 },
    { name: "50", value: 50 },
    { name: "20", value: 20 },
    { name: "15", value: 15 },
    { name: "10", value: 10 },
  ];
  const product_type = [
    { name: "أسطوانة", value: "cylinder" },
    { name: "دوار", value: "dewar" },
  ];
  const urlParams = new URLSearchParams();
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  const [StockReviewGetRequest, StockReviewGetResponse] = useRequest({
    path: STOCK_REVIEW,
    method: "get",
  });
  const handleView = () => {
    if (controls.product) {
    
      urlParams.append("product", controls.product);
      urlParams.append("date", controls.date);
      urlParams.append("volume", controls.volumes);
      urlParams.append("product_type", controls.product_type);
   
    StockReviewGetRequest({
    //   id: controls.tanknumber + "/filled",
      params: urlParams,

      onSuccess: (res) => {
        dispatch({ type: "stocks/addItem", payload: { id: res.data.id } });
        setReviewStock(res.data);
        console.log(reviewStock);
      },
    });
  }
  };
  const [requestParams, setRequestParams] = useState({});

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    handleView();
  }, []);
  return (
    <>
      <Card>
        <Box
          sx={{
            maxWidth: "100%",
            display: "flex",
            flexDirection: {
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              sx: "column",
            },
            // justifyContent: {xl:"space-between",lg:"space-between",md:"center",sm:"center",sx:"center"},
            flexWrap: "wrap",
            m: "auto",
            p: 1,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={globalLang.date[lang]}
              value={controls.date}
              format="YYYY/MM/DD"
              onChange={(newValue) => {
                const new_date = `${newValue["$y"]}-${
                  parseInt(newValue["$M"]) + 1
                }-${newValue["$D"]}`;

                setControl("date", new_date);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: {
                      xl: "40%",
                      lg: "40%",
                      md: "90%",
                      sm: "90%",
                      sx: "90%",
                    },
                    m: 2,
                    "& .MuiInputBase-root": {
                      borderColor: "#F0F0F8",
                      paddingRight: "5px",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#666666",
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <SelectField
            label={globalLang.Gas[lang]}
            placeholder={globalLang.Gas[lang]}
            renderValue={(selected) => {
              return productsStore.results.find(
                (product) => product.id === selected
              ).name;
            }}
            isPending={productsGetResponse.isPending}
            onOpen={getProducts}
            onChange={(e) => {
              setControl("product", e.target.value);
            }}
            value={controls.product}
            required={required.includes("product")}
            error={Boolean(invalid.product)}
            helperText={invalid.product}
            sx={{
              width: {
                xl: "40%",
                lg: "40%",
                md: "90%",
                sm: "90%",
                sx: "90%",
              },
              m: 2,
            }}
          >
            {productsStore?.results?.map((product, index) => (
              <MenuItem value={product.id} key={`${product.name} ${index}`}>
                {product.name}
              </MenuItem>
            ))}
          </SelectField>
          <SelectField
            label={globalLang.package[lang]}
            placeholder={globalLang.package[lang]}
            renderValue={(selected) => {
              return product_type.find((type) => type.value === selected).name;
            }}
            onChange={(e) => {
              setControl("product_type", e.target.value);
            }}
            value={controls.product_type}
            required={required.includes("product_type")}
            error={Boolean(invalid.product_type)}
            helperText={invalid.product_type}
            sx={{
              width: {
                xl: "40%",
                lg: "40%",
                md: "90%",
                sm: "90%",
                sx: "90%",
              },
              m: 2,
            }}
          >
            {product_type.map((product, index) => (
              <MenuItem value={product.value} key={`${product.name} ${index}`}>
                {product.name}
              </MenuItem>
            ))}
          </SelectField>
          <SelectField
            label={globalLang.volume[lang]}
            placeholder={globalLang.volume[lang]}
            renderValue={(selected) => {
              return volumes.find((type) => type.value === selected).name;
            }}
            onChange={(e) => {
              setControl("volumes", e.target.value);
            }}
            value={controls.volumes}
            required={required.includes("volumes")}
            error={Boolean(invalid.volumes)}
            helperText={invalid.volumes}
            sx={{
              width: {
                xl: "40%",
                lg: "40%",
                md: "90%",
                sm: "90%",
                sx: "90%",
              },
              m: 2,
            }}
          >
            {volumes.map((volume, index) => (
              <MenuItem value={volume.value} key={`${volume.name} ${index}`}>
                {volume.name}
              </MenuItem>
            ))}
          </SelectField>
          <Button
              sx={{
                backgroundColor: "#233975",
                color:"white",
                maxWidth: "150px",
                width: "100vmax",
                height: "50px",
                fontWeight: "bold",
                m: 3,
                ":hover":{
                  backgroundColor: "#233975",
                }
                }} 
              onClick={handleView}
              disabled={StockReviewGetResponse.isPending}
            >
              {globalLang.view[lang]}
            </Button>
        </Box>
        {"difference" in reviewStock && (
        <Table
          sx={{
            overflowX:"scroll",
            justifyContent: "center",
            p: 1,
            ".MuiTableRow-head": {
              padding: 2,
              textAlign: "center",
            },
            ".MuiTableCell-head": {
              fontWeight: "600",
              color: "gray",
              textAlign: "center",
              fontSize: "11px",
            },
            ".MuiTableCell-body": {
              textAlign: "center",
              p: 3,
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#f8f9fa",
                
              },
            },
          }}
        >
          <TableHead>
            
            <TableCell>{globalLang.date[lang]}</TableCell>
            <TableCell>{globalLang.product[lang]}</TableCell>
            <TableCell>{globalLang.quantity[lang]}</TableCell>
            <TableCell>{globalLang.sold[lang]}</TableCell>
            <TableCell>{globalLang.difference[lang]}</TableCell>
          </TableHead>
          <TableBody>
            
            <TableCell>{reviewStock?.date}</TableCell>
            <TableCell>{reviewStock?.product}</TableCell>
            <TableCell>{reviewStock?.quantity}</TableCell>
            <TableCell>{reviewStock?.sold}</TableCell>
            <TableCell>{reviewStock?.difference}</TableCell>
            
          </TableBody>
        </Table>
         )}
      </Card>
    </>
  );
}
export default ReviewGas;

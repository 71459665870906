import React, { useState } from "react";

import Form, {
  InputField,

  NumberField,

  SelectField,
} from "../../../features/form";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";

import {
  CLIENTAWAYBILL,
  EMPLOYEES,
  TRANSPORT,
} from "../../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Stack, Box } from "@mui/material";
import filter from "../../../utils/ClearNull";
import ReceiptTable from "../../../components/ReceiptTable";

const AwayDistribution = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [success, setSuccess] = useState(false)
  const globalLang = useSelector((state) => state.lang.value.global);

  const columns = [
    {
      name: "driven",
      value: "driven",
    },
    {
      name: "product",
      value: "product",
    },
    {
      name: "volume",
      value: "volume",
    },
  ];
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (gas) => {
    setFormData([...gas]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };
  //////////////////////////////////
  //----store----

  const employeesStore = useSelector((state) => state.employees.value);

  const transportStore = useSelector((state) => state.transport.value);
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "number",
      value: "",
      isRequired: true,
    },
    {
      control: "transport",
      value: "",
      isRequired: true,
    },
    {
      control: "driver",
      value: "",
      isRequired: true,
    },

    {
      control: "Date",
      value: new Date(),
      isRequired: false,
    },
    {
      control: "bills",
      value: [],
      isRequired: true,
    },
  ]);



  //----request hooks----

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const [transportGetRequest, transportGetResponse] = useRequest({
    path: TRANSPORT,
    method: "get",
  });

  const [ClientAwayBillPostRequest, ClientAwayBillPostResponse] = useRequest({
    path: CLIENTAWAYBILL,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });

  //----functions----

  const getEmployees = () => {
    if (Boolean(employeesStore.results.length)) return;
    employeesGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "employees/set", payload: res.data });
      },
    });
  };

  const getTransportation = () => {
    if (Boolean(transportStore.results.length)) return;
    transportGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "transport/set", payload: res.data });
      },
    });
  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          permit_uuid: controls.number,
          exit_date: controls.Date.toISOString(),
          driver: controls.driver,
          transport: controls.transport,
          products: [
            ...FormData.filter((object) => Object.keys(object).length !== 0),
            ...LiquidFormData.filter(
              (object) => Object.keys(object).length !== 0
            ),
          ],
        },
        output: "object",
      });
      ClientAwayBillPostRequest({
        body: requestBody,
        onSuccess: () => {
          resetControls();
          setSuccess(true)
        },
      }).then((res) => {

        if (res?.id) {
          dispatch({
            type: "client_away_bill/addItem",
            payload: res,
          });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>

      <Form
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: ClientAwayBillPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: ClientAwayBillPostResponse.isPending,
          },
        }}
      >
        <Stack sx={{ gap: 2, padding: "10px", margin: "10px" }}>
          <Stack
            sx={{
              gap: 2,
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
              padding: "10px",
              "& .MuiTextField-root": {
                minWidth: "16px",
                width: "100%",
              },

            }}
          >
            <NumberField
              label={globalLang.receiptnumber[lang]}
              placeholder={globalLang.receiptnumber[lang]}
              onChange={(e) => setControl("number", e.target.value)}
              value={controls.number}
              required={required.includes("number")}
              error={Boolean(invalid.number)}
              helperText={invalid.number}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={globalLang.Date[lang]}
                value={controls.Date}
                onChange={(newValue) => {
                  setControl("Date", newValue);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <SelectField
              label={globalLang.driver[lang]}
              placeholder={globalLang.driver[lang]}
              isPending={employeesGetResponse.isPending}
              onOpen={getEmployees}
              renderValue={(selected) => {
                let employee = employeesStore.results.find(
                  (employee) => employee.id === selected
                ).user;

                return employee.first_name + " " + employee.last_name;
              }}
              onChange={(e) => {
                setControl("driver", e.target.value);
              }}
              value={controls.driver}
              required={required.includes("driver")}
              error={Boolean(invalid.driver)}
              helperText={invalid.driver}
            >
              {employeesStore.results?.map(
                (employee, index) =>
                  employee?.job.title === "سائق" && (
                    <MenuItem value={employee.id} key={` ${index}`}>
                      {employee.user.first_name} {employee.user.last_name}
                    </MenuItem>
                  )
              )}
            </SelectField>
            <SelectField
              label={globalLang.transportation[lang]}
              placeholder={globalLang.transportation[lang]}
              renderValue={(selected) => {
                return transportStore.results.find(
                  (transport) => transport.id === selected
                ).name;
              }}
              isPending={transportGetResponse.isPending}
              onOpen={getTransportation}
              onChange={(e) => {
                setControl("transport", e.target.value);
              }}
              value={controls.transport}
              required={required.includes("transport")}
              error={Boolean(invalid.transport)}
              helperText={invalid.transport}
            >
              {transportStore?.results?.map((transport, index) => (
                <MenuItem
                  value={transport.id}
                  key={`${transport.name} ${index}`}
                >
                  {transport.name}
                </MenuItem>
              ))}
            </SelectField>
          </Stack>

          <Box>
            <Stack
              sx={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReceiptTable
                type="cylinder"
                columns={columns}
            
                getformData={getFormData}
                success={success}
              />
              <ReceiptTable
                type="dewar"
                columns={columns}
           
                getformData={getLiquidFormData}
                success={success}
              />

            </Stack>
          </Box>
        </Stack>
      </Form>
      {ClientAwayBillPostResponse.successAlert}
      {ClientAwayBillPostResponse.failAlert}
    </>
  );
};

export default AwayDistribution;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../../hooks/useRequest";
import useControls from "../../../hooks/useControls";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import { FINANCEANDDEPOSITE } from "../../../data/APIs";
import useAfterEffect from "../../../hooks/useAfterEffect";
import format from "../../../utils/ISOToReadable";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import Dialog, { DialogButton, DialogButtonsGroup, DialogForm, DialogHeading, DialogInputField, DialogNumberField } from "../../../features/dialog";
import filter from "../../../utils/ClearNull";
import compare from "../../../utils/Compare";

function FinanceView(){
    const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const FinanceStore = useSelector((state) => state.finance_deposite.value);

  const [editData,setEditData]=useState(null)
  const isPermitted = useIsPermitted();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();

  const columns = [
    {
          field: "bill_date",
          headerName: globalLang.Date[lang],
          customContent: ({ bill_date }) =>
          bill_date ? format(bill_date) : "-",
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid ? paid : "-"),
    },
    {
      field: "bill_uuid",
      headerName: globalLang.bill_uuid[lang],
      customContent: ({ bill_uuid }) => (bill_uuid ? bill_uuid : "-"),
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) =>
      statement? statement : "-",
    },
    {
      field: "category",
      headerName: globalLang.thetype[lang],
      customContent: ({ category }) =>
      category ? category ==="finance"?"تمويل" : "-"||category ==="deposite"?"ايداع" : "-":"-",
    },
    
  ];
  const [FINANCEANDDEPOSITERequest, FINANCEANDDEPOSITEReponse] = useRequest({
    path: FINANCEANDDEPOSITE,
    method: "get",
  });

  

  const getFinance = () => {
    
    FINANCEANDDEPOSITERequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "finance_deposite/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getFinance();
  }, [requestParams]);



  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const [financeDeleteRequest, financeDeleteResponse] = useRequest({
    path: FINANCEANDDEPOSITE,
    method: "delete",
    successMessage: "تم الحذف بنجاح",
  });

  const deleteFinance = (e, {id}) => {

    financeDeleteRequest({
      id:id,
      onSuccess: (res) => {
        dispatch({ type:"finance_deposite/deleteItem", payload:id });
      },
    });
  };

  const [handleDeleteFinance, deleteFinanceConfirmDialog] = useConfirmMessage({
    onConfirm: deleteFinance,
    text: "هل انت متأكد من انك تريد حذف هذه الفاتورة؟",
  });

  return (
    <>
      <Box
      sx={{my:3}}>
      <Breadcrumbs
        path={[globalLang.finance[lang], globalLang.view[lang]]}
       />
      
      <DataGrid
        columns={columns}
        rows={FinanceStore.results}
        total={FinanceStore.count}
        isPending={FINANCEANDDEPOSITEReponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        sum={FinanceStore.total}
        onDelete={isPermitted(handleDeleteFinance,["delete_factorystock"])
        }
        onEdit={
          isPermitted((e, row) => setEditData(row),["change_factorystock"]
        )
      }
      
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      </Box>
      {deleteFinanceConfirmDialog}
      {financeDeleteResponse.successAlert}
      {financeDeleteResponse.failAlert}
    </>
  );
}
export default FinanceView;

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);


  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "paid",
        value: data?.paid,
        isRequired: false,
      },
      {
        control: "statement",
        value: data?.statement,
        isRequired: false,
      },
    ],
    [data]
  );

  

  const [financePatchRequest, financePatchResponse] = useRequest({
    path: FINANCEANDDEPOSITE,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const dispatch = useDispatch();


  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.paid, data.paid],
        [controls.statement, data.statement],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          paid: controls.paid,
          statement: controls.statement,
        },
        output: "object",
      });

      financePatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "finance_deposite/putItem",
            payload:{ id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };
  
  useAfterEffect(() => {
    if (!open) return;
  },[open]);
  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.editFinanceData[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.paid[lang]}
          placeholder={globalLang.paid[lang]}
          value={controls.paid}
          onChange={(e) => setControl("paid", e.target.value)}
        />
        <DialogInputField
          label={globalLang.statement[lang]}
          placeholder={globalLang.statement[lang]}
          value={controls.statement}
          onChange={(e) => setControl("statement", e.target.value)}
        />
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={financePatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {financePatchResponse.failAlert}
    </Dialog>
  );
};
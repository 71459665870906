import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Stack } from "@mui/system";
import DropBox from "../../components/DropBox";
import useRequest from "../../hooks/useRequest";
import { IMPORT_CLIENTS, IMPORT_EXPORT_CLIENTS } from "../../data/APIs";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

const ImportClients = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const importClientsLang = useSelector(
    (state) => state.lang.value.importClients
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [importClientsGetRequest, importClientsGetResponse] = useRequest({
    path: IMPORT_EXPORT_CLIENTS,
    method: "get",
    responseType: "blob",
  });

  const handleDownload = () => {
    importClientsGetRequest({
      onSuccess: (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sheet.xls");
        document.body.appendChild(link);
        link.click();
        console.log(res.data)
      },
    });
    
  };

  const handleDownloadModel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "full_name",
        // "phone",
        // "email",
        // "channel",
        // "agent",
        // "project",
        // "max_budget",
        // "event",
        // "comment",
      ],
    ]);

    XLSX.utils.book_append_sheet(wb, ws, "clientsSheet");

    XLSX.writeFile(wb, "CRM Clients Sheet.xls");
  };

  return (
    <Wrapper>
      <Breadcrumbs
        path={[
          globalLang.customers[lang],
          importClientsLang.importClients[lang],
        ]}
      />
      <Stack alignItems="center" spacing={2} >
        <DropBox
          buttonLabel={globalLang.downloadFile[lang]}
          onClick={handleDownload}
          isPending={importClientsGetResponse.isPending}
          download={true}
          title={globalLang.downloadCustomerData[lang]}
        />
        {/* <Button
          variant="contained"
          sx={{ width: "200px", height: "50px" }}
          onClick={handleDownloadModel}
        >
          {globalLang.downloadTemplate[lang]}
        </Button> */}
      </Stack>
    </Wrapper>
  );
};

export default ImportClients;

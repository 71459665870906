import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  BACKANDFORTHRECEIPT,
  STATIONBILL,
  STATIONENTERBILL,
  STATIONEXITBILL,
  STATIONS,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import Wrapper from "../../../components/Wrapper";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import FilterForm from "../../../components/FilterationForm";
const ShippingReceipt = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const backandforthreceiptLang = useSelector(
    (state) => state.lang.value.addhomereceipt
  );
  const stationStore = useSelector((state) => state.stations.value);

  const StationEnterBillStore = useSelector(
    (state) => state.station_enter_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [StationBillPostRequest, StationBillPostResponse] = useRequest({
    path: STATIONBILL,
    method: "post",
    successMessage: "تم التعديل بنجاح",
  });

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    Enter_Permission_Station_bill: "",
  });
const getBill = (bill) => {
  setfilterdata({ Enter_Permission_Station_bill: bill });
};
  const [EnterPermissiondata, setEnterPermissiondata] = useState({});
  const [station_bills, setStationBills] = useState(
    EnterPermissiondata?.bills
  );

  const [gasBill, setGasBill] = useState([
    {
      station: "",

      bill_id:"",
      product_uuid: "",
      price: "",
      paid: "",
      dokhan: "",
  
    },
  ]);


  ///////////////////////////////
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });
  const getStations = () => {
    stationsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control:"bill_date",
      value:new Date(),
      isRequired:true,
    },
    {
      control: "fill_number",
      value: "",
      isRequired: false,
    },
    {
      control: "bills",
      value: [],
      isRequired: false,
    },
   
  ]);


  const [StationEnterBillGetRequest, StationEnterBillGetResponse] = useRequest({
    path: STATIONENTERBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getStationEnterBill = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed",false)
    StationEnterBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "station_enter_bill/set", payload: res.data });
      },
    });
  };
  console.log(gasBill);

  const handleChange = (event, index, id) => {
    let data = [...gasBill];

    data[index] = {
      ...data[index],
      [event.target.name]:
        event.target.name === "fill_type"
          ? event.target.value
          : parseInt(event.target.value),
      'bill_id':id
    };
    console.log(data)
    setGasBill(data);

    console.log(gasBill);

  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      console.log(output);

      const requestBody = filter({
        obj: {
          bill_date:controls.bill_date.toISOString(),
          permit_uuid: filterdata.Enter_Permission_Station_bill,
          bill_uuid: parseInt(controls.fill_number),
          bills: [...gasBill],
        },
        output: "object",
      });

      StationBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          handleresetFilters();
          resetControls();
          dispatch({
            type: "station_bill/addItem",
            payload: res?.data,
          });
        },
      }).then((res) => {
        let response = res?.response?.data;
        const responseBody = filter({
          obj: {
            home_date: response?.home_date,
            home_quantity_empty: response?.home_quantity_empty,
            home_quantity_filled: response?.home_quantity_filled,
            total_price: response?.total_price,
          },
          output: "object",
        });

        setInvalid(responseBody);
      });
    });
  };

  const handleFilterSubmit = () => {


    if (!filterdata.Enter_Permission_Station_bill) {
      return;
    }

    StationEnterBillGetRequest({
      id: StationEnterBillStore.results?.find(
        (bf) => bf?.permit_uuid === filterdata?.Enter_Permission_Station_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setEnterPermissiondata(res?.data);
      },
    });
  };
  const handleresetFilters = () => {
    setfilterdata({ Enter_Permission_Station_bill: "" });
    setGasBill([]);
    setfiltered(true);

    setIsCleared(uuid());
  };

  return (
    <>
  
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.Enter_Permission_Station_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <FilterForm
            data={StationEnterBillStore}
            getData={getStationEnterBill}
            isPending={StationEnterBillGetResponse.isPending}
            label={globalLang.exit_enter_number[lang]}
            getFormData={getBill}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: StationBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: StationBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ gap: 2 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "40px",
                  // width: "100%",
                },
                // flexWrap:"wrap"
              }}
            >
              <InputField
                disabled
                label={globalLang.Exit_number[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                defaultValue={filterdata.Enter_Permission_Station_bill}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                label={globalLang.exit_date[lang]}
                value={format(EnterPermissiondata?.exit_date)}
              />
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5 !important",
                  },
                }}
                label={globalLang.enter_date[lang]}
                value={format(EnterPermissiondata?.enter_date)}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.driver[lang]}
                placeholder={globalLang.driver[lang]}
                value={EnterPermissiondata?.driver?.name}
              ></InputField>
              <InputField
                disabled
                label={globalLang.transportation[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.transportation[lang]}
                value={EnterPermissiondata?.transport?.name}
              ></InputField>
              <NumberField
                label={globalLang.fill_number[lang]}
                placeholder={globalLang.fill_number[lang]}
                value={controls.fill_number}
                onChange={(e) => setControl("fill_number", e.target.value)}
                required={required.includes("fill_number")}
                error={Boolean(invalid.fill_number)}
                helperText={invalid.fill_number}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={globalLang.billDate[lang]}
                    value={controls.bill_date}
                    onChange={(newValue) => {
                      setControl("bill_date", newValue);
                    }}
                    renderInput={(params) => (
                      <InputField
                        sx={{
                          "& .MuiInputBase-root": {
                    
                            borderColor: "#F0F0F8",
                            paddingRight: "5px",
                          },
                          "& .MuiFormLabel-root": {
                            color: "#666666",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
            </Stack>

            <Box>
              <Stack sx={{ gap: 2 }}>
                <TableContainer
                  component={Paper}
                  sx={{
                    // padding: "10px",
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "90%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography  sx={{ flexGrow: "1",fontSize:'1.3rem' }}>
                      الغازات
                    </Typography>
                    {/* <Tooltip title="أضف منتج ">
                      <AddIcon
                        htmlColor="green"
                        sx={{ cursor: "pointer" }}
                        onClick={handleAddGasRow}
                      />
                    </Tooltip> */}
                  </Stack>

                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {globalLang.exit_quantity[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.enter_quantity[lang]}
                        </TableCell>
                        <TableCell>{globalLang.product[lang]}</TableCell>

                        <TableCell align="left">
                          {globalLang.volume[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.fill_type[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.returned[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.receiptnumber[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.station[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.price[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.paid[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.tips[lang]}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {EnterPermissiondata?.bills?.map(
                        (bill, Gasindex) =>
                          bill.product_type === "cylinder" && (
                            <TableRow
                              key={`${bill.id}${Gasindex}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                <Typography
                                 component="span" variant="p"
                                  name="quantityout"
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityout === 0
                                    ? "0"
                                    : bill.quantityout}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                             component="span" variant="p"
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityin ? bill.quantityin : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.product_name}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.volume}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.fill_type ? bill.fill_type : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityreturned
                                    ? bill.quantityreturned
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.receiptnumber[lang]}
                                  name="product_uuid"
                                  type="number"
                                  onChange={(e) => {
                                    
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                  
                                ></InputField>
                              </TableCell>

                              <TableCell>
                                <SelectField
                                  placeholder={globalLang.station[lang]}
                                  renderValue={(selected) => {
                                    return stationStore?.results?.find(
                                      (station) => station?.id === selected
                                    )?.user?.name;
                                  }}
                                  name="station"
                                  isPending={stationsGetResponse.isPending}
                                  onOpen={getStations}
                                  onChange={(e) => {
                                    setControl("station_gas", e.target.value);
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                  value={gasBill[Gasindex]?.station || ""}
                                  error={Boolean(invalid.station_gas)}
                                  helperText={invalid.station_gas}
                                  required={required.includes("station_gas")}
                                >
                                  {stationStore?.results?.map(
                                    (station, index) => (
                                      <MenuItem
                                        value={station?.id}
                                        key={`${station?.user?.name} ${index}`}
                                      >
                                        {station?.user?.name}
                                      </MenuItem>
                                    )
                                  )}
                                </SelectField>
                              </TableCell>

                              <TableCell>
                                <InputField
                                  placeholder={globalLang.price[lang]}
                                  name="price"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("price_gas", e.target.value);
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                  error={Boolean(invalid.price_gas)}
                                  helperText={invalid.price_gas}
                                  required={required.includes("price_gas")}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.paid[lang]}
                                  name="paid"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("paid_gas", e.target.value);
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                  error={Boolean(invalid.paid_gas)}
                                  helperText={invalid.paid_gas}
                                  required={required.includes("paid_gas")}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.tips[lang]}
                                  name="dokhan"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("dokhan_gas", e.target.value);
                                    handleChange(e, Gasindex, bill.id);
                                  }}
                                  error={Boolean(invalid.dokhan_gas)}
                                  helperText={invalid.dokhan_gas}
                                  required={required.includes("dokhan_gas")}
                                ></InputField>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TableContainer
                  component={Paper}
                  sx={{
                
                    height: "fit-content",
                    "& .MuiInputBase-input": {
                      minWidth: "90px",
                      width: "100%",
                    },
                  }}
                >
                  <Stack sx={{ flexDirection: "row" }}>
                    <Typography  sx={{ flexGrow: "1",fontSize:'1.3rem' }}>
                      السوائل
                    </Typography>
                  </Stack>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          {globalLang.exit_quantity[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.enter_quantity[lang]}
                        </TableCell>
                        <TableCell>{globalLang.product[lang]}</TableCell>

                        <TableCell align="left">
                          {globalLang.volume[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.fill_type[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.returned[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.receiptnumber[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.station[lang]}
                        </TableCell>

                        <TableCell align="left">
                          {globalLang.price[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.paid[lang]}
                        </TableCell>
                        <TableCell align="left">
                          {globalLang.tips[lang]}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {EnterPermissiondata?.bills?.map(
                        (bill, Liquidindex) =>
                          bill.product_type === "dewar" && (
                            <TableRow
                              key={`${bill.id}${Liquidindex}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityout ? bill.quantityout : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityin ? bill.quantityin : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.product ? bill.product_name : "-"}
                                </Typography>
                              </TableCell>

                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.volume ? bill.volume : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.fill_type ? bill.fill_type : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                  }}
                                >
                                  {bill.quantityreturned
                                    ? bill.quantityreturned
                                    : "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.receiptnumber[lang]}
                                  name="product_uuid"
                                  type="number"
                                  onChange={(e) => {
                                    
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                             
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <SelectField
                                  placeholder={globalLang.station[lang]}
                                  renderValue={(selected) => {
                                    return stationStore.results.find(
                                      (station) => station.id === selected
                                    )?.user?.name;
                                  }}
                                  name="station"
                                  isPending={stationsGetResponse.isPending}
                                  onOpen={getStations}
                                  onChange={(e) => {
                                    setControl(
                                      "station_liquid",
                                      e.target.value
                                    );
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                  value={gasBill[Liquidindex]?.station || ""}
                                  error={Boolean(invalid.station_liquid)}
                                  helperText={invalid.station_liquid}
                                  required={required.includes("station_liquid")}
                                >
                                  {stationStore?.results?.map(
                                    (station, index) => (
                                      <MenuItem
                                        value={station?.id}
                                        key={`${station?.user?.name} ${index}`}
                                      >
                                        {station?.user?.name}
                                      </MenuItem>
                                    )
                                  )}
                                </SelectField>
                              </TableCell>

                              <TableCell>
                                <InputField
                                  placeholder={globalLang.price[lang]}
                                  name="price"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("price_liquid", e.target.value);
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                  error={Boolean(invalid.price_liquid)}
                                  helperText={invalid.price_liquid}
                                  required={required.includes("price_liquid")}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.paid[lang]}
                                  name="paid"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("paid_liquid", e.target.value);
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                  error={Boolean(invalid.paid_liquid)}
                                  helperText={invalid.paid_liquid}
                                  required={required.includes("paid_liquid")}
                                ></InputField>
                              </TableCell>
                              <TableCell>
                                <InputField
                                  placeholder={globalLang.tips[lang]}
                                  name="dokhan"
                                  type="number"
                                  onChange={(e) => {
                                    setControl("dokhan_liquid", e.target.value);
                                    handleChange(e, Liquidindex, bill.id);
                                  }}
                                  error={Boolean(invalid.dokhan_liquid)}
                                  helperText={invalid.dokhan_liquid}
                                  required={required.includes("dokhan_liquid")}
                                ></InputField>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Box>
          </Stack>
        </Form>
      )}

      {StationBillPostResponse.successAlert}
      {StationBillPostResponse.failAlert}
    </>
  );
};
export default ShippingReceipt;

import React from "react";

import Wrapper from "../../../components/Wrapper";
import ExitPermissionStation from "./ExitPermissionStation";
import ViewExitPermissionStation from "./ViewExitPermissionStation";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

const StationExitBillView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorystationpermit"]}>
        <ExitPermissionStation />
      </PermissionsGate>

      <PermissionsGate permissions={["view_factorystationpermit"]}>
        <ViewExitPermissionStation />
      </PermissionsGate>
    </Wrapper>
  );
};
export default StationExitBillView;

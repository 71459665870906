import React, { useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ShipReceiptGasView from "./Shipping/ShippingReceiptGasView";
import FillingBillView from "./Filling/FillingBillView";
import DistributionBillView from "./Distribution/DistributionBillView";
import TankReceiptsView from "./TankReceipt/TankReceiptsView";
import ExpensesReceiptView from "./Expenses/ExpensesReceiptView";
import SalesBill from "./Bills/SalesBill";
import PurchaseBillOverView from "./Bills/PurchaseBillOverView";
import SalesBillOverView from "./Bills/SalesBillOverView";
import FinanceOverView from "./Bills/FinanceOverview";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

const ReceiptsPanel = ({ boxes, index }) => {
  const [value, setValue] = React.useState(index);
  const [subValue, setSub] = useState(0);

  const handleSub = (event, newValue) => {
    setSub(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(index);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            {boxes.map((box, index) => {
              return <Tab label={box.title} {...a11yProps(index)} />
            })}
          </Tabs>
        </Box>
        {boxes.map((box, index) => (
          <TabPanel value={value} key={index} index={index}>
            {box.title == "شحن" ? (
              <TabPanel value={value} index={1}>
                <ShipReceiptGasView />
              </TabPanel>
            ) : box.title == "تعبئة" ? (
              <TabPanel value={value} index={0}>
                <FillingBillView />
              </TabPanel>
            ) : box.title == "توزيع" ? (
              <TabPanel value={value} index={2}>
                <DistributionBillView />
              </TabPanel>
            ) : box.title == "شحن التانك" ? (
              <TabPanel value={value} index={3}>
                <TankReceiptsView />
              </TabPanel>
            ) : box.title == "مصروفات" ? (
              <TabPanel value={value} index={4}>
                <ExpensesReceiptView />
              </TabPanel>
            ) : (
              
                <TabPanel value={value} index={5}>
                  <Tabs
                    value={subValue}
                    onChange={handleSub}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      "& .MuiTab-root.Mui-selected": {
                        color: "black",
                        background: "rgba(35,57,117,0.2)",
                      },
                    }}
                  >
                    <Tab label="فاتورة مبيعات" {...a11yProps(0)}></Tab>
                    <Tab label="فاتورة مشتريات" {...a11yProps(1)}></Tab>
                    <Tab label="تمويل وايداع " {...a11yProps(2)}></Tab>
                  </Tabs>
                  <TabPanel value={subValue} index={0}>
                    <SalesBillOverView />
                  </TabPanel>
                  <TabPanel value={subValue} index={1}>
                    <PurchaseBillOverView />
                  </TabPanel>
                  <TabPanel value={subValue} index={2}>
                    <FinanceOverView />
                  </TabPanel>
                </TabPanel>
              
            )}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
export default ReceiptsPanel;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

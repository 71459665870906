

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COUNTER_READINGS,
  STOCK_PRACTICAL, TANK,
} from "../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { MenuItem } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

const CounterReadings = () => {

  const getDate = () => {
    const t = new Date();
    const year = t.getFullYear();
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const date = ("0" + t.getDate()).slice(-2);

    return `${year}-${month}-${date}`;
  }
  

  const lang = useSelector((state) => state.lang.value.lang);
  // const volumes = [{ name: "40", value: 40 }, { name: "50", value: 50 }, { name: "20", value: 20 }, { name: "15", value: 15 }, { name: "10", value: 10 }]
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const tankStore=useSelector((state)=>state.tank.value)
  const [responsebody, setResponse] = useState([]);
  
  // get data for client with dispatch
 


  ///////////////////////////////
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "counter_Reading",
      value: "",
      isRequired: true,
    },
    {
      control: "tanknumber",
      value: "",
      isRequired: true,
    },
    {
      control: "date",
      value: new Date(),
      isRequired: true,
    },
  ]);

  const [tankgetRequest, tankgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const getTanks = () => {
    tankgetRequest({
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };
  const [counterPostRequest, counterPostResponse] = useRequest({
    path: COUNTER_READINGS,
    method: "post",
    successMessage: "تم إضافة التانك بنجاح",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    
    validate().then((data) => {
      console.log(data);
      if (!data.isOk) return;

      //requst body 
      const requestBody = filter({
        obj: {
          reading: controls.counter_Reading,
          tank: controls.tanknumber,
          date: controls.date.toISOString(),
          
        },

        output: "object",
      });
      if (
        Object.keys(
          filter({
            obj: {
              reading: controls.counter_Reading,
              tank: controls.tanknumber,
              date: controls.date.toISOString(),
              
            },
          })
        ).length <= 1
      )
        return;

        counterPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          resetControls();
          dispatch({
            type: "counterreadings/addItem",
            payload: res?.data,
          });
        },
      }).then((res) => {
        const response = res?.response?.data;
        const responseBody = filter({
          obj: {
            id: res?.id,
            counter_Reading: res?.counter_Reading,
            tanknumber: res?.tanknumber,
            date: res?.date,
           
          },
        });
        
        if (res?.id) {
        } else {
          setInvalid(res?.message);
        }
      });
     
    });
  };

  return (
    <>
      <Form
         component="form"
         onSubmit={handleSubmit}
         childrenProps={{
           saveBtn: {
             disabled: counterPostResponse.isPending,
           },
           closeBtn: {
             disabled: counterPostResponse.isPending,
           },
         }}
      >
      
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={globalLang.DateAdded[lang]}
                value={controls.date}
                
                onChange={(newValue) => {
            
                  setControl("date", newValue);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <SelectField
              label={globalLang.tanknumber[lang]}
              placeholder={globalLang.tanknumber[lang]}
              renderValue={(selected) => {
                return tankStore?.results.find(
                  (tank) => tank?.id === selected
                )?.tank_uuid;
              }}
              isPending={tankgetResponse?.isPending}
              onOpen={getTanks}
              onChange={(e) => {
                setControl("tanknumber", e.target.value);
              }}
              value={controls.tanknumber}
              required={required.includes("tanknumber")}
              error={Boolean(invalid.tanknumber)}
              helperText={invalid.tanknumber}
            >
              {tankStore?.results?.map((tank, index) => (
                <MenuItem value={tank?.id} key={`${tank?.name} ${index}`}>
                  {tank?.tank_uuid}
                </MenuItem>
              ))}
            </SelectField>
            <NumberField
              label={globalLang.counter_Reading[lang]}
              placeholder={globalLang.counter_Reading[lang]}
              onChange={(e) => setControl("counter_Reading", e.target.value)}
              value={controls.counter_Reading}
              required={required.includes("counter_Reading")}
              error={Boolean(invalid.counter_Reading)}
              helperText={invalid.counter_Reading}
            ></NumberField>
           
      </Form>
     
      {counterPostResponse.successAlert}
      {counterPostResponse.failAlert}
    </>
  );
};

export default CounterReadings;

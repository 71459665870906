import React from "react";
import SalesBill from "./SalesBill";
import SalesBillView from "./SalesBillView";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
function SalesBillOverView (){
    return(
        <Wrapper>
        <PermissionsGate permissions={['add_factorysalesbills']}>
        <SalesBill/>
        </PermissionsGate>
        <PermissionsGate permissions={['view_factorysalesbills']}>
            <SalesBillView/>
        </PermissionsGate>
            
        </Wrapper>
    )
}
export default SalesBillOverView
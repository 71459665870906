import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Stack } from "@mui/system";
import PicturePicker from "../../components/PicturePicker";
import useControls from "../../hooks/useControls";
import Form, { InputField, SelectField } from "../../features/form";
import { Button, MenuItem } from "@mui/material";
import useRequest from "../../hooks/useRequest";
import { CHANNELS, EXPENSES } from "../../data/APIs";
import filter from "../../utils/ClearNull";
import { useDispatch, useSelector } from "react-redux";

const AddExpenses = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addExpenseLang = useSelector((state) => state.lang.value.addExpense);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([

    {
      control: "name",
      value: "",
      isRequired: true,
    },
    {
      control: "category",
      value: "",
      isRequired: true,
    },
  ]);

  const category = [
    { name: "تسويق", value: 0 },
    { name: "تشغيل", value: 1 },
    { name: "أاصول رأس مالية", value: 2 },
  ];
  const [expensesPostRequest, expensesPostResponse] = useRequest({
    path: EXPENSES,
    method: "post",
    successMessage: "تم إضافة مصروف بنجاح",
  });
  const dispatch=useDispatch()
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          name: controls.name,
          category:controls.category
        
        },
        output: "formData",
      });

      expensesPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          resetControls();
          if(res.status===200 || res.status===201){
            dispatch({ type: "expenses/addItem", payload: res?.data });
          }
          else{
            setInvalid(res?.data)
          }
        },
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[globalLang.expenses[lang], addExpenseLang.addExpense[lang]]}
      /> */}
      <Stack
        spacing={2}
        alignItems="center"
        sx={{ width: "max-content" }}
        component="form"
        onSubmit={handleSubmit}
        noValidate
      >
      
        
          <Form hideFooter hideHeader sx={{ flex: 1 }} maxChildWidth="550px">
            <InputField
              label={globalLang.name[lang]}
              placeholder={globalLang.name[lang]}
              value={controls.name}
              onChange={(e) => setControl("name", e.target.value)}
              required={required.includes("name")}
              error={Boolean(invalid.name)}
              helperText={invalid.name}
            />
            <SelectField
                label={globalLang.category[lang]}
                placeholder={globalLang.category[lang]}
                renderValue={(selected) => {
                  return category.find((type) => type.value === selected)
                    .name;
                }}
                onChange={(e) => {
                  setControl("category", e.target.value);
                }}
                value={controls.category}
                required={required.includes("category")}
                error={Boolean(invalid.category)}
                helperText={invalid.category}
                sx={{
                  width: "45%",
                  width: {
                    xl: "45%",
                    lg: "45%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mb: 2,
                }}
              >
                {category.map((category, index) => (
                  <MenuItem
                    value={category.value}
                    key={` ${index}`}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </SelectField>
          </Form>

        <Button
          variant="contained"
          type="submit"
          disabled={expensesPostResponse.isPending}
          sx={{ width: 140, height: 50 }}
        >
          {globalLang.save[lang]}
        </Button>
      </Stack>
      {expensesPostResponse.successAlert}
      {expensesPostResponse.failAlert}
    </>
  );
};

export default AddExpenses;

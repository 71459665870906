import React from "react";
import { TextField } from "@mui/material";

const InputField = ({
  variant = null,
  onChange,
  label = "",
  value,
  placeholder = "",
  color = null,
  sx = {},
  type = "",
  ...rest
}) => {
  return (
    <TextField
      variant={variant ? variant : "standard"}
      label={label}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      type={type}
      color={color}
      sx={{
        "input::-webkit-outer-spin-button , input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        ...sx,
      }}
      {...rest}
    />
  );
};

export default InputField;

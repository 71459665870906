import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import AddClient from "./AddClient";
import ViewClients from "./ViewClients";

const ClientsView = () => {

  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factoryclient"]}>
        <AddClient />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factoryclient"]}>
        <ViewClients />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ClientsView;

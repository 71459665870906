import React, { useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { CHANNELS, EXPENSES } from "../../data/APIs";
import { useEffect } from "react";
import useDataGrid from "../../hooks/useDataGrid";
import { Avatar, TextField } from "@mui/material";
import format from "../../utils/ISOToReadable";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import { Stack } from "@mui/system";
import { InputField } from "../../features/form";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

const ViewExpenses = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewExpensesLang = useSelector(
    (state) => state.lang.value.viewExpenses
  );
  const globalLang = useSelector((state) => state.lang.value.global);
const confirmMessage = useSelector(
  (state) => state.lang.value.DeleteMessages.expensesMessage
);
  ///////////////////////////////
  const expensesStore = useSelector((state) => state.expenses.value);

  const columns = [

    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: ({ name }) => (name ? name : globalLang.unknown[lang]),
    },
    {
      field: "createdAt",
      headerName: globalLang.createdAt[lang],
      customContent: ({ created_at }) => format(created_at),
    },
  ];

  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];

  const dispatch = useDispatch();

  const [expensesGetRequest, expensesGetResponse] = useRequest({
    path: EXPENSES,
    method: "get",
  });

  const getExpenses = (urlParams) => {
    expensesGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "expenses/set", payload: res.data });
      },
    });
  };

  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: getExpenses,
  });

  const [expensesDeleteRequest, expensesDeleteResponse] = useRequest({
    path: EXPENSES,
    method: "delete",
    successMessage: "تم حذف المصروف بنجاح",
  });

  const deleteExpense= (e, row) => {
    expensesDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "expenses/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteExpense, deleteExpenseConfirmDialog] = useConfirmMessage({
    onConfirm: deleteExpense,
    text: confirmMessage[lang],
  });

  const isPermitted = useIsPermitted();

  return (
    <>
      <Breadcrumbs
        path={[globalLang.expenses[lang], viewExpensesLang.viewExpenses[lang]]}
      />
      <DataGrid
        columns={columns}
        rows={expensesStore.results}
        isPending={expensesGetResponse.isPending}
        total={expensesStore.count}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteExpense, ["delete_factoryexpense"])}
        filters={filters}
      />
      {deleteExpenseConfirmDialog}
      {expensesDeleteResponse.successAlert}
      {expensesDeleteResponse.failAlert}
    </>
  );
};

export default ViewExpenses;

const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};

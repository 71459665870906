import React from "react";

import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import EnterPermissionStation from "./EnterPermissionStationBill";
import ViewStationEnterBill from "./ViewStationEnterBill";
const StationEnterBillView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorystationpermit"]}>
        <EnterPermissionStation />
      </PermissionsGate>

      <PermissionsGate permissions={["view_factorystationpermit"]}>
        <ViewStationEnterBill />
      </PermissionsGate>
    </Wrapper>
  );
};
export default StationEnterBillView;

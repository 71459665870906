import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
  CLIENTS,

  PRODUCTS,

  TRADEROVERVIEW,

  TRADERS,

} from "../../data/APIs";
;

import Form, { SelectField } from "../../features/form";
import { MenuItem, TextField } from "@mui/material";

import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

import {

  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import { Stack } from "@mui/system";
import format from "../../utils/ISOToReadable";

const FilterTraders = () => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const TraderBillStore = useSelector((state) => state.traderOverview.value);
  const tradersStore = useSelector((state) => state.traders.value);

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };
  ///////////////////////////////
  const [filterdata, setfilterdata] = useState({
    trader: "",
    startDate: "",
    endDate: "",
  });



  const columns = [
    {
      field: "bill_date",
      headerName: globalLang.billDate[lang],
      customContent: ({ bill_date }) => (bill_date ? format(bill_date) : "-"),
    },
     {
       field: "bill_uuid",
       headerName: globalLang.receiptnumber[lang],
     },
     {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ product }) => product?.name,
    },

    {
      field: "enter_empty",
      headerName: globalLang.enter_empty[lang],
      customContent: ({ enter_empty }) =>
      enter_empty === 0 ? "0" : enter_empty ? enter_empty : "-",
    },
    {
      field: "enter_filled",
      headerName: globalLang.enter_filled[lang],
      customContent: ({ enter_filled }) =>
      enter_filled === 0 ? "0" : enter_filled ? enter_filled : "-",
    },
    {
      field: "exit_empty",
      headerName: globalLang.exit_empty[lang],
      customContent: ({ exit_empty }) =>
      exit_empty === 0 ? "0" : exit_empty ? exit_empty : "-",
    },
    {
      field: "exit_filled",
      headerName: globalLang.exit_filled[lang],
      customContent: ({ exit_filled }) =>
      exit_filled === 0 ? "0" : exit_filled ? exit_filled : "-",
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
      customContent: ({ quantityreturned }) =>
        quantityreturned === 0
          ? "0"
          : quantityreturned
          ? quantityreturned
          : "-",
    },
    {
      field: "fill",
      headerName: globalLang.fill[lang],
      customContent: ({ fill }) => (fill === 0 ? "0" : fill ? fill : "-"),
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) => (price === 0 ? "0" : price ? price : "-"),
    },
    {
      field: "fillprice",
      headerName: globalLang.fill_price[lang],
      customContent: ({ fillprice }) =>
        fillprice === 0 ? "0" : fillprice ? fillprice : "-",
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid === 0 ? "0" : paid ? paid : "-"),
    },
    {
      field: "remain_price",
      headerName: globalLang.remainprice[lang],
      customContent: ({ remain_price }) =>
        remain_price === 0 ? "0" : remain_price ? remain_price : "-",
    },
    {
      field: "dokhan",
      headerName: globalLang.tips[lang],
      customContent: ({ dokhan }) =>
        dokhan === 0 ? "0" : dokhan ? dokhan : "-",
    },
    {
      field: "safi",
      headerName: globalLang.all[lang],
      customContent: ({ safi }) => (safi === 0 ? "0" : safi ? safi : "-"),
    },
    {
      field: "store",
      headerName: globalLang.wallet[lang],
      customContent: ({ store }) => (store === 0 ? "0" : store ? store : "-"),
    },
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ client }) => (client ? client : "-"),
    },
    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ trader }) =>(
        trader ? trader: "-"),
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) => 
      (
        statement? statement : "-"
      ),
    },
    
  ];

  const dispatch = useDispatch();
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const getTraders = () => {
    tradersGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };


  const [traderBillGetRequest, traderBillGetResponse] = useRequest({
    path: TRADEROVERVIEW,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTradersBills = () => {
    if(Object.values(filterdata).filter((val)=>val!=='').length>0){
      urlParams.delete('page')
    }
    filterdata.trader && urlParams.append("trader", filterdata.trader);
    filterdata.startDate && filterdata.endDate
      ? urlParams.append(
          "date",
          `${formatDate(String(filterdata.startDate))}-${formatDate(
            String(filterdata.endDate)
          )}`
        )
      : "";
    

    traderBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "trader-overview/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],

  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getTradersBills();
  }, [requestParams, filterdata]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };


  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };

  const handleResetFilter = () => {

    setfilterdata({
      trader: "",
      startDate: "",
      endDate: "",
    });
    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const isPermitted = useIsPermitted();

  return (
    <>
      <Form
        childrenProps={{
          saveBtn: {
            sx: { display: "none" },
          },
          closeBtn: {
            onClick: handleResetFilter,
          },
        }}
      >
        <Stack spacing={3}>
          <AutocompleteField
            label={globalLang.trader[lang]}
            sx={{
              width: {
                lg: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
                xs: "100%",
              },
            }}
            isPending={tradersGetResponse.isPending}
            onOpen={getTraders}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({ ...filterdata, trader: options.value });
                  break;
                case "clear":
                  setfilterdata({ ...filterdata, trader: "" });
              }
            }}
            data={[
              ...tradersStore.results.map((c, index) => ({
                label: c.user.first_name + " " + c.user.last_name,
                value: c.id,
              })),
            ]}
            value={filterdata.id}
            // required={required.includes("Exit_Permission_Station_bill")}
          />
          <Stack spacing={2}>
            <TextField
              variant="standard"
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              type="date"
              label={globalLang.from[lang]}
              value={filterdata.startDate}
              onChange={(e) =>
                setfilterdata({ ...filterdata, startDate: e.target.value })
              }
            />
            <TextField
              variant="standard"
              type="date"
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              label={globalLang.to[lang]}
              value={filterdata.endDate}
              disabled={filterdata.startDate ? false : true}
              onChange={(e) =>
                setfilterdata({ ...filterdata, endDate: e.target.value })
              }
            />
          </Stack>
        </Stack>
      </Form>
      {/* <Breadcrumbs
        path={[globalLang.distributionreceipt[lang], globalLang.view[lang]]}
      /> */}
      <DataGrid
        columns={columns}
        extraData={TraderBillStore?.extra_bills}
        rows={TraderBillStore?.results}
        sum={TraderBillStore.total}
        total={TraderBillStore?.count}
        isPending={traderBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}

      />

    </>
  );
};

export default FilterTraders;


import { Box, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Wrapper from "../../components/Wrapper";
import {
  CLIENTS,
  COUNTRY_FILTER,
  PRODUCTS,
  STATES,
  STOCK,
  TANK,
} from "../../data/APIs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
} from "../../features/form";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import format from "../../utils/ISOToReadable";
import { DialogInputField } from "../../features/dialog";

const AddTank = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const addTankLang = useSelector((state) => state.lang.value.addTank);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [responsebody, setResponse] = useState([]);
  const productsStore = useSelector((state) => state.products.value);
  const [preview, setpreview] = useState("");
  ///////////////////////////////
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "tank_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "product",
      value: "",
      isRequired: true,
    },
    {
      control: "size",
      value: "",
      isRequired: true,
    },
    // {
    //   control:"minmum",
    //   value:"",
    //   required:false,
    // }
    // ,
    {
      control:"maximum",
      value:"",
      required:false,
    }
    // ,
    // {
    //   control:"number_of_tags",
    //   value:"",
    //   required:false,
    // }
  ]);

  const [tankPostRequest, tankPostRespone] = useRequest({
    path: TANK,
    method: "post",
    successMessage: "تم الإضافة",
  });

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore.results.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };

  const urlParams = new URLSearchParams();

 

  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          tank_uuid:controls.tank_uuid,
          product: controls.product,
          size: parseFloat(controls.size),
          maximum:controls.maximum
       
     
        },
        output: "object",
      });

      if (
        Object.keys(
          filter({
            obj: {
              tank_uuid: controls.tank_uuid,
              product: controls.product,
              size: controls.size,
              maximum:controls.maximum,
       
            },
          })
        ).length <= 1
      )
        return;

      tankPostRequest({
        body: requestBody,
        onSuccess: () => {
          // getStocks();
          resetControls();
        },
      }).then((res) => {
        const response = res?.response?.data;

        if (res?.id) {
          dispatch({ type: "tank/addItem", payload: res });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs path={[globalLang.tanks[lang], addTankLang.addTank[lang]]} /> */}
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: tankPostRespone.isPending,
          },
          closeBtn: {
            disabled: tankPostRespone.isPending,
          },
        }}
      >
        <InputField
          label={globalLang.tanknumber[lang]}
          placeholder={globalLang.tanknumber[lang]}
          onChange={(e) => {
            setControl("tank_uuid", e.target.value);
          }}
          value={controls.tank_uuid}
          required={required.includes("tank_uuid")}
          error={Boolean(invalid.tank_uuid)}
          helperText={invalid.tank_uuid}
        ></InputField>
        <SelectField
          label={globalLang.product[lang]}
          placeholder={globalLang.product[lang]}
          renderValue={(selected) => {
            return productsStore.results.find(
              (product) => product.id === selected
            ).name;
          }}
          isPending={productsGetResponse.isPending}
          onOpen={getProducts}
          onChange={(e) => {
            setControl("product", e.target.value);
          }}
          value={controls.product}
          required={required.includes("product")}
          error={Boolean(invalid.product)}
          helperText={invalid.product}
        >
          {productsStore?.results?.map((product, index) => (
            <MenuItem value={product.id} key={`${product.name} ${index}`}>
              {product.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.volume_kilo[lang]}
          placeholder={globalLang.volume_kilo[lang]}
          onChange={(e) => {
            setControl("size", e.target.value);
          }}
          value={controls.size}
          required={required.includes("size")}
          error={Boolean(invalid.size)}
          helperText={invalid.size}
        ></NumberField>
        {/* <NumberField
          label={globalLang.minimum[lang]}
          placeholder={globalLang.minimum[lang]}
          onChange={(e) => {
            setControl("mimmum", e.target.value);
          }}
          value={controls.minimum}
          required={required.includes("minimum")}
          error={Boolean(invalid.minimum)}
          helperText={invalid.minimum}
        ></NumberField> */}
        <NumberField
          label={globalLang.maximum[lang]}
          placeholder={globalLang.maximum[lang]}
          onChange={(e) => {
            setControl("maximum", e.target.value);
          }}
          value={controls.maximum}
          required={required.includes("maximum")}
          error={Boolean(invalid.maximum)}
          helperText={invalid.maximum}
        ></NumberField>
        {/* <NumberField
          label={globalLang.number_of_tags[lang]}
          placeholder={globalLang.number_of_tags[lang]}
          onChange={(e) => {
            setControl("number_of_tags", e.target.value);
          }}
          value={controls.number_of_tags}
          required={required.includes("number_of_tags")}
          error={Boolean(invalid.number_of_tags)}
          helperText={invalid.number_of_tags}
        ></NumberField> */}
      </Form>
      {tankPostRespone.successAlert}
      {tankPostRespone.failAlert}
    </>
  );
};

export default AddTank;

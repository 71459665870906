import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import Wrapper from "../../components/Wrapper";
import { COUNTRY_FILTER, STOCK, TRANSPORT } from "../../data/APIs";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import format from "../../utils/ISOToReadable";

const ViewTransport = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const ViewTransportLang = useSelector(
    (state) => state.lang.value.ViewTransport
  );
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.TransportMessage
  );
  const [stocks, setstocks] = useState([]);
  ///////////////////////////////
  const transportStore = useSelector((state) => state.transport.value);

  const columns = [
    {
      field: "name",
      headerName: globalLang.name[lang],
    
    },
    // {
    //   field: "factory",
    //   headerName: globalLang.factory[lang],
    //   customContent: ({ factory }) => factory?.name,
    // },
 
    {
      field: "created_at",
      headerName: globalLang.createdAt[lang],
      customContent: (params) => {
        return `${params?.created_at ? format(params?.created_at) : "-"}`;
      },
    },
  ];

  const dispatch = useDispatch();

  const [transportGetRequest, transportGetResponse] = useRequest({
    path: TRANSPORT,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTransports = () => {
    transportGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "transport/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });
//   useEffect(() => {
//     setstocks(stocksStore);
//   }, [stocksStore]);

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getTransports();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [transportDeleteRequest, transportDeleteResponse] = useRequest({
    path: TRANSPORT,
    method: "delete",
    successMessage: "تم الحذف بنجاح",
  });

  const deleteTransport = (e, row) => {
    transportDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "transport/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteTransport, deleteTransportConfirmDialog] =
    useConfirmMessage({
      onConfirm: deleteTransport,
      text: confirmMessage[lang],
    });

//   const [{ controls }, { setControl, resetControls }] = useControls([
//     {
//       control: "client",
//       value: "",
//       isRequired: false,
//     },
//     {
//       control: "factory",
//       value: "",
//       isRequired: false,
//     },
//     {
//       control: "product",
//       value: "",
//       isRequired: false,
//     },
//     {
//       control: "product_type",
//       value: "",
//       isRequired: false,
//     },
//   ]);

//   const handleFilter = () => {
//     const requestBody = filter({
//       obj: {
//         name: controls.name,
//         logo: controls.logo,
//         url: controls.website,
//         // state: controls.governorate,
//         // city: controls.city,
//         // area: controls.district,
//         // part: controls.near,
//         // flat_number: controls.number,
//         // unit_type: controls.type,
//         // sales_type: controls.sale,
//         // floor_number: controls.floor,
//         // area_size: controls.area,
//         // room_number: controls.rooms,
//         // bath_count: controls.bathrooms,
//         // complete_type: controls.genre,
//         // price: controls.price,
//         // client: controls.client,
//       },
//     });

//     setRequestParams((old) => ({
//       ...old,
//       filters: Object.entries(requestBody),
//     }));
//   };

//   const handleResetFilter = () => {
//     resetControls();

//     setRequestParams((old) => ({
//       ...old,
//       filters: [],
//     }));
//   };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

  const [countriesGetRequest, countriesGetResponse] = useRequest({
    path: COUNTRY_FILTER,
    method: "get",
  });

  //   const getCountries = () => {
  //     countriesGetRequest({
  //       onSuccess: (res) => {
  //         setCountriesData(res.data.data);
  //       },
  //     });
  //   };

  //   const [governoratesData, setGovernoratesData] = useState([]);

  //   const [statesGetRequest, statesGetResponse] = useRequest({
  //     path: STATES,
  //     method: "post",
  //   });

  //   const getGovernorates = () => {
  //     statesGetRequest({
  //       body: {
  //         country: controls.country,
  //       },
  //       onSuccess: (res) => {
  //         setGovernoratesData(res.data.data);
  //       },
  //     });
  //   };

  //===End===== Get Countries logic =========

  return (
    <>
      <Breadcrumbs
        path={[
          globalLang.transportation[lang],
          ViewTransportLang.ViewTransport[lang],
        ]}
      />

      <DataGrid
        columns={columns}
        rows={transportStore.results}
        total={transportStore.count}
        isPending={transportGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        // onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        // onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onDelete={isPermitted(handleDeleteTransport,['delete_factorytransport']) }
        // onEdit={(e, row) => setEditData(row)}
        // onView={(e, row) => setInfoData(row)}
      />
      {/* <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      /> */}
      {/* <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      /> */}
      {deleteTransportConfirmDialog}
      {transportDeleteResponse.successAlert}
      {transportDeleteResponse.failAlert}
    </>
  );
};

export default ViewTransport;
// const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
//   // Change lang
//   const lang = useSelector((state) => state.lang.value.lang);
//   const globalLang = useSelector((state) => state.lang.value.global);

//   ///////////////////////////////
//   const [{ controls }, { setControl, resetControls }] = useControls(
//     [
//       {
//         control: "name",
//         value: data?.name,
//         isRequired: false,
//       },
//       {
//         control: "logo",
//         value: data?.logo,
//         isRequired: false,
//       },
//       {
//         control: "website",
//         value: data?.url,
//         isRequired: false,
//       },
//       {
//         control: "picture",
//         value: "",
//         isRequired: false,
//       },
//       {
//         control: "owner_phone",
//         value: data?.owner_phone?.phone,
//         isRequired: false,
//       },
//       {
//         control: "driver_phone",
//         value: data?.driver_phone?.phone,
//         isRequired: false,
//       },
//       {
//         control: "sales_phone",
//         value: data?.sales_phone?.phone,
//         isRequired: false,
//       },
//       {
//         control: "accountant_phone",
//         value: data?.accountant_phone?.phone,
//         isRequired: false,
//       },

//       {
//         control: "owner_code",
//         value: data?.owner_phone?.country_code,
//         isRequired: false,
//       },
//       {
//         control: "driver_code",
//         value: data?.driver_phone?.country_code,
//         isRequired: false,
//       },
//       {
//         control: "sales_code",
//         value: data?.sales_phone?.country_code,
//         isRequired: false,
//       },
//       {
//         control: "accountant_code",
//         value: data?.accountant_phone?.country_code,
//         isRequired: false,
//       },
//       {
//         control: "fax_phone",
//         value: data?.fax?.phone,
//         isRequired: false,
//       },
//       {
//         control: "fax_code",
//         value: data?.fax?.country_code,
//         isRequired: false,
//       },

//     ],
//     [data]
//   );

//   useAfterEffect(() => {
//     if (!open) return;
//   }, [open]);

//   const compare_fields = (str1, str2) => {
//     obj = {};
//     if (str1 !== str2) {
//       obj = { ...obj, str1 };
//     }
//     return obj;
//   };
//   const [stationPatchRequest, stationPatchResponse] = useRequest({
//     path: STATIONS,
//     method: "patch",
//     successMessage: "تم تعديل المحطة بنجاح",
//   });

//   const dispatch = useDispatch();
//   const [preview, setpreview] = useState("");

//   const handleSubmit = () => {
//     const isThereChange = !compare(
//       [
//         [controls.name, data.name],
//         [controls.logo, data.logo],
//         [controls.website, data.url],
//         [controls.owner_phone, data.owner_phone.phone],
//         [controls.owner_code, data.owner_phone.country_code],
//         [controls.driver_phone, data.driver_phone.phone],
//         [controls.driver_code, data.driver_phone.country_code],
//         [controls.accountant_phone, data.accountant_phone.phone],
//         [controls.accountant_code, data.accountant_phone.country_code],
//         [controls.sales_phone, data.sales_phone.phone],
//         [controls.sales_code, data.sales_phone.country_code],
//         [controls.fax_phone, data.fax.phone],
//         [controls.fax_code, data.fax.country_code],
//         // [controls.district, data.area],
//         // [controls.near, data.part],
//         // [controls.address, data.address],
//         // [controls.number, data.flat_number],
//         // [controls.type, data.unit_type],
//         // [controls.floor, data.floor_number],
//         // [controls.area, data.area_size],
//         // [controls.rooms, data.room_number],
//         // [controls.bathrooms, data.bath_count],
//         // [controls.genre, data.complete_type],
//         // [controls.price, data.price],
//         // [controls.client, data.client],
//         // [controls.phone, data.phone_client],
//         // [controls.countryCode, data.country_code_phone_client],
//         // [controls.notes, data.comment],
//       ],
//       true
//     );

//     if (isThereChange) {
//       const requestBody = filter({
//         obj: {
//           name: controls.name,
//           logo: controls.logo,
//           url: controls.website,
//           fax: controls.fax_code + controls.fax_phone,
//           owner_phone: controls.owner_code + controls.owner_phone,
//           driver_phone: controls.driver_code + controls.driver_phone,
//           accountant_phone:
//             controls.accountant_code + controls.accountant_phone,
//           sales_phone: controls.sales_code + controls.sales_phone,
//           // area: controls.district,
//           // part: controls.near,
//           // flat_number: controls.number,
//           // unit_type: controls.type,
//           // sales_type: controls.sale,
//           // floor_number: controls.floor,
//           // area_size: controls.area,
//           // room_number: controls.rooms,
//           // bath_count: controls.bathrooms,
//           // complete_type: controls.genre,
//           // price: controls.price,
//           // client: controls.client,
//           // phone_client: controls.countryCode + controls.phone,
//           // comment: controls.notes,
//         },
//         output: "formData",
//       });

//       stationPatchRequest({
//         id: data.id,
//         body: requestBody,
//         onSuccess: (res) => {
//           dispatch({
//             type: "stations/putItem",
//             payload: { id: res.data.id, item: res.data },
//           });
//           onClose();
//         },
//       });
//     }
//   };
//   //===Start===== Get Countries logic =========
//   const [countriesData, setCountriesData] = useState([]);

//   const [countriesGetRequest, countriesGetResponse] = useRequest({
//     path: COUNTRY_FILTER,
//     method: "get",
//   });

//   const getCountries = () => {
//     if (countriesData.length) return;
//     countriesGetRequest({
//       onSuccess: (res) => {
//         setCountriesData(res.data.data);
//       },
//     });
//   };

//   const [governoratesData, setGovernoratesData] = useState([]);

//   const [statesGetRequest, statesGetResponse] = useRequest({
//     path: STATES,
//     method: "post",
//   });

//   const getGovernorates = () => {
//     if (governoratesData.length) return;
//     statesGetRequest({
//       body: {
//         country: controls.country,
//       },
//       onSuccess: (res) => {
//         setGovernoratesData(res.data.data);
//       },
//     });
//   };

//   //===End===== Get Countries logic =========

//   return (
//     <Dialog open={open} onClose={onClose} paperProps={{ height: "100%" }}>
//       <DialogHeading>{globalLang.editstationData[lang]}</DialogHeading>

//       <DialogForm>
//         <Avatar
//           src={controls.picture ? controls.picture : controls.logo}
//           sx={{ borderRadius: "0", width: "20%", height: "100%" }}
//         ></Avatar>
//         {/* <Box
//           component="input"
//           type="file"
//           onChange={(e, path) => {
//             // setpreview(path);
//             setControl("logo", e.target.files[0]);
//           }}
//         ></Box> */}
//         <PicturePicker
//           description={globalLang.logo[lang]}
//           picture={controls.picture}
//           onChange={(e, path) => {
//             setControl("logo", e.target.files[0]);
//             setControl("picture", path);
//           }}
//         ></PicturePicker>
//         {/* <Stack sx={{ flexDirection: "row", rowGap: "3" }}>
//           <Avatar
//             src={controls.logo}
//             sx={{ borderRadius: "0", width: "150px", height: "150px" }}
//           ></Avatar>
//           <PicturePicker

//             description={globalLang.logo[lang]}

//             picture={controls}
//             onChange={(e, path) => {
//               setControl("logo", e.target.files[0]);
//               setControl("picture", path);
//             }}
//           ></PicturePicker>
//         </Stack> */}

//         {/* <DialogSelectField
//           label={globalLang.country[lang]}
//           placeholder={globalLang.country[lang]}
//           onOpen={getCountries}
//           isPending={countriesGetResponse.isPending}
//           value={controls.country}
//           onChange={(e) => {
//             setControl("country", e.target.value);
//             setControl("governorate", "");
//             setControl("city", "");
//           }}
//         >
//           {countriesData.map((country, index) => (
//             <MenuItem value={country} key={`country ${index}`}>
//               {country}
//             </MenuItem>
//           ))}
//         </DialogSelectField>
//         <DialogSelectField
//           label={globalLang.governorate[lang]}
//           placeholder={globalLang.governorate[lang]}
//           disabled={!Boolean(controls.country)}
//           onOpen={getGovernorates}
//           isPending={statesGetResponse.isPending}
//           value={controls.governorate}
//           onChange={(e) => {
//             setControl("governorate", e.target.value);
//             setControl("city", "");
//           }}
//         >
//           {governoratesData.map((governorate, index) => (
//             <MenuItem value={governorate} key={`state ${index}`}>
//               {governorate}
//             </MenuItem>
//           ))}
//         </DialogSelectField> */}

//         <DialogInputField
//           label={globalLang.name[lang]}
//           placeholder={globalLang.name[lang]}
//           value={controls.name}
//           onChange={(e) => setControl("name", e.target.value)}
//         />

//         <DialogInputField
//           placeholder={globalLang.website[lang]}
//           label={globalLang.website[lang]}
//           value={controls.website}
//           onChange={(e) => setControl("website", e.target.value)}
//         />

//         <DialogPhoneField
//           placeholder={globalLang.fax[lang]}
//           label={globalLang.fax[lang]}
//           selectProps={{
//             value: controls.fax_code,
//             onChange: (e) => setControl("fax_code", e.target.value),
//           }}
//           value={controls.fax_phone}
//           onChange={(e) => setControl("fax_phone", e.target.value)}
//         />
//         <DialogPhoneField
//           placeholder={globalLang.owner_phone[lang]}
//           label={globalLang.owner_phone[lang]}
//           selectProps={{
//             value: controls.owner_code,
//             onChange: (e) => setControl("owner_code", e.target.value),
//           }}
//           value={controls.owner_phone}
//           onChange={(e) => setControl("owner_phone", e.target.value)}
//         />
//         <DialogPhoneField
//           placeholder={globalLang.driver_phone[lang]}
//           label={globalLang.driver_phone[lang]}
//           selectProps={{
//             value: controls.driver_code,
//             onChange: (e) => setControl("driver_code", e.target.value),
//           }}
//           value={controls.driver_phone}
//           onChange={(e) => setControl("driver_phone", e.target.value)}
//         />
//         <DialogPhoneField
//           placeholder={globalLang.accountant_phone[lang]}
//           label={globalLang.accountant_phone[lang]}
//           selectProps={{
//             value: controls.accountant_code,
//             onChange: (e) => setControl("accountant_code", e.target.value),
//           }}
//           value={controls.accountant_phone}
//           onChange={(e) => setControl("accountant_phone", e.target.value)}
//         />
//         <DialogPhoneField
//           placeholder={globalLang.sales_phone[lang]}
//           label={globalLang.sales_phone[lang]}
//           selectProps={{
//             value: controls.sales_code,
//             onChange: (e) => setControl("sales_code", e.target.value),
//           }}
//           value={controls.sales_phone}
//           onChange={(e) => setControl("sales_phone", e.target.value)}
//         />
//         {/*
//         <DialogInputField
//           placeholder={globalLang.address[lang]}
//           label={globalLang.address[lang]}
//           value={controls.address}
//           onChange={(e) => setControl("address", e.target.value)}
//         />
//         <DialogNumberField
//           placeholder={globalLang.buildingNumber[lang]}
//           label={globalLang.buildingNumber[lang]}
//           value={controls.number}
//           onChange={(e) => setControl("number", e.target.floatValue)}
//         />
//         <DialogInputField
//           placeholder={globalLang.unitType[lang]}
//           label={globalLang.unitType[lang]}
//           value={controls.type}
//           onChange={(e) => setControl("type", e.target.value)}
//         />
//         <DialogNumberField
//           placeholder={globalLang.floor[lang]}
//           label={globalLang.floor[lang]}
//           value={controls.floor}
//           onChange={(e) => setControl("floor", e.target.floatValue)}
//         />
//         <DialogNumberField
//           placeholder={globalLang.area[lang]}
//           label={globalLang.area[lang]}
//           prefix="متر "
//           value={controls.area}
//           onChange={(e) =>
//             setControl("area", e.target.floatValue.replace(/متر/gi, "").trim())
//           }
//         />
//         <DialogNumberField
//           placeholder={globalLang.roomsNumber[lang]}
//           label={globalLang.roomsNumber[lang]}
//           value={controls.rooms}
//           onChange={(e) => setControl("rooms", e.target.floatValue)}
//         />
//         <DialogNumberField
//           placeholder={globalLang.bathroomsNumber[lang]}
//           label={globalLang.bathroomsNumber[lang]}
//           value={controls.bathrooms}
//           onChange={(e) => setControl("bathrooms", e.target.floatValue)}
//         />
//         <DialogInputField
//           placeholder={globalLang.finishingType[lang]}
//           label={globalLang.finishingType[lang]}
//           value={controls.genre}
//           onChange={(e) => setControl("genre", e.target.value)}
//         />
//         <DialogNumberField
//           placeholder={globalLang.price[lang]}
//           label={globalLang.price[lang]}
//           value={controls.price}
//           thousandSeparator
//           onChange={(e) => setControl("price", e.target.floatValue)}
//         />
//         <DialogInputField
//           placeholder={globalLang.customerName[lang]}
//           label={globalLang.customerName[lang]}
//           value={controls.client}
//           onChange={(e) => setControl("client", e.target.value)}
//         />
//         <DialogPhoneField
//           placeholder={globalLang.customerPhone[lang]}
//           label={globalLang.customerPhone[lang]}
//           selectProps={{
//             value: controls.countryCode,
//             onChange: (e) => setControl("countryCode", e.target.value),
//           }}
//           value={controls.phone}
//           onChange={(e) => setControl("phone", e.target.value)}
//         />
//         <DialogInputField
//           placeholder={globalLang.notes[lang]}
//           label={globalLang.notes[lang]}
//           value={controls.notes}
//           onChange={(e) => setControl("notes", e.target.value)}
//         /> */}
//       </DialogForm>

//       <DialogButtonsGroup>
//         <DialogButton
//           onClick={handleSubmit}
//           disabled={stationPatchResponse.isPending}
//         >
//           {globalLang.save[lang]}
//         </DialogButton>
//         <DialogButton variant="close" onClick={onClose}>
//           {globalLang.cancel[lang]}
//         </DialogButton>
//       </DialogButtonsGroup>
//       {stationPatchResponse.failAlert}
//     </Dialog>
//   );
// };

import React, { useState } from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import ViewStationBillOverView from "./StationBillOverview";
import ViewFillingOverView from "./FillingOverview";
import ViewClientBillOverview from "./ClientBillOverview";
import FilterClients from "./FilterClients";
import FilterStations from "./FilterStations";
import FilterTraders from "./FilterTraders";
import StockHistory from "./StockHistory";
import DailyBillsOverView from "./DailyBillsOverView";
import ExpensesBillOverview from "./expensesBillOverview";
import StockBalance from "./StockBalance";
import ViewSafeFactory from "./ViewSafeFactory";
import CustomSafeFactory from "./CustomSafeFactory";
import ViewTank from "../Tank/ViewTank";
import TankByID from "../Tank/TankByID";
import GasesByID from "./GasesByID";
import ReviewGas from "./ReviewGas";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

const PermissionPanel = ({ index, boxes }) => {
  const [value, setValue] = React.useState(index);
  const [subValue, setSub] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSub = (event, newValue) => {
    setSub(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                // color: "black",
                background: "rgba(35,57,117,0.2)",
              },
              // "& .MuiTab-root": {
              // display:"inline-block",
              // borderLeft:"1px solid gray"
              // },
            }}
          >
            {boxes.map((box, index) => {
              return <Tab label={box.title} {...a11yProps(index)} />;
            })}
          </Tabs>
        </Box>
        {boxes.map((box, index) => (
          <TabPanel value={value} key={index} index={index}>
            {box.title == "شحن" ? (
              <TabPanel value={value} index={3}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="يومي" {...a11yProps(0)}></Tab>
                  <Tab label="شهري" {...a11yProps(1)}></Tab>
                  <Tab label="سنوي" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ViewStationBillOverView period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ViewStationBillOverView period="m" />
                </TabPanel>
                <TabPanel value={subValue} index={2}>
                  <ViewStationBillOverView period="a" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "تعبئة" ? (
              <TabPanel value={value} index={2}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="يومي" {...a11yProps(0)}></Tab>
                  <Tab label="شهري" {...a11yProps(1)}></Tab>
                  <Tab label="سنوي" {...a11yProps(2)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ViewFillingOverView period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ViewFillingOverView period="m" />
                </TabPanel>
                <TabPanel value={subValue} index={2}>
                  <ViewFillingOverView period="a" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "توزيع" ? (
              <TabPanel value={value} index={4}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="يومي" {...a11yProps(0)}></Tab>
                  <Tab label="شهري" {...a11yProps(1)}></Tab>
                  <Tab label="سنوي" {...a11yProps(2)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ViewClientBillOverview period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ViewClientBillOverview period="m" />
                </TabPanel>
                <TabPanel value={subValue} index={2}>
                  <ViewClientBillOverview period="a" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "مصروفات" ? (
              <TabPanel value={value} index={5}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="يومي" {...a11yProps(0)}></Tab>
                  <Tab label="شهري" {...a11yProps(1)}></Tab>
                  <Tab label="سنوى" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ExpensesBillOverview period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ExpensesBillOverview period="m" />
                </TabPanel>
                <TabPanel value={subValue} index={2}>
                  <ExpensesBillOverview period="a" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "عميل" ? (
              <TabPanel value={value} index={6}>
                <FilterClients />
              </TabPanel>
            ) : box.title == "تاجر" ? (
              <TabPanel value={value} index={7}>
                <FilterTraders />
              </TabPanel>
            ) : box.title == "محطة" ? (
              <TabPanel value={value} index={8}>
                <FilterStations />
              </TabPanel>
            ) : box.title == "تقارير" ? (
              <TabPanel value={value} index={1}>
                <DailyBillsOverView />
              </TabPanel>
            ) : box.title == "رصيد" ? (
              <TabPanel value={value} index={9}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="الجرد" {...a11yProps(0)}></Tab>
                  <Tab label="الرصيد" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <StockBalance period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <StockHistory period="m" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "الخزنة" ? (
              <TabPanel value={value} index={0}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="الرئيسية" {...a11yProps(0)}></Tab>
                  <Tab label="تحديد فترة زمنية" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ViewSafeFactory period="d" />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <CustomSafeFactory period="m" />
                </TabPanel>
              </TabPanel>
            ) : box.title == "تانك" ? (
              <TabPanel value={value} index={10}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="التانكات" {...a11yProps(0)}></Tab>
                  <Tab label="عرض بيانات تانك" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <ViewTank />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <TankByID />
                </TabPanel>
              </TabPanel>
            ) : box.title=="غازات"&&(
              <TabPanel value={value} index={11}>
                <Tabs
                  value={subValue}
                  onChange={handleSub}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{
                    "& .MuiTab-root.Mui-selected": {
                      color: "black",
                      background: "rgba(35,57,117,0.2)",
                    },
                  }}
                >
                  <Tab label="غازات" {...a11yProps(0)}></Tab>
                  <Tab label="مراجعة" {...a11yProps(1)}></Tab>
                </Tabs>
                <TabPanel value={subValue} index={0}>
                  <GasesByID />
                </TabPanel>
                <TabPanel value={subValue} index={1}>
                  <ReviewGas />
                </TabPanel>
              </TabPanel>
            )}
          </TabPanel>
        ))}
      </Box>
    </>
  );
};
export default PermissionPanel;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataGrid from "../../../components/DataGrid";
import Wrapper from "../../../components/Wrapper";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../../hooks/useRequest";
import {
  ClientReceiptFillGas,
  CLIENTS,
  EXPENSES,
  EXPENSESRECEIPT,
  EXPENSES_BILL_HISTORY,
  JOBS,
  JOB_PERMISSIONS,
  PERMISSIONS,
  PRODUCTS,
} from "../../../data/APIs";
import useDataGrid from "../../../hooks/useDataGrid";
import format from "../../../utils/ISOToReadable";
import { InputField, NumberField, SelectField } from "../../../features/form";
import { MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogNumberField,
  DialogSelectField,
  DialogTable,
} from "../../../features/dialog";
import PermissionToggles from "../../../components/PermissionToggles";
import useControls from "../../../hooks/useControls";
import { useRef } from "react";
import useAfterEffect from "../../../hooks/useAfterEffect";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

const ViewExpensesReceipts = () => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewexpensesreceiptLang = useSelector(
    (state) => state.lang.value.viewexpensesreceipt
  );
  const globalLang = useSelector((state) => state.lang.value.global);
  const [expensesBillDetails, setexpensesBillDetails] = useState({
    details: null,
    tab: "details",
  });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  ///////////////////////////////

  const dispatch = useDispatch();
  const expensesReceiptsStore = useSelector(
    (state) => state.expensesReceipt.value
  );
  console.log(expensesReceiptsStore)
  const [expensesReceiptGetRequest, expensesReceiptGetResponse] = useRequest({
    path: EXPENSESRECEIPT,
    method: "get",
  });

  const getexpensesReceipts = (urlParams) => {
    expensesReceiptGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "receipt_expenses/set", payload: res.data });
      },
    });
  };

  const { handlePaginate, handleChangeAmount, handleFilter } = useDataGrid({
    onParamsChange: getexpensesReceipts,
  });

  const [expensesReceiptsDeleteRequest, expensesReceiptsDeleteResponse] =
    useRequest({
      path: EXPENSESRECEIPT,
      method: "delete",
    });

  const handleDeleteExpensesReceipt = (e, row) => {
    expensesReceiptsDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({
          type: "receipt_expenses/deleteItem",
          payload: { id: row.id },
        });
      },
    });
  };
  const [expensesBillHistoryGetRequest, expensesBillHistoryGetResponse] =
    useRequest({
      path: EXPENSES_BILL_HISTORY,
      method: "get",
    });

  const getexpensesBillHistory = () => {
    expensesBillHistoryGetRequest({
      params: {
        id: expensesBillDetails.details.bill_uuid,
      },
      onSuccess: (res) => {
        dispatch({ type: "expensesBillHistory/set", payload: res.data });
      },
    });
  };
  const [handleDeleteexpenses, deleteExpensesReceiptConfirmDialog] =
    useConfirmMessage({
      onConfirm: handleDeleteExpensesReceipt,
      text: confirmMessage[lang],
    });

  const isPermitted = useIsPermitted();

  const [editData, setEditData] = useState(null);

  const handleEditReceipt = (e, row) => {
    setEditData(row);
  };

  // Filters
  const filters = [
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
    {
      name: globalLang.expenses[lang],
      component: <ExpensesFilter />,
    },
    {
      name: globalLang.date[lang],
      component: <DateFilter />,
    },
  ];

  /////////////////
  const handleCloseDetailsDialog = () => {
    setexpensesBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };
  useAfterEffect(() => {
    if (!expensesBillDetails.details) return;
    setexpensesBillDetails((old) => ({
      ...old,
      details: expensesReceiptsStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [expensesReceiptsStore]);
  // Columns name
  const columns = [
    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "created_date",
      headerName: globalLang.DateAdded[lang],
      customContent: ({ created_date }) => format(created_date),
    },
    {
      field: "expenses",
      headerName: globalLang.expenses[lang],
      customContent: ({ expenses }) => expenses?.name,
    },
    // {
    //   field: "department",
    //   headerName: globalLang.department[lang],
    //   customContent: ({ department }) => department ? department : "-",
    // },
    {
      field: "price",
      headerName: globalLang.expensesPrice[lang],
   
    },
    {
      field: "comment",
      headerName: globalLang.comment[lang],
      customContent: ({ comment }) => (comment ? comment : "-"),
    },
  ];

  /////////////////

  return (
    <>
      <Breadcrumbs path={[viewexpensesreceiptLang.viewexpensesreceipt[lang]]} />
      <DataGrid
        columns={columns}
        rows={expensesReceiptsStore.results}
        total={expensesReceiptsStore.count}
        isPending={expensesReceiptGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteexpenses, [
          "delete_factoryexpensesbill",
        ])}
        onEdit={isPermitted(handleEditReceipt, ["change_factoryexpensesbill"])}
        onView={isPermitted(
          (e, row) => {
            setexpensesBillDetails((old) => ({
              ...old,
              details: row,
            }));
          },
          ["view_historicalfactoryexpensesbill"]
        )}
  
        filters={filters}
      />
      <InfoDialog
        open={Boolean(
          expensesBillDetails.details && expensesBillDetails.tab === "details"
        )}
        onOpen={getexpensesBillHistory}
        onClose={handleCloseDetailsDialog}
        data={
          Boolean(expensesBillDetails.details) && expensesBillDetails.details
        }
        isHistoryPending={expensesBillHistoryGetResponse.isPending}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      {deleteExpensesReceiptConfirmDialog}
      {expensesReceiptsDeleteResponse.successAlert}
      {expensesReceiptsDeleteResponse.failAlert}
    </>
  );
};

export default ViewExpensesReceipts;

export const UUIDFilter = ({ value, onChange }) => {

  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);
 

  ///////////////////////////////
  const handleChange = (e) => {
  
    onChange({
      query: ["bill_uuid", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <NumberField
      placeholder={globalLang.receiptnumber[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};

const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_date",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `من ${formatDate(String(startPoint))} - إلى ${formatDate(
        String(endPoint)
      )}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};
 export const ExpensesFilter = ({ value, onChange }) => {
   // Change lang
   const lang = useSelector((state) => state.lang.value.lang);
   const globalLang = useSelector((state) => state.lang.value.global);

   ///////////////////////////////

   const [expenses, setExpenses] = useState([]);

   const handleChange = (e) => {
     onChange({
       query: ["expenses", e.target.value],
       renderedValue: expenses.find((s) => s.id === e.target.value).name,
       value: e.target.value,
     });
   };

   const [expensesGetRequest, expensesGetResponse] = useRequest({
     path: EXPENSES,
     method: "get",
   });

   const getexpenses = () => {
     expensesGetRequest({
       params: {
         size: 1000,
       },
       onSuccess: (res) => {
         setExpenses(res.data.results);
       },
     });
   };

   return (
     <SelectField
       placeholder={globalLang.expenses[lang]}
       renderValue={(selected) => expenses.find((s) => s.id === selected).name}
       onOpen={getexpenses}
       isPending={expensesGetResponse.isPending}
       onChange={handleChange}
     >
       {expenses.map((expense, index) => (
         <MenuItem value={expense.id} key={`${index}`}>
           {expense.name}
         </MenuItem>
       ))}
     </SelectField>
   );
 };
const EditDialog = ({ open, onClose, data = {} }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewfillreceiptLang = useSelector(
    (state) => state.lang.value.viewfillreceipt
  );
  const globalLang = useSelector((state) => state.lang.value.global);
  const productsStore = useSelector((state) => state.products.value);
  const clientsStore = useSelector((state) => state.clients.value);
  const expensesStore = useSelector((state) => state.expenses.value);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  ///////////////////////////////
  const allPermissions = useSelector((state) => state.allPermissions.value);
  const dispatch = useDispatch();

  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "price",
        value: data?.price,
      },
      {
        control: "expenses",
        value: data?.expenses?.id,
      },
    ],
    [data]
  );

  //===Start==== Permissions Logic =========

  const [toggles, setToggles] = useState([]);
  const initialPermissions = useRef([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [expensesGetRequest, expensesGetResponse] = useRequest({
    path: EXPENSES,
    method: "get",
  });
  const getExpenses = () => {
    expensesGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "expenses/set", payload: res.data });
      },
    });
  };
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (Boolean(productsStore?.results?.length)) return;
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };
  const [clientsGetRequest, clientsGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });
  const getClients = () => {
    if (Boolean(clientsStore?.results?.length)) return;
    clientsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  const [jobPermissionsGetRequest, jobPermissionsGetResponse] = useRequest({
    path: JOB_PERMISSIONS,
    method: "get",
  });

  const [parentPermissionsGetRequest, parentPermissionsGetResponse] =
    useRequest({
      path: JOB_PERMISSIONS,
      method: "get",
    });

  const [allPermissionsGetRequest, allPermissionsGetResponse] = useRequest({
    path: PERMISSIONS,
    method: "get",
  });

  const getJobPermissions = () => {
    jobPermissionsGetRequest({
      params: {
        id: data.id,
      },
      onSuccess: (res) => {
        initialPermissions.current = res.data.map((perm) => perm.codename);
        setSelectedPermissions(res.data.map((perm) => perm.codename));
      },
    });
  };

  const getParentPermissions = () => {
    parentPermissionsGetRequest({
      params: {
        id: controls.parent ?? data.parent.id,
      },
      onSuccess: (res) => {
        setToggles(res.data);
        getJobPermissions();
      },
    });
  };

  const getAllPermissions = () => {
    allPermissionsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "allPermissions/set", payload: res.data.permissions });
        getJobPermissions();
      },
    });
  };

  useEffect(() => {
    if (!open) return;
    if (!Boolean(data?.parent)) {
      !allPermissions.length && getAllPermissions();
      setToggles([...allPermissions]);
    } else {
      getParentPermissions();
    }
  }, [open]);

  useAfterEffect(() => {
    setToggles([...allPermissions]);
  }, [allPermissions.length]);

  //===End==== Permissions Logic =========

  //====Start====== Parent Logic =========

  const [parents, setParents] = useState([]);

  const [parentsGetRequest, parentsGetResponse] = useRequest({
    path: JOBS,
    method: "get",
  });

  const getParents = () => {
    parentsGetRequest({
      params: {
        top: 1,
        id: data.id,
      },
      onSuccess: (res) => {
        setParents(res.data);
      },
    });
  };

  //====End====== Parent Logic =========

  //====Start====== Submit Logic =========

  const [expensesreceiptPatchRequest, expensesreceiptPatchResponse] =
    useRequest({
      path: EXPENSESRECEIPT,
      method: "patch",
    });

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [data?.price, controls.price],
        [data?.expenses?.id, controls.expenses],
      ],
      true
    );

    if (!isThereChange) return;

    const requestBody = filter({
      obj: {
        price: controls.price,
        expenses: controls.expenses,

        // permissions: selectedPermissions.map((perm) => ({ codename: perm })),
      },
    });

    expensesreceiptPatchRequest({
      id: data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "receipt_expenses/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };

  //====End====== Submit Logic =========

  return (
    <Dialog
      open={open}
      onClose={onClose}
      isPending={
        // jobPermissionsGetResponse.isPending ||
        // parentPermissionsGetResponse.isPending ||
        // allPermissionsGetResponse.isPending
        expensesGetResponse.isPending
      }
      onOpen={getExpenses}
      paperProps={{ height: "fit-content" }}
    >
      <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>
      <DialogForm>
        <DialogSelectField
          label={globalLang.expenses[lang]}
          placeholder={globalLang.expenses[lang]}
          renderValue={(selected) => {
            return expensesStore?.results?.find(
              (expense) => expense.id === selected
            )?.name;
          }}
          isPending={expensesGetResponse.isPending}
          // onOpen={getProducts}
          onChange={(e) => {
            setControl("expenses", e.target.value);
          }}
          value={controls.expenses}
        >
          {expensesStore?.results?.map((expense, index) => (
            <MenuItem value={expense.id} key={`${expense.name} ${index}`}>
              {expense.name}
            </MenuItem>
          ))}
        </DialogSelectField>

        <DialogNumberField
          label={globalLang.expensesPrice[lang]}
          placeholder={globalLang.expensesPrice[lang]}
          value={controls.price}
          onChange={(e) => setControl("price", e.target.value)}
        />

        {/* {data?.parent && (
          <DialogSelectField
            label={viewJobsLang.followed[lang]}
            placeholder={viewJobsLang.followed[lang]}
            onOpen={getParents}
            isPending={parentsGetResponse.isPending}
            value={controls.to}
            onChange={(e) => {
              setControl("to", e.target.value);
              getParentPermissions();
            }}
            renderValue={(selected) => {
              return (
                parents.find((parent) => parent.id === selected)?.title ??
                data?.parent?.title
              );
            }}
          >
            {parents.map((parent, index) => (
              <MenuItem value={parent.id} key={`job ${index}`}>
                {parent.title}
              </MenuItem>
            ))}
          </DialogSelectField>
        )} */}
      </DialogForm>
      {/* <DialogContent>
        <PermissionToggles
          permissions={toggles}
          initialToggles={selectedPermissions}
          onToggle={({ toggles }) => setSelectedPermissions(toggles)}
        />
      </DialogContent> */}
      <DialogButtonsGroup>
        <DialogButton
          sx={{ width: "100%" }}
          disabled={expensesreceiptPatchResponse.isPending}
          onClick={handleSubmit}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose} sx={{ width: "100%" }}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
  onTransferAgentClick = () => {},
  onCommentsClick = () => {},
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewClientsLang = useSelector((state) => state.lang.value.viewClients);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const expensesBillhistoryStore = useSelector(
    (state) => state.expensesBillHistory.value
  );

  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  const [previewDatePatchRequest, previewDatePatchResponse] = useRequest({
    path: CLIENTS,
    method: "patch",
  });

  const handlePreviewSubmit = () => {
    previewDatePatchRequest({
      id: data.id,
      body: {
        user: {},
        followup: moment(selectedPreviewDate).format(),
      },
      onSuccess: (res) => {
        setPreviewDatePicker(null);
        dispatch({
          type: "clients/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        data.followup = res.data.followup;
      },
    }).then(() => {
      setPreviewDatePicker(null);
    });
  };

  //----variables----
  const info = [
    {
      name: globalLang.receiptnumber[lang],
      value: `${data?.bill_uuid}`,
    },
    {
      name: globalLang.DateAdded[lang],
      value: `${data?.created_date ? format(data?.created_date) : "-"}`,
    },
    {
      name: globalLang.expenses[lang],
      value: `${data?.expenses?.name}`,
    },
    {
      name: globalLang.expensesPrice[lang],
      value: `${data?.price}`,
    },
    {
      name: globalLang.comment[lang],
      value: `${data?.comment}`,
    },

    // {
    //   name: globalLang.phone[lang],
    //   value: `(${data?.user?.country_code})${data?.user?.phone}`,
    //   addition: (
    //     <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
    //       <IconButton
    //         sx={{ color: "white" }}
    //         onClick={() =>
    //           window.open(
    //             "https://wa.me/" +
    //               `${data?.user?.country_code}${data?.user?.phone}`,
    //             "_blank"
    //           )
    //         }
    //       >
    //         <WhatsAppIcon fontSize="small" sx={{ color: "#5ef979" }} />
    //       </IconButton>
    //       <IconButton
    //         sx={{ color: "white" }}
    //         onClick={() =>
    //           window.open(
    //             "https://www.truecaller.com/search/eg/" +
    //               `${data?.user?.country_code}${data?.user?.phone}`,
    //             "_blank"
    //           )
    //         }
    //       >
    //         <CallIcon fontSize="small" sx={{ color: "#127fff" }} />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
    // {
    //   name: globalLang.product[lang],
    //   value: data?.business?.map((product) => product?.name)?.join(" ، "),
    // },
    // {
    //   name: globalLang.adChannel[lang],
    //   value: data?.channel?.name,
    // },
    // {
    //   name: globalLang.employee[lang],
    //   value: data?.agent?.name,
    //   addition: (
    //     <PermissionsGate permissions={["aqartransfer_clients"]}>
    //       <IconButton sx={{ color: "white" }} onClick={onTransferAgentClick}>
    //         <ChangeCircleIcon
    //           sx={{ color: "white", transform: "scale(1.2)" }}
    //         />
    //       </IconButton>
    //     </PermissionsGate>
    //   ),
    // },
    // {
    //   name: globalLang.createdAt[lang],
    //   value: data?.created_at ? format(data?.created_at) : "",
    //   customEmpty: globalLang.notFound[lang],
    // },
    // {
    //   name: globalLang.previewDate[lang],
    //   value: data?.followup ? format(data?.followup) : "",
    //   customEmpty: globalLang.notFound[lang],
    //   addition: (
    //     <PermissionsGate permissions={[]}>
    //       <IconButton
    //         sx={{ color: "white" }}
    //         onClick={(e) => setPreviewDatePicker(e.currentTarget)}
    //       >
    //         <DateRangeIcon sx={{ color: "white", transform: "scale(1.2)" }} />
    //       </IconButton>
    //       <Menu
    //         open={openPreviewPicker}
    //         anchorEl={previewDatePicker}
    //         onClose={() => setPreviewDatePicker(null)}
    //         PaperProps={{
    //           sx: {
    //             "& .MuiList-root": {
    //               p: 2,
    //             },
    //           },
    //         }}
    //       >
    //         <LocalizationProvider dateAdapter={AdapterMoment}>
    //           <DateTimePicker
    //             label="DateTimePicker"
    //             value={selectedPreviewDate}
    //             onChange={(newValue) => {
    //               setSelectedPreviewDate(newValue);
    //             }}

    //             renderInput={(params) => (
    //               <InputField
    //                 sx={{
    //                   width: "100%",
    //                   "& .MuiInputBase-root": {
    //                     border: "none",
    //                   },
    //                 }}
    //                 {...params}
    //               />
    //             )}
    //           />
    //         </LocalizationProvider>
    //         <Button
    //           variant="contained"
    //           sx={{ width: "100%", marginTop: 3 }}
    //           disabled={previewDatePatchResponse.isPending}
    //           onClick={(e) => {
    //             handlePreviewSubmit({
    //               ...e,
    //               selectedPreviewDate,
    //               clientId: data.id,
    //             });
    //           }}
    //         >
    //           {globalLang.save[lang]}
    //         </Button>
    //       </Menu>
    //     </PermissionsGate>
    //   ),
    // },
    // {
    //   name: globalLang.comment[lang],
    //   value: data?.comment,
    //   addition: (
    //     <PermissionsGate permissions={[]}>
    //       <IconButton sx={{ color: "white" }} onClick={onCommentsClick}>
    //         <ModeCommentIcon sx={{ color: "white" }} />
    //       </IconButton>
    //     </PermissionsGate>
    //   ),
    // },
    // {
    //   name: globalLang.budget[lang],
    //   value: data?.max_budget,
    // },
  ];

  const columns = [
    // {
    //   field: "products",
    //   headerName: globalLang.products[lang],
    //   customContent: ({ rowData }) => `${rowData?.bussiness?.join(" ، ")}`,
    // },

    {
      field: "bill_uuid",
      headerName: globalLang.receiptnumber[lang],
    },

    {
      field: "created_date",
      headerName: globalLang.DateAdded[lang],
      customContent: ({ rowData }) =>
        rowData.created_date ? format(rowData.created_date) : "-",
    },
    {
      field: "expenses",
      headerName: globalLang.expenses[lang],
      customContent: ({ rowData }) =>
        rowData.expenses ? `${rowData.expenses?.name} ` : "-",
    },
    {
      field: "price",
      headerName: globalLang.expensesPrice[lang],
      customContent: ({ rowData }) => `${rowData?.price}`,
    },
    {
      field: "comment",
      headerName: globalLang.comment[lang],
      customContent: ({ rowData }) => `${rowData?.comment}`,
    },

    // {
    //   field: "agent",
    //   headerName: globalLang.employee[lang],
    // },
    {
      field: "history_user",
      headerName: globalLang.editor[lang],
      customContent: ({ rowData }) =>
        rowData.history_user
          ? `${rowData.history_user.first_name} ${rowData.history_user.last_name}`
          : "",
    },
    // {
    //   field: "comment",
    //   headerName: globalLang.comment[lang],
    // },
    // {
    //   field: "event",
    //   headerName: globalLang.status[lang],
    // },
    {
      field: "history_date",
      headerName: globalLang.dateOfProcedure[lang],
      customContent: ({ rowData }) =>
        rowData.history_date ? format(rowData.history_date) : "",
    },
    {
      field: "history_type",
      headerName: globalLang.historytype[lang],
      customContent: ({ rowData }) =>
        rowData?.history_type
          ? rowData?.history_type === "~"
            ? "تعديل"
            : "انشاء"
          : "-",
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
    onOpen();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading>
      <DialogInfoWindow information={info} />
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.billHistory[lang]}</DialogHeading>
        <DialogTable
          rows={expensesBillhistoryStore.results}
          columns={columns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

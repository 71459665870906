import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import HomeDistribution from "./commissionFrom";
import ViewHomeDistribution from "./CommissionToView";
import MediationFrom from "./commissionFrom";
import MediationFromView from "./CommissionToView";
import CommissionFrom from "./commissionFrom";
import CommissionFromView from "./CommissionFromView";
const ViewCommissionFrom = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factoryhomeawaypermit"]}>
        <CommissionFrom />
      </PermissionsGate>

      <PermissionsGate permissions={["view_factoryhomeawaypermit"]}>
        <CommissionFromView />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ViewCommissionFrom;

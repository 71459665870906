import { createSlice } from "@reduxjs/toolkit";
export const LangSlice = createSlice({
  name: "lang",
  initialState: {
    value: {
      lang: localStorage.getItem("lang")
        ? JSON.parse(localStorage.getItem("lang"))
        : "ar",
      home: { ar: "الرئيسية", en: "Home" },

      control: {
        changePassword: { ar: "تغير الرقم السري", en: "Change Password" },
        changeLanguage: { ar: "تغير اللغه", en: "Change Language" },
      },

      // Employees
      addEmployees: {
        addEmployee: { ar: "إضافة موظف", en: "Add Employee" },
        job: { ar: "الوظيفة", en: "job" },
        followed: { ar: "التابع له", en: "followed by" },
      },
      addJob: {
        addEmployee: { ar: "إضافة وظيفة موظف", en: "Add Employee Job" },
        followed: { ar: "التابع له", en: "followed by" },
      },
      viewJobs: {
        viewJobs: { ar: "عرض وظائف الموظفين", en: "View employee jobs" },
        followed: { ar: "التابع له", en: "followed by" },
        jobName: { ar: "اسم الوظيفة", en: "Job Name" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
      },
      viewEmployees: {
        viewEmployees: { ar: "عرض الموظفين", en: "View Employees" },
        avatar: { ar: "الصورة الشخصية", en: "Avatar" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        employeesNumbers: { ar: "عدد العملاء", en: "Number of clients" },
        newEmployeesNumbers: {
          ar: "عدد العملاء الجدد",
          en: "Number of new clients",
        },
        salary: { ar: "الراتب", en: "salary" },
        incentives: { ar: "الحوافز", en: "incentives" },
      },

      //////////////

      // client
      ReceiptOverView: {
        filling: { ar: "تعبئة", en: "Filling" },
        shipping: { ar: "شحن", en: "Shipping" },
        distribution: { ar: "توزيع", en: "Distrobution" },
        daily: { ar: "يومي", en: "Daily" },
        monthly: { ar: "شهري", en: "Monthly" },
        annual: { ar: "سنوي", en: "Annual" },
      },
      overview: {
        statistics: { ar: "الإحصائيات", en: "statistics" },
        allClients: { ar: "جميع العملاء", en: "All clients" },
        periodic: { ar: "الفترة الزمنية", en: "periodic Time" },

        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        positive: { ar: "لنا", en: "For Us" },
        negative: { ar: "علينا", en: "From Us" },
        allEmployees: { ar: "جميع الموظفين", en: "All Employees" },
        newEmployees: { ar: "الموظفين الجدد", en: "New Employees" },
        customerCase: { ar: "حالة العميل", en: "Customer Case" },
        bestEmployee: { ar: "افضل موظف", en: "Best Employee" },
        advertisingChannel: {
          ar: "القناة الاعلانية",
          en: "AdvertisingChannel",
        },
      },

      addClients: {
        addClients: { ar: "إضافة عميل", en: "Add Clients" },
      },

      viewClients: {
        allClients: { ar: "جميع العملاء", en: "All Clients" },
      },
      deleteCustomer: {
        deleteCustomer: { ar: "العملاء المحذوفين", en: "Deleted Customers" },
      },
      addStatus: {
        addStatus: { ar: "إضافة حالة عميل", en: "Add Status" },
      },
      viewStatus: {
        viewStatus: { ar: "عرض حالات العملاء", en: "View customer cases" },
      },
      importClients: {
        importClients: { ar: "إستيراد عملاء", en: "Import Clients" },
      },
      exportClients: {
        exportClients: { ar: "تصدير عملاء", en: "Export Clients" },
      },
      exportRegistry: {
        exportRegistry: { ar: "سجل الإستيراد", en: "Export Registry" },
      },
      importLog: {
        importLog: { ar: "سجل الإستيراد", en: "Import Log" },
      },

      ////////////////////

      // Product
      addProduct: {
        addProduct: { ar: "إضافة غاز", en: "Add Gas" },

        ProductName: { ar: "إسم الغاز", en: "Gas Name" },
      },

      viewProducts: {
        viewProducts: { ar: "عرض الغازات", en: "View Gases" },
        productName: { ar: "إسم الغاز", en: "Gas Name" },
      },

      ////////////////////

      // Channels
      addChannel: {
        addChannel: { ar: "إضافة قناة", en: "Add Channel" },
        addChannelPhoto: {
          ar: "اضف صورة القناة هنا",
          en: "Add Channel Photo Here",
        },
        channelName: { ar: "إسم القناة", en: "Channel Name" },
      },

      viewChannels: {
        viewChannels: { ar: "عرض المشاريع", en: "View Projects" },
        projectName: { ar: "إسم المشروع", en: "Project Name" },
      },

      //Expenses

      addExpense: {
        addExpense: { ar: "إضافة مصروفات", en: "Add Expenses" },
        addExpensePhoto: {
          ar: "اضف الصورة هنا",
          en: "Add Photo Here",
        },
        // channelName: { ar: "إسم القناة", en: "Channel Name" },
      },

      viewExpenses: {
        viewExpenses: { ar: "عرض المصروفات", en: "View Expenses" },
      },

      ////////////////////

      //Tradrrs
      addTrader: {
        addTrader: { ar: "إضافة تاجر", en: "Add Trader" },
      },
      ViewTraders: {
        ViewTraders: { ar: "عرض التجار", en: "View Traders" },
      },

      ////////////////////

      //Stations

      addStations: {
        addStations: { ar: "إضافة محطة", en: "Add Stations" },
      },
      ViewStations: {
        ViewStations: { ar: "عرض المحطات", en: "View Stations" },
      },

      //stocks
      addStock: {
        addStock: { ar: "إضافة مخزن", en: "Add Stock" },
      },
      ViewStocks: {
        ViewStocks: { ar: "عرض المخازن", en: "View Stocks" },
      },
      //tanks
      addTank: {
        addTank: { ar: "إضافة تانك", en: "Add Tank" },
      },
      ViewTank: {
        ViewTank: { ar: "عرض التانك", en: "View Tanks" },
      },
      //Transport

      addTransport: {
        addTransport: { ar: "إضافة وسيلة نقل", en: "Add Transportation" },
      },
      ViewTransport: {
        ViewTransport: { ar: "عرض وسائل النقل", en: "View Transportation" },
      },

      addprices: {
        addprices: { ar: "إضافة سعر", en: "Add Price" },
      },
      viewPrices: {
        viewPrices: { ar: "عرض الأسعار", en: "View Prices" },
      },

      addfillreceipt: {
        addfillreceipt: { ar: "إضافة فاتورة تعبئة", en: "Add Filling Receipt" },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },
      viewfillreceipt: {
        viewfillreceipt: {
          ar: "عرض فواتير التعبئة",
          en: "View Filling Receipts",
        },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },
      addshippingreceipt: {
        addshippingreceipt: {
          ar: "إضافة فاتورة شحن",
          en: "Add shipping Receipt",
        },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },
      addexpensesreceipt: {
        addexpensesreceipt: {
          ar: "إضافة فاتورة مصروفات",
          en: "Add Expenses Receipt",
        },
      },
      viewexpensesreceipt: {
        viewexpensesreceipt: {
          ar: "عرض فواتير المصروفات",
          en: "View Expenses Receipts",
        },
      },
      addawayreceipt: {
        addawayreceipt: {
          ar: "إضافة فاتورة ذهاب",
          en: "Add Away Receipt",
        },
      },
      addhomereceipt: {
        addhomereceipt: {
          ar: "إضافة بيانات العودة",
          en: "Add Home Details",
        },
      },
      viewawayreceipt: {
        viewawayreceipt: {
          ar: "عرض فواتير الذهاب",
          en: "View Away Receipts",
        },
      },
      viewhomereceipt: {
        viewhomereceipt: {
          ar: "عرض فواتير العودة",
          en: "View Home Receipts",
        },
      },
      viewback_forthreceipt: {
        viewback_forthreceipt: {
          ar: "عرض فواتير الذهاب والعودة",
          en: "View Back And Forth Receipts",
        },
        completed: {
          ar: "عرض فواتير الذهاب والعودة المكتملة",
          en: "View Completed Home Away Bills",
        },
      },
      adddistributionreceipt: {
        adddistributionreceipt: {
          ar: "إضافة فاتورة توزيع",
          en: "Add Distribution Receipt",
        },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },
      viewdistributionreceipt: {
        viewdistributionreceipt: {
          ar: "عرض فواتير التوزيع",
          en: "View Distribution Receipts",
        },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },
      viewshippingreceipt: {
        viewshippingreceipt: {
          ar: "عرض فواتير الشحن",
          en: "View shipping Receipts",
        },
        type: {
          gas: { ar: "غاز", en: "gas" },
          liquid: { ar: "سائل", en: "liquid" },
        },
      },

      //Delete Messages

      DeleteMessages: {
        BillMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذه الفاتورة؟",
          en: "Are you sure that you want to delete this receipt?",
        },
        EmployeeMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا الموظف؟",
          en: "Are you sure that you want to delete this employee?",
        },
        StockMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا الغاز من المخزن؟",
          en: "Are you sure that you want to delete this Gas from the stock?",
        },
        TankMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا التانك؟",
          en: "Are you sure that you want to delete this Tank?",
        },
        FactoryPowerMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا الرصيد؟",
          en: "Are you sure that you want to delete this Factory Power?",
        },
        TransportMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف وسيلة النقل هذه؟",
          en: "Are you sure that you want to delete this Transportation Way ?",
        },
        TraderMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا التاجر؟",
          en: "Are you sure that you want to delete this trader?",
        },
        ProductMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا الغاز؟",
          en: "Are you sure that you want to delete this gas?",
        },
        channelMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف  هذه القناة؟",
          en: "Are you sure that you want to delete this channel?",
        },
        stationMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف  هذه المحطة؟",
          en: "Are you sure that you want to delete this station?",
        },
        stationPriceMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا السعر لهذه المحطة؟",
          en: "Are you sure that you want to delete this price for this station?",
        },
        expensesMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف  هذا المصروف؟",
          en: "Are you sure that you want to delete this expense?",
        },
        jobsMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف  هذه الوظيفة؟",
          en: "Are you sure that you want to delete this job?",
        },
        TraderPermanentMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا التاجر نهائيا؟",
          en: "Are you sure that you want to permenanetly delete this trader?",
        },
        TraderPriceMessage: {
          ar: "هل أنت متأكد من أنك تريد حذف هذا السعر لهذا التاجر؟ ",
          en: "Are you sure that you want to delete this price for this trader?",
        },
      },

      form: {
        title: { ar: "مرحباً بك", en: "Hello" },
        subtitle: {
          ar: "قم بملئ الحقول للحصول على النتائج المطلوبة",
          en: "Fill in the fields to get the required results",
        },
      },
      global: {
        logout: { ar: "تسجيل الخروج", en: "Logout" },
        login: { ar: "تسجيل الدخول", en: "LogIn" },
        control: { ar: "التحكم", en: "Control" },

        // user data
        name: { ar: " الاسم", en: "The Name" },
        first_name: { ar: " الإسم الاول", en: "First Name" },
        last_name: { ar: " الإسم الاخير", en: "Last Name" },
        username: { ar: "إسم المستخدم", en: "Username" },
        username_en: { ar: "الرجاء كتابة اسم المستخدم باللغة الانجليزية", en: "Please Enter Username in English" },
        create_acc: { ar: "إنشاء حساب", en: "Create New Account" },
        customer: { ar: "العميل", en: "Customer" },
        customerName: { ar: "اسم العميل", en: "Customer Name" },
        customerPhone: { ar: "هاتف العميل", en: "Customer Phone" },
        employeeName: { ar: "اسم العميل", en: "Customer Name" },
        employeePhone: { ar: "رقم العميل", en: "Customer Phone" },
        phone: { ar: "الهاتف", en: "Phone" },
        email: { ar: "البريد الإلكتروني", en: "E-mail" },
        password: { ar: "الرقم السري", en: "Password" },
        newPassword: { ar: "الرقم السري الجديد", en: "New Password" },
        oldPassword: { ar: "الرقم السري القديم", en: "Old Password" },
        confirmPassword: { ar: "تأكيد الرقم السري", en: "Confirm Password" },
        whatsApp: { ar: "الواتساب", en: "whats app" },
        contactWay: { ar: "طريقة التواصل", en: "Contact Way" },
        // password: { ar: "الرقم السري الجديد", en: "New Password" },
        job: { ar: "الوظيفة", en: "Job" },
        employee: { ar: "الموظف", en: "Employee" },
        TransferEmployee: {
          ar: "تحويل إلى موظف",
          en: "Transfer To An Employee",
        },
        salesOfficer: { ar: "مسؤول المبيعات", en: "Sales Officer" },
        newCustomers: { ar: "العملاء الجدد", en: "New Customers" },
        editor: { ar: "المحرر", en: "Editor" },
        createdBy: { ar: "تم الإنشاء بواسطة", en: "Created By" },

        //////////////////////

        // Actions
        actions: { ar: "إجرائات", en: "Actions" },
        save: { ar: "حفظ", en: "Save" },
        delete: { ar: "حذف", en: "Delete" },
        deleteTo: { ar: "نقل إلى سلة المهملات", en: "Move to trash" },
        cancel: { ar: "الغاء", en: "Cancel" },
        submit: { ar: "تنفيذ", en: "Submit" },
        add: { ar: "إضافة", en: "Add" },
        addComment: { ar: "اكتب تعليق", en: "Add Comment" },
        editPost: { ar: "تعديل المنشور", en: "Edit Post" },
        editUnitData: { ar: "تعديل بيانات الوحدة", en: "Edit Unit Data" },
        editstationData: { ar: "تعديل بيانات المحطة", en: "Edit Station Data" },
        editstockData: { ar: "تعديل المخزن", en: "Edit Stock" },
        editbillData: { ar: "تعديل بيانات الفاتورة", en: "Edit Receipt Data" },
        search: { ar: "بحث", en: "Search" },
        viewItems: { ar: "عرض العناصر", en: "View Items" },
        editJob: { ar: "تعديل الوظيفة", en: "Edit Job" },
        showAll: { ar: "عرض الجميع", en: "Show All" },
        filter: { ar: "تصفية", en: "Filter" },
        addFilter: { ar: "إضافة تصفية", en: "Add Filter" },
        status: { ar: "الحالة", en: "Status" },
        deleteRecords: { ar: "حذف السجلات", en: "Delete Records" },
        downloadFile: { ar: "تنزيل ملف", en: "Download File" },
        uploadFile: { ar: "تصدير ملف", en: "Upload File" },
        downloadTemplate: { ar: "تنزيل نموذج", en: "Download Template" },
        downloadCustomerData: {
          ar: "قم بتحميل ملف العملاء",
          en: "Download Customer Data",
        },
        uploadCustomerData: {
          ar: "قم برفع ملف العملاء",
          en: "Upload Customer Data",
        },
        pleaseConfirm: { ar: "الرجاء التأكيد", en: "please, confirm" },
        areUSure: {
          ar: "هل انت متأكدل؟",
          en: "Are you sure you want to delete this client?",
        },
        editCusomerData: {
          ar: "تعديل بيانات العميل",
          en: "Edit Customer Data",
        },
        cusomerDetails: {
          ar: "تفاصيل العميل",
          en: "Customer Details",
        },
        customerRecord: {
          ar: "سجل العميل",
          en: "Customer Record",
        },
        stationDetails: {
          ar: "تفاصيل المحطة",
          en: "Station Details",
        },
        stationRecord: {
          ar: "سجل المحطة",
          en: "Station Record",
        },

        ///////////////////////

        // Selected
        deleteSelected: { ar: "حذف المحدد", en: "Delete Selected" },
        retrievalSelected: { ar: "إسترجاع المحدد", en: "retrieval Selected" },
        exportSelected: { ar: "تصدير المحدد", en: "Export Selected" },
        transferSelected: { ar: "تحويل المحدد", en: "Transfer Selected" },
        changeSelected: {
          ar: "تغيير مشاريع المحدد",
          en: "Change Selected Projects",
        },
        ///////////////////////

        // Single word
        no: { ar: "لا", en: "No" },
        yes: { ar: "نعم", en: "Yes" },
        ok: { ar: "حسناً", en: "OK" },
        admin: { ar: "أدمن", en: "Admin" },
        like: { ar: "أعجبني", en: "Like" },
        comment: { ar: "البيان", en: "comment"},
        deparment: { ar: "القسم", en: "Department"},
        comments: { ar: "تعليقات", en: "Comments" },
        theDescription: { ar: "الوصف", en: "The Description" },
        file: { ar: "الملف", en: "File" },
        picture: { ar: "الصورة", en: "Picture" },
        details: { ar: "تفاصيل", en: "Details" },
        notes: { ar: "ملاحظات", en: "Notes" },
        molecular_weight:{ar:"الوزن الذري",en:"Molecular Weight"},
        color:{ar:"اللون",en:"Color"},
        counter_Reading:{ar:"قراءة العداد",en:"Counter Reading"},
        quantity_balance:{ar:"العدد",en:"The Quantity"},
        action:{ar:"الاجراء",en:"The Action"},
        price:{ar:"السعر",en:"The Price"},
        statement:{ar:"البيان",en:"The Statement"},
        add_balance:{ar:"اضافة رصيد",en:"ِAdd Balance"},
        procedure:{ar:"الاجراء",en:"ِThe procedure"},
        editpowerData: { ar: "تعديل قوة المصنع", en: "Edit Factory Power" },
        total: { ar: " الاجمالى", en: "The Total Number" },
        total_capacity: { ar: "الحجم الاجمالى", en: "The Total Capacity" },
        raseef: { ar: " الرصيف", en: "The Sidewalk" },
        factory_safe: { ar: "الخزنة ", en: "The Factory Safe" },
        payment: { ar: "المدفوعات", en: "The Payment" },
        balance: { ar: "المقبوضات", en: "The Balance" },
        day_total_balance: { ar: "اجمالى المقبوضات", en: "The Balance Total"},
        day_total_payment: { ar: "اجمالى المدفوعات", en: "The Payment Total"},
        expenses_safe: { ar: " المصروفات", en: "The Expenses"},
        safe_before: { ar: "الرصيد السابق", en: "The Safe Before"},
        total_balance: { ar: "الرصيد اليوم", en: "The balance"},
        safe_balance: { ar: "رصيد الخزنة ", en: "The balance"},
        thetype: { ar: "النوع ", en: "The Type"},
        bill_uuid: { ar: "رقم الفاتورة ", en: "The Bill Number"},
        thevalue: { ar: "القيمه", en: "The Value"},
        others: { ar: "اخرى", en: "Others"},
        Destribution: { ar: "توزيع", en: "Destribution"},
        Filling: { ar: "تعبئة", en: "Filling"},
        tankShiping: { ar: "شحن التانك", en: "The Tank Shiping"},
        sales_bill: { ar: "فاتورة مبيعات ", en: "The Sales Bill"},
        purchases_bill: { ar: "فاتورة مشتريات ", en: "The Purchases Bill"},
        total_paid: { ar: " اجمالى المدفوع ", en: "The Total Paid"},
        statement_bill: { ar: "البيان", en: "The Statemenet"},
        tank_uuid: { ar: "رقم التانك", en: "The Tank Number"},
        view_readings: { ar: "عرض القراءات", en: "View The Readings"},
        paid: { ar: "المدفوع", en: " The Payment"},
        fromgas: { ar: "من غاز", en: "From Gas"},
        togas: { ar: "الى غاز", en: "To Gas"},
        charged_gas: { ar: "المشحون ", en: " The Charged"},
        distributor: { ar: "الموزع ", en: " The Distributor"},
        filled_number: { ar: "المعبأ", en: " The Filled Number"},
        the_amount: { ar: " المبلغ", en: " The Amount"},
        sold: { ar: " مُباع ", en: " The Sold"},
        difference: { ar: " الفرق", en: " The Difference"},
        finance: { ar: " تمويل وايداع", en: " Finance and Deposite"},
        financebill: { ar: " تمويل", en: " Finance"},
        depositebill: { ar: " ايداع", en: " Deposite"},
        editFinanceData: { ar: " تعديل فاتورة التمويل والايداع", en: " Edit Finance and Deposite Bill"},
        remain_details: { ar: "تفاصيل المتبقي", en: " The Remain Price Details"},
        remain: { ar: " المتبقي", en: " The Remain Price"},
        volume_kilo: { ar: " الحجم (كيلوجرام)", en: " The Volume with Kilogram"},
        gasNumber:{ar:"عدد أنابيب الغازات المعبئة",en:"The Number of Filled Gases"},
        gasNumber:{ar:"عدد أنابيب الغازات المعبئة",en:"The Number of Filled Gases"},
        Revenues:{ar:"المبيعات",en:"The Revenues"},
        total_production:{ar:"اجمالي الانتاج",en:"The Total Production"},
        category:{ar:"نوع المصروف ",en:"The Expense Type"},
        clinttimeline:{ar:"عميل",en:"Client"},
        tradertimeline:{ar:"تاجر",en:"Trader"},
        stationtimeline:{ar:"محطة",en:"Station"},
        numberperm:{ar:"رقم الأذن",en:"Permession Number"},
        company_name:{ar:"اسم الشركة والشعار",en:" Company/Factory Name"},
        admin_name:{ar:"اسم المسؤول",en:"Admin Name"},
        website:{ar:"موقعك الخاص ",en:"Your Website Url "},
        phone_number:{ar:"رقم الهاتف الخاص بك  ",en:"Your  Phone Number "},
        commission_type:{ar:"المصدر",en:"The Source"},
        delivery_uuid:{ar:"رقم التوصيل",en:"The Delivery Number"},
        order_date:{ar:"رقم التوصيل",en:"The Delivery Number"},
        delivery_date:{ar:"تاريخ التوصيل",en:"The Delivery Date"},
        order_uuid:{ar:"اذن طلب التوصيل ",en:"The Order of Delivery Number"},
        from_location:{ar:"من",en:"From"},
        to_location:{ar:"الى",en:"To"},

        ///////////////////

        // Location
        country: { ar: "البلد", en: "Country" },
        governorate: { ar: "المحافظة", en: "Governorate" },
        city: { ar: "المدينة", en: "city" },
        region: { ar: "المنطقة", en: "Region" },
        neighboring: { ar: "المجاورة", en: "Neighboring" },
        buildingNumber: { ar: "رقم العمارة", en: "Building Number" },
        unitType: { ar: "نوع الوحدة", en: "Unit Type" },
        unit:{ar:"الوحدة",en:"unit"},
        saleType: { ar: "نوع البيع", en: "Sale Type" },
        floor: { ar: "الدور", en: "Floor" },
        area: { ar: "المساحة", en: "Area" },
        roomsNumber: { ar: "عدد الغرف", en: "Rooms Number" },
        bathroomsNumber: { ar: "عدد الحمامات", en: "Bathrooms Number" },
        finishingType: { ar: "نوع التشطيب", en: "Finishing Type" },
        price: { ar: "السعر", en: "Price" },
        address: { ar: "العنوان", en: "Address" },
        factory: { ar: "المصنع", en: "factory" },
        channel: { ar: "القناة الاعلانية", en: "Channel" },
        owner_phone: { ar: "هاتف صاحب المحطة", en: "Station's Owner Phone" },
        accountant_phone: { ar: "هاتف المحاسب", en: "Accountant's Phone" },
        sales_phone: { ar: "هاتف المبيعات", en: "Sales Phone" },

        ///////////////////

        // scale unites
        meter: { ar: "متر", en: "Meter" },

        ///////////////////

        latestPosts: { ar: "احدث المنشورات", en: "Latest Posts" },
        yourMind: { ar: "مالذي يدور في بالك،", en: "what's on your mind," },
        followed: { ar: "التابع له", en: "followed by" },
        allPermission: { ar: "جميع الصلاحيات", en: "All Permission" },
        notFound: { ar: "لا يوجد", en: "Not Found" },
        unknown: { ar: "غير معروف", en: "Unknown" },
        unknownLink: { ar: "الرابط غير معروف", en: "Unknown Link" },
        code: { ar: "الكود", en: "Code" },
        numberOfClients: { ar: "عدد العملاء", en: "Number of clients" },
        product: { ar: "الغاز", en: "Gas" },
        products: { ar: "الغازات", en: "Gases" },
        budget: { ar: "الميزانية", en: "Budget" },
        totalNumber: { ar: "العدد الكلي", en: " total number" },
        allStations: { ar: "جميع المحطات", en: "All Stations" },
        sameLevel: { ar: "نفس المرحلة", en: "Same Level" },
        editEmployeeData: {
          ar: "تعديل بيانات الموظفين",
          en: "Edit Employee Data",
        },
        adChannel: {
          ar: "القناة الإعلانية",
          en: "Ad Channel",
        },
        channels: {
          ar: "القنوات",
          en: "Channels",
        },
        mediator: {
          ar: "الوسيط",
          en: "Mediator",
        },
        statusName: {
          ar: "اسم الحالة",
          en: "Status name",
        },

        // Budget
        budget: { ar: "الميزانية", en: "Budget" },
        equal: { ar: "يساوي", en: "Equal" },
        bigger: { ar: "اكبر من", en: "Bigger" },
        smaller: { ar: "اصغر من", en: "Smaller" },

        //////////////////

        // Dates
        day: { ar: "اليوم", en: "Day" },
        week: { ar: "الإسبوع", en: "Week" },
        month: { ar: "الشهر", en: "Month" },
        year: { ar: "السنة", en: "Year" },
        Date: { ar: "التاريخ", en: "Date" },
        date: { ar: "تاريخ", en: "Date" },
        createdAt: { ar: "تاريخ الإنشاء", en: "Created At" },
        previewDate: { ar: "تاريخ المعاينة", en: "Preview Date" },
        dateOfProcedure: { ar: "تاريخ الإجراء", en: "Date Of Procedure" },
        from: { ar: "من", en: "From" },
        periodFrom: { ar: "الفترة من", en: "Period From" },
        to: { ar: "الي", en: "To" },
        periodTo: { ar: "الفترة إلى", en: "Period To" },
        ///////////////

        // Sidebar
        Employees: { ar: "الموظفين", en: "Employees" },
        customers: { ar: "العملاء", en: "Customers" },
        products: { ar: "الغازات", en: "Gases" },
        channels: { ar: "القنوات", en: "Channels" },
        units: { ar: "الوحدات", en: "Units" },
        stations: { ar: "المحطات", en: "stations" },
        station: { ar: "المحطة", en: "station" },
        driver: { ar: "اسم السائق", en: "Driver's Name" },
        client: { ar: "العميل", en: "Client" },
        price: { ar: "السعر", en: "price" },
        fillreceipt: { ar: "فاتورة التعبئة", en: "Filling Receipt" },
        shipreceipt: { ar: "فاتورة الشحن", en: "Shipping Receipt" },
        distributionreceipt: {
          ar: "فاتورة التوزيع",
          en: "Distribution Receipt",
        },
        BackAndForthreceipt: {
          ar: "إذن الذهاب والعودة",
          en: "Back And Forth Receipt",
        },
        awaybill: {
          ar: "إذن الذهاب",
          en: "Back Receipt",
        },
        homebill: {
          ar: "إذن العودة",
          en: "Home Receipt",
        },
        receiptnumber: { ar: "رقم الفاتورة", en: "receipt number" },

        DateAdded: { ar: "تاريخ الإضافة", en: "Date Added" },
        logo: { ar: "اللوجو", en: "Logo" },
        website: { ar: "الموقع الالكتروني", en: "website" },
        driver_phone: { ar: "رقم هاتف السائق", en: "driver Phone" },
        product_type: { ar: "العبوة", en: "Packaging" },
        expenses: { ar: "نوع المصروف", en: "Expenses" },
        expensesPrice: { ar: "القيمة", en: "Value" },
        fax: { ar: "الفاكس", en: "fax" },
        input: { ar: "الداخل", en: "input" },
        output: { ar: "الخارج", en: "output" },
        returned: { ar: "المرتجع", en: "returned" },
        tips: { ar: "الدخان", en: "tips" },
        paid: { ar: "المدفوع", en: "paid" },
        store: { ar: "المخزن", en: "store" },
        all: { ar: "الصافي", en: "Total" },
        fill: { ar: "المعبأ", en: "filled" },
        fill_price: { ar: "حساب التعبئة", en: "Receipt Price" },
        fill_number: { ar: "رقم التعبئة", en: "Fill Number" },
        tanknumber: { ar: "اسم التانك", en: "Tank Number" },
        minimum: { ar: "حد ادنى", en: "Minimum Number" },
        maximum: { ar: "حد اقصى", en: "Maximum Number" },
        number_of_tags: { ar: " عدد العلامات", en: "Number of Tags " },
        remainprice: { ar: "المتبقي", en: "remain Price" },
        empty: { ar: "الفارغ", en: "empty" },
        filled: { ar: "الممتلئ", en: "filled" },
        billDate:{ar:"تاريخ الفاتورة",en:"Bill Date"},
        wallet: { ar: "الرصيد", en: "Wallet" },
        Gas: { ar: "الغاز", en: "Gas" },
        package: { ar: "العبوة", en: "Package" },
        state: { ar: "الحالة", en: "State" },
        billDetails: { ar: "بيانات الفاتورة", en: "Receipt Details" },
        billHistory: { ar: "سجل الفاتورة", en: "Receipt History" },
        historytype: { ar: "نوع التعديل", en: "History Type" },
        stocks: { ar: "المخازن", en: "stocks" },
        traders: { ar: "التجار", en: "Traders" },
        allTraders: { ar: "جميع التجار", en: "All Traders" },
        trader: { ar: "التاجر", en: "trader" },
        traderDetails: { ar: "بيانات التاجر", en: "Trader Details" },
        traderHistory: { ar: "سجل التاجر", en: "Trader History" },
        edittraderData: { ar: "تعديل بيانات التاجر", en: "Edit Trader Data" },
        deleteTrader: { ar: "حذف تاجر", en: "Delete Trader" },
        avatar: { ar: "الصورة الشخصية", en: "Avatar" },
        store_price: { ar: "سعر المخزن", en: "Store Price" },
        transportation: { ar: "وسيلة النقل", en: "Transportation" },
        driven: { ar: "المسلم", en: "Driven" },
        taken: { ar: "المستلم", en: "Taken" },
        AwayDate: { ar: "تاريخ الذهاب", en: "Away Date" },
        BackDate: { ar: "تاريخ العودة", en: "Back Date" },
        home_away_bill_number: {
          ar: "رقم فاتورة الذهاب ",
          en: "Away Bill Number ",
        },

        taken_empty: { ar: "مستلم فارغ", en: "Taken Empty" },
        taken_filled: { ar: "مستلم ممتلئ", en: "Taken Filled" },

        away_quantity: { ar: "عدد الذهاب", en: "Away Quantity" },
        home_quantity: { ar: "عدد العودة", en: "Home Quantity" },
        home_quantity_empty: { ar: "عدد فارغ", en: "Empty Quantity" },
        home_quantity_filled: { ar: "عدد ممتلئ", en: "Filled Quantity" },
        empty_quantity: { ar: "عدد فارغ", en: "Empty Quantity" },
        filled_quantity: { ar: "عدد ممتلئ", en: "Filled Quantity" },
        quantity: { ar: "الكمية", en: "Quantity" },
        total_price: { ar: "السعر", en: "Total Price" },
        addProducttoBill: {
          ar: "إضافة غاز للفاتورة",
          en: "Add Gas to Bill",
        },
        get_away_data: { ar: "بيانات الفاتورة", en: "Get Bill Data" },
        clients: { ar: "العملاء", en: "clients" },
        gas: { ar: "غاز", en: "Gas" },
        liquid: { ar: "سائل", en: "Liquid" },
        date: { ar: "اليوم", en: "Date" },
        raseef: { ar: "الرصيف", en: "Raseef" },
        factorypower: { ar: "قوة المصنع", en: "factorypower" },
        // add empty and full stock field
        empty_store: { ar: "فارغ", en: "Empty" },
        filled_store: { ar: " ممتلئ", en: "Full" },
        outputs: { ar: "الخوارج", en: "outputs" },
        select_client:{ar:"حدد العميل",en:'Select The client'},
        select_station:{ar:"حدد المحطه",en:'Select The Station'},
        select_trader:{ar:"حدد التاجر",en:'Select The Trader'},
        number: { ar: "العدد", en: "Number" },
        in_number: { ar: "عدد الدخول", en: "Entering Number" },
        station_name: { ar: "اسم المحطة", en: "Station Name" },
        volume: { ar: "الحجم", en: "Volume" },
        Enter_number: {
          ar: "رقم إذن الدخول",
          en: "Entering Permission Number",
        },
        bill_number: { ar: "رقم الايصال", en: "Receipt Number" },
        enter_quantity: { ar: " الدخول", en: "Enter Quantity" },
        Exit_number: { ar: "رقم إذن الخروج", en: "Exit Permission Number" },
        enter_empty:{ar:"دخول فارغ",en:"Entered Empty"},
        enter_filled:{ar:"دخول ممتلئ",en:"Entered filled"},
        exit_empty:{ar:"خروج فارغ",en:"Exited Empty"},
        exit_filled:{ar:"خروج ممتلئ",en:"Exited filled"},
        exit_quantity: { ar: "الخروج", en: "Exit Quantity" },
        exit_date: { ar: "تاريخ الخروج" },
        enter_date: { ar: "تاريخ الدخول" },
        Exit_Permission: { ar: "إذن خروج", en: "Exit Permission" },
        Enter_Permission: { ar: "إذن دخول", en: "Enter Permission" },
        view: { ar: "عرض", en: "View" },
        tax_number: { ar: "رقم البطاقة الضريبية", en: "Tax Number" },
        reg_number: {
          ar: "السجل التجاري",
          en: "commercial registeration number",
        },
        exit_enter_number: {
          ar: "رقم إذن الدخول والخروج",
          en: "Exit Enter Number",
        },
        quantityreturned: { ar: "مرتجع", en: "Returned" },
        edit_tank: { ar: "تعديل بيانات التانك", en: "Edit Tank Data" },
        fill_type: { ar: "نوع التعبئة ", en: "Fill Type" },
        fill_number: { ar: " رقم االتعبئة", en: "Fill Number" },
        tanks: { ar: "التانكات", en: "Tanks" },
        tank: { ar: "التانك", en: "Tank" },
        rate: { ar: "المعدل", en: "Rate" },
        achieve: { ar: "التحصيل", en: "Achievement" },
        TankShipReceipt: { ar: "فاتورة شحن تانك", en: "Tank Shipping Receipt" },
        filled_liter: { ar: "ممتلئ (لتر)", en: "Filled(Liter)" },
        filled_kg: { ar: "ممتلئ (كيلو جرام)", en: "Filled(Kg)" },
        fill_date: { ar: "تاريخ التعبئة", en: "Filling Date" },
        outDetails: { ar: "تفاصيل الخوارج", en: "Out Details" },
        maintenance:{ar:"صيانة",en:"Maintenance"},
        phones:{ar:"الهواتف",en:"Phones"},
        pressure: { ar: "الضغط", en: "Pressure" },
      },
    },
  },
  reducers: {
    toggleLang: (state, action) => {
      if (
        Boolean(action.payload) &&
        action.payload != "ar" &&
        action.payload != "en"
      ) {
        throw new Error(
          "The only possible languages to toggle between are either ar or en"
        );
      }

      if (action.payload) {
        state.value.lang = action.payload;
        localStorage.setItem("lang", JSON.stringify(action.payload));
      } else {
        switch (state.value.lang) {
          case "en":
            state.value.lang = "ar";
            break;
          case "ar":
            state.value.lang = "en";
            break;
          default:
            state.value.lang = "en";
        }
      }
    },
  },
});

export default LangSlice.reducer;

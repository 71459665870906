import {
    MenuItem,
  } from "@mui/material";
  import React, {useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  
 
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { DatePicker } from "@mui/x-date-pickers";
import { CLIENTS, STATIONS, Sales_Bill, TRADERS } from "../../../data/APIs";
import useControls from "../../../hooks/useControls";

import useRequest from "../../../hooks/useRequest";
import Form, { InputField, NumberField, SelectField, TextareaField } from "../../../features/form";
import filter from "../../../utils/ClearNull";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
  
  const SalesBill = () => {
  
    const getDate = () => {
      const t = new Date();
    //   const year = t.getFullYear();
    //   const month = ("0" + (t.getMonth() + 1)).slice(-2);
    //   const date = ("0" + t.getDate()).slice(-2);
    //   return `${year}-${month}-${date}`;
      return t;
    };
    const [filterdataTr, setfilterdataTr] = useState({
      trader: "",
      Date: getDate(),
    });
    const [filterdataCl, setfilterdataCl] = useState({
      client: "",
      Date: getDate(),
    });
   
  
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
    const clientsStore = useSelector((state) => state.clients.value);
    const tradersStore = useSelector((state) => state.traders.value);
   
    const [responsebody, setResponse] = useState([]);
    ///////////////////////////////
    const dispatch = useDispatch();
    const [
      { controls, invalid, required },
      { setControl, resetControls, setInvalid, validate },
    ] = useControls([
      {
        control: "bill_uuid",
        value: "",
        isRequired: false,
      },
      {
        control: "paid",
        value: "",
        isRequired: false,
      },
      {
        control: "thetype",
        value: "",
        isRequired: false,
      },
      {
        control: "commissionType",
        value: "",
        isRequired: false,
      },
      {
        control: "trader",
        value: "",
        isRequired: false,
      },
      {
        control: "client",
        value: "",
        isRequired: false,
      },
      {
        control: "statement",
        value: "",
        isRequired: false,
      },
      {
        control: "date",
        value: new Date(),
        isRequired: false,
      },
    ]);
  
    const [paymentBillPostRequest, paymentBillPostResponse] = useRequest({
      path: Sales_Bill,
      method: "post",
      successMessage: "تم إضافة الفاتورة بنجاح",
    });
    
    const [tradersGetRequest, tradersGetResponse] = useRequest({
      path: TRADERS,
      method: "get",
    });
  
    const getTraders = () => {
      if (Boolean(tradersStore.results.length)) return;
      tradersGetRequest({
        params: {
          size: 1000,
        },
        onSuccess: (res) => {
          dispatch({ type: "traders/set", payload: res.data });
        },
      });
    };
    
    const [clientGetRequest, clientGetResponse] = useRequest({
      path: CLIENTS,
      method: "get",
    });
  
    const getClients = () => {
      if (Boolean(clientsStore.results.length)) return;
      clientGetRequest({
        params: {
          size: 1000,
        },
        onSuccess: (res) => {
          dispatch({ type: "clients/set", payload: res.data });
        },
      });
    };
   
    // products data connection to api
    const thetype = [
      {name: "توزيع", value: "Distribution"},
      {name: "تعبئة", value: "Filling"},
      {name: "وساطة", value: "C"},
      {name: "اخرى", value: "Others"},
    ];
    const commissionType = [
      {name: "تاجر", value: "trader"},
      {name: "عميل", value: "client"},
      
    ];
  
   
    const handleSubmit = (e) => {
      e.preventDefault();
      
      validate().then((data) => {
        console.log(data);
        if (!data.isOk) return
       
        const requestBody = filter({
          obj: {
            bill_date: controls.date.toISOString(),
            bill_uuid: controls.bill_uuid,
            paid: controls.paid,
            statement:controls.statement,
            client: filterdataCl.client,
            trader: filterdataTr.trader,
            type:controls.thetype==="C"?"C":"NC"
          },
          output: "object",
        });
          paymentBillPostRequest({
          body: requestBody,
          onSuccess: (res) => {
            resetControls();
            dispatch({ type: "salesbill/addItem", payload: res.data });
          },
        }).then((res) => {
          const response = res?.response?.data;
          const responseBody = filter({
            obj: {
              id: res?.id,
              bill_date: res?.date,
              bill_uuid: res?.bill_uuid,
              paid: res?.paid,
              client: res?.client,
              trader: res?.trader,  
              statement:res?.statement,

            },
          });
          
          if (res?.id) {
          } else {
            setInvalid(res?.message);
          }
        });
       
      });
    };
  
    return (
      <>
        <Form
           component="form"
           onSubmit={handleSubmit}
           childrenProps={{
             saveBtn: {
               disabled: paymentBillPostResponse.isPending,
             },
             closeBtn: {
               disabled: paymentBillPostResponse.isPending,
             },
           }}
        >
            <NumberField
                label={globalLang.bill_uuid[lang]}
                placeholder={globalLang.bill_uuid[lang]}
                onChange={(e) => setControl("bill_uuid", e.target.value)}
                value={controls.bill_uuid}
                required={required.includes("bill_uuid")}
                error={Boolean(invalid?.bill_uuid)}
                helperText={invalid.bill_uuid}
              ></NumberField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={globalLang.DateAdded[lang]}
                  value={controls.date}
                  
                  onChange={
                    (newValue) => {
                    // const new_date = newValue.toISOString()
                    // `${newValue["$y"]}-${
                    //   parseInt(newValue["$M"]) + 1
                    // }-${newValue["$D"]}`;
        
                    setControl("date", newValue);
                  }}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <SelectField
                label={globalLang.thetype[lang]}
                placeholder={globalLang.thetype[lang]}
                renderValue={(selected) => {
                  return thetype.find((type) => type.value === selected).name;
                }}
                onChange={(e) => {
                  setControl("thetype", e.target.value);
                }}
                value={controls.thetype}
                required={required.includes("thetype")}
                error={Boolean(invalid.thetype)}
                helperText={invalid.thetype}
              >
                {thetype.map((thetype, index) => (
                  <MenuItem value={thetype.value} key={`${thetype.value} ${index}`}>
                    {thetype.name}
                  </MenuItem>
                ))}
              </SelectField>
              {controls.thetype ==="C"&&
              <SelectField
                label={globalLang.commission_type[lang]}
                placeholder={globalLang.commission_type[lang]}
                renderValue={(selected) => {
                  return commissionType.find((type) => type.value === selected).name;
                }}
                onChange={(e) => {
                  setControl("commissionType", e.target.value);
                }}
                value={controls.commissionType}
                required={required.includes("commissionType")}
                error={Boolean(invalid.commissionType)}
                helperText={invalid.commissionType}
              >
                {commissionType.map((type, index) => (
                  <MenuItem value={type.value} key={`${type.value} ${index}`}>
                    {type.name}
                  </MenuItem>
                ))}
              </SelectField>
              }
              {controls.thetype === "Filling"&&
              
                <AutocompleteField
                          label={globalLang.traders[lang]}
                          sx={{
                            
                            width:"100%"
                              
                          }}
                          isPending={tradersGetResponse.isPending}
                          onOpen={getTraders}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            switch (reason) {
                              case "selectOption":
                                setfilterdataTr((old) => ({
                                  ...old,
                                  trader: options.value,
                                }));
                                break;
                              case "clear":
                                setfilterdataTr((old) => ({
                                  ...old,
                                  trader: "",
                                }));
                            }
                          }}
                          data={[
                            ...tradersStore?.results.map((c, index) => ({
                              label: c?.user?.first_name +""+c?.user?.last_name,
                              value: c.id,
                            })),
                          ]}
                          value={filterdataTr.trader}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
        
              }
              {controls.commissionType ==="trader"&&
              
                <AutocompleteField
                          label={globalLang.traders[lang]}
                          sx={{
                            
                            width:"100%"
                              
                          }}
                          isPending={tradersGetResponse.isPending}
                          onOpen={getTraders}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            switch (reason) {
                              case "selectOption":
                                setfilterdataTr((old) => ({
                                  ...old,
                                  trader: options.value,
                                }));
                                break;
                              case "clear":
                                setfilterdataTr((old) => ({
                                  ...old,
                                  trader: "",
                                }));
                            }
                          }}
                          data={[
                            ...tradersStore?.results.map((c, index) => ({
                              label: c?.user?.first_name +""+c?.user?.last_name,
                              value: c.id,
                            })),
                          ]}
                          value={filterdataTr.trader}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
        
              }
              
                  {controls.thetype === "Distribution" &&
                  
                  <AutocompleteField
                          label={globalLang.select_client[lang]}
                          sx={{
                            width: "100%"
                          }}
                          isPending={clientGetResponse.isPending}
                          onOpen={getClients}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            switch (reason) {
                              case "selectOption":
                                setfilterdataCl((old) => ({
                                  ...old,
                                  client: options.value,
                                }));
                                break;
                              case "clear":
                                setfilterdataCl((old) => ({
                                  ...old,
                                  client: "",
                                }));
                            }
                          }}
                          data={[
                            ...clientsStore?.results.map((c, index) => ({
                              label: c?.user.first_name + " " + c?.user?.last_name,
                              value: c.id,
                            })),
                          ]}
                          value={filterdataCl.client}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
                   }
                  {controls.commissionType ==="client" &&
                  
                  <AutocompleteField
                          label={globalLang.select_client[lang]}
                          sx={{
                            width: "100%"
                          }}
                          isPending={clientGetResponse.isPending}
                          onOpen={getClients}
                          isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                          }
                          onChange={(e, options, reason) => {
                            switch (reason) {
                              case "selectOption":
                                setfilterdataCl((old) => ({
                                  ...old,
                                  client: options.value,
                                }));
                                break;
                              case "clear":
                                setfilterdataCl((old) => ({
                                  ...old,
                                  client: "",
                                }));
                            }
                          }}
                          data={[
                            ...clientsStore?.results.map((c, index) => ({
                              label: c?.user.first_name + " " + c?.user?.last_name,
                              value: c.id,
                            })),
                          ]}
                          value={filterdataCl.client}
                          // required={required.includes("Exit_Permission_Station_bill")}
                        />
                   }
                  
              <NumberField
                label={globalLang.thevalue[lang]}
                placeholder={globalLang.thevalue[lang]}
                onChange={(e) => setControl("paid", e.target.value)}
                value={controls.paid}
                required={required.includes("paid")}
                error={Boolean(invalid.paid)}
                helperText={invalid.paid}
              ></NumberField>
              {/* add empty and full input field ,popper for outputs */}
              <TextareaField
                label={globalLang.statement[lang]}
                placeholder={globalLang.statement[lang]}
                onChange={(e) => setControl("statement", e.target.value)}
                value={controls.statement}
                required={required.includes("statement")}
                error={Boolean(invalid.statement)}
                helperText={invalid.statement}
              ></TextareaField>
        </Form>
       
        {paymentBillPostResponse.successAlert}
        {paymentBillPostResponse.failAlert}
      </>
    );
  };
  
  export default SalesBill;
  
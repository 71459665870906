import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
    CLIENTS,
    PRODUCTS,
    SUMMARYCLIENT,
    SUMMARYEXPENSES,
    SUMMARYSTATION,
    SUMMARYTRADER,
} from "../../data/APIs";

import { InputField, SelectField } from "../../features/form";
import { Box, CircularProgress, MenuItem, Paper, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Print from "../../components/PrintComponent";
import { TraderFilter } from "./FillingOverview";
import { StationFilter } from "./StationBillOverview";
import { Stack } from "@mui/system";
import { ExpensesFilter } from "../Receipts/Expenses/ViewExpensesReceipts";

const DailyBillsOverView = () => {
    //states
    const lang = useSelector((state) => state.lang.value.lang); //lang
    const globalLang = useSelector((state) => state.lang.value.global); //lang
    const SummaryclientReceiptStore = useSelector(
        (state) => state.summaryclient.value
    );
    const SummaryfillReceiptStore = useSelector(
        (state) => state.summarytrader.value
    );
    const SummaryStationBillStore = useSelector(
        (state) => state.summarystation.value
    );
    const SummaryExpensesBillStore = useSelector(
        (state) => state.summary_expenses.value
    );
    //format Date
    const getDate = () => {
        var today = new Date();
        let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
        return date;
    };
    const [date, setdate] = useState(getDate());
    const [params, setparams] = useState({ date: date, page: 1 })

    ///////////////////////////////

    const dispatch = useDispatch();

    //requests
    const [
        summaryclientreceiptGetRequest,
        summaryclientreceiptGetResponse,
    ] = useRequest({
        path: SUMMARYCLIENT,
        method: "get",
    });
    const [summaryfillreceiptGetRequest, summaryfillreceiptGetResponse] =
        useRequest({
            path: SUMMARYTRADER,
            method: "get",
        });

    const [summaryStationBillGetRequest, summaryStationBillGetResponse] =
        useRequest({
            path: SUMMARYSTATION,
            method: "get",
        });
    const [summaryExpensesBillGetRequest, summaryExpensesBillGetResponse] =
        useRequest({
            path: SUMMARYEXPENSES,
            method: "get",
        });
    useEffect(() => {
        const urlParams = new URLSearchParams();
        Object.keys(params).map((key) => urlParams.append(key, params[key]));
        summaryclientreceiptGetRequest({
            id: 'd',
            params: urlParams,
            onSuccess: (res) => {
                dispatch({ type: "summary-client/set", payload: res.data });
            },
        });
        summaryfillreceiptGetRequest({
            id: 'd',
            params: urlParams,
            onSuccess: (res) => {
                dispatch({ type: "summary-trader/set", payload: res.data });
            },
        });
        summaryStationBillGetRequest({
            id: 'd',
            params: urlParams,

            onSuccess: (res) => {
                dispatch({ type: "summary-station/set", payload: res.data });
            },
        });
        summaryExpensesBillGetRequest({
            id: 'd',
            params: urlParams,

            onSuccess: (res) => {
                dispatch({ type: "summary-expenses/set", payload: res.data });
            },
        });
    }, [params]);

 //columns

    let tradercolumns = [

        {
            field: "enter_empty",
            headerName: globalLang.enter_empty[lang],
            customContent: ({ enter_empty }) =>
                enter_empty === 0 ? "0" : enter_empty ? enter_empty : "-",
        },
        {
            field: "enter_filled",
            headerName: globalLang.enter_filled[lang],
            customContent: ({ enter_filled }) =>
                enter_filled === 0 ? "0" : enter_filled ? enter_filled : "-",
        },
        {
            field: "exit_empty",
            headerName: globalLang.exit_empty[lang],
            customContent: ({ exit_empty }) =>
                exit_empty === 0 ? "0" : exit_empty ? exit_empty : "-",
        },
        {
            field: "exit_filled",
            headerName: globalLang.exit_filled[lang],
            customContent: ({ exit_filled }) =>
                exit_filled === 0 ? "0" : exit_filled ? exit_filled : "-",
        },
        {
            field: "quantityreturned",
            headerName: globalLang.returned[lang],
            customContent: ({ quantityreturned }) =>
                quantityreturned === 0
                    ? "0"
                    : quantityreturned
                        ? quantityreturned
                        : "-",
        },
        {
            field: "price",
            headerName: globalLang.price[lang],
            customContent: ({ price }) => (price === 0 ? "0" : price ? price : "-"),
        },

        {
            field: "fill",
            headerName: globalLang.fill[lang],
            customContent: ({ fill }) => (fill === 0 ? "0" : fill ? fill : "-"),
        },
        {
            field: "fillprice",
            headerName: globalLang.fill_price[lang],
            customContent: ({ fillprice }) =>
                fillprice === 0 ? "0" : fillprice ? fillprice : "-",
        },
        {
            field: "paid",
            headerName: globalLang.paid[lang],
            customContent: ({ paid }) => (paid === 0 ? "0" : paid ? paid : "-"),
        },
        {
            field: "remain_price",
            headerName: globalLang.remainprice[lang],
            customContent: ({ remain_price }) =>
                remain_price === 0 ? "0" : remain_price ? remain_price : "-",
        },
        {
            field: "dokhan",
            headerName: globalLang.tips[lang],
            customContent: ({ dokhan }) =>
                dokhan === 0 ? "0" : dokhan ? dokhan : "-",
        },

        {
            field: "safi",
            headerName: globalLang.all[lang],
            customContent: ({ safi }) => (safi === 0 ? "0" : safi ? safi : "-"),
        },
        {
            field: "store",
            headerName: globalLang.wallet[lang],
            customContent: ({ store }) => (store === 0 ? "0" : store ? store : "-"),
        },
    ];
    let stationcolumns = [

        {
            field: "quantityin",
            headerName: globalLang.input[lang],
            customContent: ({ quantityin }) => (quantityin === 0 ? "0" : quantityin),
        },
        {
            field: "quantityout",
            headerName: globalLang.output[lang],
            customContent: ({ quantityout }) =>
                quantityout === 0 ? "0" : quantityout,
        },
        {
            field: "quantityreturned",
            headerName: globalLang.returned[lang],
            customContent: ({ quantityreturned }) =>
                quantityreturned === 0 ? "0" : quantityreturned,
        },
        {
            field: "price",
            headerName: globalLang.price[lang],
            customContent: ({ price }) => (price === 0 ? "0" : price),
        },

        {
            field: "fill",
            headerName: globalLang.fill[lang],
            customContent: ({ fill }) => (fill === 0 ? "0" : fill),
        },
        {
            field: "fillprice",
            headerName: globalLang.fill_price[lang],
            customContent: ({ fillprice }) => (fillprice === 0 ? "0" : fillprice),
        },
        {
            field: "paid",
            headerName: globalLang.paid[lang],
            customContent: ({ paid }) => (paid === 0 ? "0" : paid),
        },
        {
            field: "remain_price",
            headerName: globalLang.remainprice[lang],
            customContent: ({ remain_price }) =>
                remain_price === 0 ? "0" : remain_price,
        },
        {
            field: "dokhan",
            headerName: globalLang.tips[lang],
            customContent: ({ dokhan }) => (dokhan === 0 ? "0" : dokhan),
        },

        {
            field: "safi",
            headerName: globalLang.all[lang],
            customContent: ({ safi }) => (safi === 0 ? "0" : safi),
        },
        {
            field: "store",
            headerName: globalLang.wallet[lang],
            customContent: ({ store }) => (store === 0 ? "0" : store),
        },
    ];
    let expensescolumns = [
       
        {
            field: "price",
            headerName: globalLang.expensesPrice[lang],
            customContent: ({ price }) => (price ? price : "-"),
        },

    ];
    const handleFilter = (filters) => {
        const filter = filters.map(({ query }) => query);
        let values = Object.values(filter).map(([key, value]) => {
            return { [key]: value };
        });
        const object = Object.assign({}, ...values);

        setparams({
            date: params.date,
            page: params.page,
            ...object,
        });
    };
    const handleChangeAmount = ({ value }) => {
        setparams((old) => ({
            ...old,
            page: 1,
            size: value,
        }));
    };
    const handlePaginate = (params) => {
        console.log(params);
        setparams((old) => ({
            ...old,
            page: params.current,
        }));
    };



    //filter using date
    const handleChangeFilter = (date) => {


        let newDate = `${date["$y"]}-${parseInt(date["$M"]) + 1}-${date["$D"]}`;

        setparams((old) => ({ ...old, date: newDate }));
    };

    /////////////////

    // Columns name

    let columns = [



        {
            field: "driven",
            headerName: globalLang.driven[lang],
            customContent: ({ driven }) =>
                driven === 0 ? "0" : driven ? driven : "-",
        },
        {
            field: "taken",
            headerName: globalLang.taken[lang],
            customContent: ({ taken }) => (taken === 0 ? "0" : taken ? taken : "-"),
        },
        {
            field: "returned",
            headerName: globalLang.returned[lang],
            customContent: ({ returned }) =>
                returned === 0 ? "0" : returned ? returned : "-",
        },
        {
            field: "fill",
            headerName: globalLang.fill[lang],
            customContent: ({ fill }) => (fill === 0 ? "0" : fill ? fill : "-"),
        },
        {
            field: "price",
            headerName: globalLang.price[lang],
            customContent: ({ price }) => (price === 0 ? "0" : price ? price : "-"),
        },

        {
            field: "fillprice",
            headerName: globalLang.fill_price[lang],
            customContent: ({ fillprice }) => (fillprice === 0 ? "0" : fillprice),
        },
        {
            field: "paid",
            headerName: globalLang.paid[lang],
            customContent: ({ paid }) => (paid === 0 ? "0" : paid),
        },
        {
            field: "remain_price",
            headerName: globalLang.remainprice[lang],
            customContent: ({ remain_price }) =>
                remain_price === 0 ? "0" : remain_price,
        },
        {
            field: "dokhan",
            headerName: globalLang.tips[lang],
            customContent: ({ dokhan }) =>
                dokhan === 0 ? "0" : dokhan ? dokhan : "-",
        },

        {
            field: "safi",
            headerName: globalLang.all[lang],
            customContent: ({ safi }) => (safi === 0 ? "0" : safi),
        },
        {
            field: "store",
            headerName: globalLang.wallet[lang],
            customContent: ({ store }) => (store === 0 ? "0" : store),
        },
    ];


    return (
        <Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    openTo={"day"}
                    views={
                        ["year", "month", "day"]
                    }
                    value={params.date}
                    onChange={(newValue) => {
                        handleChangeFilter(newValue);
                    }}
                    renderInput={(params) => (
                        <InputField
                            sx={{
                                width: "20%",
                                margin: "15px",
                                width: {
                                    lg: "auto",
                                    xl: "auto",
                                    md: "auto",
                                    sm: "90%",
                                    xs: "90%",
                                },
                                "& .MuiInputBase-root": {
                                    borderColor: "#F0F0F8",
                                    paddingRight: "5px",
                                },
                                "& .MuiFormLabel-root": {
                                    color: "#666666",
                                },
                            }}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>
            {summaryStationBillGetResponse.isPending || summaryfillreceiptGetResponse.isPending || summaryclientreceiptGetResponse.isPending &&
                <Box
                >
                    <CircularProgress sx={{ color: "gray" }} />
                </Box>
            }
            <Stack sx={{ padding: '10px' }}>
                <Print>
                    <Box className='showdate'>{params.date}</Box>
                    <Typography sx={{ color: '#141414', fontWeight: 'bold' }} variant='string' >تعبئة</Typography>
                    <DataGrid
                        sx={{ height: '8.5rem' }}
                        totaloverview={true}
                        columns={tradercolumns}
                        sum={SummaryfillReceiptStore.total}
                        isPending={summaryfillreceiptGetResponse.isPending}
    
                        onPaginate={handlePaginate}
                        onAmountChange={handleChangeAmount}
                    />
                    <Typography sx={{ color: '#141414', fontWeight: 'bold' }} variant='string' >شحن</Typography>
                    <DataGrid
                        sx={{ height: '8.5rem' }}
                        totaloverview={true}
                      
                        columns={stationcolumns}
                        sum={SummaryStationBillStore.total}
                        total={SummaryStationBillStore.count}
                        isPending={summaryStationBillGetResponse.isPending}
                        onPaginate={handlePaginate}
                        onAmountChange={handleChangeAmount}
                    />

                    <Typography sx={{ color: '#141414', fontWeight: 'bold' }} variant='string' >توزيع</Typography>
                    <DataGrid
                        sx={{ height: '8.5rem' }}
                        totaloverview={true}
                        columns={columns}
                        sum={SummaryclientReceiptStore.total}
                        isPending={summaryclientreceiptGetResponse.isPending}
                    
                        onPaginate={handlePaginate}
                        onAmountChange={handleChangeAmount}
                    />
                    <Typography sx={{ color: '#141414', fontWeight: 'bold' }} variant='string' >مصروفات</Typography>
                    <DataGrid
                        sx={{ height: '8.5rem' }}
                        totaloverview={true}
                        columns={expensescolumns}
                        sum={SummaryExpensesBillStore.total}

                        total={SummaryExpensesBillStore.count}
                        isPending={summaryExpensesBillGetResponse.isPending}
                        onPaginate={handlePaginate}
                        onAmountChange={handleChangeAmount}
              
              
                    />

                </Print>

            </Stack>


        </Box>
    );
};

export default DailyBillsOverView;
export const ProductFilter = ({ value, onChange, func }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////

    const [products, setProducts] = useState([]);

    const handleChange = (e) => {
        onChange({
            query: ["product", e.target.value],
            renderedValue: products.find((p) => p.id === e.target.value).name,
            value: e.target.value,
        });
    };

    const [productsGetRequest, productsGetResponse] = useRequest({
        path: PRODUCTS,
        method: "get",
    });

    const getProducts = () => {
        productsGetRequest({
            params: {
                size: 1000,
            },
            onSuccess: (res) => {
                setProducts(res.data.results);
            },
        });
    };

    return (
        <SelectField
            placeholder={globalLang.product[lang]}
            renderValue={(selected) => products.find((p) => p.id === selected).name}
            onOpen={getProducts}
            isPending={productsGetResponse.isPending}
            onChange={handleChange}
        >
            {products.map((product, index) => (
                <MenuItem value={product.id} key={`${index}`}>
                    {product.name}
                </MenuItem>
            ))}
        </SelectField>
    );
};
const ClientFilter = ({ value, onChange, func }) => {
    // Change lang
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);

    ///////////////////////////////

    const [clients, setClients] = useState([]);

    const handleChange = (e) => {
        onChange({
            query: ["client", e.target.value],
            renderedValue:
                clients.find((c) => c.id === e.target.value)?.user.first_name +
                " " +
                clients.find((c) => c.id === e.target.value)?.user.last_name,
            value: e.target.value,
        });
    };

    const [clientsGetRequest, clientsGetResponse] = useRequest({
        path: CLIENTS,
        method: "get",
    });

    const getClients = () => {
        clientsGetRequest({
            params: {
                size: 1000,
            },
            onSuccess: (res) => {
                setClients(res.data.results);
            },
        });
    };

    return (
        <SelectField
            placeholder={globalLang.client[lang]}
            renderValue={(selected) => {
                let client = clients.find((c) => c.id === selected)?.user;
                return client.first_name + " " + client.last_name;
            }}
            onOpen={getClients}
            isPending={clientsGetResponse.isPending}
            onChange={handleChange}
        >
            {clients.map((client, index) => (
                <MenuItem value={client.id} key={`${index}`}>
                    {client?.user?.first_name + " " + client?.user.last_name}
                </MenuItem>
            ))}
        </SelectField>
    );
};



import { MenuItem, Typography } from "@mui/material";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../components/DataGrid";
import { deleteStockLoan, SELFFILL, STOCK, STOCK_OUTS, STOCK_PRACTICAL, TANK, TRANSFERSTOCK, UPDATE_STOCK, UPDATE_STOCK_PRACTICAL } from "../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInputField,
  DialogNumberField,
  DialogSelectField,
  DialogTable,
} from "../../features/dialog";
import Form, { InputField, NumberField } from "../../features/form";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../hooks/useAfterEffect";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import filter from "../../utils/ClearNull";
import compare from "../../utils/Compare";
import { useNavigate } from "react-router-dom";
import Print from "../../components/PrintComponent";

function StockBalance() {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  // const stockBalanceStore = useSelector((state) => state.stockbalance.value);
  const stocksStore = useSelector((state) => state.practicalStock.value);
  const [editData, setEditData] = useState(null);
  const [filterdata, setfilterdata] = useState({
    Date: getDate(),
  });
 
  const [stockDetails, setStockDetails] = useState({
    details: null,
    tab: "details",
  });

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();
  const columns = [
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ product }) => (product ? product.name : "-"),
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
      customContent: ({ product_type }) => (product_type ? product_type === "cylinder"&&"اسطوانة"||product_type==="dewar"&&"دوار": "-"),
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
      customContent: ({ volume }) =>
        volume === 0 ? "0" : volume ? volume : "-",
    },
    {
      field: "empty_store",
      headerName: globalLang.empty[lang],
      customContent: ({ empty_store }) =>
        empty_store === 0 ? "0" : empty_store ? empty_store : "-",
    },
    {
      field: "filled_store",
      headerName: globalLang.filled[lang],
      customContent: ({ filled_store }) =>
        filled_store === 0 ? "0" : filled_store ? filled_store : "-",
    },
    {
      field: "factorypower",
      headerName: globalLang.factorypower[lang],
      customContent: ({ factorypower }) =>
      factorypower === 0 ? "0" : factorypower ? factorypower : "-",
    },
    {
      field: "outside",
      headerName: globalLang.outputs[lang],
      customContent: ({ outside }) => (outside ? outside : "0"),
    },
    {
      field: "total_capacity",
      headerName: globalLang.raseef[lang],
      customContent: ({ total_capacity }) =>
        total_capacity === 0 ? "0" : total_capacity ? total_capacity : "-",
    },
  ];
  const [StockBalanceGetRequest, stockHiBalanceGetResponse] = useRequest({
    path: STOCK_PRACTICAL,
    method: "get",
  });

  const [stockDetailsGetRequest, stockDetailsGetResponse] = useRequest({
    path: STOCK_OUTS,
    method: "get",
  });

  const [{ controls }, { setControl }] = useControls([
    {
      control: "date",
      value: getDate(),
      isRequired: false,
    },
  ]);

  const getBalance = () => {
    // filterdata.stock && urlParams.append("id", filterdata.stock);
    if (controls.date) {
      urlParams.append("date", controls.date);
    }
    StockBalanceGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "practical-stock/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getBalance();
  }, [requestParams, controls.date]);

  useAfterEffect(() => {
    if (!stockDetails.details) return;
    setStockDetails((old) => ({
      ...old,
      details: stocksStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [stocksStore]);
// to update data from edite icon
  const isPermitted = useIsPermitted();
  useAfterEffect(() => {
    if (!stockDetails.details) return;
    setStockDetails((old) => ({
      ...old,
      details: stocksStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [stocksStore]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };

  const getStockDetails = () => {
    stockDetailsGetRequest({
      id: stockDetails.details.id,
      
      onSuccess: (res) => {
        dispatch({ type: "loans-stock/set", payload: res.data });
        
      },
    });
  };
  const handleCloseDetailsDialog = () => {
    setStockDetails((old) => ({ ...old, details: null, tab: "details" }));
  };
    
  //delete data from api and data grid
  const [addselffilldata, setSelfFillData] = useState(null);
    const [stocksDeleteRequest, stocksDeleteResponse] = useRequest({
      path: STOCK,
      method: "delete",
      successMessage: "تم حذف المنتج بنجاح",
    });

    const [TransferStockRequest, TransferStockResponse] = useRequest({
      path: TRANSFERSTOCK,
      method: "patch",
      successMessage: "تم تحويل المنتج بنجاح",
    });

    const deleteStock = (e, row) => {
      stocksDeleteRequest({
        id: row.id,
        onSuccess: (res) => {
          dispatch({ type: "stocks/deleteItem", payload: { id: row.id } });
        },
      });
    };
    const navigate=useNavigate()
    const transferStock=(e,row)=>{
      TransferStockRequest({
        id: row.id,
      });
      
    }
    const [handleDeleteStock, deleteStockConfirmDialog] = useConfirmMessage({
      onConfirm: deleteStock,
      text: useConfirmMessage[lang],
    });
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={globalLang.DateAdded[lang]}
          value={controls.date}
          format={"yyyy,MM,dd"}
          onChange={(newValue) => {
            const new_date = `${newValue["$y"]}-${
              parseInt(newValue["$M"]) + 1
            }-${newValue["$D"]}`;

   
            setControl("date", new_date);

            // setfilterdata({Date:newValue})
       
          }}
          renderInput={(params) => (
            <InputField
              sx={{
                width: "40%",
                width: {
                  xl: "40%",
                  lg: "40%",
                  md: "100%",
                  sm: "100%",
                  xs: "100%",
                },
                ml: {
                  xl: 1,
                  lg: 1,
                  md: "auto",
                  sm: "auto",
                  xs: "auto",
                },
                "& .MuiInputBase-root": {
                  borderColor: "#F0F0F8",
                  paddingRight: "5px",
                  mb: 2,
                },
                "& .MuiFormLabel-root": {
                  color: "#666666",
                },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
      <DataGrid
        columns={columns}
        rows={stocksStore.results}
        total={stocksStore.count}
        isPending={stockHiBalanceGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onView={(e, row) => {
          setStockDetails((old) => ({
            ...old,
            details: row,
          }));
        }}
        
        onEdit={isPermitted(
          (e, row) => setEditData(row),
          ["change_practicalstock"]
        )}
        ontransfare={isPermitted((e,row)=>{ transferStock(e,row)},[''])}
        
      />
      <InfoDialog
        open={Boolean(stockDetails.details && stockDetails.tab === "details")}
        onOpen={getStockDetails}
        onClose={handleCloseDetailsDialog}
        data={Boolean(stockDetails.details) && stockDetails.details}
        isHistoryPending={stockDetailsGetResponse.isPending}
        stockDetails={stockDetails.details}
        // onDelete={isPermitted(handleDeleteStock, ["delete_factorystock"])}
      />
      
       <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
        
      />
      <AddSelfFillDailog 
        open={Boolean(addselffilldata)}
        onClose={() => setSelfFillData(null)}
        data={addselffilldata}
      />
      
    </>
  );
}
export default StockBalance;
// to view data
export const InfoDialog = ({
  isHistoryPending,
  open,
  onOpen,
  onClose,
  data,
  stockDetails
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewTradersLang = useSelector((state) => state.lang.value.ViewTraders);
  const globalLang = useSelector((state) => state.lang.value.global);
  const stocksStore = useSelector((state) => state.stocks.value);
  const [editData, setEditData] = useState({});
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.StockMessage
  );
  
  //////////////////////////////////
  //----store----
  const stodkDetailsStore = useSelector((state) => state.loansStock.value);
console.log(stodkDetailsStore)
  const dispatch = useDispatch();
  
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "clients",
        value: data?.clients,
        isRequired: true,
      },
      {
        control: "owned_number",
        value: data?.owned_number,
        isRequired: true,
      },
      {
        control: "stations",
        value: data?.stations,
        isRequired: true,
      },
      {
        control: "traders",
        value: data?.traders,
        isRequired: true,
      },

    ],
    [data]
  );

  //----variables----
  const Tradercolumns = [
    {
      field: "username",
      headerName: globalLang.trader[lang],
      customContent: ({ rowData }) =>
        rowData.username ? `${rowData.username}` : "-",
    },

    // {
    {
      field: "owned_number",
      headerName: globalLang.wallet[lang],
      // customContent: ({ rowData }) =>
      // editTrader? <NumberField sx={{width:"30px",background:"#fff",textAlign:"center"}}
      // onChange={(e) => setControl("owned_number", e.target.value)}
      // value={controls.owned_number}
      // />:rowData.owned_number === 0
      //     ? "0"
      //     : rowData.owned_number
      //     ? rowData.owned_number
      //     : "-",
    // }
  },
    
  ];
  const stationColumns = [
    {
      field: "username",
      headerName: globalLang.station[lang],
      customContent: ({ rowData }) =>
        rowData.username ? `${rowData.username}` : "-",
    },

    {
      field: "owned_number",
      headerName: globalLang.wallet[lang],
      // customContent: ({ rowData }) =>
      // editStation? <NumberField
      // sx={{width:"30px",background:"#fff",textAlign:"center"}}
      // onChange={(e) => setControl("owned_number", e.target.value)}
      // value={controls.owned_number}
      // />: rowData.owned_number === 0
      //     ? "0"
      //     : rowData.owned_number
      //     ? rowData.owned_number
      //     : "-",
    },

  ];
  const clientsColumns = [
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ rowData }) =>
        rowData.username ? `${rowData.username}` : "-",
    },

    {
      field: "owned_number",
      headerName: globalLang.wallet[lang],
      // customContent: ({ rowData }) =>
      // // editClient? <NumberField 
      // // sx={{width:"30px",background:"#fff",textAlign:"center"}}
      // // onChange={(e) => setControl("owned_number", e.target.value)}
      // // value={controls.owned_number}
      // // />
      // rowData.owned_number === 0
      //     ? "0"
      //     : rowData.owned_number
      //     ? rowData.owned_number
      //     : "-",
    },
   
  ];
  
  const urlParams = new URLSearchParams();
  const isPermitted = useIsPermitted();
  const [stocksDeleteRequest, stocksDeleteResponse] = useRequest({
    path:  UPDATE_STOCK ,
    method: "delete",
    successMessage: "تم حذف المنتج بنجاح",
  });
  

  const deleteStockLoans = (e, rowData,rowKey) => {
    console.log(rowData);
    if (rowKey) {
      urlParams.append("type", rowKey);
    }
    stocksDeleteRequest({
      params: urlParams,
      id: stockDetails.id + "/loans/" + rowData.id,
      body:{
            owned_number:rowData.owned_number
      },
      onSuccess: (res) => {
        dispatch({ type: "loans-stock/deleteItem",   payload: { id: rowData.id, item:rowData,type:rowKey }, });
      },
    });
    
  };

  const [handleDeleteStockLoans, deleteStockLoansConfirmDialog] = useConfirmMessage({
    onConfirm: deleteStockLoans,
    text: confirmMessage[lang],
  });
  const EditStock = (e, rowData) => {
    setEditData(rowData)
  }
  
   //----effects----
   useAfterEffect(() => {
    if (!open) return;
    onOpen();
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose}>

      <DialogHeading>{globalLang.outDetails[lang]}</DialogHeading>
    {stodkDetailsStore.detail ? (<Typography component={'h2'} sx={{p:'1rem'}}>لا يوجد خوارج</Typography>):(  
      <>
      <Print>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.clients[lang]}</DialogHeading>
        <DialogTable
          rows={stodkDetailsStore?.clients}
          columns={clientsColumns}
          isPending={isHistoryPending}
          onEdit={
            (e,rowData,rowKey)=>{EditStock(e,rowData,rowKey="client") }
          
          }
          onDelete={(e,rowData,rowKey)=>{
            
            deleteStockLoans(e,rowData,rowKey="client")
          }}
          type="client"
          editData={editData}
          stockDetails={stockDetails}
        />
      </PermissionsGate>
      </Print>
      <Print>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.traders[lang]}</DialogHeading>
        <DialogTable
          edit={false}
          rows={stodkDetailsStore?.traders}
          columns={Tradercolumns}
          isPending={isHistoryPending}
          onEdit={
            (e,rowData,rowKey)=>{EditStock(e,rowData,rowKey="trader")}
          }
          onDelete={(e,rowData,rowKey)=>{
            
            deleteStockLoans(e,rowData,rowKey="trader")
           
          }
          }
          type="trader"
          editData={editData}
          stockDetails={stockDetails}
        />
      </PermissionsGate>
      </Print>
      <Print>
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.stations[lang]}</DialogHeading>
        <DialogTable
          rows={stodkDetailsStore?.stations}
          columns={stationColumns}
          isPending={isHistoryPending}
          onEdit={
            (e,rowData,rowKey)=>{EditStock(e,rowData,rowKey="station")}
          }

          onDelete={(e,rowData,rowKey)=>{
            deleteStockLoans(e,rowData,rowKey="station")}
          }
          type="station"
          editData={editData}
          stockDetails={stockDetails}
        />
      </PermissionsGate>
      </Print>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      
      </>
      )} 

    
    
    </Dialog>
  );
};

// to delete data
const AddSelfFillDailog = ({ open = false, onClose = () => {}, data = {} }) => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const tanksStore = useSelector((state) => state.tank.value);
  const stocksStore = useSelector((state) => state.stocks.value);
  ///////////////////////////////
  const [tanksgetRequest, tanksgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const getTanks = () => {
    tanksgetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };
  const [stockgetRequest, stockgetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });
  const getStock = () => {
    stockgetRequest({

      onSuccess: (res) => {
        dispatch({ type: "stocks/set", payload: res.data });
      },
    });
  };
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "bill_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "fill_type",
      value: "",
      isRequired: true,
    },
    {
      control: "tank",
      value: "",
      isRequired: false,
    },
    {
      control: "filled",
      value: "",
      isRequired: true,
    },
  ]);
  const fill_types = [
    {
      name: "تانك",
      value: "tank",
    },
    {
      name: "مصنع",
      value: "factory",
    },
  ];

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [selfFillPostRequest, selfFillPostResponse] = useRequest({
    path: SELFFILL,
    method: "post",
    successMessage: "تم الإضافة بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const requestBody = filter({
      obj: {
        bill_uuid: controls.bill_uuid,
        filled_date: controls.date.toISOString(),
        product: data.product.id,
        fill_type: controls.fill_type,
        product_type: data.product_type,
        volume: data.volume,
        filled: parseInt(controls.filled),
      },
      output: "object",
    });
    console.log(requestBody);
    if (controls.tank) {
      requestBody["tank"] = controls.tank;
    }

    selfFillPostRequest({
      body: requestBody,
      onSuccess: (res) => {
        dispatch({ type: "self_fill/addItem", payload: res.data });
        getStock()
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.add[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.receiptnumber[lang]}
          placeholder={globalLang.receiptnumber[lang]}
          value={controls.bill_uuid}
          onChange={(e) => setControl("bill_uuid", e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={globalLang.Date[lang]}
            value={controls.date}
            onChange={(newValue) => {
              setControl("date", newValue["$d"]);
            }}
            renderInput={(params) => (
              <DialogInputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderColor: "#F0F0F8",
                    paddingRight: "5px",
                    background: "white",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#666666",
                  },
                }}
                required={required.includes("date")}
                error={Boolean(invalid.date)}
                helperText={invalid.date}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <DialogSelectField
          label={globalLang.fill_type[lang]}
          placeholder={globalLang.fill_type[lang]}
          name="fill_type"
          renderValue={(selected) => {
            return fill_types.find((type) => type.value === selected)?.name;
          }}
          onChange={(e) => {
            setControl("fill_type", e.target.value);
          }}
          value={controls.fill_type}
          error={Boolean(invalid.fill_type)}
          helperText={invalid.fill_type}
          required={required.includes("fill_type")}
        >
          {fill_types?.map((type, index) => (
            <MenuItem value={type.value} key={`${type.name} ${index}`}>
              {type.name}
            </MenuItem>
          ))}
        </DialogSelectField>
        {controls.fill_type === "tank" && (
          <DialogSelectField
            label={globalLang.tank[lang]}
            placeholder={globalLang.tank[lang]}
            renderValue={(selected) => {
              return tanksStore.results.find((tank) => tank.id === selected)
                ?.tank_uuid;
            }}
            isPending={tanksgetResponse.isPending}
            onOpen={getTanks}
            onChange={(e) => {
              setControl("tank", e.target.value);
            }}
            value={controls.tank}
            required={required.includes("tank")}
            error={Boolean(invalid.tank)}
            helperText={invalid.tank}
          >
            {tanksStore?.results?.map((tank, index) => (
              <MenuItem value={tank.id} key={`${tank?.product?.name} ${index}`}>
                {tank?.tank_uuid}
              </MenuItem>
            ))}
          </DialogSelectField>
        )}
        <DialogNumberField
          label={globalLang.fill[lang]}
          placeholder={globalLang.fill[lang]}
          value={controls.filled}
          onChange={(e) => setControl("filled", e.target.value)}
        />
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={selfFillPostResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {selfFillPostResponse.failAlert}
    </Dialog>
  );
};

// to update data
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const UpdateStockBalance=useSelector((state)=>state.practicalStock.value)

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "empty_store",
        value: data?.empty_store,
        isRequired: true,
      },
      {
        control: "filled_store",
        value: data?.filled_store,
        isRequired: true,
      },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [stockPatchRequest, stockPatchResponse] = useRequest({
    path: UPDATE_STOCK_PRACTICAL,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.empty_store, data.empty_store],
        [controls.filled_store, data.filled_store],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          empty_store: controls.empty_store,
          filled_store: controls.filled_store,
        },
        output: "object",
      });

      stockPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "practical-stock/putItem",
            payload:{ id: res.data.id, item: res.data },
          });
          onClose();
          console.log(res.data)
        },
      });
    }
  };
  //===Start===== Get Countries logic =========
  // const [countriesData, setCountriesData] = useState([]);

  // const [countriesGetRequest, countriesGetResponse] = useRequest({
  //   path: COUNTRY_FILTER,
  //   method: "get",
  // });

  // const getCountries = () => {
  //   if (countriesData.length) return;
  //   countriesGetRequest({
  //     onSuccess: (res) => {
  //       setCountriesData(res.data.data);
  //     },
  //   });
  // };

  // const [governoratesData, setGovernoratesData] = useState([]);

  // const [statesGetRequest, statesGetResponse] = useRequest({
  //   path: STATES,
  //   method: "post",
  // });

  // const getGovernorates = () => {
  //   if (governoratesData.length) return;
  //   statesGetRequest({
  //     body: {
  //       country: controls.country,
  //     },
  //     onSuccess: (res) => {
  //       setGovernoratesData(res.data.data);
  //     },
  //   });
  // };

  //===End===== Get Countries logic =========

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.editpowerData[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.empty[lang]}
          placeholder={globalLang.empty[lang]}
          value={controls.empty_store}
          onChange={(e) => setControl("empty_store", e.target.value)}
        />
        <DialogNumberField
          label={globalLang.filled_store[lang]}
          placeholder={globalLang.filled_store[lang]}
          value={controls.filled_store}
          onChange={(e) => setControl("filled_store", e.target.value)}
        />
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={stockPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {stockPatchResponse.failAlert}
    </Dialog>
  );
};
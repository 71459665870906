import React, { useState } from "react";

import Form, {
  InputField,

  NumberField,

  SelectField,
} from "../../../features/form";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";

import {
  CLIENTAWAYBILL,
  CLIENTS,
  Commission,
  EMPLOYEES,
  STATIONS,
  TRADERS,
  TRANSPORT,
} from "../../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Stack, Box } from "@mui/material";
import filter from "../../../utils/ClearNull";
import ReceiptTable from "../../../components/ReceiptTable";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import CommissionFrom from "./commissionFrom";

const CommissionTo = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const [success, setSuccess] = useState(false)
  const globalLang = useSelector((state) => state.lang.value.global);
  const stationsStore = useSelector((state) => state.stations.value);

  const columns = [
    {
      name: "driven",
      value: "driven",
    },
    {
      name: "product",
      value: "product",
    },
    {
      name: "volume",
      value: "volume",
    },
   
  ];
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (gas) => {
    setFormData([...gas]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };
  //////////////////////////////////
  //----store----
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday
  }
 
  const employeesStore = useSelector((state) => state.employees.value);
  const tradersStore = useSelector((state) => state.traders.value);
  const clientsStore = useSelector((state) => state.clients.value);
  const transportStore = useSelector((state) => state.transport.value);
  const dispatch = useDispatch();

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "number",
      value: "",
      isRequired: true,
    },
    {
      control: "transport",
      value: "",
      isRequired: true,
    },
    {
      control: "driver",
      value: "",
      isRequired: true,
    },

    {
      control: "Date",
      value: new Date(),
      isRequired: false,
    },
    {
      control: "bills",
      value: [],
      isRequired: true,
    },
    {
      control: "thetype",
      value: "",
      isRequired: false,
    },
    
  ]);


  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });

  const getStations = () => {
    stationsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  const [filterdata, setfilterdata] = useState({
    station: "",
    Date: getDate(),
   
  })
  const [filterdataTr, setfilterdataTr] = useState({
    trader: "",
    Date: getDate(),
  });
  const [filterdataCl, setfilterdataCl] = useState({
    client: "",
    Date: getDate(),
  });
  //----request hooks----

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const [transportGetRequest, transportGetResponse] = useRequest({
    path: TRANSPORT,
    method: "get",
  });

  const [CommissionPostRequest, CommissionPostResponse] = useRequest({
    path: Commission,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });

  //----functions----
  const thetype = [
    {name: "محطة", value: "S"},
    {name: "تاجر", value: "T"},
    {name: "عميل", value: "C"},
  ];
  const getEmployees = () => {
    if (Boolean(employeesStore.results.length)) return;
    employeesGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "employees/set", payload: res.data });
      },
    });
  };

  const getTransportation = () => {
    if (Boolean(transportStore.results.length)) return;
    transportGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "transport/set", payload: res.data });
      },
    });
  };

  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const getTraders = () => {
    if (Boolean(tradersStore.results.length)) return;
    tradersGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };
  const [clientGetRequest, clientGetResponse] = useRequest({
    path: CLIENTS,
    method: "get",
  });

  const getClients = () => {
    if (Boolean(clientsStore.results.length)) return;
    clientGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        dispatch({ type: "clients/set", payload: res.data });
      },
    });
  };
  
  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          order_uuid: controls.number,
          order_date:  controls.Date.toISOString(),
          driver: controls.driver,
          transport: controls.transport,
          from_location_type:controls.thetype,
          from_location_object_id:controls.thetype==="S"&&filterdata.station||controls.thetype==="T"&&filterdataTr.trader ||controls.thetype==="C"&&filterdataCl.client,
          products: [
            ...FormData.filter((object) => Object.keys(object).length !== 0),
            ...LiquidFormData.filter(
              (object) => Object.keys(object).length !== 0
            ),
          ],
        },
        output: "object",
      });
      CommissionPostRequest({
        body: requestBody,
        onSuccess: () => {
          resetControls();
          setSuccess(true)
        },
      }).then((res) => {

        if (res?.id) {
          dispatch({
            type: "commission/addItem",
            payload: res,
          });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  
  return (
    <>

      <Form
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: CommissionPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: CommissionPostResponse.isPending,
          },
        }}
      >
        <Stack sx={{ gap: 2, padding: "10px", margin: "10px" }}>
          <Stack
            sx={{
              gap: 5 ,
              display:"flex",
              width: "100%",  
              mx:"auto",  
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              flexWrap:"wrap",
              // padding: "10px",
              // "&.MuiTextField-root": {
              //   minWidth: "16px",
               
              // },

            }}
          >
            <NumberField
              label={globalLang.receiptnumber[lang]}
              placeholder={globalLang.receiptnumber[lang]}
              onChange={(e) => setControl("number", e.target.value)}
              value={controls.number}
              required={required.includes("number")}
              error={Boolean(invalid.number)}
              helperText={invalid.number}
              //  sx={{xl:"48%",lg:"48%",md:"48%",sm:"100%",xs:"100%"}}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label={globalLang.Date[lang]}
                value={controls.Date}
                //  sx={{ width:{xl:"48%",lg:"48%",md:"48%",sm:"100%",xs:"100%"},}}
                onChange={(newValue) => {
                  setControl("Date", newValue);
                }}
                renderInput={(params) => (
                  <InputField
                    sx={{
                     
                      "& .MuiInputBase-root": {
                        borderColor: "#F0F0F8",
                        paddingRight: "5px",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#666666",
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <InputField
              label={globalLang.driver[lang]}
              placeholder={globalLang.driver[lang]}
              onChange={(e) => {
                setControl("driver", e.target.value);
              }}
              value={controls.driver}
              required={required.includes("driver")}
              error={Boolean(invalid.driver)}
              helperText={invalid.driver}
              // sx={{width:{xl:"48%",lg:"48%",md:"48%",sm:"100%",xs:"100%"},}}
           />
            
           
            <InputField
              label={globalLang.transportation[lang]}
              placeholder={globalLang.transportation[lang]}
              value={controls.transport}
              required={required.includes("transport")}
              error={Boolean(invalid.transport)}
              helperText={invalid.transport}
              onChange={(e) => setControl("transport", e.target.value)}
              // sx={{width:{xl:"48%",lg:"48%",md:"48%",sm:"100%",xs:"100%"},}}
            />
            {/* </Stack>
            <Stack gap={2}  sx={{
              
              display:"flex",
              width: {xl:"50%",lg:"50%",md:"50%",sm:"100%",xs:"100%"},    
              flexDirection: {
                xl: "row",
                lg: "row",
                md: "column",
                sm: "column",
                xs: "column",
              },
              flexWrap:"wrap",
              padding: "10px",
              "& .MuiTextField-root": {
                minWidth: "16px",
               
              },

            }}> */}
              <SelectField
                label={globalLang.thetype[lang]}
                placeholder={globalLang.thetype[lang]}
                renderValue={(selected) => {
                  return thetype.find((type) => type.value === selected).name;
                }}
                onChange={(e) => {
                  setControl("thetype", e.target.value);
                }}
                value={controls.thetype}
                required={required.includes("thetype")}
                error={Boolean(invalid.thetype)}
                helperText={invalid.thetype}
                 sx={{width:{xl:"20%",lg:"20%",md:"50%",sm:"100%",xs:"100%"},}}
              >
                {thetype.map((thetype, index) => (
                  <MenuItem value={thetype.value} key={`${thetype.value} ${index}`}>
                    {thetype.name}
                  </MenuItem>
                ))}
              </SelectField>
           {controls.thetype === "S" &&
      
            <AutocompleteField
            label={globalLang.stations[lang]}
            sx={{width:{xl:"20%",lg:"20%",md:"50%",sm:"100%",xs:"100%"},}}
            isPending={stationsGetResponse.isPending}
            onOpen={getStations}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata((old) => ({ ...old, station: options.value }));
                  break;
                case "clear":
                  setfilterdata((old) => ({ ...old, station: "" }));
              }
            }}
            data={[
              ...stationsStore?.results.map((c, index) =>
         
                 ({
              
               
                label: c?.user?.name,
                value: c.id,
                
              })
            
            ),
            ]}
            value={filterdata.station}
            // required={required.includes("Exit_Permission_Station_bill")}
          /> 
        }
        {controls.thetype==="T" &&
           <AutocompleteField
           label={globalLang.traders[lang]}
           sx={{width:{xl:"25%",lg:"25%",md:"50%",sm:"100%",xs:"100%"},}}
           isPending={tradersGetResponse.isPending}
           onOpen={getTraders}
           isOptionEqualToValue={(option, value) =>
             option.value === value.value
           }
           onChange={(e, options, reason) => {
             switch (reason) {
               case "selectOption":
                 setfilterdataTr((old) => ({
                   ...old,
                   trader: options.value,
                 }));
                 break;
               case "clear":
                 setfilterdataTr((old) => ({
                   ...old,
                   trader: "",
                 }));
             }
           }}
           data={[
             ...tradersStore?.results.map((c, index) => ({
               label: c?.user?.first_name +""+c?.user?.last_name,
               value: c.id,
             })),
           ]}
           value={filterdataTr.trader}
           // required={required.includes("Exit_Permission_Station_bill")}
         />
        }
        {controls.thetype ==="C" &&
         <AutocompleteField
         label={globalLang.select_client[lang]}
         sx={{width:{xl:"25%",lg:"25%",md:"50%",sm:"100%",xs:"100%"},}}
         isPending={clientGetResponse.isPending}
         onOpen={getClients}
         isOptionEqualToValue={(option, value) =>
           option.value === value.value
         }
         onChange={(e, options, reason) => {
           switch (reason) {
             case "selectOption":
               setfilterdataCl((old) => ({
                 ...old,
                 client: options.value,
               }));
               break;
             case "clear":
               setfilterdataCl((old) => ({
                 ...old,
                 client: "",
               }));
           }
         }}
         data={[
           ...clientsStore?.results.map((c, index) => ({
             label: c?.user.first_name + " " + c?.user?.last_name,
             value: c.id,
           })),
         ]}
         value={filterdataCl.client}
         // required={required.includes("Exit_Permission_Station_bill")}
       />
        }
          </Stack>

          <Box>
            <Stack
              sx={{
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReceiptTable
                type="cylinder"
                columns={columns}
            
                getformData={getFormData}
                success={success}
              />
              <ReceiptTable
                type="dewar"
                columns={columns}
           
                getformData={getLiquidFormData}
                success={success}
              />

            </Stack>
          </Box>
        </Stack>
      </Form>
      {CommissionPostResponse.successAlert}
      {CommissionPostResponse.failAlert}
    </>
  );
};

export default CommissionTo;

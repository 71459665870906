import { Box } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import { Sales_Bill } from "../../../data/APIs";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";


function SalesBillView() {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const SalesBillStore = useSelector((state) => state.salesbill.value);
//   const [filterdata, setfilterdata] = useState({
//     Date: new Date(),
//   });
  const [salesBillDetails, setSalesBillDetails] = useState({
    details: null,
    tab: "details",
  });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.FactoryPowerMessage
  );
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();

  const columns = [
    {
          field: "bill_date",
          headerName: globalLang.Date[lang],
          customContent: ({ bill_date }) =>
          bill_date ? format(bill_date) : "-",
    },
    {
      field: "paid",
      headerName: globalLang.paid[lang],
      customContent: ({ paid }) => (paid ? paid : "-"),
    },
    {
      field: "bill_uuid",
      headerName: globalLang.bill_uuid[lang],
      customContent: ({ bill_uuid }) => (bill_uuid ? bill_uuid : "-"),
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) =>
      statement? statement : "-",
    },
    {
      field: "type",
      headerName: globalLang.thetype[lang],
      customContent: ({ type }) =>
      type? type ==="C"?"وساطة":"غير وساطة" : "-",
    },
    {
      field: "client",
      headerName: globalLang.client[lang],
      customContent: ({ client }) =>
      client ? client : "-",
    },
    {
      field: "trader",
      headerName: globalLang.trader[lang],
      customContent: ({ trader }) =>
      trader? trader : "-",
    },
  ];
  const [SalesBillGetRequest, SalesBillGetResponse] = useRequest({
    path: Sales_Bill,
    method: "get",
  });

  

  const [{ controls }, { setControl }] = useControls([
    {
      control: "date",
      value: new Date(),
      isRequired: false,
    },
  ]);

  const getSalesBill = () => {
    
    SalesBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "salesbill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getSalesBill();
  }, [requestParams, controls.date]);

  useAfterEffect(() => {
    if (!salesBillDetails.details) return;
    setSalesBillDetails((old) => ({
      ...old,
      details: SalesBillStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [SalesBillStore]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };


  return (
    <>
      <Box
      sx={{my:3}}>
      <Breadcrumbs
        path={[globalLang.sales_bill[lang], globalLang.view[lang]]}
       />
      
      <DataGrid
        columns={columns}
        rows={SalesBillStore.results}
        total={SalesBillStore.count}
        isPending={SalesBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        sum={SalesBillStore.total}
      />
      </Box>
      
    </>
  );
}
export default SalesBillView;


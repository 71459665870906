import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import ShippingReceipt from "./ShippingReceipt";
import ViewShipping from "./ViewShippingReceipt";
const ShipReceiptGasView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factorystationbill']}>
        <ShippingReceipt />
      </PermissionsGate>

      <PermissionsGate permissions={['view_factorystationbill']}>
        <ViewShipping />
      </PermissionsGate>
    </Wrapper>
  );
};
export default ShipReceiptGasView;

import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  BACKANDFORTHRECEIPT,
  CLIENTAWAYBILL,
  CLIENTHOMEBILL,
  STATIONENTERBILL,
  STATIONEXITBILL,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import Wrapper from "../../../components/Wrapper";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomTable from "../../../components/CustomTable";

const HomeDistribution = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const backandforthreceiptLang = useSelector(
    (state) => state.lang.value.addhomereceipt
  );
  const ClientAwayBillStore = useSelector(
    (state) => state.client_away_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [ClientHomeBillPostRequest, ClientHomeBillPostResponse] =
    useRequest({
      path: CLIENTHOMEBILL,
      method: "post",
      successMessage: "تم التعديل بنجاح",
    });

    const [FormData, setFormData] = useState([]);
    const [LiquidFormData, setLiquidFormData] = useState([]);
    const getFormData = (data) => {
      setFormData([...data]);
    };
    const getLiquidFormData = (liquid) => {
      setLiquidFormData([...liquid]);
    };

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    Client_away_bill: "",
  });
  const [ClientAwayBillData, setClientAwayBillData] = useState({});
  const [Products, setProducts] = useState([{}]);


  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "enter_date",
      value: new Date(),
      isRequired: true,
    },

  ]);

  const [ClientAwayBillGetRequest, ClientAwayBillGetResponse] = useRequest({
    path: CLIENTAWAYBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getClientAwayBill = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed", false);
    ClientAwayBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "client_away_bill/set", payload: res.data });
      },
    });
  };
  


  const handleSubmit = () => {
    validate().then((output) => {
        console.log(output)
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          permit_uuid: filterdata.Client_away_bill,
          enter_date: controls.enter_date.toISOString(),
          products: [
            ...FormData.filter((object) => Object.keys(object).length !== 0), 
            ...LiquidFormData.filter(
            (object) => typeof object !== 'undefined' && Object.keys(object).length !== 0 ? Object.keys(object): ""
          )],
        },
        output: "object",
      });

      ClientHomeBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
         handleresetFilters()
          resetControls();
          dispatch({
            type: "client_home_bill/addItem",
            payload: res?.data,
          });
        },
      }).then((res) => {
        let response = res?.response?.data;
      
       if(!res.id){
        setInvalid(res?.message);
       }
      });
    });
  };

  const handleFilterSubmit = () => {
    if (!filterdata.Client_away_bill) {
      return;
    }

    ClientAwayBillGetRequest({
      id: ClientAwayBillStore.results?.find(
        (bf) => bf?.permit_uuid === filterdata?.Client_away_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setClientAwayBillData(res?.data);
      }
    });
  };

 const handleresetFilters = () => {
   setfilterdata({ Client_away_bill: "" });
   setProducts([]);
   setfiltered(true);

   setIsCleared(uuid());
 };

 const text = [
  {text: globalLang.driven[lang]},
  {text: globalLang.product[lang]},
  {text: globalLang.volume[lang]},
  {text: globalLang.taken_empty[lang]},
  {text: globalLang.taken_filled[lang]},
]
const columns = [
  {
    name: "driven",
    value: globalLang.driven[lang],
  },
  {
    name: "product",
    value: globalLang.product[lang],
  },
  {
    name: "volume",
    value: globalLang.volume[lang],
  },
  {name: 'taken_empty', value: globalLang.taken_empty[lang]},
  {name: 'taken_filled', value:  globalLang.taken_filled[lang]},
];

const info = ["driven", "product", "volume"];
  return (
    <>
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.Client_away_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <AutocompleteField
            label={globalLang.BackAndForthreceipt[lang]}
            sx={{ width: "30vw" }}
            placeholder={globalLang.BackAndForthreceipt[lang]}
            isPending={ClientAwayBillGetResponse.isPending}
            onOpen={getClientAwayBill}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({
                    Client_away_bill: options.value,
                  });
                  break;
                case "clear":
                  setfilterdata({ Client_away_bill: "" });
              }
            }}
            data={[
              ...ClientAwayBillStore.results.map((bf, index) => ({
                label: String(bf.permit_uuid),
                value: bf.permit_uuid,
              })),
            ]}
            value={filterdata.Client_away_bill}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: ClientHomeBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: ClientHomeBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ padding: "20px", gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.receiptnumber[lang]}
                placeholder={globalLang.receiptnumber[lang]}
                value={filterdata.Client_away_bill}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.Date[lang]}
                value={format(ClientAwayBillData.exit_date)}
              />

              <InputField
                disabled
                label={globalLang.driver[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.driver[lang]}
                value={ClientAwayBillData.driver.name}
              ></InputField>
              <InputField
                disabled
                label={globalLang.transportation[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.transportation[lang]}
                value={ClientAwayBillData.transport.name}
              ></InputField>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={globalLang.BackDate[lang]}
                  value={controls.enter_date}
                  onChange={(newValue) => {
                    setControl("enter_date", newValue);
                  }}
                  error={Boolean(invalid.enter_date)}
                  helperText={invalid.enter_date}
                  required={required.includes("enter_date")}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Box>
              <Stack
                sx={{
                  flexDirection: {
                    xl: "row",
                    lg: "row",
                    md: "row",
                    sm: "column",
                    xs: "column",
                  },
                  gap: 2,
                  justifyContent: "center",
                  alignItems: "center",
                  width:"100%"
                }}
                >
                  
                   <CustomTable 
                      data={ClientAwayBillData?.distribution_products} 
                      type="cylinder"
                      text={text}
                      columns={columns}
                      getFormData={getFormData}
                      info={info}
                    /> 
                    <CustomTable 
                      data={ClientAwayBillData?.distribution_products} 
                      type="dewar"
                      text={text}
                      columns={columns}
                      getFormData={getLiquidFormData}
                      info={info}
                    /> 
              </Stack>
            </Box>
          </Stack>
        </Form>
  
      )}

      {ClientHomeBillPostResponse.successAlert}
      {ClientHomeBillPostResponse.failAlert}
    </>
  );
};
export default HomeDistribution;

import React, { useState } from "react";

import Wrapper from "../../../components/Wrapper";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import useControls from "../../../hooks/useControls";
import { EXPENSES, EXPENSESRECEIPT } from "../../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "@mui/material";
import filter from "../../../utils/ClearNull";
import useRequest from "../../../hooks/useRequest";
import ViewExpensesReceipts from "./ViewExpensesReceipts";
const ExpensesReceipt = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addexpensesreceiptLang = useSelector(
    (state) => state.lang.value.addexpensesreceipt
  );
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const expensesStore = useSelector((state) => state.expenses.value);

  const dispatch = useDispatch();

  const getDate = () => {
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;

    return dateTime;
  };
  //----states----
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "number",
      value: "",
      isRequired: true,
    },
    {
      control: "Date",
      value: getDate(),
      isRequired: false,
    },
    {
      control: "price",
      value: "",
      isRequired: true,
    },
    {
      control: "comment",
      value: "",
      isRequired: false,
    },

    {
      control: "expenses",
      value: "",
      isRequired: true,
    },
    {
      control:"deparment",
      value:"",
      isRequired:false
    }
  ]);

  const deparment=[{name:"التوزيع",value:"distribution"}
                  ,{name:"التعبئه",value:"filling"},
                  {name:"الشحن",value:"shipping"}]

  const [expensesGetRequest, expensesGetResponse] = useRequest({
    path: EXPENSES,
    method: "get",
  });
  const getExpenses = () => {
    expensesGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "expenses/set", payload: res.data });
      },
    });
  };

  //----request hooks----

  const [ExpensesReceiptPostRequest, ExpensesReceiptPostResponse] = useRequest({
    path: EXPENSESRECEIPT,
    method: "post",
    successMessage: "تم إضافة الفاتورة بنجاح",
  });

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const requestBody = filter({
        obj: {
          bill_uuid: controls.number,
          created_date: controls.Date,
          price: controls.price,
          comment: controls.comment,
          expenses: controls.expenses,
          category: controls.deparment,
        },
        output: "object",
      });
      ExpensesReceiptPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "receipt_expenses/addItem",
            payload: res?.data,
          });
          resetControls();
        },
      }).then((res) => {
        let response = res?.response?.data;
      
        if(!res?.bill_uuid)
        setInvalid(res?.data);
        
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.expenses[lang],
          addexpensesreceiptLang.addexpensesreceipt[lang],
        ]}
      /> */}
      <Form
        subtitle=" الرجاء ملئ المعلومات الآتية لاضافة سعر جديد للعميل"
        childrenProps={{
          saveBtn: {
            onClick: handleSubmit,
            disabled: ExpensesReceiptPostResponse.isPending,
          },
          closeBtn: {
            onClick: resetControls,
            disabled: ExpensesReceiptPostResponse.isPending,
          },
        }}
      >
        <NumberField
          label={globalLang.receiptnumber[lang]}
          placeholder={globalLang.receiptnumber[lang]}
          onChange={(e) => setControl("number", e.target.value)}
          value={controls.number}
          required={required.includes("number")}
          error={Boolean(invalid?.number)}
          helperText={invalid?.number}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={globalLang.DateAdded[lang]}
            value={controls.Date}
            onChange={(newValue) => {
              setControl("Date", newValue["$d"].toISOString());
            }}
            renderInput={(params) => (
              <InputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderColor: "#F0F0F8",
                    paddingRight: "5px",
                  },
                  "& .MuiFormLabel-root": {
                    color: "#666666",
                  },
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <SelectField
          label={globalLang.expenses[lang]}
          placeholder={globalLang.expenses[lang]}
          isPending={expensesGetResponse.isPending}
          onOpen={getExpenses}
          renderValue={(selected) => {
            return expensesStore?.results?.find(
              (expense) => expense.id === selected
            ).name;
          }}
          onChange={(e) => {
            setControl("expenses", e.target.value);
          }}
          value={controls.expenses}
          required={required.includes("expenses")}
          error={Boolean(invalid?.expenses)}
          helperText={invalid?.expenses}
        >
          {expensesStore.results?.map((expense, index) => (
            <MenuItem value={expense.id} key={` ${index}`}>
              {expense.name}
            </MenuItem>
          ))}
        </SelectField>
        <SelectField
          label={globalLang.deparment[lang]}
          placeholder={globalLang.deparment[lang]}
          isPending={expensesGetResponse.isPending}
          // onOpen={deparment.value}
          renderValue={(selected) => {
            return deparment.find(
              (deparment) => deparment.value === selected
            ).name;
          }}
          onChange={(e) => {
            setControl("deparment", e.target.value);
          }}
          value={controls.deparment}
          required={required.includes("deparment")}
          error={Boolean(invalid?.deparment)}
          helperText={invalid?.deparment}
        >
          {deparment.map((deparment, index) => (
            <MenuItem value={deparment.value} key={` ${index}`}>
              {deparment.name}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.expensesPrice[lang]}
          placeholder={globalLang.expensesPrice[lang]}
          onChange={(e) => setControl("price", e.target.value)}
          required={required.includes("price")}
          value={controls.price}
          error={Boolean(invalid?.price)}
          helperText={invalid?.price}
        ></NumberField>

        <InputField
          label={globalLang.comment[lang]}
          placeholder={globalLang.comment[lang]}
          onChange={(e) => setControl("comment", e.target.value)}
          required={required.includes("comment")}
          error={Boolean(invalid?.comment)}
          helperText={invalid?.comment}
        ></InputField>
      </Form>
      {ExpensesReceiptPostResponse.successAlert}
      {ExpensesReceiptPostResponse.failAlert}
    </>
  );
};

export default ExpensesReceipt;

import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import DistributionBill from "./DistributionBill";
import ViewDistributionBill from "./ViewDistibrutionBill";
const DistributionBillView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factoryclientdistribution']}>
        <DistributionBill />
      </PermissionsGate>

      <PermissionsGate permissions={['view_factoryclientdistribution']}>
        <ViewDistributionBill />
      </PermissionsGate>
    </Wrapper>
  );
};
export default DistributionBillView;

import React, { useState } from 'react'
import logo from '../assets/cardlogo.png'
import { Box, Button, Divider, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery } from '@mui/material'
import Image from 'mui-image'
import { LOGIN } from '../data/APIs'
import { useDispatch, useSelector } from 'react-redux'
import filter from '../utils/ClearNull'
import useControls from '../hooks/useControls'
import { useNavigate } from 'react-router-dom'
import useRequest from '../hooks/useRequest'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import  { InputField, PasswordField } from '../features/form'
export const Signin = () => {
    const lang = useSelector((state) => state.lang.value.lang);
    const globalLang = useSelector((state) => state.lang.value.global);
    const global = useSelector((state) => state.lang.value.global);
    const navigate =useNavigate()
  const sm = useMediaQuery(`(max-width: 1259px)`);
  const [visibilities, setVisibilities] = useState({
    password: false,
  });

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "username",
      value: "",
      isRequired: true,
    },
    {
      control: "password",
      value: "",
      isRequired: true,
    },
  ]);

  const [loginPostRequest, loginPostResponse] = useRequest({
    path: LOGIN,
    method: "post",
    successMessage: "تم تسجيل الدخول بنجاح",
  });

  const dispatch = useDispatch();

  const handleKeypress = e => {
    if (e.key === "Enter") {
      handleLogin()
    }
  }

  const handleLogin = (e) => {
    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          username: controls.username,
          password: controls.password,
        },
      });

      loginPostRequest({
        body: requestBody,
        noHeaders: true,
        onSuccess: (res) => {
          dispatch({ type: "userInfo/setToken", payload: res.data.token });
          resetControls();
        },
      });
    });
  };

  return (
    <Stack sx={{display:"flex",flexDirection:{xl:"row",lg:"row",md:"row",sm:"column",xs:"column"},width:"100%",flexWrap:"nowrap",}}>

        <Stack sx={{width:"20%",display:{xl:"block",lg:"block",md:"block",sm:"none",xs:"none"},position:"static",height:"100vh"}}>
      <Image  src={logo}/>
    </Stack>

        <Stack sx={{width:{xl:"80%",lg:"80%",md:"80%",sm:"100%",xs:"100%"},backgroundColor:"#F8F8F8"}}>
        <Box
            component="form"
            sx={{ px:"10%" }}
        
            noValidate
           
          >
            <Stack sx={{width:{xl:"70%",xs:"100%"},mx:"auto",gap:2}}>
              <Stack sx={{mt:{xl:"252px",sm:"150px",xs:"150px"},}} >
                <Typography sx={{fontFamily:"Oxanium",fontWeight:700,fontSize:"32px",color:"#090979",lineHeight:"40px"}}>
                {global.login[lang]}
                </Typography>
              </Stack>
              <Stack >
              <InputField
              label={global.username[lang]}
              placeholder={global.username[lang]}
              required={required.includes("username")}
              value={controls.username}
              onChange={(e) => setControl("username", e.target.value)}
              error={Boolean(invalid.username)}
              helperText={invalid.username}
              sx={{
                width: "100%",
                mb: "24px",
                ".MuiInput-root": { padding: "8px 14px 8px 8px" },
              }}
            />
            <PasswordField
            label={global.password[lang]}
            placeholder={global.password[lang]}
            required={required.includes("password")}
            value={controls.password}
            onChange={(e) => setControl("password", e.target.value)}
            error={Boolean(invalid.password)}
            helperText={invalid.password}
            onKeyPress={handleKeypress}
            sx={{
              width: "100%",
              mb: "24px",
              ".MuiInput-root": { padding: "8px 14px 8px 8px" },
            }}
          />
          
              
              </Stack>
              <Stack >
              <Button
            sx={{
              backgroundColor: "#090979",
              borderRadius: "8px",
              height: "44px",
              p:"10px",
              my:"32px",
              justifyContent: "center",
              color: "#fff",
              fontSize: {xl:"16px",xs:"12px"},
              fontWeight: 500,
              lineHeight: "24px",
              width: {xl:"25%",lg:"25%",md:"40%",sm:"50%",xs:"60%"},
              ":hover":{
                backgroundColor:"#090979"
              }
            }}
            onClick={handleLogin}
            ispending={loginPostResponse.ispending}
          >
            {global.login[lang]}
          </Button>
          <Divider color="#ccc"/>
              <Typography color='#ccc'> ليس لديك حساب؟ <Button onClick={()=>navigate('/registeration')} sx={{color:'#090979','&:hover':{
                color:'GrayText'
              }}}>{global.create_acc[lang]}</Button> </Typography>
              
              </Stack>
              
            
            </Stack>
          </Box>
        </Stack>
        {loginPostResponse.successAlert}
      {loginPostResponse.failAlert}
    </Stack>
  )
}

import { configureStore } from "@reduxjs/toolkit";
import userInfoSlice from "./store/userInfoSlice";
import EmployeesSlice from "./store/pages/EmployeesSlice";
import PostsSlice from "./store/pages/PostsSlice";
import ProductSlice from "./store/pages/ProductSlice";
import ChannelsSlice from "./store/pages/ChannelsSlice";
import NotificationSlice from "./store/pages/NotificationSlice";
import ClientsSlice from "./store/pages/ClientsSlice";
import StatusSlice from "./store/pages/StatusSlice";
import ClientHistorySlice from "./store/pages/ClientHistorySlice";
import StationBillSlice from "./store/pages/StationBillSlice";
import StationEnterPermissionSlice from "./store/pages/StationEnterPermissionSlice";
import DeletedClientsSlice from "./store/pages/DelectedClientsSlice";
import StationExitBillSlice from "./store/pages/StationExitBillSlice";
import ImportRegistrySlice from "./store/pages/ImportRegistrySlice";
import ExportRegistrySlice from "./store/pages/ExportRegistrySlice";
import JobsSlice from "./store/pages/JobsSlice";
import allPermissionsSlice from "./store/allPermissionsSlice";
import TransportSlice from "./store/pages/TransportSlice";
import UnitsSlice from "./store/pages/UnitsSlice";
import ExportClientsSlice from "./store/pages/ExportClientsSlice";
import LangSlice from "./store/LangSlice.js";
import StationSlice from "./store/pages/StationSlice";
import ClientsPricesSlice from "./store/pages/ClientsPricesSlice";
import ExpensesSlice from "./store/pages/ExpensesSlice";
import StationPriceSlice from "./store/pages/StationPriceSlice";
import ExpensesReceiptSlice from "./store/pages/ExpensesReceiptSlice";
import StockBalanceSlice from "./store/pages/StockBalanceSlice";
import ExpensesBillHistorySlice from "./store/pages/ExpensesBillHistorySlice";
import StocksSlice from "./store/pages/StocksSlice";
import TradersSlice from "./store/pages/TradersSlice";
import TradersHistorySlice from "./store/pages/TradersHistorySlice";
import TraderPriceSlice from "./store/pages/TraderPriceSlice";
import DeletedTradersSlice from "./store/pages/DeletedTradersSlice";

import BackSlice from "./store/pages/BackSlice";
import ForthSlice from "./store/pages/ForthSlice";
import BackAndForthHistorySlice from "./store/pages/BackAndForthHistorySlice";
// import ClientOverviewSlice from "./store/pages/ClientOverviewSlice";
import StationOverviewSlice from "./store/pages/StationOverviewSlice";
import TraderOverviewSlice from "./store/pages/TraderOverview";
import SummaryTraderSlice from "./store/pages/BillsOverView/SummaryTrader";
import SummaryTraderLiquidSlice from "./store/pages/BillsOverView/SummaryTraderLiquid";
import SummaryClientSlice from "./store/pages/BillsOverView/SummaryClient";
import TankSlice from "./store/pages/TankSlice";
import TankShipReceiptSlice from "./store/pages/TankShipReceiptSlice";
import ClientAwayBillSlice from "./store/pages/ClientAwayBillSlice";
import DistributionBillSlice from "./store/pages/DistributionBillSlice";
import ClientHomeBillSlice from "./store/pages/ClientHomeBillSlice";
import TraderEnterBillSlice from "./store/pages/TraderEnterBillSlice";
import TraderExitBillSlice  from "./store/pages/TraderExitBillSlice";
import SelfFillSlice from "./store/pages/SelfFillSlice";
import TraderBillSlice from "./store/pages/TraderBillSlice";
import SummaryStationSlice  from "./store/pages/BillsOverView/SummaryStation";
import  StockSummarySlice  from "./store/pages/StockSummary";
import StockHistorySlice from "./store/pages/StockHistorySlice";
import ClientOverviewSlice from "./store/pages/ClientOverviewSlice";
import StationHistorySlice from "./store/pages/StationHistorySlice";
import SummaryExpensesSlice  from "./store/pages/BillsOverView/Summaryexpenses";
import PracticalStockSlice from "./store/pages/PracticalStockSlice";
import FactorySafeSlice from "./store/pages/FactorySafeSlice";
import CounterReadingsSlice from "./store/pages/CounterReadingsSlice";
import PurchaseBillSlice from "./store/pages/PurchaseBillSlice";
import SalesBillSlice from "./store/pages/SalesBillSlice";
import LoansStockSlice from "./store/pages/LoansStockSlice";
import  OwnerPermessionsSlice  from "./store/pages/OwnerPermessionSlice";
import  FinanceAndDepositeSlice  from "./store/pages/FinanceAndDepositSlice";
import  CommissionSlice  from "./store/pages/CommissionSlice";
import CommissionToSlice from "./store/pages/CommissionToSlice";

export default configureStore({
  reducer: {
    lang: LangSlice,
    allPermissions: allPermissionsSlice,
    userInfo: userInfoSlice,
    posts: PostsSlice,
    products: ProductSlice,
    tank: TankSlice,
    tank_ship_receipt: TankShipReceiptSlice,
    employees: EmployeesSlice,
    channels: ChannelsSlice,
    clients: ClientsSlice,
    clientsPrices: ClientsPricesSlice,
    stationsPrices: StationPriceSlice,
    client_away_bill: ClientAwayBillSlice,
    client_home_bill: ClientHomeBillSlice,
    expenses: ExpensesSlice,
    traders: TradersSlice,
    traderHistory: TradersHistorySlice,
    expensesReceipt: ExpensesReceiptSlice,
    station_exit_bill: StationExitBillSlice,
    station_bill: StationBillSlice,
    station_history:StationHistorySlice,
    stocks: StocksSlice,
    transport: TransportSlice,
    self_fill: SelfFillSlice,
    stock_summary: StockSummarySlice,
    client_bill: DistributionBillSlice,
    trader_bill: TraderBillSlice,
    summary_expenses:SummaryExpensesSlice,
    back: BackSlice,
    forth: ForthSlice,
    back_forth_history: BackAndForthHistorySlice,
    tradersprice: TraderPriceSlice,
    deletedClients: DeletedClientsSlice,
    deletedTraders: DeletedTradersSlice,
    expensesBillHistory: ExpensesBillHistorySlice,
    status: StatusSlice,
    stations: StationSlice,
    clientHistory: ClientHistorySlice,
    stockHistory:StockHistorySlice,
    station_enter_bill: StationEnterPermissionSlice,
    trader_enter_bill: TraderEnterBillSlice,
    trader_exit_bill: TraderExitBillSlice,
    clientOverview: ClientOverviewSlice,
    stationOverview: StationOverviewSlice,
    traderOverview: TraderOverviewSlice,
    summaryclient: SummaryClientSlice,
    summarytrader: SummaryTraderSlice,
    summarystation: SummaryStationSlice,
    summarytraderliquid: SummaryTraderLiquidSlice,
    stockbalance:StockBalanceSlice,
    practicalStock:PracticalStockSlice,
    factorySafe:FactorySafeSlice,
    importRegistry: ImportRegistrySlice,
    exportRegistry: ExportRegistrySlice,
    jobs: JobsSlice,
    units: UnitsSlice,
    exportClients: ExportClientsSlice,
    notifications: NotificationSlice,
    counterReadings:CounterReadingsSlice,
    purchasebill:PurchaseBillSlice,
    salesbill:SalesBillSlice,
    loansStock:LoansStockSlice,
    ownerPermessions:OwnerPermessionsSlice,
    finance_deposite:FinanceAndDepositeSlice,
    commission:CommissionSlice,
    commissionTo:CommissionToSlice,
  },
});

import React, { useEffect, useState } from "react";

import { InputField, SelectField } from "../features/form";

import useRequest from "../hooks/useRequest";
import {

  PRODUCTS,

} from "../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Typography, Tooltip } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import RemoveIcon from "@mui/icons-material/Remove";

const ReceiptTable = ({
  type,
  columns = [],
  getformData,
  success,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //states
  const [GasRows, setGasRows] = useState([{}]);
  const [LiquidRows, setLiquidRows] = useState([{}]);
  const [TableColumns, setTableColumns] = useState([{}]);
  //----store----
  const productsStore = useSelector((state) => state.products.value);
  const volumes = [{ name: "40", value: 40 }, { name: "50", value: 50 }, { name: "20", value: 20 }, { name: "15", value: 15 }, { name: "10", value: 10 }]
  const dispatch = useDispatch();

  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);

  const handleAddGasRow = () => {
    setGasRows([...GasRows, {}]);
  };
  const handleAddLiquidRow = () => {
    setLiquidRows([...LiquidRows, {}]);
  };
  useEffect(() => {
    type === "cylinder" ? getformData(GasRows) : getformData(LiquidRows);
  }, [GasRows, LiquidRows]);
  useEffect(() => {
    if (success) {
      setGasRows([{}]);
      setLiquidRows([{}]);

    }
  }, [success]);
  const handleRemoveGasRow = (index) => {
    let gas_data = [...GasRows];
    gas_data.splice(index, 1);
    setGasRows(gas_data);
  };
  const handleRemoveLiquidRow = (index) => {
    let liquid_data = [...LiquidRows];
    liquid_data.splice(index, 1);
    setLiquidRows(liquid_data);
  };

  //----states----
  const handleChange = (event, index, type) => {
    let data = [...GasRows];

    data[index][event.target.name] =
      event.target.name === "product_type"
        ? event.target.value
        : parseFloat(event.target.value);
    data[index]["product_type"] = type;
    setGasRows(data);
  };
  const handleLiquidChange = (event, index, type) => {
    let liquid_data = [...LiquidRows];

    liquid_data[index][event.target.name] =
      event.target.name === "product_type"
        ? event.target.value
        : parseFloat(event.target.value);
    liquid_data[index]["product_type"] = type;

    setLiquidRows(liquid_data);
  };

  //----request hooks----
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  //----functions----
  const getProducts = () => {
    productsGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "products/set", payload: res.data });
      },
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          padding: {xl:"10px",xs:"5px"},
          height: "fit-content",
          margin: {xl:"10px",lg:"3px",md:"3px",sm:"3px",xs:"3px"},
          overflowX:"scroll",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "80vw",
          },
          ".MuiTableCell-body":{
            maxWidth:"100px"
          }
        }}
      >
        <Typography sx={{fontSize:"1.3rem"}}>
          {type === "cylinder" ? "الغازات" : "السوائل"}
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={index}>
                  {globalLang[column.value][lang]}
                </TableCell>
              ))}
              <TableCell>
                <Tooltip title="أضف منتج ">
                  <AddIcon
                    htmlColor="green"
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      type === "cylinder"
                        ? handleAddGasRow()
                        : handleAddLiquidRow()
                    }
                  />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {type === "cylinder"
              ? GasRows.map((row, index) => (
                <TableRow
                  key={`${row.name}${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    width:"100px"
                  }}
                >
                  {columns.map((column, columnIndex) =>
                    column.name === "product" ? (
                      <TableCell key={columnIndex}>
                        <SelectField
                          placeholder={globalLang.product[lang]}
                          sx={{width:"60px"}}
                          renderValue={(selected) => {
                            return productsStore?.results?.find(
                              (s) => s?.id === selected
                            )?.name;
                          }}
                          name={column.name}
                          value={row[column.name] || ""}
                          isPending={productsGetResponse.isPending}
                          onOpen={getProducts}
                          onChange={(e) => {
                            handleChange(e, index, type);
                          }}
                        >
                          {productsStore?.results?.map((product, Gasindex) => (
                            <MenuItem
                              value={product?.id}
                              key={`${product?.name} ${Gasindex}`}
                            >
                              {product?.name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </TableCell>
                    ) : column.name === "volume" ? (<TableCell>
                      <SelectField
                        placeholder={globalLang.volume[lang]}
                        renderValue={(selected) => {
                          return volumes?.find(
                            (v) => v.value === selected
                          ).name;
                        }}
                        name={column.name}
                        value={row[column.name] || ""}
                     
     
                        onChange={(e) => {
                          handleChange(e, index, type);
                        }}
                      >
                        {volumes?.map((v, index) => (
                          <MenuItem
                            value={v.value}
                            key={`${v.name} ${index}`}
                          >
                            {v.name} 
                          </MenuItem>
                        ))}
                      </SelectField>
                    </TableCell>) : (
                      <TableCell key={columnIndex}>
                        <InputField
                          name={column.name}
                          type="number"
                          placeholder={column.name =='pressure' ? '120':""}
                          sx={{width:"50px"}}
                          value={
                            row[column.name] === 0
                              ? "0"
                              : row[column.name] || ""
                          }
                          onChange={(e) => {
                            handleChange(e, index, type);
                          }}
                        ></InputField>
                      </TableCell>
                    )
                  )}
                  <TableCell>
                    <Tooltip title="حذف">
                      <RemoveIcon
                        htmlColor="red"
                        onClick={() => handleRemoveGasRow(index)}
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
              : LiquidRows.map((liquidrow, index) => (
                <TableRow
                  key={`${liquidrow.name}${index}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {TableColumns.map((column, columnIndex) =>
                    column.name === "product" ? (
                      <TableCell key={columnIndex}>
                      
                        <SelectField
                          placeholder={globalLang.product[lang]}
                          renderValue={(selected) => {
                            return productsStore?.results?.find(
                              (s) => s?.id === selected
                            )?.name;
                          }}
                          name={column.name}
                          value={liquidrow[column.name] || ""}
                          isPending={productsGetResponse.isPending}
                          onOpen={getProducts}
                          onChange={(e) => {
                            handleLiquidChange(e, index, type);
                          }}
                        >
                          {productsStore?.results?.map((product, index) => (
                            <MenuItem
                              value={product?.id}
                              key={`${product?.name} ${index}`}
                            >
                              {product?.name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </TableCell>
               
                    )
                
                    : (
                      <TableCell key={columnIndex}>
                        <InputField
                        sx={{width:"50px"}}
                          name={column.name}
                          type="number"
                          value={
                            liquidrow[column.name] === 0
                              ? "0"
                              : liquidrow[column.name] || ""
                          }
                          onChange={(e) => {
                            handleLiquidChange(e, index, type);
                          }}
                        ></InputField>
                      </TableCell>
                    )
                  )}
                  <TableCell>
                    <Tooltip title="حذف">
                      <RemoveIcon
                        htmlColor="red"
                        onClick={() => handleRemoveLiquidRow(index)}
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReceiptTable;

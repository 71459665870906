import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import DataGrid from "../../components/DataGrid";
import Breadcrumbs from "../../components/Breadcrumbs";
import format from "../../utils/ISOToReadable";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
  CHANNELS,
  CLIENTS,
  CLIENTS_COMMENTS,
  CLIENTS_HISTORY,
  CLIENTS_SELECTED_DELETE,
  CLIENTS_TRANSFER,
  CLIENTS_TRANSFER_PROJECT,
  EMPLOYEES,
  PRODUCTS,
  STATUS,
  TRADERS,
  TRADERS_HISTORY,
} from "../../data/APIs";
import {
  Button,
  Stack,
  Box,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Checkbox,
  IconButton,
  RadioGroup,
  Radio,
  FormGroup,
  Menu,
  TextField,
} from "@mui/material";
import useAfterEffect from "../../hooks/useAfterEffect";
import InputField from "../../features/form/components/InputField";
import useControls from "../../hooks/useControls";
import Form, {
  MultiSelectItem,
  NumberField,
  PhoneField,
  SelectField,
  TextareaField,
} from "../../features/form";
import { v4 as uuid } from "uuid";
import filter from "../../utils/ClearNull";
import AutocompleteField from "../../features/form/components/AutocompleteField";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogMultiSelectField,
  DialogNumberField,
  DialogPhoneField,
  DialogSelectField,
  DialogTable,
} from "../../features/dialog";

import moment from "moment";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import DateRangeIcon from "@mui/icons-material/DateRange";

import * as XLSX from "xlsx";
import DialogPeopleWindow, {
  DialogSelectItem,
} from "../../features/dialog/components/DialogPeopleWindow";
import usePropState from "../../hooks/usePropState";
import compare from "../../utils/Compare";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import ReactToPrint from "react-to-print";
import Print from "../../components/PrintComponent";

const ViewMerchants = React.forwardRef((props,ref) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const ViewTradersLang = useSelector((state) => state.lang.value.ViewTraders);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const tradersStore = useSelector((state) => state.traders.value);

const confirmMessage = useSelector(
  (state) => state.lang.value.DeleteMessages.TraderMessage
);
  const dispatch = useDispatch();

  //----states----
  
  const [traderDetails, settraderDetails] = useState({
    details: null,
    tab: "details",
  });
  const [selected, setSelected] = useState([]);
  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "name",
      value: "",
      isRequired: false,
    },

    {
      control: "code",
      value: "",
      isRequired: false,
    },
    {
      control: "driver_code",
      value: "",
      isRequired: false,
    },
    {
      control: "driver",
      value: "",
      isRequired: false,
    },
    {
      control: "driver_phone",
      value: "",
      isRequired: false,
    },
    {
      control: "phone",
      value: "",
      isRequired: false,
    },

    {
      control: "channel",
      value: "",
      isRequired: false,
    },

    // {
    //   control: "budgetType",
    //   value: "max_budget",
    //   isRequired: false,
    // },
    // {
    //   control: "dateStart",
    //   value: "",
    //   isRequired: false,
    // },
    // {
    //   control: "dateEnd",
    //   value: "",
    //   isRequired: false,
    // },
    {
      control: "history",
      value: "",
      isRequired: false,
    },
    {
      control: "newClients",
      value: false,
      isRequired: false,
    },
  ]);

  //----request hooks----
  const [tradersGetRequest, tradersGetResponse] = useRequest({
    path: TRADERS,
    method: "get",
  });

  const [clientCommentsGetRequest, clientCommentsGetResponse] = useRequest({
    path: CLIENTS_COMMENTS,
    method: "get",
  });

  const [traderHistoryGetRequest, traderHistoryGetResponse] = useRequest({
    path: TRADERS_HISTORY,
    method: "get",
  });

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const [channelsGetRequest, channelsGetResponse] = useRequest({
    path: CHANNELS,
    method: "get",
  });

  const [statusGetRequest, statusGetResponse] = useRequest({
    path: STATUS,
    method: "get",
  });

  const [clientCommentPostRequest] = useRequest({
    path: CLIENTS_COMMENTS,
    method: "post",
  });

  const [clientTransferPostRequest, clientTransferPostResponse] = useRequest({
    path: CLIENTS_TRANSFER,
    method: "post",
    successMessage: "تم التحويل",
  });

  const [clientTransferProjectPostRequest, clientTransferProjectPostResponse] =
    useRequest({
      path: CLIENTS_TRANSFER_PROJECT,
      method: "post",
      successMessage: "تم تغيير المشروع",
    });

  //----effects----
  useEffect(() => {
    getTraders();
  }, []);

  useAfterEffect(() => {
    if (!traderDetails.details) return;
    settraderDetails((old) => ({
      ...old,
      details: tradersStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [tradersStore]);

  useAfterEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    tradersGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  }, [requestParams]);

  //----functions----
  const getTraders = () => {
    tradersGetRequest({
      onSuccess: (res) => {
        dispatch({ type: "traders/set", payload: res.data });
      },
    });
  };
  // filters
  const filters = [
    {
      name: globalLang.name[lang],
      component: <NameFilter />,
    },
    {
      name: globalLang.channel[lang],
      component: <ChannelFilter />,
    },
    {
      name: globalLang.phone[lang],
      component: <PhoneFilter />,
    },
    {
      name: globalLang.createdAt[lang],
      component: <DateFilter />,
    },
  ];
  //===Start===== Delete Client Logic =========

  const [traderDeleteRequest, traderDeleteResponse] = useRequest({
    path: TRADERS,
    method: "delete",
    successMessage: "تم الحذف بنجاح",
  });

  const deleteTrader = (e, { id }) => {
    traderDeleteRequest({
      id: id,
      onSuccess: () => {
        dispatch({ type: "traders/deleteItem", payload: { id } });
      },
    });
  };

  const [handleDeleteTrader, deleteTraderConfirmDialog] = useConfirmMessage({
    onConfirm: deleteTrader,
    text: confirmMessage[lang],
  });

  //===End===== Delete Client Logic =========

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChecks = ({ checks }) => {
    setSelected(checks);
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
    }));
  };

  // const handleFormFilterSubmit = () => {
  //   const params = filter({
  //     obj: {
  //       name: controls.name,
  //       agent: controls.employee === 0 ? "0" : controls.employee,
  //       job: controls.job,
  //       user__phone: controls.code + controls.phone,
  //       bussiness: controls.product,
  //       event: controls.newClients ? undefined : controls.status,
  //       channel: controls.channel,
  //       state: controls.newClients ? 1 : undefined,
  //       [controls.budgetType]: controls.budget.replace(/,/gi, ""),
  //       created_at:
  //         controls.dateStart && controls.dateEnd
  //           ? `${controls.dateStart
  //               .split("-")
  //               .reverse()
  //               .join("/")}-${controls.dateEnd.split("-").reverse().join("/")}`
  //           : undefined,
  //       history_date: controls?.history,
  //     },
  //     output: "object",
  //   });
  //   setRequestParams((old) => ({
  //     filters: Object.entries(params),
  //   }));
  // };

  // const handleClearFilters = () => {
  //   setRequestParams((old) => ({ ...old, filters: [] }));
  //   resetControls();
  //   setIsCleared(uuid());
  // };

  // const getEmployees = () => {
  //   if (employeesStore.results.length) return;
  //   employeesGetRequest({
  //     params: {
  //       size: 1000,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "employees/set", payload: res.data });
  //     },
  //   });
  // };

  // const getProducts = () => {
  //   if (productsStore.results.length) return;
  //   productsGetRequest({
  //     params: {
  //       size: 1000,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "products/set", payload: res.data });
  //     },
  //   });
  // };

  // const getChannels = () => {
  //   if (channelsStore.results.length) return;
  //   channelsGetRequest({
  //     params: {
  //       size: 1000,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "channels/set", payload: res.data });
  //     },
  //   });
  // };

  // const getStatus = () => {
  //   if (statusStore.results.length) return;
  //   statusGetRequest({
  //     params: {
  //       size: 1000,
  //     },
  //     onSuccess: (res) => {
  //       dispatch({ type: "status/set", payload: res.data });
  //     },
  //   });
  // };

  // const handleExportExcelSheet = () => {
  //   const properFormat = selected.map((item) => {
  //     return {
  //       full_name: item.user.first_name
  //         ? `${item.user.first_name} ${item.user.last_name}`
  //         : "",
  //       phone: `${item.user.country_code}${item.user.phone}`,
  //       product: `${item.bussiness
  //         .map((product) => product?.name)
  //         .join(" ، ")}`,
  //       channel: `${item.channel}`,
  //       agent: `${item.agent.name}`,
  //       status: `${item.event}`,
  //       comment: `${item.comment}`,
  //       created_by: `${item.created_by}`,
  //       created_at: `${item.created_at}`,
  //     };
  //   });

  //   const wb = XLSX.utils.book_new();
  //   const ws = XLSX.utils.json_to_sheet(properFormat);

  //   XLSX.utils.book_append_sheet(wb, ws, "clientsSheet");

  //   XLSX.writeFile(wb, "CRM Clients Sheet.xlsx");
  // };

  const getTraderHistory = () => {
    traderHistoryGetRequest({
      params: {
        id: traderDetails.details.id,
      },
      onSuccess: (res) => {
        dispatch({ type: "traderHistory/set", payload: res.data });
      },
    });
  };

  const getComments = () => {
    clientCommentsGetRequest({
      params: {
        id: clientDetails.details.id,
      },
      onSuccess: (res) => {
        dispatch({ type: "clientComments/set", payload: res.data });
      },
    });
  };

  const handleCloseDetailsDialog = () => {
    settraderDetails((old) => ({ ...old, details: null, tab: "details" }));
  };

  // const handleSubmitComment = async (e) => {
  //   const requestBody = filter({
  //     obj: e,
  //     output: "object",
  //   });

  //   return clientCommentPostRequest({
  //     body: requestBody,
  //     onSuccess: (res) => {
  //       dispatch({ type: "clientComments/addItem", payload: res.data });
  //       dispatch({
  //         type: "clients/patchItem",
  //         payload: {
  //           id: res.data.client_id,
  //           item: { comment: res.data.comment, event: res.data.event },
  //         },
  //       });
  //     },
  //   });
  // };

  // const handleSubmitEmployeeTransfer = (e) => {
  //   clientTransferPostRequest({
  //     body: e,
  //     onSuccess: (res) => {
  //       setOpenTransferMultipleClientsToEmployee(false);
  //       setClientDetails((old) => ({ ...old, details: null, tab: "details" }));
  //       if (res.data.hasOwnProperty("clients")) {
  //         res.data.clients.map((client) => {
  //           dispatch({
  //             type: "clients/patchItem",
  //             payload: { id: client.id, item: { ...client } },
  //           });
  //         });
  //       } else {
  //         dispatch({
  //           type: "clients/patchItem",
  //           payload: { id: res.data.id, item: { ...res.data } },
  //         });
  //       }
  //     },
  //   });
  // };

  // const handleSubmitProjectTransfer = (e) => {
  //   clientTransferProjectPostRequest({
  //     body: e,
  //     onSuccess: (res) => {
  //       setOpenTransferProject(false);
  //       if (res.data.hasOwnProperty("clients")) {
  //         res.data.clients.map((client) => {
  //           dispatch({
  //             type: "clients/patchItem",
  //             payload: { id: client.id, item: { ...client } },
  //           });
  //         });
  //       } else {
  //         dispatch({
  //           type: "clients/patchItem",
  //           payload: { id: res.data.id, item: { ...res.data } },
  //         });
  //       }
  //     },
  //   });
  // };

  const [editData, setEditData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Delete Selected Clients Logic ===========

  // const [selectedTradersDeleteRequest, selectedTradersDeleteResponse] =
  //   useRequest({
  //     path: CLIENTS_SELECTED_DELETE,
  //     method: "delete",
  //     successMessage: "تم حذف العملاء بنجاح",
  //   });

  const deleteSelected = () => {
    selectedClientsDeleteRequest({
      body: {
        client: selected.map((client) => client.id),
      },
      onSuccess: () => {
        selected.map((client) => {
          dispatch({ type: "clients/deleteItem", payload: { id: client.id } });
        });
      },
    });
  };

  const [handleDeleteSelected, deleteSelectedConfirmDialog] = useConfirmMessage(
    {
      onConfirm: deleteSelected,
      text: "هل انت متأكد من انك تريد حذف هؤلاء العملاء؟",
    }
  );

  //===End===== Delete Selected Clients Logic ===========

  const columns = [
    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: ({ user }) => `${user.first_name} ${user.last_name}`,
    },
    {
      field: "email",
      headerName: globalLang.email[lang],
      customContent: ({ user }) => user?.email ? user?.email :"-",
    },
    {
      field: "username",
      headerName: globalLang.username[lang],
      customContent: ({ user }) => `${user.username}`,
    },
    {
      field: "phone",
      headerName: globalLang.phone[lang],
      customContent: ({ user }) => `${user.country_code}${user.phone}`,
    },

    {
      field: "total_remain",
      headerName: globalLang.wallet[lang],
      customContent: (params) =>
        params?.total_remain ? params?.total_remain : "0",
    },

    // {
    //   field: "channel",
    //   headerName: globalLang.channel[lang],
    //   customContent: (params) =>
    //     params?.channel ? params?.channel?.name : "-",
    // },
    // {
    //   field: "fav_contacts",
    //   headerName: globalLang.contactWay[lang],
    //   customContent: (params) => params?.fav_contacts,
    // },

    // {
    //   field: "created_at",
    //   headerName: globalLang.createdAt[lang],
    //   customContent: ({ created_at }) => format(created_at),
    // },

    // {
    //   field: "created_by",
    //   headerName: globalLang.createdBy[lang],
    // },
    // {
    //   field: "followup",
    //   headerName: globalLang.previewDate[lang],
    //   customContent: ({ followup }) => (followup ? format(followup) : null),
    //   customEmpty: globalLang.notFound[lang],
    // },
    // {
    //   field: "agent",
    //   headerName: globalLang.employee[lang],
    //   customContent: ({ agent }) => Boolean(agent.name) && `${agent.name}`,
    //   customEmpty: "أدمن",
    // },
  ];

  return (
    <>
      <Breadcrumbs
        path={[globalLang.traders[lang], ViewTradersLang.ViewTraders[lang]]}
      />

      <DataGrid
        columns={columns}
        rows={tradersStore.results}
        isPending={tradersGetResponse.isPending}
        total={tradersStore.count ? tradersStore.count : 1}
        onCheck={handleChecks}
        onEdit={isPermitted(
          (e, row) => setEditData(row),
          ["change_factorytrader"]
        )}
        onDelete={isPermitted(
          (e, row) => handleDeleteTrader(e, row),
          ["delete_factorytrader"]
        )}
        onView={isPermitted(
          (e, row) => {
            settraderDetails((old) => ({
              ...old,
              details: row,
            }));
            50;
          },
          ["view_historicalfactorytrader"]
        )}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        filters={filters}
      />

      {/* <TransferToEmployeeDialog
        open={Boolean(
          clientDetails.details && clientDetails.tab === "transfer"
        )}
        onOpen={getEmployees}
        onClose={handleCloseDetailsDialog}
        onGoBack={() => setClientDetails((old) => ({ ...old, tab: "details" }))}
        initSelected={clientDetails?.details?.agent?.id}
        id={clientDetails.details?.id}
        data={[
          {
            title: "الأدمن",
            body: "الأدمن",
            picture: "asdfsd",
            id: null,
          },
          ...employeesStore.results.map((employee) => ({
            title: `${employee.user.first_name} ${employee.user.last_name}`,
            body: employee.job.title,
            id: employee.id,
            picture: employee.user.image,
          })),
        ]}
        isPending={employeesGetResponse.isPending}
        submitPending={clientTransferPostResponse.isPending}
        onSubmit={handleSubmitEmployeeTransfer}
      /> */}

      {/* <CommentDialog
        open={Boolean(clientDetails.details && clientDetails.tab === "comment")}
        onOpen={getComments}
        onClose={handleCloseDetailsDialog}
        onGoBack={() => setClientDetails((old) => ({ ...old, tab: "details" }))}
        isPending={clientCommentsGetResponse.isPending}
        data={clientCommentsStore.results.map((item) => ({
          body: item.comment,
          date: format(item.created_at),
          title: item.commenter.fullname,
          picture: item.commenter.image,
          status: item.event,
        }))}
        id={clientDetails.details?.id}
        onStatusOpen={getStatus}
        isStatusPending={statusGetResponse.isPending}
        status={statusStore.results.map((item) => ({
          name: item.name,
          value: item.id,
        }))}
        onSubmit={handleSubmitComment}
      /> */}

      <InfoDialog
        open={Boolean(traderDetails.details && traderDetails.tab === "details")}
        onOpen={getTraderHistory}
        onClose={handleCloseDetailsDialog}
        data={Boolean(traderDetails.details) && traderDetails.details}
        isHistoryPending={traderHistoryGetResponse.isPending}
        // onTransferAgentClick={() =>
        //   setClientDetails((old) => ({ ...old, tab: "transfer" }))
        // }
        // onCommentsClick={() =>
        //   setClientDetails((old) => ({ ...old, tab: "comment" }))
        // }
      />

      {/* <TransferToEmployeeDialog
        open={openTransferMultipleClientsToEmployee}
        onClose={() => setOpenTransferMultipleClientsToEmployee(false)}
        onOpen={getEmployees}
        id={selected.map((item) => item.id)}
        data={[
          {
            title: "الأدمن",
            body: "الأدمن",
            picture: "asdfsd",
            id: null,
          },
          ...employeesStore.results.map((employee) => ({
            title: `${employee.user.first_name} ${employee.user.last_name}`,
            body: employee.job.title,
            id: employee.id,
            picture: employee.user.image,
          })),
        ]}
        isPending={employeesGetResponse.isPending}
        submitPending={clientTransferPostResponse.isPending}
        onSubmit={handleSubmitEmployeeTransfer}
      /> */}

      {/* <TransferToProjectDialog
        open={openTransferProject}
        onClose={() => setOpenTransferProject(false)}
        onOpen={getProducts}
        isPending={productsGetResponse.isPending}
        id={selected.map((item) => item.id)}
        data={productsStore.results.map((item) => ({
          name: item.name,
          picture: item.logo,
          id: item.id,
        }))}
        submitPending={clientTransferProjectPostResponse.isPending}
        onSubmit={handleSubmitProjectTransfer}
      /> */}

      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />

      {/* buttons */}
      {/* <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <PermissionsGate permissions={[]}>
          <Button
            variant="contained"
            color="error"
            disabled={
              !Boolean(selected.length) ||
              selectedClientsDeleteResponse.isPending
            }
            sx={{ width: "200px", height: "50px" }}
            onClick={handleDeleteSelected}
          >
            {globalLang.deleteSelected[lang]}
          </Button>
        </PermissionsGate>
        <Button
          variant="contained"
          disabled={!Boolean(selected.length)}
          sx={{ width: "200px", height: "50px" }}
          onClick={() => handleExportExcelSheet()}
        >
          {globalLang.exportSelected[lang]}
        </Button>
        <PermissionsGate permissions={["aqartransfer_clients"]}>
          <Button
            variant="contained"
            disabled={!Boolean(selected.length)}
            sx={{ width: "200px", height: "50px" }}
            onClick={() => setOpenTransferMultipleClientsToEmployee(true)}
          >
            {globalLang.transferSelected[lang]}
          </Button>
        </PermissionsGate>
        <Button
          variant="contained"
          disabled={!Boolean(selected.length)}
          sx={{ width: "200px", height: "50px" }}
          onClick={() => setOpenTransferProject(true)}
        >
          {globalLang.changeSelected[lang]}
        </Button>
      </Stack> */}

      {/* {selectedClientsDeleteResponse.successAlert}
      {selectedClientsDeleteResponse.failAlert} */}
      {deleteTraderConfirmDialog}
      {traderDeleteResponse.successAlert}
      {traderDeleteResponse.failAlert}

      {/* {deleteSelectedConfirmDialog} */}
    </>
  );
});

export default ViewMerchants;

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
  onTransferAgentClick = () => {},
  onCommentsClick = () => {},
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewTradersLang = useSelector((state) => state.lang.value.ViewTraders);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const historyStore = useSelector((state) => state.traderHistory.value);

  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  const [previewDatePatchRequest, previewDatePatchResponse] = useRequest({
    path: CLIENTS,
    method: "patch",
  });

  const handlePreviewSubmit = () => {
    previewDatePatchRequest({
      id: data.id,
      body: {
        user: {},
        followup: moment(selectedPreviewDate).format(),
      },
      onSuccess: (res) => {
        setPreviewDatePicker(null);
        dispatch({
          type: "clients/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        data.followup = res.data.followup;
      },
    }).then(() => {
      setPreviewDatePicker(null);
    });
  };

  //----variables----
  const info = [
    {
      name: globalLang.name[lang],
      value: `${data?.user?.first_name} ${data?.user?.last_name}`,
    },
    {
      name: globalLang.phone[lang],
      value: `(${data?.user?.country_code})${data?.user?.phone}`,
      addition: (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={() =>
              window.open(
                "https://wa.me/" +
                  `${data?.user?.country_code}${data?.user?.phone}`,
                "_blank"
              )
            }
          >
            <WhatsAppIcon fontSize="small" sx={{ color: "#5ef979" }} />
          </IconButton>
          <IconButton
            sx={{ color: "white" }}
            onClick={() =>
              window.open(
                "https://www.truecaller.com/search/eg/" +
                  `${data?.user?.country_code}${data?.user?.phone}`,
                "_blank"
              )
            }
          >
            <CallIcon fontSize="small" sx={{ color: "#127fff" }} />
          </IconButton>
        </Box>
      ),
    },
    // {
    //   name: globalLang.product[lang],
    //   value: data?.business?.map((product) => product?.name)?.join(" ، "),
    // },
    {
      name: globalLang.adChannel[lang],
      value: data?.channel? data?.channel?.name :"-",
    },
    // {
    //   name: globalLang.employee[lang],
    //   value: data?.agent?.name,
    //   addition: (
    //     <PermissionsGate permissions={["aqartransfer_clients"]}>
    //       <IconButton sx={{ color: "white" }} onClick={onTransferAgentClick}>
    //         <ChangeCircleIcon
    //           sx={{ color: "white", transform: "scale(1.2)" }}
    //         />
    //       </IconButton>
    //     </PermissionsGate>
    //   ),
    // },
    {
      name: globalLang.createdAt[lang],
      value: data?.created_at ? format(data?.created_at) : "",
      customEmpty: globalLang.notFound[lang],
    },
    // {
    //   name: globalLang.previewDate[lang],
    //   value: data?.followup ? format(data?.followup) : "",
    //   customEmpty: globalLang.notFound[lang],
    //   addition: (
    //     <PermissionsGate permissions={[]}>
    //       <IconButton
    //         sx={{ color: "white" }}
    //         onClick={(e) => setPreviewDatePicker(e.currentTarget)}
    //       >
    //         <DateRangeIcon sx={{ color: "white", transform: "scale(1.2)" }} />
    //       </IconButton>
    //       <Menu
    //         open={openPreviewPicker}
    //         anchorEl={previewDatePicker}
    //         onClose={() => setPreviewDatePicker(null)}
    //         PaperProps={{
    //           sx: {
    //             "& .MuiList-root": {
    //               p: 2,
    //             },
    //           },
    //         }}
    //       >
    //         <LocalizationProvider dateAdapter={AdapterMoment}>
    //           <DateTimePicker
    //             label="DateTimePicker"
    //             value={selectedPreviewDate}
    //             onChange={(newValue) => {
    //               setSelectedPreviewDate(newValue);
    //             }}

    //             renderInput={(params) => (
    //               <InputField
    //                 sx={{
    //                   width: "100%",
    //                   "& .MuiInputBase-root": {
    //                     border: "none",
    //                   },
    //                 }}
    //                 {...params}
    //               />
    //             )}
    //           />
    //         </LocalizationProvider>
    //         <Button
    //           variant="contained"
    //           sx={{ width: "100%", marginTop: 3 }}
    //           disabled={previewDatePatchResponse.isPending}
    //           onClick={(e) => {
    //             handlePreviewSubmit({
    //               ...e,
    //               selectedPreviewDate,
    //               clientId: data.id,
    //             });
    //           }}
    //         >
    //           {globalLang.save[lang]}
    //         </Button>
    //       </Menu>
    //     </PermissionsGate>
    //   ),
    // },
    // {
    //   name: globalLang.comment[lang],
    //   value: data?.comment,
    //   addition: (
    //     <PermissionsGate permissions={[]}>
    //       <IconButton sx={{ color: "white" }} onClick={onCommentsClick}>
    //         <ModeCommentIcon sx={{ color: "white" }} />
    //       </IconButton>
    //     </PermissionsGate>
    //   ),
    // },
    // {
    //   name: globalLang.budget[lang],
    //   value: data?.max_budget,
    // },
  ];

  const columns = [
    // {
    //   field: "products",
    //   headerName: globalLang.products[lang],
    //   customContent: ({ rowData }) => `${rowData?.bussiness?.join(" ، ")}`,
    // },
    {
      field: "user",
      headerName: globalLang.trader[lang],
      customContent: ({ rowData }) =>
        rowData.user ? `${rowData.user.name}` : "-",
    },
    {
      field: "user",
      headerName: globalLang.phone[lang],
      customContent: ({ rowData }) =>
        rowData.user ? `${rowData.user.phone}` : "-",
    },
    {
      field: "channel",
      headerName: globalLang.channel[lang],
      customContent: ({ rowData }) =>
        rowData.channel ? `${rowData.channel?.name}` : "-",
    },
    {
      field: "history_user",
      headerName: globalLang.editor[lang],
      customContent: ({ rowData }) =>
        rowData?.history_user
          ? `${rowData.history_user.first_name} ${rowData.history_user.last_name}`
          : "-",
    },
    {
      field: "history_type",
      headerName: globalLang.historytype[lang],
      customContent: ({ rowData }) =>
        rowData.history_type
          ? rowData.history_type === "~"
            ? "تعديل"
            : "انشاء"
          : "-",
    },
    {
      field: "history_date",
      headerName: globalLang.dateOfProcedure[lang],
      customContent: ({ rowData }) =>
        rowData.history_date ? format(rowData.history_date) : "-",
    },

    // {
    //   field: "agent",
    //   headerName: globalLang.employee[lang],
    // },

    // {
    //   field: "comment",
    //   headerName: globalLang.comment[lang],
    // },
    // {
    //   field: "event",
    //   headerName: globalLang.status[lang],
    // },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
    onOpen();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeading>{globalLang.traderDetails[lang]}</DialogHeading>
      <DialogInfoWindow information={info} />
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.traderHistory[lang]}</DialogHeading>
        <DialogTable
          rows={historyStore.results}
          columns={columns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};
// const StateFilter = ({ value, onChange }) => {
//   // Change lang
//   const lang = useSelector((state) => state.lang.value.lang);
//   const globalLang = useSelector((state) => state.lang.value.global);

//   ///////////////////////////////

//   const [jobs, setJobs] = useState([]);

//   const handleChange = (e) => {
//     onChange({
//       query: ["job", e.target.value],
//       renderedValue: jobs.find((job) => job.id === e.target.value).title,
//       value: e.target.value,
//     });
//   };

//   const [jobGetRequest, jobGetResponse] = useRequest({
//     path: JOBS,
//     method: "get",
//   });

//   const getJobs = () => {
//     jobGetRequest({
//       params: {
//         size: 1000,
//       },
//       onSuccess: (res) => {
//         setJobs(res.data.results);
//       },
//     });
//   };

//   return (
//     <InputField
//       placeholder={globalLang.job[lang]}
//       renderValue={(selected) => jobs.find((job) => job.id === selected).title}
//       onOpen={getJobs}
//       isPending={jobGetResponse.isPending}
//       onChange={handleChange}
//     >
//       {jobs.map((job, index) => (
//         <MenuItem value={job.id} key={`employeeFilterJob ${index}`}>
//           {job.title}
//         </MenuItem>
//       ))}
//     </InputField>
//   );
// };

export const NameFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["name", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.name[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};
export const ChannelFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [channels, setchannels] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["channel", e.target.value],
      renderedValue: channels.find((ch) => ch.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [channelsGetRequest, channelsGetResponse] = useRequest({
    path: CHANNELS,
    method: "get",
  });

  const getChannels = () => {
    channelsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setchannels(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.channel[lang]}
      renderValue={(selected) => channels.find((ch) => ch.id === selected).name}
      onOpen={getChannels}
      isPending={channelsGetResponse.isPending}
      onChange={handleChange}
    >
      {channels.map((channel, index) => (
        <MenuItem value={channel.id} key={` ${index}`}>
          {channel.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
export const DateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewJobsLang = useSelector((state) => state.lang.value.viewJobs);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "created_at",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `من ${formatDate(String(startPoint))} - إلى ${formatDate(
        String(endPoint)
      )}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};
export const PhoneFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const handleChange = (e) => {
    onChange({
      query: ["phone", e.target.value],
      renderedValue: e.target.value,
      value: e.target.value,
    });
  };

  return (
    <InputField
      placeholder={globalLang.phone[lang]}
      value={value}
      onChange={handleChange}
    />
  );
};
const TransferToEmployeeDialog = ({
  open,
  onOpen = () => {},
  onClose = () => {},
  onGoBack = () => {},
  isPending = false,
  submitPending = false,
  id,
  data = [],
  initSelected,
  onSubmit = () => {},
}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [selected, setSelected] = usePropState(initSelected, [initSelected]);
  const [searchValue, setSearchValue] = useState("");
  const [method, setMethod] = useState(1);

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({
      agent: selected,
      client: id,
      option: Boolean(parseInt(method)),
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      paperProps={{
        maxWidth: 450,
      }}
    >
      <DialogHeading onGoBack={onGoBack}>
        {globalLang.TransferEmployee[lang]}
      </DialogHeading>
      <DialogPeopleWindow
        isPending={isPending}
        searchValue={searchValue}
        onSearch={handleChangeSearchValue}
        sx={{ height: 400 }}
      >
        {data
          ?.filter(
            (item) =>
              item.title.toLowerCase().includes(searchValue) ||
              item.body.toLowerCase().includes(searchValue)
          )
          ?.map((item, index) => (
            <DialogSelectItem
              key={`selectEmployeeItem ${index}`}
              selected={item.id === selected}
              picture={item.picture}
              title={item.title}
              body={item.body}
              onClick={() => setSelected(item.id)}
            />
          ))}
      </DialogPeopleWindow>
      <PermissionsGate permissions={["delete_historicalaqarclient"]}>
        <DialogContent>
          <FormGroup>
            <RadioGroup
              name="transfer-method"
              value={method}
              onChange={(e) => setMethod(e.target.value)}
            >
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      color: "white",
                      "& *": {
                        color: "white",
                      },
                    }}
                  />
                }
                label={globalLang.sameLevel[lang]}
                value={1}
              />
              <FormControlLabel
                control={
                  <Radio
                    sx={{
                      color: "white",
                      "& *": {
                        color: "white",
                      },
                    }}
                  />
                }
                label={globalLang.deleteRecords[lang]}
                value={0}
              />
            </RadioGroup>
          </FormGroup>
        </DialogContent>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton onClick={handleSubmit} disabled={submitPending}>
          {globalLang.submit[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

TransferToEmployeeDialog.propTypes = {
  open: PropTypes.bool,
  isPending: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      picture: PropTypes.string,
      title: PropTypes.string,
      body: PropTypes.string,
    })
  ),
};

const CommentDialog = ({
  open,
  onOpen = () => {},
  onClose = () => {},
  onGoBack = () => {},
  isPending = false,
  isStatusPending = false,
  onStatusOpen = () => {},
  data = [],
  id = null,
  status = [],
  onSubmit = () => {},
}) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [search, setSearch] = useState("");
  const [
    { controls, required, invalid },
    { setControl, validate, resetControls },
  ] = useControls([
    {
      control: "comment",
      value: "",
      isRequired: true,
    },
    {
      control: "status",
      value: "",
      isRequired: true,
    },
  ]);

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      onSubmit({
        event: controls.status,
        comment: controls.comment,
        client: id,
      }).then((e) => {
        resetControls();
      });
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      paperProps={{
        maxWidth: 600,
      }}
    >
      <DialogHeading onGoBack={onGoBack}>
        {globalLang.comment[lang]}
      </DialogHeading>
      <DialogPeopleWindow
        searchValue={search}
        onSearch={(e) => setSearch(e.target.value)}
        isPending={isPending}
        sx={{ height: 400 }}
      >
        {data
          .filter(
            (item) =>
              item.title.includes(search) ||
              item.body.includes(search) ||
              item.status.includes(search)
          )
          .map((item, index) => (
            <DialogSelectItem
              key={`comment ${index}`}
              title={item.title}
              body={`${item.body} [${item.status}]`}
              subtitle={item.date}
              picture={item.picture}
            />
          ))}
      </DialogPeopleWindow>
      <PermissionsGate permissions={["add_aqarclientcomment"]}>
        <DialogContent>
          <Stack spacing={2}>
            <TextareaField
              placeholder={globalLang.comment[lang]}
              sx={{
                "& .MuiInput-root": {
                  bgcolor: "white",
                },
              }}
              value={controls.comment}
              required={required.includes("comment")}
              error={Boolean(invalid?.comment)}
              helperText={invalid?.comment}
              onChange={(e) => setControl("comment", e.target.value)}
            />
            <SelectField
              placeholder={globalLang.status[lang]}
              isPending={isStatusPending}
              onOpen={onStatusOpen}
              sx={{
                "& .MuiInput-root": {
                  bgcolor: "white",
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 100,
                      overflowY: "auto",
                    },
                  },
                },
              }}
              renderValue={(selected) =>
                status.find((item) => item.value === selected).name
              }
              value={controls.status}
              required={required.includes("status")}
              error={Boolean(invalid?.status)}
              helperText={invalid?.status}
              onChange={(e) => setControl("status", e.target.value)}
            >
              {status.map((item, index) => (
                <MenuItem key={`message status ${index}`} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </SelectField>
          </Stack>
        </DialogContent>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton onClick={handleSubmit}>
          {globalLang.submit[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

const TransferToProjectDialog = ({
  open,
  onOpen = () => {},
  onClose = () => {},
  onGoBack = () => {},
  isPending = false,
  submitPending = false,
  id,
  data = [],
  onSubmit = () => {},
}) => {
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const handleChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({
      bussiness: selected,
      client: id,
    });
  };

  const handleToggleSelect = (selectedItem) => {
    switch (selected.includes(selectedItem)) {
      case true:
        setSelected((old) => old.filter((item) => item !== selectedItem));
        break;
      case false:
        setSelected((old) => [...old, selectedItem]);
        break;
      default:
        setSelected((old) => old);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      paperProps={{
        maxWidth: 450,
      }}
    >
      <DialogHeading onGoBack={onGoBack}>تغيير مشاريع المحدد</DialogHeading>
      <DialogPeopleWindow
        isPending={isPending}
        searchValue={searchValue}
        onSearch={handleChangeSearchValue}
        sx={{ height: 400 }}
      >
        {data
          ?.filter((item) => item.name.toLowerCase().includes(searchValue))
          ?.map((item, index) => (
            <DialogSelectItem
              key={`selectProjectItem ${index}`}
              selected={selected.includes(item.id)}
              picture={item.picture}
              body={item.name}
              onClick={() => handleToggleSelect(item.id)}
            />
          ))}
      </DialogPeopleWindow>
      <DialogButtonsGroup>
        <DialogButton onClick={handleSubmit} disabled={submitPending}>
          تنفيذ
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          إلغاء
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};

TransferToProjectDialog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      picture: PropTypes.string,
    })
  ),
};

const EditDialog = ({ open, onClose, data }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [clientPermissions, setclientPermissions] = useState([]);

  ///////////////////////////////

  const contactMeans = [
    {
      title: globalLang.phone[lang],
      value: "phone",
    },
    {
      title: globalLang.email[lang],
      value: "email",
    },
    {
      title: globalLang.whatsApp[lang],
      value: "whats app",
    },
  ];
  const [{ controls, invalid }, { setControl, validate }] = useControls(
    [
      {
        control: "name",
        value: `${data?.user?.first_name} ${data?.user?.last_name}`,
        isRequired: false,
      },
      {
        control: "username",
        value: data?.user?.username,
        isRequired: false,
      },
      {
        control: "email",
        value: data?.user?.email,
        validations: [
          {
            test: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "البريد غير صالح",
          },
        ],
      },
      {
        control: "phone",
        value: data?.user?.phone,
        isRequired: true,
      },
      {
        control: "countryCode",
        value: data?.user?.country_code,
        isRequired: true,
      },

      {
        control: "contact",
        value: data?.fav_contacts,
      },
      {
        control: "channel",
        value: data?.channel?.id,
        isRequired: false,
      },
    ],
    [data]
  );

  const [productsState, setProductsState] = useState([]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
  const getProducts = () => {
    if (productsState.length) return;
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProductsState(res.data.results);
      },
    });
  };

  const [channelsState, setChannelsState] = useState([]);
  const [channelsGetRequest, channelsGetResponse] = useRequest({
    path: CHANNELS,
    method: "get",
  });
  const getChannels = () => {
    if (channelsState.length) return;
    channelsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setChannelsState(res.data.results);
      },
    });
  };

  const [traderPatchRequest, traderPatchResponse] = useRequest({
    path: TRADERS,
    method: "patch",
    successMessage: "تم تعديل التاجر بنجاح",
  });

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const isThereChange = compare(
      [
        [controls.name, `${data?.user?.first_name} ${data?.user?.last_name}`],
        [controls.email, data?.user?.email],
        [controls.username, data?.user?.username],
        [controls.phone, data?.user?.phone],
        [controls.channel, data?.channel?.id],
        [controls.contact, data?.fav_contacts],
      ],
      false
    );

    console.log(!isThereChange);

    if (!isThereChange) return;

    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          user: {
            first_name: controls.name.split(" ").slice(0, -1).join(" "),
            last_name: controls.name.split(" ").slice(-1).join(" "),
            email: controls.email,
            username:
              !compare([[controls.username, data?.user?.username]]) &&
              controls.username,

            phone: controls.countryCode + controls.phone,
            user_permissions: [],
          },
          channel: controls.channel,
          fav_contacts: controls.contact,
        },
      });

      traderPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          onClose();
          dispatch({
            type: "traders/putItem",
            payload: { id: res.data.id, item: res.data },
          });
        },
      });
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeading onGoBack={onClose}>
        {globalLang.edittraderData[lang]}
      </DialogHeading>
      <DialogForm>
        <DialogInputField
          placeholder={globalLang.name[lang]}
          label={globalLang.name[lang]}
          value={controls.name}
          onChange={(e) => setControl("name", e.target.value)}
          error={Boolean(invalid.name)}
          helperText={invalid.name}
        />
        <DialogInputField
          placeholder={globalLang.username[lang]}
          label={globalLang.username[lang]}
          value={controls.username}
          onChange={(e) => setControl("username", e.target.value)}
          error={Boolean(invalid.username)}
          helperText={invalid.username}
        />
        <DialogInputField
          placeholder={globalLang.email[lang]}
          label={globalLang.email[lang]}
          value={controls.email}
          onChange={(e) => setControl("email", e.target.value)}
          error={Boolean(invalid.email)}
          helperText={invalid.email}
        />
        <DialogPhoneField
          placeholder={globalLang.phone[lang]}
          label={globalLang.phone[lang]}
          value={controls.phone}
          requiredCode
          onChange={(e) => setControl("phone", e.target.value)}
          selectProps={{
            value: controls.countryCode,
            onChange: (e) => setControl("countryCode", e.target.value),
          }}
          error={Boolean(invalid.countryCode)}
          helperText={invalid.countryCode}
        />

        <DialogSelectField
          placeholder={globalLang.contactWay[lang]}
          label={globalLang.contactWay[lang]}
          value={controls.contact}
          onChange={(e) => setControl("contact", e.target.value)}
          renderValue={(selected) => {
            return contactMeans.find((mean) => mean.value === selected).title;
          }}
          error={Boolean(invalid.contact)}
          helperText={invalid.contact}
        >
          {contactMeans.map((mean, index) => (
            <MenuItem value={mean.value} key={`${mean.value} ${index}`}>
              {mean.title}
            </MenuItem>
          ))}
        </DialogSelectField>
        <DialogSelectField
          placeholder={globalLang.adChannel[lang]}
          label={globalLang.adChannel[lang]}
          onOpen={getChannels}
          isPending={channelsGetResponse.isPending}
          value={controls.channel}
          onChange={(e) => setControl("channel", e.target.value)}
          renderValue={(selected) => {
            return (
              channelsState.find((channel) => channel.id === selected)?.name ??
              data?.channel?.name
            );
          }}
          error={Boolean(invalid.channel)}
          helperText={invalid.channel}
        >
          {channelsState.map((channel, index) => (
            <MenuItem value={channel.id} key={`edit client channel ${index}`}>
              {channel.name}
            </MenuItem>
          ))}
        </DialogSelectField>
        {/* <DialogNumberField
          placeholder={globalLang.budget[lang]}
          label={globalLang.budget[lang]}
          value={controls.budget}
          onChange={(e) => setControl("budget", e.target.value)}
          error={Boolean(invalid.budget)}
          helperText={invalid.budget}
        /> */}
      </DialogForm>
      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={traderPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {traderPatchResponse.failAlert}
    </Dialog>
  );
};

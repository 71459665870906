import { Box } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import { DELETE_FACTORY_POWER, FACTORY_POWER } from "../../data/APIs";
import useAfterEffect from "../../hooks/useAfterEffect";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";

function PoweFactory() {
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  };
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const stockBalanceStore = useSelector((state) => state.stockbalance.value);

  
  const [powerDetails, setPowerDetails] = useState({
    details: null,
    tab: "details",
  });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.FactoryPowerMessage
  );
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams();
  const isPermitted = useIsPermitted();
  const columns = [
    {
          field: "date",
          headerName: globalLang.date[lang],
          customContent: ({ date }) =>
            date === 0 ? "0" : date ? date : "-",
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ product }) => (product ? product.name : "-"),
    },
    {
      field: "procedure",
      headerName: globalLang.procedure[lang],
      customContent: ({ procedure }) =>
       (procedure ? procedure ==="buy"&&"شراء"|| procedure ==="sell"&& "بيع"||procedure ==="transform"&&"تحويل":"-"),
    },
    {
      field: "quantity",
      headerName: globalLang.quantity_balance[lang],
      customContent: ({ quantity }) =>
      quantity ?  quantity : "-",
    },
    {
      field: "price",
      headerName: globalLang.price[lang],
      customContent: ({ price }) =>
      price === 0 ? "0" : price ? price : "-",
    },
    {
      field: "total",
      headerName: globalLang.total[lang],
      customContent: ({ total }) =>
      total ? total : "-",
    },
    {
      field: "statement",
      headerName: globalLang.statement[lang],
      customContent: ({ statement }) =>
      statement  ? statement : "-",
    },
  ];
  const [PowerFactoryGetRequest, PowerFactoryGetResponse] = useRequest({
    path: FACTORY_POWER,
    method: "get",
  });

  const [{ controls }, { setControl }] = useControls([
    {
      control: "date",
      value: getDate(),
      isRequired: false,
    },
  ]);

  const getFactoryPower = () => {
    // filterdata.stock && urlParams.append("id", filterdata.stock);
    // if (controls.date) {
    //   urlParams.append("date", controls.date);
    // }
    PowerFactoryGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stock-balance/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getFactoryPower();
  }, [requestParams, controls.date]);

  useAfterEffect(() => {
    if (!powerDetails.details) return;
    setPowerDetails((old) => ({
      ...old,
      details: stockBalanceStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [stockBalanceStore]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  //filter
  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const [FACTORYpOWERDeleteRequest, FACTORYPOWERDeleteResponse] = useRequest({
    path: DELETE_FACTORY_POWER,
    method: "delete",
    successMessage: "تم حذف الرصيد بنجاح",
  });

  const deleteStockBalance = (e, row) => {
    FACTORYpOWERDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "stock-balance/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteFactoryPower, deleteTankConfirmDialog] = useConfirmMessage({
    onConfirm: deleteStockBalance,
    text: confirmMessage[lang],
  });
  // const getStockDetails = () => {
  //   PowerFactoryGetRequest({
  //     id: powerDetails.details.id,

  //     onSuccess: (res) => {
  //       dispatch({ type: "stock_summary/set", payload: res.data });
  //       console.log(res.data);
  //     },
  //   });
  // };
  // const handleCloseDetailsDialog = () => {
  //   setPowerDetails((old) => ({ ...old, details: null, tab: "details" }));
  // };

  return (
    <>
      <Box
      sx={{my:3}}>
      <Breadcrumbs
        path={[globalLang.add_balance[lang], globalLang.add[lang]]}
       />
      
      <DataGrid
        columns={columns}
        rows={stockBalanceStore.results}
        total={stockBalanceStore.count}
        isPending={PowerFactoryGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        sum={stockBalanceStore.total}
        onDelete={isPermitted(handleDeleteFactoryPower,['delete_factorypower']) }
      />
      {deleteTankConfirmDialog}
      {FACTORYPOWERDeleteResponse.successAlert}
      {FACTORYPOWERDeleteResponse.failAlert}
      </Box>
      
    </>
  );
}
export default PoweFactory;


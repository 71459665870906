import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import EnterFilling from "./EnterFilling";
import ViewEnterFilling from "./ViewEnterFilling";
const EnterFillingView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factorytraderpermit']}>
        <EnterFilling />
      </PermissionsGate>

      <PermissionsGate PermissionsGate={['view_factorytraderpermit']}>
        <ViewEnterFilling />
      </PermissionsGate>
    </Wrapper>
  );
};
export default EnterFillingView;

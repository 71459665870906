import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";
import Wrapper from "../../../components/Wrapper";
import Print from "../../../components/PrintComponent";
import {
  BACKANDFORTHRECEIPT,
  BACKANDFORTHRHISTORYECEIPT,
  CLIENTHOMEBILL,
  EMPLOYEES,
  PRODUCTS,
  STATIONENTERBILL,
  STOCK,
} from "../../../data/APIs";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogNumberField,
  DialogTable,
} from "../../../features/dialog";
import { InputField, SelectField } from "../../../features/form";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

import {
  MenuItem,
  Stack,
  TextField,

} from "@mui/material";
import EditReceiptDialog from "../../../components/EditReceiptDialog";

import { UUIDFilter } from "./ViewAwayDistribution";
const ViewHomeDistribution = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const viewback_forthreceiptLang = useSelector(
    (state) => state.lang.value.viewhomereceipt
  );
  const [editData, setEditData] = useState(null);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [ClientHomeBillDetails, setClientHomeBillDetails] = useState({
    details: null,
    tab: "details",
  });
  ///////////////////////////////

  const ClientHomeStationBillStore = useSelector(
    (state) => state.client_home_bill.value
  );
  const [ClientHomeBillGetRequest, CLientHomeBillGetResponse] = useRequest({
    path: CLIENTHOMEBILL,
    method: "get",
  });

  const columns = [
    {
      field: "permit_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "driver",
      headerName: globalLang.driver[lang],
      customContent: ({ driver }) => driver?.name,
    },
    {
      field: "transport",
      headerName: globalLang.transportation[lang],
      customContent: (params) => {
        return `${params?.transport ? params?.transport.name : "-"}`;
      },
    },

    {
      field: "exit_date",
      headerName: globalLang.AwayDate[lang],
      customContent: ({ exit_date }) => format(exit_date),
      customEmpty: "-",
    },
    {
      field: "enter_date",
      headerName: globalLang.BackDate[lang],
      customContent: ({ enter_date }) =>
        enter_date !== null ? format(enter_date) : "-",
      customEmpty: "-",
    },
  ];

  const dispatch = useDispatch();
  const handleCloseDetailsDialog = () => {
    setClientHomeBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };

  const urlParams = new URLSearchParams();
  const getClientHomeBill = () => {
    // urlParams.append("completed", true);
    ClientHomeBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "client_home_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
    completed: [["completed", true]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getClientHomeBill();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: CLIENTHOMEBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({
      params: {
        completed: true,
      },
      id: row.id,
      onSuccess: (res) => {
        dispatch({
          type: "client_home_bill/deleteItem",
          payload: { id: row.id },
        });
      },
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const filters = [
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
  ];
  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };
  useAfterEffect(() => {
    if (!ClientHomeBillDetails.details) return;
    setClientHomeBillDetails((old) => ({
      ...old,
      details: ClientHomeStationBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [ClientHomeStationBillStore]);

  const isPermitted = useIsPermitted();

  //   const [countriesData, setCountriesData] = useState([]);

  //   const [countriesGetRequest, countriesGetResponse] = useRequest({
  //     path: COUNTRY_FILTER,
  //     method: "get",
  //   });

  //   const getCountries = () => {
  //     countriesGetRequest({
  //       onSuccess: (res) => {
  //         setCountriesData(res.data.data);
  //       },
  //     });
  //   };

  //   const [governoratesData, setGovernoratesData] = useState([]);

  //   const [statesGetRequest, statesGetResponse] = useRequest({
  //     path: STATES,
  //     method: "post",
  //   });

  //   const getGovernorates = () => {
  //     statesGetRequest({
  //       body: {
  //         country: controls.country,
  //       },
  //       onSuccess: (res) => {
  //         setGovernoratesData(res.data.data);
  //       },
  //     });
  //   };

  //===End===== Get Countries logic =========

  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.distributionreceipt[lang],
          globalLang.BackAndForthreceipt[lang],
          globalLang.view[lang],
        ]}
      /> */}
      <DataGrid
        columns={columns}
        rows={ClientHomeStationBillStore.results}
        total={ClientHomeStationBillStore.count}
        isPending={CLientHomeBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factoryhomeawaypermit']) }
        onView={isPermitted((e, row) => {
          setClientHomeBillDetails((old) => ({
            ...old,
            details: row,
          }));
        },['view_factoryhomeawayproduct'])}
        onEdit={isPermitted((e, row) => setEditData(row),['change_factoryhomeawaypermit','change_factoryhomeawayproduct']) }
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(
          ClientHomeBillDetails.details &&
            ClientHomeBillDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={
          Boolean(ClientHomeBillDetails.details) &&
          ClientHomeBillDetails.details
        }
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewHomeDistribution;

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  //   {
  //     name: globalLang.product[lang],
  //     // value: `${data?.station_bills.product}`,
  //   },
  //   {
  //     name: globalLang.driver[lang],
  //     value: `${data?.driver ? data?.driver.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.AwayDate[lang],
  //     value: data?.away_date ? format(data?.away_date) : "-",
  //   },
  //   {
  //     name: globalLang.transportation[lang],
  //     value: data?.transport ? data?.transport?.name : "-",
  //   },
  //   {
  //     name: globalLang.product[lang],
  //     value: `${data?.product ? data?.product?.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.product_type[lang],
  //     value: `${data?.product_type ? data?.product_type : "-"}`,
  //   },
  //   {
  //     name: globalLang.away_quantity[lang],
  //     value: `${data?.away_quantity ? data?.away_quantity : "-"}`,
  //   },
  //   {
  //     name: globalLang.BackDate[lang],
  //     value: `${data?.home_date ? format(data?.home_date) : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_empty[lang],
  //     value: `${data?.home_quantity_empty ? data?.home_quantity_empty : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_filled[lang],
  //     value: `${data?.home_quantity_filled ? data?.home_quantity_filled : "-"}`,
  //   },
  //   {
  //     name: globalLang.total_price[lang],
  //     value: `${data?.total_price ? data?.total_price : "-"}`,
  //   },

  // ];

  const columns = [
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ rowData }) =>
        rowData.product ? `${rowData.product?.name} ` : "-",
    },

    {
      field: "driven",
      headerName: globalLang.driven[lang],
      customContent: ({ rowData }) =>
        rowData.driven ? (rowData.driven === 0 ? "0" : rowData.driven) : "-",
    },
    {
      field: "taken_empty",
      headerName: globalLang.taken_empty[lang],
      customContent: ({ rowData }) =>
        rowData.taken_empty === 0 ? "0" : rowData.taken_empty,
    },
    {
      field: "taken_filled",
      headerName: globalLang.taken_filled[lang],
      customContent: ({ rowData }) =>
        rowData.taken_filled === 0 ? "0" : rowData.taken_filled,
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
    // {
    //   field: "price",
    //   headerName: globalLang.achieve[lang],
    //   customContent: ({ rowData }) =>
    //     rowData.price === 0 ? "0" : rowData.price,
    // },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading> */}
      {/* <DialogInfoWindow information={info} /> */}

      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          {/* <Typography component="p" variant="p" sx={{ marginLeft: "20px" }}>
            {data?.distribution_products
              ? data?.distribution_products[0]?.id
              : ""}
          </Typography> */}
          <DialogTable
            rows={data?.distribution_products?.filter(
              (bill) => bill?.product_type === "cylinder"
            )}
            columns={columns}
          />
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.distribution_products?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};
const DriverFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [drivers, setDrivers] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["driver", e.target.value],
      renderedValue: drivers.find((p) => p.id === e.target.value)?.user
        .username,
      value: e.target.value,
    });
  };

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const getEmployees = () => {
    employeesGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setDrivers(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.driver[lang]}
      renderValue={(selected) =>
        drivers.find((p) => p.id === selected).user?.username
      }
      onOpen={getEmployees}
      isPending={employeesGetResponse.isPending}
      onChange={handleChange}
    >
      {drivers.map(
        (driver, index) =>
          driver.job.title === "سواق" && (
            <MenuItem value={driver.id} key={`${index}`}>
              {driver?.user?.username}
            </MenuItem>
          )
      )}
    </SelectField>
  );
};
const HomeDateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "home_date",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
   const [FormData, setFormData] = useState([]);
   const getFormData = (data) => {
     setFormData([...data]);
   };
  ///////////////////////////////
  const columns = [
    {
      field: "driven",
      headerName: globalLang.driven[lang],
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },

    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },

    {
      field: "taken_empty",
      headerName: globalLang.taken_empty[lang],
    },
    {
      field: "taken_filled",
      headerName: globalLang.taken_filled[lang],
    },
 
  ];
  const EditableColumns = ["taken_empty", "taken_filled"];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "bill_id",
        value: "",
        isRequired: false,
      },
      {
        control: "bills",
        value: [],
        isRequired: false,
      },
      {
        control:"enter_date",
        value:data?.enter_date,
          isRequired: false,
      }
    ],
    [data]
  );
  const [station_data, setData] = useState(data);
  useEffect(() => {
    let object = [];
    data?.distribution_products.map((bill, billindex) => {
      EditableColumns.map((column) => {
        object[billindex] = { ...object[billindex], [column]: bill[column] };
      });
    });
    setControl("bills", (old) => [...old, ...object]);
  }, [data?.distribution_products]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });
console.log(FormData)
  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);
  const handleChange = (e, index, id) => {
    console.log(controls.bills);
    let data = [...controls.bills];
    data[index] = { ...data[index], [e.target.name]: parseInt(e.target.value) };
  
    setControl("bills", data);
  };

  const handleEditBill = (id, index) => {

    const requestBody = filter({
      obj: {
        product_id: id,
        products: [FormData[index]],
      },
      output: "object",
    });

    CLientHomeBillPatchRequest({
      id: station_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "client_home_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };

  const [inform, setinform] = useState(false);
  const [CLientHomeBillPatchRequest, CLientHomeBillPatchResponse] = useRequest({
    path: CLIENTHOMEBILL,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output)
      if (!output.isOk) return;
      const isThereChange = !compare(
        [[controls.enter_date, data.enter_date]],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
            enter_date: controls.enter_date.toISOString(),
          
          },
          output: "object",
        });

        CLientHomeBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "client_home_bill/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={globalLang.BackDate[lang]}
              value={controls.enter_date}
              onChange={(newValue) => {
                setControl("enter_date", newValue);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      // borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      fontWeight:"400",
                      background:"#fff"
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                  required={required.includes("enter_date")}
                  error={Boolean(invalid.enter_date)}
                  helperText={invalid.enter_date}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <EditReceiptDialog
            data={data?.distribution_products}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />
          {/* <TableContainer
            component={Paper}
            sx={{
              margin: "10px",
              padding: "10px",
              height: "fit-content",
              background: "#233975",
              width: "auto",

              "& .MuiTableCell-root": {
                borderBottom: "none !important",
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{
                        color: "white",
                      }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.distribution_products?.map((bill, billindex) => (
                  <>
                    <TableRow>
                      {columns.map((c, index) =>
                        EditableColumns.includes(c.field) ? (
                          <>
                            <TableCell sx={{ width: "auto" }}>
                              <InputField
                                sx={{
                                  "& .MuiInputBase-input": {
                                    color: "black",
                                    background: "white",
                                  },
                                }}
                                type="number"
                                onChange={(e) =>
                                  handleChange(e, billindex, bill.id)
                                }
                                name={c.field}
                                defaultValue={bill[c.field]}
                              ></InputField>
                            </TableCell>
                          </>
                        ) : typeof bill[c.field] === "object" ? (
                          <TableCell>
                            <Typography sx={{ color: "white" }}>
                              {bill[c.field].name}
                            </Typography>
                          </TableCell>
                        ) : bill[c.field] ? (
                          <TableCell>
                            <Typography sx={{ color: "white" }}>
                              {bill[c.field]}
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell>
                            <Typography sx={{ color: "white" }}>-</Typography>
                          </TableCell>
                        )
                      )}
                      <TableCell>
                        <Tooltip title="حفظ">
                          <DoneIcon
                            htmlColor="lightgreen"
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleEditBill(bill.id, billindex)}
                            // disabled={stationExitBillPatchResponse.isPending}
                          >
                            {globalLang.save[lang]}
                          </DoneIcon>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}
        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {CLientHomeBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};

import React, { useEffect, useState } from "react";

import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
import AddExpenses from "./AddExpense";
import ViewExpenses from "./ViewExpenses";

const ExpensesView = () => {
  return (
    <Wrapper>
      <PermissionsGate permissions={['add_factoryexpense']}>
      <AddExpenses />
      </PermissionsGate>
        
   <PermissionsGate permissions={['view_factoryexpense']}>
   <ViewExpenses />
   </PermissionsGate>
       

    </Wrapper>
  );
};
export default ExpensesView;

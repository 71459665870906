import React from "react";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddTank from "../Tank/AddTank";

import AddEmployees from "../employee/AddEmployees";
import AddJobs from "../employee/AddJobs";
import ProductsView from "../product/ProductsView";
import ExpensesView from "../expenses/ExpensesView";
import TransportView from "../Transport/TransportView";
import ViewPowerFactory from "../Stock/ViewPowerFactory";
import ViewTankPanel from "../Tank/ViewTankPanel";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";
const ControlsPanel = ({ index,boxes }) => {
  const [value, setValue] = React.useState(index);
  console.log(boxes)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            padding: "10px",
            margin: "auto",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: "black",
                background: "rgba(35,57,117,0.2)",
              },
            }}
          >
            {boxes.map((box, index) => {
              console.log(index)
              return <Tab label={box.title} {...a11yProps(index)} />
            })}
          </Tabs>
        </Box>
        {boxes.map((box,index)=>(
          
          <TabPanel value={value} key={index} index={index}>
     
            {box.title=="إضافة موظف"?(
        
        <TabPanel value={value} index={index}>
          <AddEmployees />
        </TabPanel>
        
        ):box.title=="إضافة وظيفة"?(
       
        <TabPanel value={value} index={index}>
          <AddJobs />
        </TabPanel>
       
        ):box.title=="إضافة غاز"?(
        
        <TabPanel value={value} index={index}>
          <ProductsView />
        </TabPanel>
       
        ):box.title=="إضافة قوة المصنع"?(
        
        <TabPanel value={value} index={index}>
          {/* <AddStock /> */}
          <ViewPowerFactory/>
        </TabPanel>
        
        ):box.title=="إضافة تانك"?(
       
        <TabPanel value={value} index={index}>
          <ViewTankPanel />
        </TabPanel>
        
       
      ): box.title=="إضافة مصروف"?(
        <TabPanel value={value} index={index}>
          <ExpensesView />
        </TabPanel>
        
        ):box.title=="إضافة نقل"?(
        
        <TabPanel value={value} index={index}>
          <TransportView />
        </TabPanel>
        
        ):<></>}
        </TabPanel>
        ))}
      </Box>
    </>
  );
};
export default ControlsPanel;

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../../components/DataGrid";

// import AddProductDialog from "../../../components/AddProductDialog";
import {

  EMPLOYEES,
  PRODUCTS,
  STATIONEXITBILL,
  STOCK,
  TRANSPORT,
} from "../../../data/APIs";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,

  DialogTable,
} from "../../../features/dialog";
import Form, { InputField, SelectField } from "../../../features/form";

import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Dialog as MDialog,

  Typography,
  Tooltip,
} from "@mui/material";
import { Stack } from "@mui/system";
import Print from "../../../components/PrintComponent";
import ErrorIcon from "@mui/icons-material/Error";
import AddProduct from "../../../components/AddProductDialog";
import EditReceiptDialog from "../../../components/EditReceiptDialog";
import { UUIDFilter } from "../Distribution/ViewAwayDistribution";
const ViewExitPermissionStation = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [inform, setinform] = useState({ uuid: "", status: false });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [openAddProduct, setOpenAddProduct] = useState(null);
  const [ExitPermissionStationDetails, setExitPermissionStationDetails] =
    useState({
      details: null,
      tab: "details",
    });
  ///////////////////////////////
  const handleCloseDetailsDialog = () => {
    setExitPermissionStationDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };
  const StationExitBillStore = useSelector(
    (state) => state.station_exit_bill.value
  );

  const columns = [
    {
      field: "permit_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "exit_date",
      headerName: globalLang.exit_date[lang],
      customContent: ({ exit_date }) => format(exit_date),
    },

    {
      field: "transport",
      headerName: globalLang.transportation[lang],
      customContent: ({ transport }) => transport?.name,
    },
  ];

  const dispatch = useDispatch();

  const [StationExitBillGetRequest, StationExitBillGetResponse] = useRequest({
    path: STATIONEXITBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  
  const getStationExitBill = () => {
    urlParams.append('completed',true)
    StationExitBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "station_exit_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });
  const setOpen = () => {
    setinform({ status: false, uuid: "" });
  };
  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getStationExitBill();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: STATIONEXITBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({
          type: "station_exit_bill/deleteItem",
          payload: { id: row.id },
        });
      },
    }).then((res) => {
      if (res.response) {
        setinform({ uuid: row.permit_uuid, status: true });
      }
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "factory",
      value: "",
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
  ]);
  useAfterEffect(() => {
    if (!ExitPermissionStationDetails.details) return;
    setExitPermissionStationDetails((old) => ({
      ...old,
      details: StationExitBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [StationExitBillStore]);
  const filters = [
    // {
    //   name: globalLang.driver[lang],
    //   component: <DriverFilter />,
    // },
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
    // {
    //   name: globalLang.product[lang],
    //   component: <ProductFilter />,
    // },
    // {
    //   name: globalLang.transportation[lang],
    //   component: <TransportFilter />,
    // },
    // {
    //   name: globalLang.AwayDate[lang],
    //   component: <AwayDateFilter />,
    // },

    // {
    //   name: globalLang.product_type[lang],
    //   component: <ProductTypeFilter />,
    // },
  ];

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
    }));
  };

  // const handleResetFilter = () => {
  //   resetControls();

  //   setRequestParams((old) => ({
  //     ...old,
  //     filters: [],
  //   }));
  // };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  return (
    <>
    {inform.status && <InformDialog uuid={inform.uuid} setopen={setOpen} />}
    

      <DataGrid
        columns={columns}
        rows={StationExitBillStore.results}
        total={StationExitBillStore.count}
        isPending={StationExitBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onAddProduct={(e, row) => setOpenAddProduct(row.id)}
        onView={(e, row) => {
          setExitPermissionStationDetails((old) => ({
            ...old,
            details: row,
          }));
        }}
        onEdit={isPermitted((e, row) => setEditData(row),['change_factorystationpermit','change_factorystationproduct'])}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factorystationpermit']) }
        filters={filters}
        // onView={(e, row) => setInfoData(row)}
      />
      <AddProductDialog
        open={Boolean(openAddProduct)}
        onClose={() => setOpenAddProduct(null)}
        id={openAddProduct}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />

      <InfoDialog
        open={Boolean(
          ExitPermissionStationDetails.details &&
            ExitPermissionStationDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={
          Boolean(ExitPermissionStationDetails.details) &&
          ExitPermissionStationDetails.details
        }
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewExitPermissionStation;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
  const [FormData, setFormData] = useState([]);
  const getFormData = (data) => {
    console.log(data);
    setFormData([...data]);
  };
  ///////////////////////////////
  const columns = [
    {
      field: "product",
      headerName: globalLang.product[lang],
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },
    {
      field: "quantityout",
      headerName: globalLang.exit_quantity[lang],
    },

    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "exit_date",
        value: data?.exit_date,
        isRequired: false,
      },
      {
        control: "bill_id",
        value: "",
        isRequired: false,
      },
      {
        control: "bills",
        value: [],
        isRequired: false,
      },
    ],
    [data]
  );
  const EditableColumns = ["quantityout", "volume"];
  const [station_data, setData] = useState(data);
  useEffect(() => {
    data?.bills.map((bill, billindex) => {
      setControl("bills", (old) => [
        ...old,
        { quantityout: bill["quantityout"], volume: bill["volume"] },
      ]);
    });
  }, [data?.bills]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);
  const handleChange = (e, index, id) => {
    let data = [...controls.bills];
    data[index] = { ...data[index], [e.target.name]: parseInt(e.target.value) };
    setControl("bills", data);
  };

  const handleEditBill = (id, index) => {
    const requestBody = filter({
      obj: {
        bill_id: id,
        bills: [FormData[index]],
      },
      output: "object",
    });

    stationExitBillPatchRequest({
      id: station_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "station_exit_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };

  const [inform, setinform] = useState(false);
  const [stationExitBillPatchRequest, stationExitBillPatchResponse] =
    useRequest({
      path: STATIONEXITBILL,
      method: "patch",
      successMessage: "تم التعديل بنجاح",
    });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const isThereChange = !compare(
        [[controls.exit_date, data.exit_date]],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
            exit_date: controls.exit_date.toISOString(),
          },
          output: "object",
        });

        stationExitBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "station_exit_bill/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        })
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={globalLang.exit_date[lang]}
              value={controls.exit_date}
              onChange={(newValue) => {
                setControl("exit_date", newValue);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      // borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      fontWeight: "400",
                      background: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                  required={required.includes("exit_date")}
                  error={Boolean(invalid.exit_date)}
                  helperText={invalid.exit_date}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>

          <EditReceiptDialog
            data={data?.bills}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />
        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {stationExitBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};
const ProductFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [products, setProducts] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["product", e.target.value],
      renderedValue: products.find((p) => p.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.product[lang]}
      renderValue={(selected) => products.find((p) => p.id === selected).name}
      onOpen={getProducts}
      isPending={productsGetResponse.isPending}
      onChange={handleChange}
    >
      {products.map((product, index) => (
        <MenuItem value={product.id} key={`${index}`}>
          {product.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
const DriverFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [drivers, setDrivers] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["driver", e.target.value],
      renderedValue: drivers.find((p) => p.id === e.target.value)?.user
        ?.username,
      value: e.target.value,
    });
  };

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const getEmployees = () => {
    employeesGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setDrivers(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.driver[lang]}
      renderValue={(selected) =>
        drivers.find((driver) => driver.id === selected).user?.username
      }
      onOpen={getEmployees}
      isPending={employeesGetResponse.isPending}
      onChange={handleChange}
    >
      {drivers.map(
        (driver, index) =>
          driver?.job?.title === "سواق" && (
            <MenuItem value={driver.id} key={`${index}`}>
              {driver?.user?.username}
            </MenuItem>
          )
      )}
    </SelectField>
  );
};
export const TransportFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [transport, setTransport] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["transport", e.target.value],
      renderedValue: transport.find((t) => t.id === e.target.value).name,
      value: e.target.value,
    });
  };

  const [transportGetRequest, transportGetResponse] = useRequest({
    path: TRANSPORT,
    method: "get",
  });

  const getTransport = () => {
    transportGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setTransport(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.transportation[lang]}
      renderValue={(selected) => transport.find((t) => t.id === selected)?.name}
      onOpen={getTransport}
      isPending={transportGetResponse.isPending}
      onChange={handleChange}
    >
      {transport.map((t, index) => (
        <MenuItem value={t.id} key={`${index}`}>
          {t?.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

const AwayDateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "away_date",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};

export const ProductTypeFilter = ({ value = "", onChange = () => {} }) => {
  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const handleChange = (e) => {
    onChange({
      query: ["product_type", e.target.value],
      value: e.target.value,
      renderedValue: e.target.value,
    });
  };

  return (
    <InputField
      value={value}
      onChange={handleChange}
      placeholder={globalLang.product_type[lang]}
    />
  );
};

const InformDialog = ({ uuid, setopen = () => {} }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setopen();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ width: "30%",margin:'auto' }}
    >
      <DialogTitle>
        <ErrorIcon htmlColor="red" fontSize="medium" /> حدث خطأ أثناء عملية
        الحذف
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <h4 style={{ color: "white",fontWeight:"normal" }}>
            برجاء حذف فواتير الشحن برقم خروج {uuid} أولا.
          </h4>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={handleClose}>تم</DialogButton>
      </DialogActions>
    </Dialog>
  );
};
export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");


  const columns = [
    {
      field: "product_name",
      headerName: globalLang.product[lang],
      customContent: ({ rowData }) =>
        rowData.product_name ? `${rowData.product_name} ` : "-",
    },

    {
      field: "quantityout",
      headerName: globalLang.exit_quantity[lang],
      customContent: ({ rowData }) =>
        rowData.quantityout
          ? rowData.quantityout === 0
            ? "0"
            : rowData.quantityout
          : "-",
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
    console.log(data);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading> */}
      {/* <DialogInfoWindow information={info} /> */}

      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <Typography  component="span" variant="p" sx={{ marginLeft: "20px" }}>
            {data?.bills ? data?.bills[0]?.permit_uuid : ""}
          </Typography>
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "cylinder"
            )}
            columns={columns}
            isPending={isHistoryPending}
          />
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
            isPending={isHistoryPending}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};
const AddProductDialog = ({ open, onClose, id }) => {
  // Change lang
  const columns = [
    {
      name: "quantityout",
      value: "exit_quantity",
    },
    {
      name: "product",
      value: "product",
    },
    {
      name: "volume",
      value: "volume",
    },
  ];
  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (gas) => {
    setFormData([...gas]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };

  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  // const columns = [
  //   {
  //     field: "quantityout",
  //     headerName: globalLang.exit_quantity[lang],
  //   },
  //   {
  //     field: "product",
  //     headerName: globalLang.product[lang],
  //   },

  //   {
  //     field: "volume",
  //     headerName: globalLang.quantity[lang],
  //   },
  // ];

  const [stationExitBillPatchRequest, stationExitBillPatchResponse] =
    useRequest({
      path: STATIONEXITBILL,
      method: "patch",
      successMessage: "تم التعديل بنجاح",
    });
  ///////////////////////////////

  const [productsGetRequest, productsGetResponse] = useRequest({
    path: STOCK,
    method: "get",
  });

  const getProducts = (type) => {
    productsGetRequest({
      params: {
        product_type: type,
      },
      onSuccess: (res) => {
        setProducts(res.data);
      },
    });
  };
  console.log(FormData);
  console.log(LiquidFormData);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    const requestBody = filter({
      obj: {
        bills: [...FormData, ...LiquidFormData],
      },
      output: "object",
    });

    stationExitBillPatchRequest({
      id: id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "station_exit_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.addProducttoBill[lang]}</DialogHeading>

        <DialogForm>
          <AddProduct
            type="cylinder"
            columns={columns}
            getformData={getFormData}
          />
          <AddProduct
            type="dewar"
            columns={columns}
            getformData={getLiquidFormData}
          />
        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {stationExitBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};

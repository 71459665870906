import React from "react";
import PropTypes from "prop-types";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import Form, { InputField, TextareaField, NumberField, SelectField } from "../../features/form";
import { Stack } from "@mui/system";
import PicturePicker from "../../components/PicturePicker";
import { Box, Button, MenuItem } from "@mui/material";
import useControls from "../../hooks/useControls";
import useRequest from "../../hooks/useRequest";
import { PRODUCTS } from "../../data/APIs";
import filter from "../../utils/ClearNull";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";
const AddProduct = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const addprodcutslang = useSelector((state) => state.lang.value.addProduct);
  const globalLang = useSelector((state) => state.lang.value.global);
  const units = [{ name: "كيلو غرام", value: "kg" }, { name: "لتر", value: "litre" }]
  ///////////////////////////////
  const [productPostRequest, productPostResponse] = useRequest({
    path: PRODUCTS,
    method: "post",
    successMessage: "تم إضافة منتج جديد بنجاح",
  });

  const [
    { controls, required, invalid },
    { setControl, resetControls, validate, setInvalid },
  ] = useControls([
    {
      control: "name",
      value: "",
      isRequired: true,
    },
    {
      control: "rate",
      value: "",
      isRequired: true,
    },
    {
      control: "weight",
      value: "",
      isRequired: false,
    },
    {
      control: "unit",
      value: "",
      isRequired: false,
    },
    {
      control: "color",
      value: "",
      isRequired: false,
    },
    {
      control: "owned",
      value: "",
      isRequired: false,
    },
  ]);
  const dispatch = useDispatch()
  const handleSubmit = (e) => {
    e.preventDefault();
    validate().then((output) => {
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          name: controls.name,
          color: controls.color,
          molecular_weight: controls.weight,
          rate: controls.rate,
          unit:controls.unit,
          owned: controls.owned
        },
        output: "formData",
      });

      productPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          resetControls();

        },
      }).then((res) => {

        const responseBody = {
          name: res?.name,

          image: res?.image,
        };
        if (res?.id) {
          dispatch({ type: "products/addItem", payload: res });
        }
        else {
          setInvalid(res?.message);
        }


      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[globalLang.products[lang], addprodcutslang.addProduct[lang]]}
      /> */}
      <Stack
        component="form"
        onSubmit={handleSubmit}
        noValidate
        alignItems="center"
        gap={2}
     

      >
        <Form
          hideFooter
          hideHeader
          minChildWidth="750px"
          sx={{
            width: "100%",
            boxShadow: {
              xl: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
              lg: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
              md: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
              sm: "none",
              xs: "none",
            },

          }}
        >

          <Stack gap={3} sx={{
            width: "100%", flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'column',
              sm: 'column',
              xs: 'column'
            }
          }}>
            <InputField
              placeholder={addprodcutslang.ProductName[lang]}
              label={addprodcutslang.ProductName[lang]}
              value={controls.name}
              onChange={(e) => setControl("name", e.target.value)}
              required={required.includes("name")}
              error={Boolean(invalid.name)}
              helperText={invalid.name}
            />
            <NumberField
              label={globalLang.rate[lang]}
              placeholder={globalLang.rate[lang]}
              onChange={(e) => setControl("rate", e.target.value)}
              value={controls.rate}
              required={required.includes("rate")}
              error={Boolean(invalid.rate)}
              helperText={invalid.rate}
            ></NumberField>
            <NumberField
              label={globalLang.molecular_weight[lang]}
              placeholder={globalLang.molecular_weight[lang]}
              onChange={(e) => setControl("weight", e.target.value)}
              value={controls.weight}
              required={required.includes("weight")}
              error={Boolean(invalid.weight)}
              helperText={invalid.weight}
            ></NumberField>
            <InputField
            type="color"
              placeholder={globalLang.color[lang]}
              label={globalLang.color[lang]}
              value={controls.color}
              onChange={(e) => setControl("color", e.target.value)}
              required={required.includes("color")}
              error={Boolean(invalid.color)}
              helperText={invalid.color}
              sx={{width:"20%"}}
            />
            <SelectField
               label={globalLang.unit[lang]}
              placeholder={globalLang.unit[lang]}
              renderValue={(selected) => {
                return units.find(
                  (unit) => unit.value === selected
                ).name;
              }}
           
             
              onChange={(e) => {
               setControl("unit",e.target.value)
              }}
            >
              {units.map((unit, index) => (
                <MenuItem
                  value={unit.value}
                  key={`${unit.value} ${index}`}
                >
                  {unit.name}
                </MenuItem>
              ))}
            </SelectField>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setControl("owned", e.target.checked)}
                />
              }
              label="ملك المصنع"
              sx={{ color: "#666666" }}
            />
          </Stack>

        </Form>
        <Button
          variant="contained"
          type="submit"
          disabled={productPostResponse.isPending}
          sx={{ width: 140, height: 50 }}
        >
          {globalLang.save[lang]}
        </Button>
      </Stack>
      {productPostResponse.successAlert}
      {productPostResponse.failAlert}
    </>
  );
};

export default AddProduct;

import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import GroupsIcon from "@mui/icons-material/Groups";
import StoreIcon from "@mui/icons-material/Store";
import BadgeIcon from "@mui/icons-material/Badge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PointOfSaleSharpIcon from "@mui/icons-material/PointOfSaleSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import PermsIcon from "../svg/PermsIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ReceiptIcon from "@mui/icons-material/Receipt";
import Home from "../pages/Home";
import AvTimerIcon from '@mui/icons-material/AvTimer';
import LockResetIcon from '@mui/icons-material/LockReset';


import Setting from "../pages/control/Setting";

import AnalyticsIcon from "@mui/icons-material/Analytics";

// import BillDay from "../pages/BillsOverView/OverViewReceipts";

import MainOverView from "../pages/BillsOverView/MainOverView";
import MainReceipts from "../pages/Receipts/MainReceipts";
import MainPermissions from "../pages/Receipts/MainPermissions";
import MainEmployees from "../pages/employee/MainEmployees";
import MainClients from "../pages/clients/MainClients";
import MainControls from "../pages/control/MainControls";
import ReadingsPermissions from "../pages/Readings/ReadingsPermissions";
import Timeline from "../pages/TimeLine";
import TimelineIcon from '@mui/icons-material/Timeline';
const pages = [
  {
    name: {
      ar: "الرئيسية",
      en: "Home",
    },
    icon: <TimelineIcon />,
    path: "/",
    element: <Home />,
    permitted: [],
  },
  {
    name: {
      ar: "يوميات",
      en: "Timeline",
    },
    icon: <AutoAwesomeMosaicIcon />,
    path: "/timeline",
    element: <Timeline />,
    permitted: [],
  },
  {
    name: {
      ar: "الإحصائيات",
      en: "Bills OverView",
    },
    icon: <AnalyticsIcon />,
    path: "/bills-overview",
    element: <MainOverView />,
    permitted: [
      "view_factorysafe",
      "view_factoryreport",
      'view_factoryclientoverview',
      'view_factoryclientbilloverview',
      'view_factoryexpensesoverview',
      'view_factorystationoverview',
      'view_factorystationbilloverview',
      'view_factorytraderoverview',
      'view_factorytraderbilloverview',
      "view_factorytank",
      'view_productoverview',
      "change_practicalstock",
      "delete_factoryloans",
      "view_factoryloans",
      "change_factoryloans",
      "view_factorypower",
      "view_factorystock",
      "delete_factorystock",
      "change_factorystock",
      "view_historicalfactorystock",
    ],
    // subtabs: [
    //   {
    //     name_en: "Daily",
    //     name: "يومي",
    //     path: "/daily-bills",
    //     element: <BillDay period="d" />,
    //     // element:<ReceiptBox/>,
    //     permitted: [],
    //   },
    //   {
    //     name_en: "Month",
    //     name: "شهري",
    //     path: "/month-bills",
    //     element: <BillDay period="m" />,
    //     permitted: [],
    //   },
    //   // {
    //   //   name_en: "Annual",
    //   //   name: "سنوي",
    //   //   path: "/annual-bills",
    //   //   element: <BillDay period="a" />,
    //   //   permitted: [],
    //   // },

    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Gas Shipping Invoice",
    //   //   name: "فواتير الشحن غاز",
    //   //   path: "/gas-shipping-invoice",
    //   //   element: <ShipReceiptGasView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Liquid Shipping Invoice",
    //   //   name: "فواتير الشحن سائل",
    //   //   path: "/liquid-shipping-invoice",
    //   //   element: <ShipReceiptLiquidView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Expenses Invoice",
    //   //   name: "فواتير المصروفات",
    //   //   path: "/expenses-invoices",
    //   //   element: <ExpensesReceiptView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Back And Forth Invoice",
    //   //   name: "فاتورة الذهاب",
    //   //   path: "/backandforth-invoices",
    //   //   element: <BackAndForthReceiptView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: " Completed Back And Forth Invoice",
    //   //   name: "فاتورة العودة",
    //   //   path: "/completed-backandforth-invoices",
    //   //   element: <CompletedBackAndForth />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Gas Distribution Invoice",
    //   //   name: "فواتير التوزيع غاز",
    //   //   path: "/gas-Distribution-invoice",
    //   //   element: <GasDistributionView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Liquid Distribution Invoice",
    //   //   name: "فواتير التوزيع سائل",
    //   //   path: "/liquid-Distribution-invoice",
    //   //   element: <LiquidDistributionView />,
    //   //   permitted: [],
    //   // },
    // ],
  },
  {
    name: {
      ar: "الحسابات",
      en: "calculations",
    },
    icon: <ReceiptIcon />,
    path: "/calculations",
    element: <MainReceipts />,
    permitted: [
      'add_factorypurchasebills',
      'change_factorypurchasebills',
      'delete_factorypurchasebills',
      'view_factorypurchasebills',
      'add_factorysalesbills',
      'change_factorysalesbills',
      'delete_factorysalesbills',
      'view_factorysalesbills',
      'add_factorystationbill',
      'change_factorystationbill',
      'delete_factorystationbill',
      'view_factorystationbill',
      'add_factorystationtankbill',
      'change_factorystationtankbill',
      'delete_factorystationtankbill',
      'view_factorystationtankbill',
      'add_factorytraderbill',
      'change_factorytraderbill',
      'delete_factorytraderbill',
      'view_factorytraderbill',
      'add_factoryexpensesbill',
      'change_factoryexpensesbill',
      'delete_factoryexpensesbill',
      'view_factoryexpensesbill',
      'add_factoryfinancedepositbills',
      'change_factoryfinancedepositbills',
      'delete_factoryfinancedepositbills',
      'view_factoryfinancedepositbills',
      'add_factoryclientdistribution',
      'change_factoryclientdistribution',
      'delete_factoryclientdistribution',
      'view_factoryclientdistribution',
    ],
    // subtabs: [
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Gas Filling Invoice",
    //   //   name: "فواتير التعبئة غاز",
    //   //   path: "/gas-filling-invoice",
    //   //   element: <FillReceiptGasView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   name_en: "Liquid Filling Invoice",
    //   //   name: "فواتير التعبئة سائل",
    //   //   path: "/liquid-filling-invoice",
    //   //   element: <FillReceiptLiquidView />,
    //   //   permitted: [],
    //   // },

    //   {
    //     name_en: "Gas Shipping Invoice",
    //     name: " الشحن",
    //     path: "/gas-shipping-invoice",
    //     element: <ShipReceiptGasView />,
    //     permitted: [
    //       // "add_factorystationgasoutbill",
    //       // "view_factorystationgasoutbill",
    //     ],
    //   },
    //   {
    //     name_en: "Tank Shipping Invoice",
    //     name: " شحن التانك",
    //     path: "/tank-shipping-invoice",
    //     element: <TankReceiptsView />,
    //     permitted: [
    //       // "add_factorystationgasoutbill",
    //       // "view_factorystationgasoutbill",
    //     ],
    //   },

    //   {
    //     name_en: "Expenses Invoice",
    //     name: " المصروفات",
    //     path: "/expenses-invoices",
    //     element: <ExpensesReceiptView />,
    //     permitted: [],
    //   },

    //   {
    //     name_en: " Distribution Invoice",
    //     name: " التوزيع ",
    //     path: "/Distribution-invoice",
    //     element: <DistributionBillView />,
    //     permitted: [],
    //   },
    //   {
    //     name_en: "Filling Invoice",
    //     name: " التعبئة",
    //     path: "/filling-invoice",
    //     element: <FillingBillView />,
    //     permitted: [],
    //   },
    //   // {
    //   //   name_en: "Liquid Distribution Invoice",
    //   //   name: "فواتير التوزيع سائل",
    //   //   path: "/liquid-Distribution-invoice",
    //   //   element: <LiquidDistributionView />,
    //   //   permitted: [],
    //   // },
    // ],
  },
  {
    name: {
      ar: "الأذونات",
      en: "Permissions",
    },
    icon: <LockResetIcon />,
    path: "/permissions",
    element: <MainPermissions />,
    permitted: [
      'add_factoryhomeawaypermit',
      'change_factoryhomeawaypermit',
      'delete_factoryhomeawaypermit',
      'view_factoryhomeawaypermit',
      'add_factoryhomeawayproduct',
      'change_factoryhomeawayproduct',
      'delete_factoryhomeawayproduct',
      'view_factoryhomeawayproduct',
      'add_factorystationpermit',
      'change_factorystationpermit',
      'delete_factorystationpermit',
      'view_factorystationpermit',
      'add_factorytraderpermit',
      'change_factorytraderpermit',
      'delete_factorytraderpermit',
      'view_factorytraderpermit'
    ],
    // subtabs: [
    //   {
    //     // name: {
    //     //   ar: "إضافة وحدة",
    //     //   en: "add unit",
    //     // },
    //     name_en: "Shipping Invoice",
    //     name: " فاتورة الشحن",
    //     path: "/shipping-invoice",
    //     element: <StationExitBillView />,
    //     permitted: [
    //       // "add_factorystationgasoutbill",
    //       // "view_factorystationgasoutbill",
    //     ],
    //   },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Enter Permission",
    //   //   name: " إذن دخول فاتورة الشحن",
    //   //   path: "/gas-shipping-invoice-permission-in",
    //   //   element: <StationEnterBillView />,
    //   //   permitted: [
    //   //     // "add_factorystationgasoutbill",
    //   //     // "view_factorystationgasoutbill",
    //   //   ],
    //   // },
    //   {
    //     name_en: "Away Invoice",
    //     name: "إذن ذهاب فاتورة توزيع",
    //     path: "/away-permission-invoices",
    //     element: <AwayDistributionView />,
    //     permitted: [],
    //   },
    //   {
    //     name_en: " Home Invoice",
    //     name: "إذن عودة فاتورة توزيع",
    //     path: "/home-permission-invoices",
    //     element: <HomeDistributionView />,
    //     permitted: [],
    //   },
    //   {
    //     name_en: "Enter Filling Invoice",
    //     name: "إذن دخول فاتورة تعبئة",
    //     path: "/enter-filling-invoice",
    //     element: <EnterFillingView />,
    //     permitted: [],
    //   },
    //   {
    //     name_en: "Exit Filling Invoice",
    //     name: "إذن خروج فاتورة تعبئة",
    //     path: "/exit-filling-invoice",
    //     element: <ExitFillingView />,
    //     permitted: [],
    //   },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة وحدة",
    //   //   //   en: "add unit",
    //   //   // },
    //   //   name_en: "Gas Filling Invoice",
    //   //   name: "فواتير التعبئة غاز",
    //   //   path: "/gas-filling-invoice",
    //   //   element: <FillReceiptGasView />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   name_en: "Liquid Filling Invoice",
    //   //   name: "فواتير التعبئة سائل",
    //   //   path: "/liquid-filling-invoice",
    //   //   element: <FillReceiptLiquidView />,
    //   //   permitted: [],
    //   // },

    //   // {
    //   //   name_en: "Liquid Distribution Invoice",
    //   //   name: "فواتير التوزيع سائل",
    //   //   path: "/liquid-Distribution-invoice",
    //   //   element: <LiquidDistributionView />,
    //   //   permitted: [],
    //   // },
    // ],
  },
  {
    name: {
      ar: "الموارد البشرية",
      en: "Human Resources",
    },
    icon: <BadgeIcon />,
    path: "/human-resources",
    element: <MainEmployees />,
    permitted: [
      "view_factoryjob",
      'change_factoryjob',
      'delete_factoryjob',
      "view_factoryemployee",
      'change_factoryemployee',
      'delete_factoryemployee'
    ],
    // subtabs: [
    //   // {
    //   //   name_en: "Add an employee job",
    //   //   name: "إضافة وظيفة موظف",
    //   //   path: "/create-jobs",
    //   //   element: <AddJobs />,
    //   //   permitted: ["add_factoryjob"],
    //   // },
    //   {
    //     // name: {
    //     //   ar: "عرض وظائف الموظفين",
    //     //   en: "View employee jobs",
    //     // },
    //     name_en: "Jobs",
    //     name: "الوظائف",
    //     path: "/view-jobs",
    //     element: <ViewJobs />,
    //     permitted: ["view_factoryjob"],
    //   },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة موظف",
    //   //   //   en: "Add an employee",
    //   //   // },
    //   //   name_en: "Add an employee",
    //   //   name: "إضافة موظف",
    //   //   path: "/create-employees",
    //   //   element: <AddEmployees />,
    //   //   permitted: ["add_factoryemployee"],
    //   // },
    //   {
    //     // name: {
    //     //   ar: "عرض الموظفين",
    //     //   en: "View the staff",
    //     // },
    //     name_en: "Employees",
    //     name: "الموظفين",
    //     path: "/view-employees",
    //     element: <ViewEmployees />,
    //     permitted: ["view_factoryemployee"],
    //   },
    // ],
  }
  ,
  {
    name: {
      ar: "القراءات",
      en: "ٌReports",
    },
    icon: <AvTimerIcon />,
    path: "/readings",
    element: <ReadingsPermissions />,
    permitted: [
      'add_factorytankreadings',
      'change_factorytankreadings',
      'delete_factorytankreadings',
      'view_factorytankreadings',
      'add_practicalstock',
      "add_factoryloans"
    ],
  },
  
  // {
  //   name: {
  //     ar: "التجار",
  //     en: "merchants",
  //   },
  //   icon: <WarehouseIcon />,
  //   path: "/merchants",
  //   permitted: [
  //     "add_factorytrader",
  //     "view_factorytrader",
  //     // "add_factorytraderproduct",
  //     // "view_factorytraderproduct",
  //   ],
  //   subtabs: [
  //     {
  //       // name: {
  //       //   ar: "الإحصائيات",
  //       //   en: "statistics",
  //       // },
  //       name_en: "Trader Summary",
  //       name: "الإحصائيات",
  //       path: "/trader-summary",
  //       element: <TraderSummary />,
  //       permitted: [],
  //     },
  //     // {
  //     //   // name: {
  //     //   //   ar: "الإحصائيات",
  //     //   //   en: "statistics",
  //     //   // },
  //     //   name_en: "statistics",
  //     //   name: "الإحصائيات",
  //     //   path: "/overview",
  //     //   element: <Overview />,
  //     //   permitted: [],
  //     // },

  //     {
  //       // name: {
  //       //   ar: "جميع العملاء",
  //       //   en: "all clients",
  //       // },
  //       name_en: "Traders",
  //       name: "إضافة تاجر",
  //       path: "/merchants",
  //       element: <MerchantsView />,
  //       permitted: ["add_factorytrader", "view_factorytrader"],
  //     },

  //     // {
  //     //   // name: {
  //     //   //   ar: "العملاء الحذوفة",
  //     //   //   en: "Deleted clients",
  //     //   // },
  //     //   name_en: "Deleted Traders",
  //     //   name: "التجار المحذوفين",
  //     //   path: "/deleted-traders",
  //     //   element: <DeletedTraders />,
  //     //   permitted: ["view_factorytrader"],
  //     // },
  //     // {
  //     //   // name: {
  //     //   //   ar: "إضافة حالة عميل",
  //     //   //   en: "Add a customer case",
  //     //   // },
  //     //   name_en: "Add a customer case",
  //     //   name: "إضافة حالة عميل",
  //     //   path: "/create-status",
  //     //   element: <AddStatus />,
  //     //   permitted: [],
  //     // },
  //     // {
  //     //   // name: {
  //     //   //   ar: "عرض حالات العملاء",
  //     //   //   en: "View customer cases",
  //     //   // },
  //     //   name_en: "View customer cases",
  //     //   name: "عرض حالات العملاء",
  //     //   path: "/view-status",
  //     //   element: <ViewStatus />,
  //     //   permitted: [],
  //     // },

  //     // {
  //     //   // name: {
  //     //   //   ar: "إستيراد عملاء",
  //     //   //   en: "import clients",
  //     //   // },
  //     //   name_en: "Trader Prices",
  //     //   name: "أسعار التجار",
  //     //   path: "/traders-price",
  //     //   element: <MerchantsPricesView />,
  //     //   permitted: ["add_factorytraderproduct", "view_factorytraderproduct"],
  //     // },
  //     // {
  //     //   // name: {
  //     //   //   ar: "تصدير عملاء",
  //     //   //   en: "export clients",
  //     //   // },
  //     //   name_en: "export clients",
  //     //   name: "تصدير عملاء",
  //     //   path: "/export-clients",
  //     //   element: <ExportClients />,
  //     //   permitted: ["aqarexport_file"],
  //     // },
  //     // {
  //     //   // name: {
  //     //   //   ar: "سجل الإستيراد",
  //     //   //   en: "import log",
  //     //   // },
  //     //   name_en: "import log",
  //     //   name: "سجل الإستيراد",
  //     //   path: "/import-records",
  //     //   element: <ImportRegistry />,
  //     //   permitted: ["view_aqarimportexportfiels"],
  //     // },
  //     // {
  //     //   // name: {
  //     //   //   ar: "سجل التصدير",
  //     //   //   en: "export record",
  //     //   // },
  //     //   name_en: "export record",
  //     //   name: "سجل التصدير",
  //     //   path: "/export-records",
  //     //   element: <ExportRegistry />,
  //     //   permitted: ["view_aqarimportexportfiels"],
  //     // },
  //   ],
  // },
  {
    name: {
      ar: "جميع العملاء",
      en: "All Clients",
    },
    icon: <GroupsIcon />,
    path: "/all-clients",
    element: <MainClients />,
    permitted: [
      "add_factoryclient",
      "view_factoryclient",
      "delete_factoryclient",
      "change_factoryclient",
      "add_factoryclientproduct",
      "view_factoryclientproduct",
      "add_factorystation",
      "change_factorystation",
      "delete_factorystation",
      "view_factorystation",
      "add_factorytrader",
      "change_factorytrader",
      "delete_factorytrader",
      "view_factorytrader"
    ],
    // subtabs: [
    //   // {
    //   //   // name: {
    //   //   //   ar: "الإحصائيات",
    //   //   //   en: "statistics",
    //   //   // },
    //   //   name_en: "Client Summary",
    //   //   name: "الإحصائيات",
    //   //   path: "/client-summary",
    //   //   element: <ClientSummary />,
    //   //   permitted: [],
    //   // },

    //   {
    //     // name: {
    //     //   ar: "جميع العملاء",
    //     //   en: "all clients",
    //     // },
    //     name_en: "all clients",
    //     name: "إضافة عميل",
    //     path: "/view-clients",
    //     element: <ClientsView />,
    //     permitted: ["add_factoryclient", "view_factoryclient"],
    //   },
    //   {
    //     // name: {
    //     //   ar: "عرض الوحدات",
    //     //   en: "Show units",
    //     // },
    //     name_en: "Show stations",
    //     name: "إضافة محطة",
    //     path: "/view-stations",
    //     element: <StationsView />,
    //     permitted: ["add_factorystation", "view_factorystation"],
    //   },
    //   {
    //     // name: {
    //     //   ar: "جميع العملاء",
    //     //   en: "all clients",
    //     // },
    //     name_en: "Traders",
    //     name: "إضافة تاجر",
    //     path: "/merchants",
    //     element: <MerchantsView />,
    //     permitted: ["add_factorytrader", "view_factorytrader"],
    //   },
    //   // {
    //   //   // name: {
    //   //   //   ar: "العملاء الحذوفة",
    //   //   //   en: "Deleted clients",
    //   //   // },
    //   //   name_en: "Deleted Clients",
    //   //   name: "العملاء المحذوفين",
    //   //   path: "/deleted-clients",
    //   //   element: <DeletedClients />,
    //   //   permitted: ["view_factoryclient"],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "إضافة حالة عميل",
    //   //   //   en: "Add a customer case",
    //   //   // },
    //   //   name_en: "Add a customer case",
    //   //   name: "إضافة حالة عميل",
    //   //   path: "/create-status",
    //   //   element: <AddStatus />,
    //   //   permitted: [],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "عرض حالات العملاء",
    //   //   //   en: "View customer cases",
    //   //   // },
    //   //   name_en: "View customer cases",
    //   //   name: "عرض حالات العملاء",
    //   //   path: "/view-status",
    //   //   element: <ViewStatus />,
    //   //   permitted: [],
    //   // },

    //   // {
    //   //   // name: {
    //   //   //   ar: "إستيراد عملاء",
    //   //   //   en: "import clients",
    //   //   // },
    //   //   name_en: "Clients Prices",
    //   //   name: "أسعار العملاء",
    //   //   path: "/clients-price",
    //   //   element: <ClientsPricesView />,
    //   //   permitted: ["add_factoryclientproduct", "view_factoryclientproduct"],
    //   // },

    //   // {
    //   //   // name: {
    //   //   //   ar: "تصدير عملاء",
    //   //   //   en: "export clients",
    //   //   // },
    //   //   name_en: "export clients",
    //   //   name: "تصدير عملاء",
    //   //   path: "/export-clients",
    //   //   element: <ExportClients />,
    //   //   permitted: ["aqarexport_file"],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "سجل الإستيراد",
    //   //   //   en: "import log",
    //   //   // },
    //   //   name_en: "import log",
    //   //   name: "سجل الإستيراد",
    //   //   path: "/import-records",
    //   //   element: <ImportRegistry />,
    //   //   permitted: ["view_aqarimportexportfiels"],
    //   // },
    //   // {
    //   //   // name: {
    //   //   //   ar: "سجل التصدير",
    //   //   //   en: "export record",
    //   //   // },
    //   //   name_en: "export record",
    //   //   name: "سجل التصدير",
    //   //   path: "/export-records",
    //   //   element: <ExportRegistry />,
    //   //   permitted: ["view_aqarimportexportfiels"],
    //   // },
    // ],
  },

  {
    name: {
      ar: "التحكم",
      en: "Control",
    },

    icon: <AddCircleOutlineIcon />,
    path: "/control",
    element:<MainControls/>,
    permitted: [
      "add_factoryemployee",
      "add_factoryjob",
      "add_factoryexpense",
      "add_factorypower",
      "delete_factorypower",
      "view_factorypower",
      "add_factoryproduct",
      "delete_factoryproduct",
      "view_factoryproduct",
      "add_factorytank",
      "delete_factorytank",
      "view_factorytank",
      "add_factoryexpense",
      "view_factoryexpense",
      "delete_factoryexpense",
      "add_factorytransport",
      "delete_factorytransport",
      "view_factorytransport"

    ],
    // subtabs: [
    //   {
    //     // name: {
    //     //   ar: "إضافة موظف",
    //     //   en: "Add an employee",
    //     // },
    //     name_en: "Add an employee",
    //     name: "إضافة موظف",
    //     path: "/create-employees",
    //     element: <AddEmployees />,
    //     permitted: ["add_factoryemployee"],
    //   },
    //   {
    //     name_en: "Add an employee job",
    //     name: "إضافة وظيفة ",
    //     path: "/create-jobs",
    //     element: <AddJobs />,
    //     permitted: ["add_factoryjob"],
    //   },
    //   {
    //     name: "الغازات",
    //     name_en: "Gases",

    //     icon: <StoreIcon />,
    //     path: "/products",
    //     element: <ProductsView />,
    //     permitted: ["view_factoryproduct", "add_factoryproduct"],
    //   },
    //   {
    //     name: "الرصيد",
    //     name_en: "Stock",

    //     icon: <InventoryIcon />,
    //     path: "/stock",
    //     element: <StockView />,
    //     permitted: ["add_factorystock", "view_factorystock"],
    //   },
    //   {
    //     name_en: "Tank",
    //     name: "التانك",

    //     icon: <PropaneTankIcon />,
    //     path: "/tank",
    //     element: <TankView />,
    //     permitted: ["add_factorytank", "view_factorytank"],
    //   },
    //   {
    //     name: "المصروفات",
    //     name_en: "expenses",

    //     icon: <PointOfSaleSharpIcon />,
    //     path: "/expenses",
    //     element: <ExpensesView />,
    //     permitted: [],
    //   },
    //   {
    //     name_en: "Transportation",
    //     name: "النقل",
    //     icon: <DirectionsTransitSharpIcon />,
    //     path: "/transport",
    //     element: <TransportView />,
    //     permitted: ["add_factorytransport", "view_factorytransport"],
    //   },
    // ],
  },

  null,
  {
    name: {
      ar: "الاعدادات",
      en: "Settings",
    },
    icon: <SettingsIcon />,
    path: "/settings",
    element: <Setting />,
    permitted: [],
  },
  null
];

export default pages;

import {
  Input,
  MenuItem,
  Stack,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { TANK, TRADERENTERBILL, TRADEREXITBILL } from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  SelectField,
} from "../../../features/form";

import Wrapper from "../../../components/Wrapper";
import AutocompleteField from "../../../features/form/components/AutocompleteField";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import { v4 as uuid } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomTable from "../../../components/CustomTable";

const ExitFilling = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const backandforthreceiptLang = useSelector(
    (state) => state.lang.value.addhomereceipt
  );
  const TraderEnterBillStore = useSelector(
    (state) => state.trader_enter_bill.value
  );
  const dispatch = useDispatch();
  const [isCleared, setIsCleared] = useState(null);
  const [TraderExitBillPostRequest, TraderExitBillPostResponse] = useRequest({
    path: TRADEREXITBILL,
    method: "post",
    successMessage: "تم التعديل بنجاح",
  });

  const columns = [
    { name: "enter_empty", value: globalLang.enter_empty[lang] },
    { name: "enter_filled", value: globalLang.enter_filled[lang] },
    { name: "product_name", value: globalLang.product[lang] },
    { name: "volume", value: globalLang.volume[lang] },
    { name: "exit_empty", value: globalLang.exit_empty[lang] },
    { name: "exit_filled", value: globalLang.exit_filled[lang] },
    { name: "quantityreturned", value: globalLang.returned[lang] },
    { name: "fill_type", value: globalLang.fill_type[lang] },
  ];
  const info = ["enter_empty", "enter_filled", "product_name", "volume"];

  const [filteration, setfiltered] = useState(true);

  const [filterdata, setfilterdata] = useState({
    trader_enter_bill: "",
  });
  const [TraderEnterBillData, setTraderEnterBillData] = useState({});
  const [Products, setProducts] = useState([{}]);

  const [FormData, setFormData] = useState([]);
  const [LiquidFormData, setLiquidFormData] = useState([]);
  const getFormData = (data) => {
    setFormData([...data]);
  };
  const getLiquidFormData = (liquid) => {
    setLiquidFormData([...liquid]);
  };

  // console.log(FormData, LiquidFormData);
  const fill_types = [
    {
      name: "تانك",
      value: "tank",
    },
    {
      name: "مصنع",
      value: "factory",
    },
  ];

  ///////////////////////////////
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "exit_date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "exit_permit",
      value: "",
      isRequired: true,
    },
  ]);

  const [TraderEnterBillGetRequest, TraderEnterBillGetResponse] = useRequest({
    path: TRADERENTERBILL,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTraderEnterBill = () => {
    urlParams.append("size", 1000);
    urlParams.append("completed", false);
    TraderEnterBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "trader_enter_bill/set", payload: res.data });
      },
    });
  };

  const handleSubmit = () => {
    validate().then((output) => {
      console.log(output);
      if (!output.isOk) return;

      const requestBody = filter({
        obj: {
          enter_permit_uuid: filterdata.trader_enter_bill,
          exit_permit_uuid: parseInt(controls.exit_permit),
          exit_date: controls.exit_date.toISOString(),
          bills: [
            ...FormData.filter((object) =>
              typeof object !== "undefined" && Object.keys(object).length !== 0
                ? Object.keys(object)
                : ""
            ),
            ...LiquidFormData.filter((object) =>
              typeof object !== "undefined" && Object.keys(object).length !== 0
                ? Object.keys(object)
                : ""
            ),
          ],
        },
        output: "object",
      });

      TraderExitBillPostRequest({
        body: requestBody,
        onSuccess: (res) => {
          handleresetFilters();
          resetControls();
          dispatch({
            type: "trader_exit_bill/putItem",
            payload: { id: res.data.id, item: res.data },
          });
        },
      }).then((res) => {
        let response = res?.response?.data;

        if (!res.id) {
          setInvalid(res?.message);
        }
      });
    });
  };

  const handleFilterSubmit = () => {
    if (!filterdata.trader_enter_bill) {
      return;
    }

    TraderEnterBillGetRequest({
      id: TraderEnterBillStore.results?.find(
        (bf) => bf?.enter_permit_uuid === filterdata?.trader_enter_bill
      )?.id,
      onSuccess: (res) => {
        setfiltered(false);
        setTraderEnterBillData(res?.data);
      },
    });
  };
  const handleresetFilters = () => {
    setfilterdata({ trader_enter_bill: "" });
    setfiltered(true);

    setIsCleared(uuid());
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[
          globalLang.fillreceipt[lang],
          globalLang.Exit_Permission[lang],
          globalLang.add[lang],
        ]}
      /> */}
      {filteration ? (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleFilterSubmit,
              children: globalLang.get_away_data[lang],
              disabled: !Boolean(filterdata.trader_enter_bill),
            },
            closeBtn: {
              sx: { display: "none" },
            },
          }}
        >
          <AutocompleteField
            label={globalLang.Enter_Permission[lang]}
            sx={{ width: "30vw" }}
            placeholder={globalLang.Enter_Permission[lang]}
            isPending={TraderEnterBillGetResponse.isPending}
            onOpen={getTraderEnterBill}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata({
                    trader_enter_bill: options.value,
                  });
                  break;
                case "clear":
                  setfilterdata({ trader_enter_bill: "" });
              }
            }}
            data={[
              ...TraderEnterBillStore.results.map((bf, index) => ({
                label: String(bf.enter_permit_uuid),
                value: bf.enter_permit_uuid,
              })),
            ]}
            value={filterdata.trader_enter_bill}
          />
        </Form>
      ) : (
        <Form
          childrenProps={{
            saveBtn: {
              onClick: handleSubmit,
              disabled: TraderExitBillPostResponse.isPending,
            },
            closeBtn: {
              onClick: resetControls,
              disabled: TraderExitBillPostResponse.isPending,
            },
          }}
        >
          <Stack sx={{ padding: "20px", gap: 3 }}>
            <Stack
              sx={{
                gap: 2,
                flexDirection: {
                  xl: "row",
                  lg: "row",
                  md: "column",
                  sm: "column",
                  xs: "column",
                },
                padding: "10px",
                "& .MuiTextField-root": {
                  minWidth: "16px",
                  width: "100%",
                },
              }}
            >
              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.receiptnumber[lang]}
                placeholder={globalLang.receiptnumber[lang]}
                value={filterdata.trader_enter_bill}
              />

              <InputField
                disabled
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                label={globalLang.Date[lang]}
                value={format(TraderEnterBillData.enter_date)}
              />

              <InputField
                disabled
                label={globalLang.driver[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.driver[lang]}
                value={TraderEnterBillData.driver}
              ></InputField>
              <InputField
                disabled
                label={globalLang.transportation[lang]}
                sx={{
                  "& .MuiInputBase-input": {
                    background: "#E0DCD5",
                  },
                }}
                placeholder={globalLang.transportation[lang]}
                value={TraderEnterBillData.transport}
              ></InputField>
              <NumberField
                label={globalLang.Exit_number[lang]}
                placeholder={globalLang.Exit_number[lang]}
                onChange={(e) => setControl("exit_permit", e.target.value)}
                value={controls.exit_permit}
                required={required.includes("exit_permit")}
                error={Boolean(invalid.exit_permit)}
                helperText={invalid.exit_permit}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={globalLang.BackDate[lang]}
                  value={controls.exit_date}
                  onChange={(newValue) => {
                    setControl("exit_date", newValue);
                  }}
                  error={Boolean(invalid.exit_date)}
                  helperText={invalid.exit_date}
                  required={required.includes("exit_date")}
                  renderInput={(params) => (
                    <InputField
                      sx={{
                        "& .MuiInputBase-root": {
                          borderColor: "#F0F0F8",
                          paddingRight: "5px",
                        },
                        "& .MuiFormLabel-root": {
                          color: "#666666",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Box>
              <Stack sx={{ gap: 2 }}>
                 <CustomTable
                  data={TraderEnterBillData?.bills}
                  type="cylinder"
                  columns={columns}
                  getFormData={getFormData}
                  info={info}
                  filling={true}
                />
                
                <CustomTable
                  data={TraderEnterBillData?.bills}
                  type="dewar"
                  columns={columns}
                  getFormData={getLiquidFormData}
                  info={info}
                  filling={true}
                /> 
              </Stack>
            </Box>
          </Stack>
        </Form>
      )}

      {TraderExitBillPostResponse.successAlert}
      {TraderExitBillPostResponse.failAlert}
    </>
  );
};
export default ExitFilling;

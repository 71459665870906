import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";

import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import {
 
  STOCKHISTORY,

} from "../../data/APIs";

import Form, { InputField } from "../../features/form";
import { TextField } from "@mui/material";

import useIsPermitted from "../../features/permissions/hook/useIsPermitted";



import ViewStock from "../Stock/ViewStocks";
import Breadcrumbs from "../../components/Breadcrumbs";

import format from "../../utils/ISOToReadable";
import Wrapper from "../../components/Wrapper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const StockHistory = () => {

  // Ghange lang
  const lang = useSelector((state) => state.lang.value.lang);;
  const globalLang = useSelector((state) => state.lang.value.global);

  const stockHistoryStore = useSelector((state) => state.stockHistory.value);
  
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday
  }
  //filter
  const [filterdata, setfilterdata] = useState({
    stock: "",
    Date: getDate(),
   
  })


  //columns
  const columns = [
    {
      field: "history_date",
      headerName: globalLang.dateOfProcedure[lang],
      customContent: ({ history_date }) =>
        history_date ? format(history_date) : "-",
    },

    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ product }) => (product ? product.name : "-"),
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
      customContent: ({ product_type }) => (product_type ? product_type === "cylinder"&&"اسطوانة"||product_type==="dewar"&&"دوار": "-"),
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
      customContent: ({ volume }) =>
        volume === 0 ? "0" : volume ? volume : "-",
    },
    {
      field: "factorypower",
      headerName: globalLang.factorypower[lang],
      customContent: ({ factorypower }) =>
        factorypower === 0 ? "0" : factorypower ? factorypower : "-",
    },
    {
      field: "empty_store",
      headerName: globalLang.empty[lang],
      customContent: ({ empty_store }) =>
        empty_store === 0 ? "0" : empty_store ? empty_store : "-",
    },
    {
      field: "filled_store",
      headerName: globalLang.filled[lang],
      customContent: ({ filled_store }) =>
        filled_store === 0 ? "0" : filled_store ? filled_store : "-",
    },
    {
      field: "outside",
      headerName: globalLang.outputs[lang],
      customContent: ({ outside }) => (outside ? outside : "0"),
    },
    // {
    //   field: "history_user",
    //   headerName: globalLang.editor[lang],
    //   customContent: ({ history_user }) =>
    //     history_user
    //       ? `${history_user.first_name} ${history_user.last_name}`
    //       : "",
    // },
    // {
    //   field: "history_type",
    //   headerName: globalLang.historytype[lang],
    //   customContent: ({ history_type }) =>
    //     history_type ? (history_type === "~" ? "تعديل" : "انشاء") : "-",
    // },
  ];

//requests
  const dispatch = useDispatch();
  
  const [StockHistoryGetRequest, stockHistoryGetResponse] = useRequest({
    path: STOCKHISTORY,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getHistory = () => {
    filterdata.stock && urlParams.append("id", filterdata.stock);
    if (filterdata.Date) {
      urlParams.append("date", String(filterdata.Date));
    }

    StockHistoryGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stock-history/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
    completed: [["completed", true]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getHistory();
  }, [requestParams, filterdata]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const handleResetFilter = () => {
    setfilterdata({
      Date: "",
      //   endDate: "",
    });
    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const isPermitted = useIsPermitted();

  return (
    <Wrapper>
      
     <ViewStock />
     
     <Breadcrumbs path={['سجل الرصيد  ', 'عرض']} />
      <Form
        childrenProps={{
          saveBtn: {
            sx: { display: "none" },
          },
          closeBtn: {
            onClick: handleResetFilter,
          },
        }}
      >
        {/* <AutocompleteField
            label={globalLang.product[lang]}
            sx={{
              width: {
                lg: "50%",
                md: "50%",
                xl: "50%",
                sm: "100%",
                xs: "100%",
              },
            }}
            isPending={stockgetResponse.isPending}
            onOpen={getStock}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata((old) => ({ ...old, stock: options.value }));
                  break;
                case "clear":
                  setfilterdata((old) => ({ ...old, stock: "" }));
              }
            }}
            data={[
              ...stocksStore.results.map((c, index) => ({
                label: c.product.name,
                value: c.id,
              })),
            ]}
            value={filterdata.id}
            // required={required.includes("Exit_Permission_Station_bill")}
          /> */}

        <TextField
          variant="standard"
          sx={{
            width: {
              lg: "50%",
              md: "50%",
              xl: "50%",
              sm: "100%",
              xs: "100%",
            },
          }}
          type="date"
          label={globalLang.date[lang]}
          value={filterdata.Date}
          onChange={(e) =>
            setfilterdata((old) => ({ ...old, Date: e.target.value }))
          }
        />
        {/* <TextField
              variant="standard"
              type="date"
              sx={{
                width: {
                  lg: "50%",
                  md: "50%",
                  xl: "50%",
                  sm: "100%",
                  xs: "100%",
                },
              }}
              label={globalLang.to[lang]}
              value={filterdata.endDate}
              disabled={filterdata.startDate ? false : true}
              onChange={(e) =>
                setfilterdata((old) => ({ ...old, endDate: e.target.value }))
              }
            /> */}
      </Form>
     
      <DataGrid
        columns={columns}
        rows={stockHistoryStore.results}
        total={stockHistoryStore.count}
        isPending={stockHistoryGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
      />
    </Wrapper>
  );
};

export default StockHistory;


import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

import AddTransport from "./AddTransport";
import ViewTransport from "./ViewTransport";
const TransportView = () => {
  const stocksStore = useSelector((state) => state.stocks.value);

  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorytransport"]}>
        <AddTransport />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factorytransport"]}>
        <ViewTransport />
      </PermissionsGate>
    </Wrapper>
  );
};
export default TransportView;

import React from "react";
import Wrapper from "../../../components/Wrapper";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";
import ExpensesReceipt from "./ExpensesReceipts";
import ViewExpensesReceipts from "./ViewExpensesReceipts";
const ExpensesReceiptView = () => {
  return (
    <Wrapper>
  <PermissionsGate permissions={['add_factoryexpense']}>
  <ExpensesReceipt />
  </PermissionsGate>
        
    <PermissionsGate permissions={['view_factoryexpense']}>
    <ViewExpensesReceipts />
    </PermissionsGate>
       
  
    </Wrapper>
  );
};
export default ExpensesReceiptView;

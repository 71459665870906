import React, { useEffect, useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";

import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";

import useRequest from "../../hooks/useRequest";
import {
  STATIONHISTORY,
  STATIONS,
  UNITS,
} from "../../data/APIs";
import { useDispatch, useSelector } from "react-redux";
import useConfirmMessage from "../../hooks/useConfirmMessage";

import useControls from "../../hooks/useControls";
import filter from "../../utils/ClearNull";
import PictureField from "../../features/form";
import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogContent,
  DialogForm,
  DialogHeading,
  DialogInfoWindow,
  DialogInputField,
  DialogPhoneField,
  DialogSelectField,
  DialogTable,
} from "../../features/dialog";
import useAfterEffect from "../../hooks/useAfterEffect";
import compare from "../../utils/Compare";
import { Box, Stack } from "@mui/system";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";


import format from "../../utils/ISOToReadable";
import PicturePicker from "../../components/PicturePicker";
import { IconButton } from "@mui/material";

const ViewStations = () => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const ViewStationsLang = useSelector(
    (state) => state.lang.value.ViewStations
  );
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.stationMessage
  );
  ///////////////////////////////
  const stationsStore = useSelector((state) => state.stations.value);
  console.log(stationsStore)
  const [stationDetails, setStationDetails] = useState({
    details: null,
    tab: "details",
  });
  const columns = [
 
    {
      field: "name",
      headerName: globalLang.name[lang],
      customContent: ({user}) => user?.name
    },
    {
      field: "username",
      headerName: globalLang.username[lang],
      customContent: ({user}) => {
        return user?.username;
      },
    },
    {
      field: "email",
      headerName: globalLang.email[lang],
      customContent: (params) => {
        return params?.user?.email ? params?.user?.email:"-";
      },
    },
   
  ];

  const dispatch = useDispatch();
  const [stationHisoryGetRequest, stationHistoryGetResponse] = useRequest({
    path: STATIONHISTORY,
    method: "get",
  });
  const [stationsGetRequest, stationsGetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    const urlParams = new URLSearchParams();

    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    stationsGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [stationDeleteRequest, stationDeleteResponse] = useRequest({
    path: STATIONS,
    method: "delete",
    successMessage: "تم حذف المحطة بنجاح",
  });

  const deleteStation = (e, row) => {
    stationDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "stations/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteStation, deleteStationConfirmDialog] = useConfirmMessage({
    onConfirm: deleteStation,
    text: confirmMessage[lang],
  });
  useAfterEffect(() => {
    if (!stationDetails.details) return;
    setStationDetails((old) => ({
      ...old,
      details: stationsStore.results.find((item) => item.id === old.details?.id),
    }));
  }, [stationsStore]);
  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "name",
      value: "",
      isRequired: false,
    },
    {
      control: "logo",
      value: "",
      isRequired: false,
    },
    {
      control: "website",
      value: "",
      isRequired: false,
    },

  ]);
  const getStationHistory = () => {
    stationHisoryGetRequest({
      params: {
        id: stationDetails.details.id,
      },
      onSuccess: (res) => {
        dispatch({ type: "station-history/set", payload: res.data });
      },
    });
  };
  const handleCloseDetailsDialog = () => {
    setStationDetails((old) => ({ ...old, details: null, tab: "details" }));
  };
  const handleFilter = () => {
    const requestBody = filter({
      obj: {
        name: controls.name,
        logo: controls.logo,
        url: controls.website,
        // state: controls.governorate,
        // city: controls.city,
        // area: controls.district,
        // part: controls.near,
        // flat_number: controls.number,
        // unit_type: controls.type,
        // sales_type: controls.sale,
        // floor_number: controls.floor,
        // area_size: controls.area,
        // room_number: controls.rooms,
        // bath_count: controls.bathrooms,
        // complete_type: controls.genre,
        // price: controls.price,
        // client: controls.client,
      },
    });

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody),
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========


  return (
    <>
      <Breadcrumbs
        path={[globalLang.stations[lang], ViewStationsLang.ViewStations[lang]]}
      />

      <DataGrid
        columns={columns}
        rows={stationsStore.results}
        total={stationsStore.count}
        isPending={stationsGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        // onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        // onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onDelete={isPermitted(handleDeleteStation, ["delete_factorystation"])}
        onEdit={isPermitted(
          (e, row) => setEditData(row),
          ["change_factorystation"]
        )}
        onView={isPermitted(
          (e, row) => {
            setStationDetails((old) => ({
              ...old,
              details: row,
            }));
          },['view_historicalfactorystation'])}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(stationDetails.details && stationDetails.tab === "details")}
        onOpen={getStationHistory}
        onClose={handleCloseDetailsDialog}
        data={Boolean(stationDetails.details) && stationDetails.details}
        isHistoryPending={stationHistoryGetResponse.isPending}
     
      />
      {deleteStationConfirmDialog}
      {stationDeleteResponse.successAlert}
      {stationDeleteResponse.failAlert}
    </>
  );
};

export default ViewStations;

const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "name",
        value: data?.user?.name,
        isRequired: false,
      },
      // {
      //   control: "logo",
      //   value: data?.logo,
      //   isRequired: false,
      // },
      {
        control: "website",
        value: data?.url,
        isRequired: false,
      },
      // {
      //   control: "picture",
      //   value: "",
      //   isRequired: false,
      // },
      // {
      //   control: "phone",
      //   value: data?.user?.phone.phone,
      //   isRequired: false,
      // },
      {
        control: "username",
        value: data?.user?.username,
        isRequired: false,
      },
      {
        control: "email",
        value: data?.user?.email,
        isRequired: false,
      },
      // {
      //   control: "driver_phone",
      //   value: data?.driver_phone?.phone,
      //   isRequired: false,
      // },
      // {
      //   control: "sales_phone",
      //   value: data?.sales_phone?.phone,
      //   isRequired: false,
      // },
      // {
      //   control: "accountant_phone",
      //   value: data?.accountant_phone?.phone,
      //   isRequired: false,
      // },

      // {
      //   control: "owner_code",
      //   value: data?.user?.phone.country_code,
      //   isRequired: false,
      // },
      // {
      //   control: "driver_code",
      //   value: data?.driver_phone?.country_code,
      //   isRequired: false,
      // },
      // {
      //   control: "sales_code",
      //   value: data?.sales_phone?.country_code,
      //   isRequired: false,
      // },
      // {
      //   control: "accountant_code",
      //   value: data?.accountant_phone?.country_code,
      //   isRequired: false,
      // },
      // {
      //   control: "fax_phone",
      //   value: data?.fax?.phone,
      //   isRequired: false,
      // },
      // {
      //   control: "fax_code",
      //   value: data?.fax?.country_code,
      //   isRequired: false,
      // },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
    
  }, [open]);


  const [stationPatchRequest, stationPatchResponse] = useRequest({
    path: STATIONS,
    method: "patch",
    successMessage: "تم تعديل المحطة بنجاح",
  });

  const dispatch = useDispatch();
const [preview, setpreview] = useState("");

  const handleSubmit = () => {

    const isThereChange = !compare(
      [
        [controls.name, data.user.first_name],
        // [controls.logo, data.logo],
        // [controls.website, data.url],
        [controls.owner_phone, data.user.phone.phone],
        [controls.owner_code, data.user.phone.country_code],
        [controls.driver_phone, data.driver_phone.phone],
        [controls.driver_code, data.driver_phone.country_code],
        [controls.accountant_phone, data.accountant_phone.phone],
        [controls.accountant_code, data.accountant_phone.country_code],
        [controls.sales_phone, data.sales_phone.phone],
        [controls.sales_code, data.sales_phone.country_code],
        [controls.fax_phone, data.fax.phone],
        [controls.fax_code, data.fax.country_code],
     
      ],
      true
    );

    if (isThereChange) {
    
      const requestBody = filter({
        obj: {
          user:{
              first_name:controls.name,
              phone:controls.owner_code+controls.phone
          },
          name: controls.name,
          url: controls.website,
          fax: controls.fax_code+controls.fax_phone,
          owner_phone: controls.owner_code + controls.owner_phone,
          driver_phone: controls.driver_code + controls.driver_phone,
          accountant_phone: controls.accountant_code + controls.accountant_phone,
          sales_phone: controls.sales_code + controls.sales_phone,
        
        },
        output: "object",
      });
      if(controls.logo !==data?.logo){
        requestBody.append('logo',controls.logo)
      }
      stationPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "stations/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };
 

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "100%" }}>
      <DialogHeading>{globalLang.editstationData[lang]}</DialogHeading>

      <DialogForm>
        {/* {controls.logo !==
          "https://sadakatcdn.cyparta.com/media/media/DefaultPhotos/defult.png" && (
          <Avatar
            src={controls.picture ? controls.picture : controls.logo}
            sx={{ borderRadius: "50%", width: "50px", height: "50px" }}
          ></Avatar>
        )}
   
        <PicturePicker
          description={globalLang.logo[lang]}
          picture={controls.picture}
          onChange={(e, path) => {
            setControl("logo", e.target.files[0]);
            setControl("picture", path);
          }}
        ></PicturePicker> */}

        <DialogInputField
          label={globalLang.name[lang]}
          placeholder={globalLang.name[lang]}
          value={controls.name}
          onChange={(e) => setControl("name", e.target.value)}
        />
        <DialogInputField
          label={globalLang.username[lang]}
          placeholder={globalLang.username[lang]}
          value={controls.username}
          onChange={(e) => setControl("username", e.target.value)}
        />
        <DialogInputField
          label={globalLang.email[lang]}
          placeholder={globalLang.email[lang]}
          value={controls.email}
          onChange={(e) => setControl("email", e.target.value)}
        />
        {/* <DialogPhoneField
          placeholder={globalLang.phone[lang]}
          label={globalLang.phone[lang]}
          selectProps={{
            value: controls.owner_code,
            onChange: (e) => setControl("owner_code", e.target.value),
          }}
          value={controls.phone}
          onChange={(e) => setControl("phone", e.target.value)}
        /> */}
        <DialogInputField
          placeholder={globalLang.website[lang]}
          label={globalLang.website[lang]}
          value={controls.website}
          onChange={(e) => setControl("website", e.target.value)}
        />

        {/* <DialogPhoneField
          placeholder={globalLang.fax[lang]}
          label={globalLang.fax[lang]}
          selectProps={{
            value: controls.fax_code,
            onChange: (e) => setControl("fax_code", e.target.value),
          }}
          value={controls.fax_phone}
          onChange={(e) => setControl("fax_phone", e.target.value)}
        />

        <DialogPhoneField
          placeholder={globalLang.driver_phone[lang]}
          label={globalLang.driver_phone[lang]}
          selectProps={{
            value: controls.driver_code,
            onChange: (e) => setControl("driver_code", e.target.value),
          }}
          value={controls.driver_phone}
          onChange={(e) => setControl("driver_phone", e.target.value)}
        />
        <DialogPhoneField
          placeholder={globalLang.accountant_phone[lang]}
          label={globalLang.accountant_phone[lang]}
          selectProps={{
            value: controls.accountant_code,
            onChange: (e) => setControl("accountant_code", e.target.value),
          }}
          value={controls.accountant_phone}
          onChange={(e) => setControl("accountant_phone", e.target.value)}
        />
        <DialogPhoneField
          placeholder={globalLang.sales_phone[lang]}
          label={globalLang.sales_phone[lang]}
          selectProps={{
            value: controls.sales_code,
            onChange: (e) => setControl("sales_code", e.target.value),
          }}
          value={controls.sales_phone}
          onChange={(e) => setControl("sales_phone", e.target.value)}
        /> */}
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={stationPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {stationPatchResponse.failAlert}
    </Dialog>
  );
};

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
  onTransferAgentClick = () => { },
  onCommentsClick = () => { },
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const viewClientsLang = useSelector((state) => state.lang.value.viewClients);
  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  const historyStore = useSelector((state) => state.station_history.value);

  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  // const [previewDatePatchRequest, previewDatePatchResponse] = useRequest({
  //   path: CLIENTS,
  //   method: "patch",
  // });

  // const handlePreviewSubmit = () => {
  //   previewDatePatchRequest({
  //     id: data.id,
  //     body: {
  //       user: {},
  //       followup: moment(selectedPreviewDate).format(),
  //     },
  //     onSuccess: (res) => {
  //       setPreviewDatePicker(null);
  //       dispatch({
  //         type: "clients/putItem",
  //         payload: { id: res.data.id, item: res.data },
  //       });
  //       data.followup = res.data.followup;
  //     },
  //   }).then(() => {
  //     setPreviewDatePicker(null);
  //   });
  // };

  //----variables----
  const info = [
    {
      name: globalLang.name[lang],
      value: `${data?.user?.name}`,
    },
    {
      name: globalLang.phones[lang],
      value: (<Stack >{data?.station_phone?.map((phone) => (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        {phone.name +" "+ "("+phone.position+")" + " " + ":" +" "+ phone.phone.country_code + " " + phone.phone.phone }      
         <IconButton
        sx={{ color: "white" }}
        onClick={() =>
          window.open(
            "https://wa.me/" +
            `${phone?.phone?.country_code}${phone?.phone?.phone}`,
            "_blank"
          )
        }
      >
        <WhatsAppIcon fontSize="small" sx={{ color: "#5ef979" }} />
      </IconButton>
        <IconButton
          sx={{ color: "white" }}
          onClick={() =>
            window.open(
              "https://www.truecaller.com/search/eg/" +
              `${phone?.phone?.country_code}${[phone]?.phone?.phone}`,
              "_blank"
            )
          }
        >
          <CallIcon fontSize="small" sx={{ color: "#127fff" }} />
        </IconButton></Box>))}</Stack>),
      // addition: (
      //   <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      //     <IconButton
      //       sx={{ color: "white" }}
      //       onClick={() =>
      //         window.open(
      //           "https://wa.me/" +
      //           `${data?.user?.country_code}${data?.user?.phone}`,
      //           "_blank"
      //         )
      //       }
      //     >
      //       <WhatsAppIcon fontSize="small" sx={{ color: "#5ef979" }} />
      //     </IconButton>
      //     <IconButton
      //       sx={{ color: "white" }}
      //       onClick={() =>
      //         window.open(
      //           "https://www.truecaller.com/search/eg/" +
      //           `${data?.user?.country_code}${data?.user?.phone}`,
      //           "_blank"
      //         )
      //       }
      //     >
      //       <CallIcon fontSize="small" sx={{ color: "#127fff" }} />
      //     </IconButton>
      //   </Box>
      // ),
    },
    // {
    //   name: globalLang.product[lang],
    //   value: data?.business?.map((product) => product?.name)?.join(" ، "),
    // },
    // {
    //   name: globalLang.adChannel[lang],
    //   value: data?.channel?.name,
    // },

    {
      name: globalLang.createdAt[lang],
      value: data?.created_at ? format(data?.created_at) : "",
      customEmpty: globalLang.notFound[lang],
    },

  ];

  const columns = [
    // {
    //   field: "products",
    //   headerName: globalLang.products[lang],
    //   customContent: ({ rowData }) => `${rowData?.bussiness?.join(" ، ")}`,
    // },
    // {
    //   field: "channel",
    //   headerName: globalLang.channel[lang],
    //   customContent: ({ rowData }) =>rowData?.channel ? rowData?.channel : "-",
    // },
    // {
    //   field: "agent",
    //   headerName: globalLang.employee[lang],
    // },
    {
      field: "history_user",
      headerName: globalLang.editor[lang],
      customContent: ({ rowData }) =>
        rowData.history_user
          ? `${rowData.history_user.first_name} ${rowData.history_user.last_name}`
          : "",
    },
    // {
    //   field: "store_price",
    //   headerName: globalLang.store_price[lang],
    // },
    {
      field: "history_type",
      headerName: globalLang.historytype[lang],
      customContent: ({ rowData }) =>
        rowData?.history_type
          ? rowData?.history_type === "~"
            ? "تعديل"
            : "انشاء"
          : "-",
    },
    {
      field: "history_date",
      headerName: globalLang.dateOfProcedure[lang],
      customContent: ({ rowData }) =>
        rowData.history_date ? format(rowData.history_date) : "-",
    },
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
    onOpen();
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeading>{globalLang.stationDetails[lang]}</DialogHeading>
      <DialogInfoWindow information={info} />
      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.stationRecord[lang]}</DialogHeading>
        <DialogTable
          rows={historyStore.results}
          columns={columns}
          isPending={isHistoryPending}
        />
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};


import { Box } from "@mui/material";
import React from "react";
import addBalance from'../../assets/addBalance.png'
import ReadingCounter from'../../assets/ReadingCounter.png'
;
import ReceiptBox from "../../components/ReceiptBox";
import ReadingsBox from "./ReadingsBox";
import { useSelector } from "react-redux";
const ReadingsPermissions = () => {
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
  const boxes = [
    {
      image: addBalance,
      title: "الرصيد",
      index:0 ,
      perm: ["add_practicalstock","add_factoryloans"],
      type: "readings",
    },
  
    {
      image: ReadingCounter,
      title: "قراءة العداد",
      index: 1,
      perm: ["add_factorytankreadings","view_factorytankreadings"],
      type: "readings",
    },
   
    // {
    //   image: tank,
    //   title: "التنك",
    //   index: 2,
    //   perm: ["view_factorystock"],
    //   type: "perms",
    // },

  ];

  return (
    <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
    </Box>
  );
};
export default ReadingsPermissions;

import React from "react";

import Wrapper from "../../components/Wrapper";
import PermissionsGate from "../../features/permissions/components/PermissionsGate";

import AddStations from "./AddStations";
import ViewStations from "./ViewStations";
const StationsView = () => {


  return (
    <Wrapper>
      <PermissionsGate permissions={["add_factorystation"]}>
        <AddStations />
      </PermissionsGate>
      <PermissionsGate permissions={["view_factorystation"]}>
        <ViewStations />
      </PermissionsGate>
    </Wrapper>
  );
};
export default StationsView;

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DataGrid from "../../../components/DataGrid";

import Print from "../../../components/PrintComponent";
import {

  EMPLOYEES,
  PRODUCTS,

  TRADEREXITBILL,
} from "../../../data/APIs";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from "@mui/material";

import Dialog, {
  DialogButton,
  DialogButtonsGroup,
  DialogForm,
  DialogHeading,

  DialogTable,
} from "../../../features/dialog";
import { InputField, SelectField } from "../../../features/form";
import useIsPermitted from "../../../features/permissions/hook/useIsPermitted";
import useAfterEffect from "../../../hooks/useAfterEffect";
import useConfirmMessage from "../../../hooks/useConfirmMessage";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import format from "../../../utils/ISOToReadable";
import compare from "../../../utils/Compare";
import filter from "../../../utils/ClearNull";
import PermissionsGate from "../../../features/permissions/components/PermissionsGate";

import { MenuItem, Stack, TextField} from "@mui/material";
import EditReceiptDialog from "../../../components/EditReceiptDialog";
import { UUIDFilter } from "../Distribution/ViewAwayDistribution";
import ErrorIcon from "@mui/icons-material/Error";
const ViewExitFilling = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [inform, setinform] = useState({ uuid: "", status: false });
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.BillMessage
  );
  const [TraderExitBillDetails, setTraderExitBillDetails] =
    useState({
      details: null,
      tab: "details",
    });
     const [editData, setEditData] = useState(null);
  ///////////////////////////////

  const TraderExitBillStore = useSelector(
    (state) => state.trader_exit_bill.value
  );
  const [TraderExitBillGetRequest, TraderExitBillGetResponse] = useRequest({
    path: TRADEREXITBILL,
    method: "get",
  });

  const columns = [
    {
      field: "exit_permit_uuid",
      headerName: globalLang.receiptnumber[lang],
    },
    {
      field: "driver",
      headerName: globalLang.driver[lang],
    },
    {
      field: "transport",
      headerName: globalLang.transportation[lang],
    },

    {
      field: "enter_date",
      headerName: globalLang.enter_date[lang],
      customContent: ({ enter_date }) => format(enter_date),
      customEmpty: "-",
    },
    {
      field: "exit_date",
      headerName: globalLang.exit_date[lang],
      customContent: ({ exit_date }) =>
        exit_date !== null ? format(exit_date) : "-",
      customEmpty: "-",
    },
  ];

  const dispatch = useDispatch();
  const handleCloseDetailsDialog = () => {
  setTraderExitBillDetails((old) => ({
      ...old,
      details: null,
      tab: "details",
    }));
  };

  const urlParams = new URLSearchParams();
  const getTraderExitBill = () => {

    TraderExitBillGetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "trader_exit_bill/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
    completed:[["completed",true]]
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getTraderExitBill();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [ReceiptDeleteRequest, ReceiptDeleteResponse] = useRequest({
    path: TRADEREXITBILL,
    method: "delete",
    successMessage: "تم حذف الفاتورة بنجاح",
  });

  const deleteReceipt = (e, row) => {
    ReceiptDeleteRequest({
    
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "trader_exit_bill/deleteItem", payload: { id: row.id } });
      },
    }).then((res) => {
      if (res.response) {
        setinform({ uuid: row.exit_permit_uuid, status: true });
      }
    });
  };

  const [handleDeleteReceipt, deleteReceiptConfirmDialog] = useConfirmMessage({
    onConfirm: deleteReceipt,
    text: confirmMessage[lang],
  });



  const handleFilter = (filters) => {
    setRequestParams((old) => ({
      filters: filters.map(({ query }) => query),
      completed: old.completed,
    }));
  };
  const setOpen = () => {
    setinform({ status: false, uuid: "" });
  };
  const filters = [
    {
      name: globalLang.receiptnumber[lang],
      component: <UUIDFilter />,
    },
  ];
  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };
  useAfterEffect(() => {
    if (!TraderExitBillDetails.details) return;
    setTraderExitBillDetails((old) => ({
      ...old,
      details: TraderExitBillStore.results.find(
        (item) => item.id === old.details?.id
      ),
    }));
  }, [TraderExitBillStore]);

  const isPermitted = useIsPermitted();

 

  return (
    <>
     {inform.status && <InformDialog uuid={inform.uuid} setopen={setOpen} />}
      {/* <Breadcrumbs
        path={[
          globalLang.fillreceipt[lang],
          globalLang.Exit_Permission[lang],
          globalLang.view[lang],
        ]}
      /> */}
      <DataGrid
        columns={columns}
        rows={TraderExitBillStore.results}
        total={TraderExitBillStore.count}
        isPending={TraderExitBillGetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        onFilter={handleFilter}
        onDelete={isPermitted(handleDeleteReceipt,['delete_factorytraderpermit']) }
        onView={isPermitted((e, row) => {
          setTraderExitBillDetails((old) => ({
            ...old,
            details: row,
          }));
        },['view_factorytraderproduct'])}
        onEdit={isPermitted((e, row) => setEditData(row),['change_factorytraderpermit','change_factorytraderproduct']) }
        filters={filters}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      <InfoDialog
        open={Boolean(
          TraderExitBillDetails.details &&
            TraderExitBillDetails.tab === "details"
        )}
        onClose={handleCloseDetailsDialog}
        data={
          Boolean(TraderExitBillDetails.details) &&
          TraderExitBillDetails.details
        }
      />
      {deleteReceiptConfirmDialog}
      {ReceiptDeleteResponse.successAlert}
      {ReceiptDeleteResponse.failAlert}
    </>
  );
};

export default ViewExitFilling;

export const InfoDialog = ({
  data,
  isHistoryPending,
  open,
  onOpen,
  onClose,
}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);

  const globalLang = useSelector((state) => state.lang.value.global);

  //////////////////////////////////
  //----store----
  useEffect(() => {
    console.log(data);
  }, []);
  const dispatch = useDispatch();

  //====Start===== Preview Date Logic ===============

  const [previewDatePicker, setPreviewDatePicker] = useState(null);
  const openPreviewPicker = Boolean(previewDatePicker);
  const [selectedPreviewDate, setSelectedPreviewDate] = useState("");

  //   {
  //     name: globalLang.product[lang],
  //     // value: `${data?.station_bills.product}`,
  //   },
  //   {
  //     name: globalLang.driver[lang],
  //     value: `${data?.driver ? data?.driver.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.AwayDate[lang],
  //     value: data?.away_date ? format(data?.away_date) : "-",
  //   },
  //   {
  //     name: globalLang.transportation[lang],
  //     value: data?.transport ? data?.transport?.name : "-",
  //   },
  //   {
  //     name: globalLang.product[lang],
  //     value: `${data?.product ? data?.product?.name : "-"}`,
  //   },
  //   {
  //     name: globalLang.product_type[lang],
  //     value: `${data?.product_type ? data?.product_type : "-"}`,
  //   },
  //   {
  //     name: globalLang.away_quantity[lang],
  //     value: `${data?.away_quantity ? data?.away_quantity : "-"}`,
  //   },
  //   {
  //     name: globalLang.BackDate[lang],
  //     value: `${data?.home_date ? format(data?.home_date) : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_empty[lang],
  //     value: `${data?.home_quantity_empty ? data?.home_quantity_empty : "-"}`,
  //   },
  //   {
  //     name: globalLang.home_quantity_filled[lang],
  //     value: `${data?.home_quantity_filled ? data?.home_quantity_filled : "-"}`,
  //   },
  //   {
  //     name: globalLang.total_price[lang],
  //     value: `${data?.total_price ? data?.total_price : "-"}`,
  //   },

  // ];

  const columns = [
    {
      field: "product_name",
      headerName: globalLang.product[lang],
    },

    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
    },
    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },

    {
      field: "enter_empty",
      headerName: globalLang.enter_empty[lang],
    },
    {
      field: "enter_filled",
      headerName: globalLang.enter_filled[lang],
    },
    {
      field: "exit_empty",
      headerName: globalLang.exit_empty[lang],
    },
    {
      field: "exit_filled",
      headerName: globalLang.exit_filled[lang],
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
    },

   
  ];

  //----effects----
  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      {/* <DialogHeading>{globalLang.billDetails[lang]}</DialogHeading> */}
      {/* <DialogInfoWindow information={info} /> */}

      <PermissionsGate permissions={[]}>
        <DialogHeading>{globalLang.gas[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "cylinder"
            )}
            columns={columns}
          />
        </Print>
        <DialogHeading>{globalLang.liquid[lang]}</DialogHeading>
        <Print
          sx={{
            background: "white",
            color: "#233975",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
        >
          <DialogTable
            rows={data?.bills?.filter(
              (bill) => bill?.product_type === "dewar"
            )}
            columns={columns}
          />
        </Print>
      </PermissionsGate>
      <DialogButtonsGroup>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
    </Dialog>
  );
};
const DriverFilter = ({ value, onChange }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////

  const [drivers, setDrivers] = useState([]);

  const handleChange = (e) => {
    onChange({
      query: ["driver", e.target.value],
      renderedValue: drivers.find((p) => p.id === e.target.value)?.user
        .username,
      value: e.target.value,
    });
  };

  const [employeesGetRequest, employeesGetResponse] = useRequest({
    path: EMPLOYEES,
    method: "get",
  });

  const getEmployees = () => {
    employeesGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setDrivers(res.data.results);
      },
    });
  };

  return (
    <SelectField
      placeholder={globalLang.driver[lang]}
      renderValue={(selected) =>
        drivers.find((p) => p.id === selected).user?.username
      }
      onOpen={getEmployees}
      isPending={employeesGetResponse.isPending}
      onChange={handleChange}
    >
      {drivers.map(
        (driver, index) =>
          driver.job.title === "سواق" && (
            <MenuItem value={driver.id} key={`${index}`}>
              {driver?.user?.username}
            </MenuItem>
          )
      )}
    </SelectField>
  );
};
const HomeDateFilter = ({ value = { start: "", end: "" }, onChange } = {}) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [startPoint, setStartPoint] = useState("");
  const [endPoint, setEndPoint] = useState("");

  const formatDate = (date) => {
    return date?.split("-").reverse().join("/");
  };

  useEffect(() => {
    if (!startPoint && !endPoint) return;
    onChange({
      query: [
        "home_date",
        `${formatDate(String(startPoint))}-${formatDate(String(endPoint))}`,
      ],
      renderedValue: `${globalLang.from[lang]} ${formatDate(
        String(startPoint)
      )} - ${globalLang.to[lang]} ${formatDate(String(endPoint))}`,
      value: {
        start: startPoint,
        end: endPoint,
      },
    });
  }, [startPoint, endPoint]);

  const handleChangeStartPoint = (e) => {
    setStartPoint(e.target.value);
  };

  const handleChangeEndPoint = (e) => {
    setEndPoint(e.target.value);
  };

  return (
    <Stack spacing={2}>
      <TextField
        variant="standard"
        type="date"
        label={globalLang.from[lang]}
        value={value.start}
        onChange={handleChangeStartPoint}
      />
      <TextField
        variant="standard"
        type="date"
        label={globalLang.to[lang]}
        value={value.end}
        onChange={handleChangeEndPoint}
      />
    </Stack>
  );
};
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [Products, setProducts] = useState([]);
  const dispatch = useDispatch();
   const [FormData, setFormData] = useState([]);
   const getFormData = (data) => {
     setFormData([...data]);
   };
  ///////////////////////////////
  const columns = [
    {
      field: "enter_empty",
      headerName: globalLang.enter_empty[lang],
    },
    {
      field: "enter_filled",
      headerName: globalLang.enter_filled[lang],
    },
    {
      field: "product_name",
      headerName: globalLang.product[lang],
    },
    {
      field: "product_type",
      headerName: globalLang.product_type[lang],
    },

    {
      field: "volume",
      headerName: globalLang.volume[lang],
    },

    {
      field: "exit_empty",
      headerName: globalLang.exit_empty[lang],
    },
    {
      field: "exit_filled",
      headerName: globalLang.exit_filled[lang],
    },
    {
      field: "fill_type",
      headerName: globalLang.fill_type[lang],
    },
    {
      field: "quantityreturned",
      headerName: globalLang.returned[lang],
    },
  ];

  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls(
    [
      {
        control: "exit_date",
        value: data?.exit_date,
        isRequired: false,
      },
      {
        control: "bill_id",
        value: "",
        isRequired: false,
      },
      {
        control: "bills",
        value: [],
        isRequired: false,
      },
    ],
    [data]
  );
  const EditableColumns = ["exit_empty",'exit_filled', "quantityreturned"];
  const [station_data, setData] = useState(data);
  useEffect(() => {
    data?.bills.map((bill, billindex) => {
      setControl("bills", (old) => [
        ...old,
        {
          exit_empty: bill["exit_empty"],
          exit_filled: bill["exit_filled"],
          quantityreturned: bill["quantityreturned"],
        },
      ]);
    });
  }, [data?.bills]);

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);
  const [productsGetRequest, productsGetResponse] = useRequest({
    path: PRODUCTS,
    method: "get",
  });

  const getProducts = () => {
    productsGetRequest({
      params: {
        size: 1000,
      },
      onSuccess: (res) => {
        setProducts(res.data.results);
      },
    });
  };
  useEffect(() => {
    setData(data);
  }, [data]);
  const handleChange = (e, index, id) => {
    let data = [...controls.bills];
    data[index] = { ...data[index], [e.target.name]: parseInt(e.target.value) };
    setControl("bills", data);
  };

  const handleEditBill = (id, index) => {
    const requestBody = filter({
      obj: {
        bill_id: id,
        bills: [FormData[index]],
      },
      output: "object",
    });

    TraderExitBillPatchRequest({
      id: station_data.id,
      body: requestBody,
      onSuccess: (res) => {
        dispatch({
          type: "trader_exit_bill/putItem",
          payload: { id: res.data.id, item: res.data },
        });
        onClose();
      },
    });
  };
  console.log(controls.bills);

  const [inform, setinform] = useState(false);
  const [TraderExitBillPatchRequest, TraderExitBillPatchResponse] =
    useRequest({
      path: TRADEREXITBILL,
      method: "patch",
      successMessage: "تم التعديل بنجاح",
    });

  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    validate().then((output) => {
      if (!output.isOk) return;
      const isThereChange = !compare(
        [[controls.exit_date, data.exit_date]],
        true
      );

      if (isThereChange) {
        const requestBody = filter({
          obj: {
            exit_date: controls.exit_date.toISOString(),
          },
          output: "object",
        });

        TraderExitBillPatchRequest({
          id: data.id,
          body: requestBody,
          onSuccess: (res) => {
            dispatch({
              type: "trader_exit_bill/putItem",
              payload: { id: res.data.id, item: res.data },
            });
            onClose();
          },
        });
      }
    });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        paperProps={{ height: "auto", overflowX: "hidden" }}
        onOpen={getProducts}
      >
        <DialogHeading>{globalLang.editbillData[lang]}</DialogHeading>

        <DialogForm>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label={globalLang.exit_date[lang]}
              value={controls.exit_date}
              onChange={(newValue) => {
                setControl("exit_date", newValue);
              }}
              renderInput={(params) => (
                <InputField
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": {
                      // borderColor: "#F0F0F8",
                      paddingRight: "5px",
                      fontWeight: "400",
                      background: "#fff",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#fff",
                    },
                  }}
                  required={required.includes("exit_date")}
                  error={Boolean(invalid.exit_date)}
                  helperText={invalid.exit_date}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <EditReceiptDialog
            data={data?.bills}
            columns={columns}
            EditbleColumns={EditableColumns}
            id={data?.id}
            handleEditBill={handleEditBill}
            getData={getFormData}
          />
        </DialogForm>

        <DialogButtonsGroup>
          <DialogButton
            onClick={handleSubmit}
            // disabled={stationExitBillPatchResponse.isPending}
          >
            {globalLang.save[lang]}
          </DialogButton>
          <DialogButton variant="close" onClick={onClose}>
            {globalLang.cancel[lang]}
          </DialogButton>
        </DialogButtonsGroup>
        {TraderExitBillPatchResponse.failAlert}
      </Dialog>
    </>
  );
};
const InformDialog = ({ uuid, setopen = () => {} }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setopen();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ width: "30%",margin:'auto' }}
    >
      <DialogTitle>
        <ErrorIcon htmlColor="red" fontSize="medium" /> حدث خطأ أثناء عملية
        الحذف
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <h4 style={{ color: "white",fontWeight:"normal" }}>
            برجاء حذف فواتير التعبئة برقم خروج {uuid} أولا.
          </h4>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <DialogButton onClick={handleClose}>تم</DialogButton>
      </DialogActions>
    </Dialog>
  );
};
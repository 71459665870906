import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useRequest from "../../hooks/useRequest";
import { TANK } from "../../data/APIs";
import { useEffect, useState } from "react";
import useConfirmMessage from "../../hooks/useConfirmMessage";
import useControls from "../../hooks/useControls";
import filter from "../../utils/ClearNull";
import useIsPermitted from "../../features/permissions/hook/useIsPermitted";
import Wrapper from "../../components/Wrapper";
import Breadcrumbs from "../../components/Breadcrumbs";
import DataGrid from "../../components/DataGrid";
import useAfterEffect from "../../hooks/useAfterEffect";
import compare from "../../utils/Compare";
import Dialog, { DialogButton, DialogButtonsGroup, DialogForm, DialogHeading, DialogNumberField } from "../../features/dialog";


const ViewTankControl = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const viewUnitsLang = useSelector((state) => state.lang.value.viewUnits);
  const globalLang = useSelector((state) => state.lang.value.global);
  const ViewTanksLang = useSelector((state) => state.lang.value.ViewTank);
  const confirmMessage = useSelector(
    (state) => state.lang.value.DeleteMessages.TankMessage
  );

  ///////////////////////////////
  const tanksStore = useSelector((state) => state.tank.value);

  const columns = [
    {
      field: "tank_uuid",
      headerName: globalLang.tanknumber[lang],
      customContent: ({ tank_uuid }) => (tank_uuid === 0 ? "0" : tank_uuid),
    },
    {
      field: "product",
      headerName: globalLang.product[lang],
      customContent: ({ product }) => product?.name,
    },
    {
      field: "size",
      headerName: globalLang.volume[lang],
      customContent: ({ size }) => size ? size : 0,
    },

    {
      field: "filled",
      headerName: globalLang.filled_liter[lang],
      customContent: ({ filled }) => (filled === 0 ? "0" : filled),
    },

    {
      field: "filled_kg",
      headerName: globalLang.filled_kg[lang],
      customContent: ({ filled_kg }) => (filled_kg === 0 ? "0" : filled_kg),
    },
    {
      field: "maximum",
      headerName: globalLang.maximum[lang],
      customContent: ({ maximum }) => (maximum === 0 ? "0" : maximum),
    },
    // {
    //   field: "empty_store",
    //   headerName: globalLang.empty[lang],
    //   customContent: ({ empty_store }) =>
    //     empty_store === 0 ? "0" : empty_store,
    // },
    // {
    //   field: "filled_store",
    //   headerName: globalLang.filled[lang],
    //   customContent: ({ filled_store }) =>
    //     filled_store === 0 ? "0" : filled_store,
    // },
    // {
    //   field: "total_store",
    //   headerName: globalLang.raseef[lang],
    //   customContent: ({ total_store }) => (total_store ? total_store : "0"),
    // },
    // {
    //   field: "outside",
    //   headerName: globalLang.outputs[lang],
    //   customContent: ({ outside }) => (outside ? outside : "0"),
    // },
    // {
    //   field: "created_at",
    //   headerName: globalLang.createdAt[lang],
    //   customContent: (params) => {
    //     return `${params?.created_at ? format(params?.created_at) : "-"}`;
    //   },
    // },
  ];

  const dispatch = useDispatch();

  const [tankgetRequest, tankgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTanks = () => {
    tankgetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };

  const [requestParams, setRequestParams] = useState({
    currentPage: [["page", 1]],
  });

  useEffect(() => {
    Object.values(requestParams).map((item) =>
      item.map(([key, value]) => urlParams.append(key, value))
    );

    getTanks();
  }, [requestParams]);

  const handlePaginate = (params) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", params.current]],
    }));
  };

  const handleChangeAmount = ({ value }) => {
    setRequestParams((old) => ({
      ...old,
      currentPage: [["page", 1]],
      amount: [["size", value]],
    }));
  };

  const [tankDeleteRequest, tanksDeleteResponse] = useRequest({
    path: TANK,
    method: "delete",
    successMessage: "تم حذف التانك بنجاح",
  });

  const deleteTank = (e, row) => {
    tankDeleteRequest({
      id: row.id,
      onSuccess: (res) => {
        dispatch({ type: "tank/deleteItem", payload: { id: row.id } });
      },
    });
  };

  const [handleDeleteTank, deleteTankConfirmDialog] = useConfirmMessage({
    onConfirm: deleteTank,
    text: confirmMessage[lang],
  });

  const [{ controls }, { setControl, resetControls }] = useControls([
    {
      control: "client",
      value: "",
      isRequired: false,
    },
    {
      control: "factory",
      value: "",
      isRequired: false,
    },
    {
      control: "product",
      value: "",
      isRequired: false,
    },
    {
      control: "product_type",
      value: "",
      isRequired: false,
    },
  ]);

  const handleFilter = () => {
    const requestBody = filter({
      obj: {
        name: controls.name,
        logo: controls.logo,
        url: controls.website,
        // state: controls.governorate,
        // city: controls.city,
        // area: controls.district,
        // part: controls.near,
        // flat_number: controls.number,
        // unit_type: controls.type,
        // sales_type: controls.sale,
        // floor_number: controls.floor,
        // area_size: controls.area,
        // room_number: controls.rooms,
        // bath_count: controls.bathrooms,
        // complete_type: controls.genre,
        // price: controls.price,
        // client: controls.client,
      },
    });

    setRequestParams((old) => ({
      ...old,
      filters: Object.entries(requestBody),
    }));
  };

  const handleResetFilter = () => {
    resetControls();

    setRequestParams((old) => ({
      ...old,
      filters: [],
    }));
  };

  const [editData, setEditData] = useState(null);

  const [infoData, setInfoData] = useState(null);

  const isPermitted = useIsPermitted();

  //===Start===== Get Countries logic =========
  const [countriesData, setCountriesData] = useState([]);

//   const [countriesGetRequest, countriesGetResponse] = useRequest({
//     path: COUNTRY_FILTER,
//     method: "get",
//   });
// 
  //   const getCountries = () => {
  //     countriesGetRequest({
  //       onSuccess: (res) => {
  //         setCountriesData(res.data.data);
  //       },
  //     });
  //   };

  //   const [governoratesData, setGovernoratesData] = useState([]);

  //   const [statesGetRequest, statesGetResponse] = useRequest({
  //     path: STATES,
  //     method: "post",
  //   });

  //   const getGovernorates = () => {
  //     statesGetRequest({
  //       body: {
  //         country: controls.country,
  //       },
  //       onSuccess: (res) => {
  //         setGovernoratesData(res.data.data);
  //       },
  //     });
  //   };

  //===End===== Get Countries logic =========

  return (
    <Wrapper>
      <Breadcrumbs
        path={[globalLang.tanks[lang], ViewTanksLang.ViewTank[lang]]}
      />

      <DataGrid
        columns={columns}
        rows={tanksStore.results}
        total={tanksStore.count}
        isPending={tankgetResponse.isPending}
        onPaginate={handlePaginate}
        onAmountChange={handleChangeAmount}
        // onDelete={isPermitted(handleDeleteUnit, ["delete_aqarunit"])}
        // onEdit={isPermitted((e, row) => setEditData(row), ["change_aqarunit"])}
        onDelete={isPermitted(handleDeleteTank,['delete_factorytank']) }
        onEdit={isPermitted(
          (e, row) => setEditData(row),
          ["change_factorytank"]
        )}
        // onView={(e, row) => setInfoData(row)}
      />
      <EditDialog
        open={Boolean(editData)}
        onClose={() => setEditData(null)}
        data={editData}
      />
      {/* <InfoDialog
        open={Boolean(infoData)}
        onClose={() => setInfoData(null)}
        data={infoData}
      /> */}
      {deleteTankConfirmDialog}
      {tanksDeleteResponse.successAlert}
      {tanksDeleteResponse.failAlert}
    </Wrapper>
  );
};

export default ViewTankControl;
const EditDialog = ({ open = false, onClose = () => {}, data = {} }) => {
  // Change lang
  const lang = useSelector((state) => state.lang.value.lang);
  const globalLang = useSelector((state) => state.lang.value.global);

  ///////////////////////////////
  const [{ controls }, { setControl, resetControls }] = useControls(
    [
      {
        control: "size",
        value: data?.size,
        isRequired: false,
      },
   
      // {
      //   control: "store",
      //   value: data?.store,
      //   isRequired: false,
      // },
    ],
    [data]
  );

  useAfterEffect(() => {
    if (!open) return;
  }, [open]);

  const [tankPatchRequest, tankPatchResponse] = useRequest({
    path: TANK,
    method: "patch",
    successMessage: "تم التعديل بنجاح",
  });

  const dispatch = useDispatch();
  const [preview, setpreview] = useState("");

  const handleSubmit = () => {
    const isThereChange = !compare(
      [
        [controls.size, data.size],
        [controls.rate, data.rate],
      ],
      true
    );

    if (isThereChange) {
      const requestBody = filter({
        obj: {
          size: controls.size,
          rate: controls.rate,

         
        },
        output: "object",
      });

      tankPatchRequest({
        id: data.id,
        body: requestBody,
        onSuccess: (res) => {
          dispatch({
            type: "tank/putItem",
            payload: { id: res.data.id, item: res.data },
          });
          onClose();
        },
      });
    }
  };
  //===Start===== Get Countries logic =========
  // const [countriesData, setCountriesData] = useState([]);

  // const [countriesGetRequest, countriesGetResponse] = useRequest({
  //   path: COUNTRY_FILTER,
  //   method: "get",
  // });

  // const getCountries = () => {
  //   if (countriesData.length) return;
  //   countriesGetRequest({
  //     onSuccess: (res) => {
  //       setCountriesData(res.data.data);
  //     },
  //   });
  // };

  // const [governoratesData, setGovernoratesData] = useState([]);

  // const [statesGetRequest, statesGetResponse] = useRequest({
  //   path: STATES,
  //   method: "post",
  // });

  // const getGovernorates = () => {
  //   if (governoratesData.length) return;
  //   statesGetRequest({
  //     body: {
  //       country: controls.country,
  //     },
  //     onSuccess: (res) => {
  //       setGovernoratesData(res.data.data);
  //     },
  //   });
  // };

  //===End===== Get Countries logic =========

  return (
    <Dialog open={open} onClose={onClose} paperProps={{ height: "auto" }}>
      <DialogHeading>{globalLang.edit_tank[lang]}</DialogHeading>

      <DialogForm>
        <DialogNumberField
          label={globalLang.volume[lang]}
          placeholder={globalLang.volume[lang]}
          value={controls.size}
          onChange={(e) => setControl("size", e.target.value)}
        />
      </DialogForm>

      <DialogButtonsGroup>
        <DialogButton
          onClick={handleSubmit}
          disabled={tankPatchResponse.isPending}
        >
          {globalLang.save[lang]}
        </DialogButton>
        <DialogButton variant="close" onClick={onClose}>
          {globalLang.cancel[lang]}
        </DialogButton>
      </DialogButtonsGroup>
      {tankPatchResponse.failAlert}
    </Dialog>
  );
};

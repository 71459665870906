import { Box, MenuItem } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Wrapper from "../../../components/Wrapper";
import {
  CLIENTS,
  COUNTRY_FILTER,
  PRODUCTS,
  STATES,
  STATIONS,
  STOCK,
  TANK,
  TANKSHIPRECETPI,
} from "../../../data/APIs";
import Form, {
  InputField,
  NumberField,
  PhoneField,
  PictureField,
  SelectField,
} from "../../../features/form";
import useControls from "../../../hooks/useControls";
import useRequest from "../../../hooks/useRequest";
import filter from "../../../utils/ClearNull";
import format from "../../../utils/ISOToReadable";
import AutocompleteField from "../../../features/form/components/AutocompleteField";

const AddTankReceipt = () => {
  const lang = useSelector((state) => state.lang.value.lang);
  const addStockLang = useSelector((state) => state.lang.value.addStock);
  const globalLang = useSelector((state) => state.lang.value.global);
  const [responsebody, setResponse] = useState([]);
  const stationStore = useSelector((state) => state.stations.value);
  const tanksStore = useSelector((state) => state.tank.value);
  const [preview, setpreview] = useState("");
  ///////////////////////////////
  const getDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;
    return formattedToday
  }
  const [filterdata, setfilterdata] = useState({
    station: "",
    Date: getDate(),
   
  })
  const dispatch = useDispatch();
  const [
    { controls, invalid, required },
    { setControl, resetControls, setInvalid, validate },
  ] = useControls([
    {
      control: "bill_uuid",
      value: "",
      isRequired: true,
    },
    {
      control: "station",
      value: "",
      isRequired: true,
    },
    {
      control: "tank",
      value: "",
      isRequired: true,
    },

    {
      control: "empty",
      value: "",
      isRequired: true,
    },

    {
      control: "filled",
      value: "",
      isRequired: true,
    },
    {
      control: "price",
      value: "",
      isRequired: true,
    },
    {
      control: "bill_date",
      value: new Date(),
      isRequired: true,
    },
    {
      control: "paid",
      value: "",
      isRequired: true,
    },
    {
      control: "dokhan",
      value: "",
      isRequired: true,
    },
  ]);

  const [TankReceiptPostRequest, TankReceiptPostResponse] = useRequest({
    path: TANKSHIPRECETPI,
    method: "post",
    successMessage: "تم الاضافة ",
  });

  const [stationgetRequest, stationsgetResponse] = useRequest({
    path: STATIONS,
    method: "get",
  });
  const getStations = () => {
    if (Boolean(stationStore.results.length)) return;
    stationgetRequest({
      onSuccess: (res) => {
        dispatch({ type: "stations/set", payload: res.data });
      },
    });
  };
  const [tanksgetRequest, tanksgetResponse] = useRequest({
    path: TANK,
    method: "get",
  });
  const urlParams = new URLSearchParams();
  const getTanks = () => {
    tanksgetRequest({
      params: urlParams,
      onSuccess: (res) => {
        dispatch({ type: "tank/set", payload: res.data });
      },
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    validate().then(({ isOk }) => {
      if (!isOk) return;

      const requestBody = filter({
        obj: {
          bill_uuid: controls.bill_uuid,
          station: controls.station,
          tank: controls.tank,
          empty: controls.empty,
          bill_date: controls.bill_date.toISOString(),
          filled: controls.filled,
          price: controls.price,
          paid: controls.paid,
          dokhan: controls.dokhan
        },
        output: "object",
      });

      if (
        Object.keys(
          filter({
            obj: {
              bill_uuid: controls.bill_uuid,
              station: controls.station,
              tank: controls.tank,
              empty: controls.empty,
              filled: controls.filled,
              price: controls.price,
              paid: controls.paid,
              bill_date: controls.bill_date,
              dokhan: controls.dokhan,
            },
          })
        ).length <= 1
      )
        return;

      TankReceiptPostRequest({
        body: requestBody,
        onSuccess: () => {
          // getStocks();
          resetControls();
        },
      }).then((res) => {
        const response = res?.response?.data;

        if (res?.id) {
          dispatch({ type: "tank_ship_receipt/addItem", payload: res });
        } else {
          setInvalid(res?.message);
        }
      });
    });
  };

  return (
    <>
      {/* <Breadcrumbs
        path={[globalLang.TankShipReceipt[lang], globalLang.add[lang]]}
      /> */}
      <Form
        component="form"
        onSubmit={handleSubmit}
        childrenProps={{
          saveBtn: {
            disabled: TankReceiptPostResponse.isPending,
          },
          closeBtn: {
            disabled: TankReceiptPostResponse.isPending,
          },
        }}
      >
        <NumberField
          label={globalLang.receiptnumber[lang]}
          placeholder={globalLang.receiptnumber[lang]}
          onChange={(e) => setControl("bill_uuid", e.target.value)}
          value={controls.bill_uuid}
          required={required.includes("bill_uuid")}
          error={Boolean(invalid.bill_uuid)}
          helperText={invalid.bill_uuid}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={globalLang.billDate[lang]}
            value={controls.bill_date}
            onChange={(newValue) => {
              setControl("bill_date", newValue);
            }}
            renderInput={(params) => (
              <InputField
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    // borderColor: "#F0F0F8",
                    paddingRight: "5px",
                    fontWeight: "400",
                    background: "#fff",
                  },
                
                }}
                required={required.includes("bill_date")}
                error={Boolean(invalid.bill_date)}
                helperText={invalid.bill_date}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <AutocompleteField
            label={globalLang.stations[lang]}
            sx={{
              width: {
                lg: "100%",
                md: "100%",
                xl: "100%",
                sm: "100%",
                xs: "100%",
              },
            }}
            isPending={stationsgetResponse.isPending}
            onOpen={getStations}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, options, reason) => {
              switch (reason) {
                case "selectOption":
                  setfilterdata((old) => ({ ...old, station: options.value }));
                  break;
                case "clear":
                  setfilterdata((old) => ({ ...old, station: "" }));
              }
            }}
            data={[
              ...stationStore?.results.map((c, index) =>
         
                 ({
              
               
                label: c?.user?.name,
                value: c.id,
                
              })
            
            ),
            ]}
            value={filterdata.station}
            // required={required.includes("Exit_Permission_Station_bill")}
          /> 
        <SelectField
          label={globalLang.tank[lang]}
          placeholder={globalLang.tank[lang]}
          renderValue={(selected) => {
            return tanksStore.results.find((tank) => tank.id === selected)
              ?.tank_uuid;
          }}
          isPending={tanksgetResponse.isPending}
          onOpen={getTanks}
          onChange={(e) => {
            setControl("tank", e.target.value);
          }}
          value={controls.tank}
          required={required.includes("tank")}
          error={Boolean(invalid.tank)}
          helperText={invalid.tank}
        >
          {tanksStore?.results?.map((tank, index) => (
            <MenuItem value={tank.id} key={`${tank?.product?.name} ${index}`}>
              {tank?.tank_uuid}
            </MenuItem>
          ))}
        </SelectField>
        <NumberField
          label={globalLang.empty[lang]}
          placeholder={globalLang.empty[lang]}
          onChange={(e) => setControl("empty", e.target.value)}
          value={controls.empty}
          required={required.includes("empty")}
          error={Boolean(invalid.empty)}
          helperText={invalid.empty}
        ></NumberField>
        <NumberField
          label={globalLang.filled[lang]}
          placeholder={globalLang.filled[lang]}
          onChange={(e) => setControl("filled", e.target.value)}
          value={controls.filled}
          required={required.includes("filled")}
          error={Boolean(invalid.filled)}
          helperText={invalid.filled}
        ></NumberField>
        <NumberField
          label={globalLang.price[lang]}
          placeholder={globalLang.price[lang]}
          onChange={(e) => setControl("price", e.target.value)}
          value={controls.price}
          required={required.includes("price")}
          error={Boolean(invalid.price)}
          helperText={invalid.price}
        ></NumberField>
        <NumberField
          label={globalLang.paid[lang]}
          placeholder={globalLang.paid[lang]}
          onChange={(e) => setControl("paid", e.target.value)}
          value={controls.paid}
          required={required.includes("paid")}
          error={Boolean(invalid.paid)}
          helperText={invalid.paid}
        ></NumberField>
        <NumberField
          label={globalLang.tips[lang]}
          placeholder={globalLang.tips[lang]}
          onChange={(e) => setControl("dokhan", e.target.value)}
          value={controls.dokhan}
          required={required.includes("dokhan")}
          error={Boolean(invalid.dokhan)}
          helperText={invalid.dokhan}
        ></NumberField>
      </Form>
      {TankReceiptPostResponse.successAlert}
      {TankReceiptPostResponse.failAlert}
    </>
  );
};

export default AddTankReceipt;

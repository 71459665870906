import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Charging from "../../assets/Charging.png";
import ReceiptBox from "../../components/ReceiptBox";
import Distribution from "../../assets/Distribution.png"
import Filling from "../../assets/Filling.png"
import TankLevel from "../../assets/TankLevel.png"
import Client from "../../assets/client.png"
import Traders from "../../assets/Traders.png"
import CylinderBalance from "../../assets/CylinderBalance.png"
import Station from "../../assets/Station.png"
import report from "../../assets/report.png"
import Expenses from "../../assets/Expenses.png"
import safe from "../../assets/the safe.png"
import { useSelector } from "react-redux";
const MainOverView=()=>{
  const userInfo = useSelector((state) => state.userInfo.value);
  const userPermissions = userInfo?.permissions?.map((perm) => perm);
const boxes = [
  {
    image: safe,
    title: "الخزنة",
    index: 0,
    perm: ["view_factorysafe"],
    type: "overview",
  },
  {
    image: report,
    title: "تقارير",
    index: 1,
    perm: ["view_factoryreport"],
    type: "overview",
  },
  {
    image: Filling,
    title: "تعبئة",
    index: 2,
    perm: ["view_factorytraderbilloverview"],
    type: "overview",
  },
  {
    image: Charging,
    title: "شحن",
    index: 3,
    perm: ["view_factorystationbilloverview"],
    type: "overview",
  },

  {
    image: Distribution,
    title: "توزيع",
    index: 4,
    perm: ["view_factoryclientbilloverview"],
    type: "overview",
  },
  {
    image: Expenses,
    title: "مصروفات",
    index: 5,
    perm: ["view_factoryexpensesoverview"],
    type: "overview",
  },
  {
    image: Client,
    title: "عميل",
    index: 6,
    perm: ["view_factoryclientoverview"],
    type: "overview",
  },
  {
    image: Traders,
    title: "تاجر",
    index: 7,
    perm: ["view_factorytraderoverview"],
    type: "overview",
  },
  {
    image: Station,
    title: "محطة",
    index: 8,
    perm: ["view_factorystationoverview"],
    type: "overview",
  },
  {
    image: CylinderBalance,
    title: "رصيد",
    index: 9,
    perm: ["view_factorystock"],
    type: "overview",
  },
  
  {
    image: TankLevel,
    title: "تانك",
    index:10,
    perm: ["view_factorytankoverview"],
    type: "overview",
  },
  {
    image: TankLevel,
    title: "غازات",
    index:11,
    perm: ["view_productoverview"],
    type: "overview",
  },
];

return (

  <Box>
      <ReceiptBox boxes={boxes.filter((box)=>box.perm.some((perm)=>userPermissions.includes(perm)))} />
  </Box>

);
}
export default MainOverView;